import { withStyles } from "@material-ui/core"
import React, { Component } from "react"
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback"
import { formatPhoneNumber } from 'phone-numbers'

const styles = (theme) => ({
	container: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgb(244, 245, 246, 0.6)",
		color: "white",
		// opacity: '0.6',
		zIndex: "1000",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
	},
	incomingBtn: {
		height: 72,
		width: 354,
		background: `linear-gradient(96.54deg, ${theme.palette.tertiary['0']} 30.3%, ${theme.palette.tertiary['-100']} 72.1%)`,
		//TODO: color 2 not in palette
		// background: "linear-gradient(96.54deg, #2A9BB7 30.3%, #39B0CD 72.1%)",
		display: "flex",
		flexDirection: "row",
		borderRadius: 36,
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		opacity: 1,
		"&:active": {
			//TODO: not in palette
			background: `linear-gradient(96.54deg, ${theme.palette.tertiary['+100']} 30.3%, ${theme.palette.tertiary['0']} 72.1%)`,
			// background: "linear-gradient(95.01deg, #228199 22.61%, #2A97B2 71.4%)",
		},
		cursor: "pointer",
		animation: `$incomingPulsate 3s infinite`,
		margin: 100,
		//TODO: 
		// backgroundColor: 'rgba(72, 185, 213, 0.65)'
	},
	"@keyframes incomingPulsate": {
		"0%": {
			boxShadow: `0 0 0 0 , 0 0 0 0 ${theme.palette.tertiary['0']}`,
		},
		"40%": {
			boxShadow: `0 0 0 15px rgba(72, 185, 213, 0), 0 0 0 0 ${theme.palette.tertiary['0']}`,
		},
		"80%": {
			boxShadow: "0 0 0 15px rgba(72, 185, 213, 0), 0 0 0 10px rgba(72, 185, 213, 0)",
		},
		"100%": {
			boxShadow: "0 0 0 0 rgba(72, 185, 213, 0), 0 0 0 10px rgba(72, 185, 213, 0)",
		},
	},
	dismissBtn: {
		height: 44,
		width: 164,
		background: `linear-gradient(278.18deg, ${theme.palette.attention['-100']} 7.94%, ${theme.palette.attention['0']} 66.26%)`,
		borderRadius: 19,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&:active": {
			background: `linear-gradient(278.18deg, ${theme.palette.attention['0']} 7.94%, ${theme.palette.attention['+100']}  66.26%)`,
		},
		cursor: "pointer",
		// animation: `$dismissPulsate 3s infinite`,
	},
	"@keyframes dismissPulsate": {
		"0%": {
			//TODO: find replacement for rgb opacity
			boxShadow: "0 0 0 0 rgba(255, 108, 92, 0.65), 0 0 0 0 rgba(255, 108, 92, 0.65)",
		},
		"40%": {
			boxShadow: "0 0 0 30px rgba(72, 185, 213, 0), 0 0 0 0 rgba(255, 108, 92, 0.65)",
		},
		"80%": {
			boxShadow: "0 0 0 30px rgba(72, 185, 213, 0), 0 0 0 15px rgba(72, 185, 213, 0)",
		},
		"100%": {
			boxShadow: "0 0 0 0 rgba(72, 185, 213, 0), 0 0 0 15px rgba(72, 185, 213, 0)",
		},
	}
})

class IncomingCallModal extends Component {
	// componentDidUpdate(oldProps) {
	// 	if (this.props.oldestIncomingCall)
	// }

	answerCall = () => {
		const { oldestIncomingCall } = this.props

		if (oldestIncomingCall) {
			this.props.answerById(oldestIncomingCall.callId)
		}
	}

	denyCall = () => {
		const { oldestIncomingCall } = this.props

		if (oldestIncomingCall) {
			this.props.hangupById(oldestIncomingCall.callId)
		}
	}

	render() {
		const { oldestIncomingCall, classes } = this.props
		return (
			<>
				{oldestIncomingCall ? (
					<div className={classes.container}>
						{/* incoming call modal placeholder */}
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<div
								className={classes.incomingBtn}
								onClick={this.answerCall}
								data-test-id={"incoming-call-answer-button"}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}
								>
									<PhoneCallbackIcon style={{ width: 27.2, height: 27.2 }} />
								</div>
								<div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div>{"INCOMING CALL FROM"}</div>
									<div data-test-id="incoming-call-answer-button-info">{this.props.oldestIncomingCall ? formatPhoneNumber(oldestIncomingCall.participants[0].phoneNumber) : 'NO ID'}</div>
								</div>
								<div>
									{this.props.oldestIncomingCall ? "ANSWER" : "HOLD AND ANSWER"}
								</div>
								</div>
							</div>

							<div className={classes.dismissBtn} onClick={this.denyCall} data-test-id={"incoming-call-dismiss-button"}>
								{"DISMISS"}
							</div>
						</div>
					</div>
				) : null}
			</>
		)
	}
}

export default withStyles(styles)(IncomingCallModal)
