import { mainTheme } from 'themes'
import { createMuiTheme } from '@material-ui/core/styles'
import webComLogo from './images/logo-RevenueWellcolor.png'
import webComNavLogo from './images/logo-RevenueWellcolor.png'
import webComLoaderLogo from './images/logo-RevenueWellcolor.png'
import ghostIcon from './images/phone-com-tag.svg'
import './css/FontImport.css'
import React from 'react'
import logoPhoneByWenCom from './images/logo-RevenueWellcolor.png'
// Do NOT add values that are not in the mainTheme. It will cause problems down the line. If something is missing add it to the main object then override it here.
let webComTheme = JSON.parse(JSON.stringify(mainTheme))

let defaultColors = {
	mainBrandColor:	'#85dcff',
	light:			'#FFCC4A',
	xlite:			'#eeCC4A',
	orange:			'#FFCC4A',
	webComBlue:		'#0840a1',
	webComSignupBlue:'#1164f3'
}

webComTheme.name		= 'revenuewell'
webComTheme.defaultRoute = 'calls/'

webComTheme.fontFamily	= 'OpenSans,sans-serif !important'

webComTheme.palette.primary.dark	= '#643c7e';
webComTheme.palette.primary.main    = defaultColors.mainBrandColor;
webComTheme.palette.primary.light   = defaultColors.light;

webComTheme.palette.secondary.main = defaultColors.webComSignupBlue;
webComTheme.palette.secondary.lite	   = '#e7effe'
webComTheme.drawerUserInfo.extButtonHoverColor = defaultColors.light

webComTheme.pdcInput.label.active.color = defaultColors.webComSignupBlue

webComTheme.contactModal.titleColor			= defaultColors.webComSignupBlue
webComTheme.contactModal.addIcon.mainColor	= '#BD2380'
webComTheme.contactModal.addIcon.hoverColor	= '#BD2380'

webComTheme.selector.labelCount.backgroundColor			= '#0840a1'
webComTheme.selector.labelCount.borderColor				= '#0840a1'
webComTheme.selector.labelCount.secondBackgroundColor	= '#964C8C'
webComTheme.selector.labelCount.secondBorderColor		= '#964C8C'

webComTheme.messagesApp.startConversation.textColor = defaultColors.mainBrandColor

webComTheme.icons.ghostIcon = ghostIcon
webComTheme.icons.logo = logoPhoneByWenCom

webComTheme.notificationDotColor = defaultColors.orange

webComTheme.messagesApp.contentItems.outBoundContentItemBackgroundColor = '#CDD8EC'
webComTheme.messagesApp.contentItems.inBoundContentItemBackgroundColor = '#E6E6E6'
webComTheme.drawerUserInfo.replaceWithLogo = true;
webComTheme.drawerUserInfo.replacement = {logo: webComNavLogo}
webComTheme.drawerUserInfo.replacement.backgroundColor = defaultColors.webComBlue
webComTheme.drawerPaper.background = defaultColors.webComBlue;
webComTheme.appBar.backgroundColor = defaultColors.webComBlue

// webComTheme.noFromNumber['& .link'].color = defaultColors.mainBrandColor

webComTheme.navigation.ghostIcon.opacity	= .85
webComTheme.navigation.ghostIcon.padding	= 31
webComTheme.navigation.ghostIcon.content	= `url(${ghostIcon})`

webComTheme.messagesApp.profileImageColorList = ['#66AF84', '#6A8CC6', '#F19D67', '#DF8BB6', '#8F88D3', '#66A6C9']

webComTheme.favicon.default	= '/icons/webcom_favicon.ico'
webComTheme.favicon.unread	= '/icons/webcom_favicon_unread.ico'

webComTheme.appName = 'RW Now'
webComTheme.loginAppName = 'RW Now'

webComTheme.showNoRecordingMessage = false

webComTheme.notificationIconName = 'webComIcon.png'

webComTheme.notificationBar.backgroundColor = defaultColors.orange

webComTheme.spinnerColor = defaultColors.webComBlue

webComTheme.appLoader.backgroundColor				=  defaultColors.webComBlue
webComTheme.appLoader.logo = {
	width:			250,
	height:			50,
	background:		`url(${webComLoaderLogo}) no-repeat`,
	backgroundSize:	'contain'
}
webComTheme.appLoader.progressBar.backgroundColor	= 'black'
webComTheme.appLoader.progressBar.fillColor			= defaultColors.mainBrandColor
webComTheme.appLoader.loadingText.color				= defaultColors.orange
webComTheme.appLoader.loadingText.fontWeight		= 'bold'
webComTheme.appLoader.loadingText.fontSize			= 14
webComTheme.appLoader.loadingText.lineHeight		= 1.29
webComTheme.appLoader.loadingText.letterSpacing		= -0.3
webComTheme.appLoader.loadingText['& .name'].color	= '#cdd8ec'

webComTheme.faxesApp.noSetup = {
	title: 'We will add support for inbound fax shortly! Stay tuned.'
}
webComTheme.faxesApp.noSentFaxMessage		= 'To send a fax, use the Send New Fax option above.'
webComTheme.faxesApp.tabs.defaultTab		= 'sent'
webComTheme.faxesApp.tabs.firstTabName		= 'sent'
webComTheme.faxesApp.tabs.secondTabName		= 'received'
webComTheme.faxesApp.tabs.firstTabColor		= defaultColors.mainBrandColor // webComBlue
webComTheme.faxesApp.tabs.secondTabColor	= defaultColors.mainBrandColor

webComTheme.audioPlayer.controlSlider = {
	defaultColor:		defaultColors.mainBrandColor,
	lightGradientColor:	defaultColors.light,
	darkGradientColor:	defaultColors.mainBrandColor
}

webComTheme.appsTabLink		= 'https://phone.revenuewell.com/download-app/'
webComTheme.supportTabLink	= 'https://phone.revenuewell.com/support/ '

webComTheme.svgIcons.voicemailNavIcon = {main:defaultColors.mainBrandColor, lite: defaultColors.light, xlite:defaultColors.xlite}
webComTheme.svgIcons.removePillBackgroundColor = '#EAD1E0'
webComTheme.svgIcons.circleShadowFe = '0 0 0 0 0.3 0 0 0 0 0 0 0 0 0 0.2 0 0 0 1 0'
webComTheme.dropdownMenu.list.item['&:hover'].color = defaultColors.mainBrandColor;
webComTheme.FirstTimeUserInfoPopupContentOverride = <div style={{ alignText: 'center', color:'white'}}>
	<h1 style={{textAlign: 'center', width:'100%', fontSize: '2em', fontWeight: 'bold'}}> Welcome to RW Now!</h1>
	<span style={{fontSize:	'1.5em'}}>
		Use the web to configure your service.
	</span>
</div>
webComTheme = createMuiTheme(webComTheme);
let revenuewellTheme = webComTheme;
export { revenuewellTheme }
