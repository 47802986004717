import { combineReducers } from 'redux'
import { teammates, currentContactId } from './users'
import { smallView, currentTab, currentView } from './people'

export default combineReducers({
	smallView,
	teammates,
	currentContactId,
	currentTab,
	currentView
})