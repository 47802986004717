import React, { Component } from 'react'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import Prompt from 'pdc-prompt'
import { DefaultArrowTooltip } from 'tooltips'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	audioUploaderWrapper: {
		marginTop:		10,
		border:			'1px solid lightgray',
		borderRadius:	6,
		width:			550,
		'&.error': {
			borderColor: '#EA0303',
			borderWidth: 2
		}
	},
	audioPlayerContainer: {
		width:		'100%',
		padding:	'20px 10px',
		borderTop:	'1px solid lightgray'
	},
	fileSection: {},
	selectButtonWrapper: {
		display:		'flex',
		justifyContent:	'space-between',
		alignItems:		'center'
	},
	uploadFileButton: {
		padding:				13,
		border:					`1px solid ${theme.palette.primary.main}`,
		margin:					0,
		color:					theme.palette.primary.main,
		fontSize:				16,
		cursor:					'pointer',
		borderTopLeftRadius:	6,
		marginLeft:				-1,
		marginTop:				-1,
		marginBottom:			-1,
		borderColor:			'lightgray',
		'&:hover': {
			color:		'white',
			background:	theme.palette.primary.main
		},
		'&.error': {
			borderTopColor:		'#EA0303',
			borderLeftColor:	'#EA0303'
		}
	},
	uploadedFileInfo: {
		padding:	10,
		display:	'flex',
		alignItems:	'center',
		'& .filename': {
			fontWeight: 'bold'
		}
	},
	infoSection: {
		borderTop:	'1px solid lightgray',
		padding:	'8px 10px',
		color:		'gray',
		display:	'flex',
		alignItems:	'center'
	},
	infoIcon: {
		marginRight:	5
	},
	removeButton: {
		cursor:		'pointer',
		marginLeft:	10,
		color:		'#ff000077',
		'&:hover': {
			color:	'#ff0000'
		}
	}
})

class AudioUploader extends Component {

	constructor(props) {
		super(props)
		this.state = {
			uploadedAudio:	props.uploadedAudio,
			readyToPlay:	false,
			maxSizeError:	false
		}
	}

	onAudioUploaded = e => {
		e.persist()
		if (!e.target.value) return
		this.setState({uploadedAudio: null})
		this.props.onDelete()
		let filename		= e.target.value.split('\\').pop()
		let inputElement	= document.getElementById(`${this.props.id}-upload-file`)
		let reader			= new FileReader()
		let maxSize			= this.props.maxSize
		if (maxSize && maxSize < inputElement.files[0].size) {
			return this.setState({maxSizeError: true})
		}
		reader.onload = async () => {
			let result			= reader.result
			let base64Data		= result.split(';')[1].split(',')[1]
			let uploadedAudio	= {download_link: result, filename, base64Data}
			this.setState({uploadedAudio})
			this.props.onUploaded(uploadedAudio)
		}
		reader.readAsDataURL(inputElement.files[0])
		this.setState({readyToPlay: false})
	}

	renderAudioPlayer = () => {
		const { classes } = this.props
		if (this.props.hidePlayer) return null
		return <div className={classes.audioPlayerContainer}>
			<div style={{display: this.state.readyToPlay ? 'block' : 'none'}}>
				<AudioPlayer
					key				= {this.state.uploadedAudio.voip_recording_id}
					url				= {this.state.uploadedAudio.download_link}
					onPlay			= {() => {}}
					onReadyToPlay	= {() => this.setState({readyToPlay: true})}
				/>
			</div>
			{!this.state.readyToPlay ? <LoadingBar/> : null}
		</div>
	}

	removeUploadedAudio = () => {
		this.setState({uploadedAudio: null, maxSizeError: false})
		document.getElementById(`${this.props.id}-upload-file`).value = ''
		this.props.onDelete()
	}

	renderInfoSection = () => {
		const { classes } = this.props
		return (
			<div className={classes.infoSection}>
				<InfoOutlinedIcon classes={{root: classes.infoIcon}}/>
				<span>
					Be sure you have permission from the rightful owner before uploading copyrighted audio. 
					See our <a href='https://www.phone.com/customer-terms/' target='_blank'>Terms of Service</a> for more info.
				</span>
			</div>
		)
	}

	renderErrorPrompt = () => {
		let { maxSize } = this.props
		let maxSizeFormatted = ''
		if (maxSize < 1024) maxSizeFormatted = `${maxSize}B`
		else if (maxSize < (1024 * 1024)) maxSizeFormatted = `${maxSize / 1024}KB`
		else if (maxSize < (1024 * 1024 * 1024)) maxSizeFormatted = `${maxSize / (1024 * 1024)}MB`
		else maxSizeFormatted = `${maxSize / (1024 * 1024 * 1024)}GB`
		return (
			<Prompt
				isOpen		= {this.state.maxSizeError}
				onClose		= {this.removeUploadedAudio}
				color		= 'attention'
				content		= {`The max audio size allowed is ${maxSizeFormatted}`}
				position	= 'bottom'
			/>
		)
	}

	render() {
		const { classes }	= this.props
		let filename		= ''
		let uploadedAudio	= this.state.uploadedAudio
		if (uploadedAudio) {
			filename = uploadedAudio.filename
			if (!filename && uploadedAudio.voip_recording)
				filename = uploadedAudio.voip_recording.filename
		}
		let errorClass = this.props.hasError ? 'error' : ''
		return (
			<div className={`${classes.audioUploaderWrapper} ${errorClass}`}>
				<div className={classes.fileSection}>
					<div className={classes.selectButtonWrapper}>
						<DefaultArrowTooltip
							title		= {this.state.uploadedAudio ? 'The current one will be replaced' : ''}
							placement	= 'right'
						>
							<label className={`${classes.uploadFileButton} ${errorClass}`} htmlFor={`${this.props.id}-upload-file`}>UPLOAD A FILE</label>
						</DefaultArrowTooltip>
						<input
							type		= 'file'
							id			= {`${this.props.id}-upload-file`}
							style		= {{display: 'none'}}
							accept		= '.mp3,audio/*'
							onChange	= {this.onAudioUploaded}
						/>
						<div className={classes.uploadedFileInfo}>
							<span className='filename'>{filename}</span>
							{this.props.onDelete && this.state.uploadedAudio ?
								<DefaultArrowTooltip
									title		= 'Remove'
									placement	= 'right'
								>
									<div className={classes.removeButton} onClick={this.removeUploadedAudio}><CloseIcon/></div>
								</DefaultArrowTooltip>
							: null}
						</div>
					</div>
					{this.state.uploadedAudio ? this.renderAudioPlayer() : null}
				</div>
				{this.renderInfoSection()}
				{this.renderErrorPrompt()}
			</div>
		)
	}
}

export default withStyles(styles)(AudioUploader)