import { formatPhoneNumber } from 'phone-numbers'
import 'whatwg-fetch';
import { CallManagerEvents } from '../../../../pages/communicator-app/src/enums/CallManagerEvents'
import { CallState } from "../../../../pages/communicator-app/src/enums/CallState"
import PDCButton from 'pdc-button'
import MenuItem from '@material-ui/core/es/MenuItem/MenuItem'
import Select from '@material-ui/core/es/Select/Select'
import React, { Component } from 'react'
import Menu from 'pdc-menu';
import { Grid } from '@material-ui/core';
import { pushNotification } from 'notification-pusher'
import { logout } from 'phonecom';

let postData = async (url, data = {}) => {
    let token = window.V5PHONECOM.cp_token;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `CP ${token}`
        },
        body: JSON.stringify(data)
    });
    let res = await response.json();
    return res
}

class Salesforce {

    static enabled = false;

    static accountConfig = null;
    static extensionConfig = null;

    static communicator = null;

    static call_states = {}

    static init = async (communicator) => {

        this.accountConfig = await this.getAccountConfig()

        
        /*let instanceUrl = window.parent.location ? window.parent.location.origin : null;

        // Logged into the incorrect Salesforce Org
        if (instanceUrl)
            console.log(`Comparing ${instanceUrl.split('.')[0]} to ${this.accountConfig.instance_url.split('.')[0]}`)
        if (instanceUrl && instanceUrl.split('.')[0] !== this.accountConfig.instance_url.split('.')[0])
           await logout();
        */
        if(this.accountConfig.enabled)
            this.enabled = true
        else
            return

        this.extensionConfig = await this.getExtensionConfig(window.V5PHONECOM.voip_phone_id)

        this.communicator = communicator
        this.setCallEventListeners()

        this.enableClickToDial()
        window.sforce.opencti.onClickToDial({listener: this.onClickToDial});
        window.sforce.opencti.onNavigationChange({listener: this.updateWhoWhat});
    }
    
    static isEnabled = () => {
        return this.enabled
    }

    static getCustomDispositions = () => {
         if(this.accountConfig.custom_dispositions)
            return this.accountConfig.custom_dispositions
        return ['Sales', 'Support', 'Other']
    }

    static updateRecord = async (body) => {
        let url = 'https://integrations.phone.com/crm/salesforce/update-disposition'
        let res = await postData(url, body)
        if(res.message) return res.message
        return res
    }
   
    static createRecord = async (body) => {
        let url = 'https://integrations.phone.com/crm/salesforce/create-disposition'
        let res = await postData(url, body)
        if(res.message) return res.message
        return res
    }

    static onClickToDial = (result) => {
        this.openSoftphonePanel();
        let num = result.number.replace('/[\D]/g', '');
        if(this.accountConfig.one_click){
            this.communicator.props.call(num)            
            this.communicator.goTo('calls', {view: 'dialer'})
        } else {
            this.communicator.goTo('calls', {view: 'make_call', callee: num})
        }
    }

    static setCallEventListeners(){
        let manager = window.pdcCall

        manager.on(CallManagerEvents.CALL_CREATED, async (session) => {
            console.log('call created', session)
            // Search SF for contact information
            this.onCallCreated(session)
            // Set this call to active call, open softphone, open dialer
            manager.switchCall(session.callId)
            this.openSoftphonePanel()
            this.communicator.goTo('calls', {view: 'dialer'})
        })

        manager.on(CallManagerEvents.CALL_ANSWERED, async (event) => {
            console.log('call answered', event)
            this.searchAndScreenPop(event)
            let fields = {
                call_id: event.call_id,
                voip_id: this.accountConfig.voip_id,
                voip_phone_id: this.extensionConfig.voip_phone_id,
                saveAs: this.accountConfig.activities,
                assignedTo: this.extensionConfig.sf_user_id,
                external_number: formatPhoneNumber(`${event.direction === 'in' ? event.from_did : event.to_did}`),
                direction: event.direction,
                pdc_number: formatPhoneNumber(`${event.direction === 'in' ? event.to_did : event.from_did}`),
                extension: event.extension
            }
            let result = await this.createRecord(fields)
            if(result !== 'success') pushNotification('Salesforce', 'Falied to create Salesforce Record.')
        })
    }

    static onCallCreated = (session) => { 
        let num = formatPhoneNumber(session.participants[0].phoneNumber)
        let callType = window.sforce.opencti.CALL_TYPE.OUTBOUND
        if(!/^[a-zA-Z]+$/.test(num)){
            window.sforce.opencti.searchAndScreenPop({
                searchParams: num,
                callType: callType,
                deferred: true,
                callback: (result) => {
                    let state = this.call_states[session.callId]
                    console.log('search contact', result, session, state)
                    if (result.returnValue) {
                        let rv = result.returnValue;
                        for(let recordId in rv) {
                            let record = {
                                recordId:recordId,
                                recordName:rv[recordId].Name, 
                                objectType:rv[recordId].RecordType
                            }
                            if(record.objectType == 'Contact' || record.objectType == 'Lead'){
                                // Update display Name
                                session.participants[0].callerId = record.recordName
                                console.log('found contact', record, session)
                            }
                            this.updateWhoWhat(record)
                        }
                    }
                }
            })
        }
    }

    static updateWhoWhat = (record) => {
        let manager = window.pdcCall
        let call = this.call_states[manager.activeCallId]
        console.log('updateWhoWhat', record, call)
        if(call && record !== null && typeof record.objectType !== 'undefined'){
            if(Salesforce.accountConfig.activities === 'task'){
                let validWhoIds = ['Contact', 'Lead']
                let validWhatIds = ['Contract','Order','Campaign','Account','Opportunity','Product','Asset','Case']
                if(validWhoIds.indexOf(record.objectType) >= 0){
                    if(call.whoId === null) call.whoId = record
                    this.updateRecents(call.recentWhoId, record)
                }
                else if(validWhatIds.indexOf(record.objectType) >= 0){
                    if(call.whatId === null) call.whatId = record
                    this.updateRecents(call.recentWhatId, record)
                }
            }
            else if(Salesforce.accountConfig.activities === 'case'){
                if(record.objectType === 'Contact'){
                    if(call.whoId === null) call.whoId = record
                    this.updateRecents(call.recentWhoId, record)
                }
                else if(record.objectType === 'Account'){
                    if(call.whatId === null) call.whatId = record
                    this.updateRecents(call.recentWhatId, record)
                }
            }
            SalesforcePop.update()
        }
    }

    static searchAndScreenPop = (event) => {
        let num = (`${event.direction === 'in' ? event.from_did : event.to_did}`)
        let fmt_num = formatPhoneNumber(num)
		let callType = event.direction === 'in' ? window.sforce.opencti.CALL_TYPE.INBOUND : window.sforce.opencti.CALL_TYPE.OUTBOUND;
        let fname = ''
		let lname = ''
        if(event.caller_id && event.caller_id !== num){
            let res = event.caller_id.split(' ')
            if(res.length > 1){
                fname = res[0]
                lname = res[1]
            } else {
                lname = res[0]
            }
        }

        if(!/^[a-zA-Z]+$/.test(fmt_num)) 
            window.sforce.opencti.searchAndScreenPop({
                searchParams: fmt_num,
                callType: callType,
                defaultFieldValues: {
                    FirstName: fname,
                    LastName: lname,
                    Phone: fmt_num,
                }
            })
    }

    static updateRecents = (recents, record) => {
        for(let i=0; i<recents.length; i++)
            if(recents[i].recordId == record.recordId)
                    return
        recents.push(record)
    }

    static enableClickToDial = () => {
        var clickToDialCallback = function(response) {
            if (response.success) {
            console.log('API method call executed successfully! returnValue:', response.returnValue);
            } else {
            console.error('Something went wrong! Errors:', response.errors);
            }
        };
        window.sforce.opencti.enableClickToDial({callback: clickToDialCallback});
    }

    static getExtensionConfig = async (ext) => {
        let url = 'https://integrations.phone.com/crm/salesforce/config/get-extension-user'
        let body = {
            voip_phone_id: ext
        }
        let res = await postData(url, body)
        if(res.message) return res.message
        return res
    }
    
    static getAccountConfig = async () => {
        let url = 'https://integrations.phone.com/crm/salesforce/config/get-account-config'
        let res = await postData(url)
        if(res.message) return res.message.voip_config
        return res
    }

    static openSoftphonePanel = () => {
        var isVisibleCallback = function(response) {
            if (response.returnValue && response.returnValue.visible !== true) {
                window.sforce.opencti.setSoftphonePanelVisibility({ visible: true });
            }
        }
        window.sforce.opencti.isSoftphonePanelVisible({callback: isVisibleCallback});
    }

    static contactcs = new Map()
    static getContactName = (number) => {
        let num = formatPhoneNumber(number)
        if(this.contactcs.has(num)) return this.contactcs.get(num)
        let callType = window.sforce.opencti.CALL_TYPE.OUTBOUND
        window.sforce.opencti.searchAndScreenPop({
            searchParams: num,
            callType: callType,
            deferred: true,
            callback: (result) => {
                if (result.returnValue) {
                    let rv = result.returnValue;
                    for(let recordId in rv) {
                        if(rv[recordId].RecordType == 'Contact' || rv[recordId].RecordType == 'Lead'){
                            // Update contact Name
                            this.contactcs.set(num, rv[recordId].Name)
                            return
                        }
                    }
                }
            }
        })
        return null
    }
}

class SalesforcePop extends Component {

    static instance = null;
    static update = () => {
        if(SalesforcePop.instance) SalesforcePop.instance.setState({})
    }

    constructor(props){
        super(props)
        if(this.newCall(props.call_id)){
            Salesforce.call_states[props.call_id] = {
                notes: '',
                disposition: 'none',
                whoId: null,
                whatId: null,
                callEnded: props.status === CallState.POSTCALL,
                recentWhoId: [],
                recentWhatId: []
            }
        }
        SalesforcePop.instance = this;
    }

    newCall = (call_id) => {
        if(Salesforce.call_states[call_id])
            return false
        return true
    }

    componentDidMount(){
        if(this.newCall(this.props.call_id)){
            Salesforce.call_states[this.props.call_id] = {
                notes: '',
                disposition: 'none',
                whoId: null,
                whatId: null,
                callEnded: this.props.status === CallState.POSTCALL,
                recentWhoId: [],
                recentWhatId: []
            }
        }
    }

    componentDidUpdate(){
        if(this.props.call_id == null){
            return
        }
        if(this.newCall(this.props.call_id)){
            Salesforce.call_states[this.props.call_id] = {
                notes: '',
                disposition: 'none',
                whoId: null,
                whatId: null,
                callEnded: this.props.status === CallState.POSTCALL,
                recentWhoId: [],
                recentWhatId: []
            }
        }
        //Check if call ended
        let call = Salesforce.call_states[this.props.call_id]
        if(!call.callEnded && this.props.status === CallState.POSTCALL){
            call.callEnded = true
            this.setState({})
        }
    }


    handleNotesChange = (notes) => {
        Salesforce.call_states[this.props.call_id].notes = notes.target.value
        this.setState({})
    }

    handleDispositionChange = (disposition) => {
       Salesforce.call_states[this.props.call_id].disposition = disposition.value
       this.setState({})
    }

    handleWhoIdChange = (record) => {
        Salesforce.call_states[this.props.call_id].whoId = record.whoId
        this.setState({})
    }

     handleWhatIdChange = (record) => {
        Salesforce.call_states[this.props.call_id].whatId = record.whatId
        this.setState({})
    }

    saveDisposition = async () => {
        let call = Salesforce.call_states[this.props.call_id];
        let fields = {
            call_id: this.props.call_id,
            voip_phone_id: Salesforce.extensionConfig.voip_phone_id,
            assignedTo: Salesforce.extensionConfig.sf_user_id,
            notes: call.notes,
            disposition: call.disposition === 'none' ? null : call.disposition,
            whoId: call.whoId ? call.whoId.recordId : null,
            whatId: call.whatId ? call.whatId.recordId : null
        }
        this.props.hangup(this.props.call_id)
        let result = await Salesforce.updateRecord(fields)
        if(result !== 'success') pushNotification('Salesforce', 'Falied to create Salesforce Record.')
    }

    saveDisabled = () => {
        let call = Salesforce.call_states[this.props.call_id]
        if (!call.callEnded || !Salesforce.accountConfig.save_no_disposition && (call.disposition === 'none' || call.disposition === null))
            return true
        return false
    }


    renderNotes = () => {
        let call = Salesforce.call_states[this.props.call_id];
        if(!this.props.screenViewType.isMobileView){
            return (
            <div style={{justifyContent:'center'}}>
                <div style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    paddingTop: '4px'
                }}>Call Notes</div>
                <textarea onChange={(e) => this.handleNotesChange(e)} style={{overflow: 'auto', margin: '0px', height: '100px', width: '80%', borderRadius: '10px', padding: '5px', fontSize: '16px'}} value={call.notes}></textarea>
            </div>
            )
        }
        return (
            <div>
                <div style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    paddingTop: '4px'
                }}>Call Notes</div>
                <textarea onChange={(e) => this.handleNotesChange(e)} style={{overflow: 'auto', margin: '0px', height: '60px', width: '100%', borderRadius: '10px', padding: '5px', fontSize: '16px'}} value={call.notes}></textarea>
            </div>
        )
    }

    renderSaveInfo = () => {
        let call = Salesforce.call_states[this.props.call_id];
        // Disposition Menu
        let dispositions = Salesforce.getCustomDispositions().map(type => {
            return {value: type, content: type}
        })
        dispositions.push({value: 'none', content: 'No Disposition'})
        let default_disposition = call.disposition === 'none' ? 'none' : call.disposition
        // WhoId Menu
        let recentWhoId = call.recentWhoId.map(record => {
            return { value: record.recordId, content: record.recordName, whoId: record }
        })
        recentWhoId.push({value: 'none', content: 'No Contact', whoId: null})
        let default_whoId = call.whoId === null ? 'none' : call.whoId.recordId
        // WhatId Menu
        let recentWhatId = call.recentWhatId.map(record => {
            return { value: record.recordId, content: record.recordName, whatId: record }
        })
        recentWhatId.push({value: 'none', content: 'No Related To', whatId: null})
        let default_whatId = call.whatId === null ? 'none' : call.whatId.recordId
        console.log('render save info', this.props.call_id, call, default_disposition)
        if(!this.props.screenViewType.isMobileView){
            return (
                <div>
                    <div style={{display: 'flex'}}>
                        <span style={{flex:'50%', padding:'4px 10px 0px 10px'}}>
                            <div style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '12px'
                            }}>Disposition</div>
                            <Menu
                                items={dispositions}
                                variant='single'
                                controlable
                                default={default_disposition}
                                onChange={this.handleDispositionChange}
                            />
                        </span>
                        <span style={{flex:'50%', padding:'4px 10px 0px 10px'}}>
                            <div style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '12px',
                            }}>Contact</div>
                            <Menu
                                items={recentWhoId}
                                variant='single'
                                controlable
                                default={default_whoId}
                                onChange={this.handleWhoIdChange}
                            />
                            <div style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                paddingTop: '4px'
                            }}>Related To</div>
                            <Menu
                                items={recentWhatId}
                                variant='single'
                                controlable
                                default={default_whatId}
                                onChange={this.handleWhatIdChange}
                            />
                            {/* <div style={{fontSize: '12px'}}>(Navigate Salesforce to link this call to objects.)</div> */}
                        </span>
                    </div>
                </div>
            )  
        }
        return (
            <div style={{paddingTop:'4px', paddingLeft:'4px'}}>
                <div style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: '12px'
                }}>Disposition</div>
                <Menu
                    items={dispositions}
                    variant='single'
                    controlable
                    default={default_disposition}
                    onChange={this.handleDispositionChange}
                />
                <div style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    paddingTop: '4px'
                }}>Contact</div>
                <Menu
                    items={recentWhoId}
                    variant='single'
                    controlable
                    default={default_whoId}
                    onChange={this.handleWhoIdChange}
                />
                <div style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    paddingTop: '4px'
                }}>Related To</div>
                <Menu
                    items={recentWhatId}
                    variant='single'
                    controlable
                    default={default_whatId}
                    onChange={this.handleWhatIdChange}
                />
                {/* <div style={{fontSize: '12px'}}>(Navigate Salesforce to link this call to objects.)</div> */}
            </div>
        )
    }

    renderSaveButton = () => {
        let call = Salesforce.call_states[this.props.call_id]
        let disabled = false;
        if (!call.callEnded || !Salesforce.accountConfig.save_no_disposition && (call.disposition === 'none' || call.disposition === null))
            disabled = true;
        return (
            <div style={{paddingTop: '10px'}}>
                <PDCButton onClick={this.saveDisposition} disabled={disabled}>Save Call</PDCButton>
                {(call.callEnded && disabled && !Salesforce.accountConfig.save_no_disposition) &&
                    <div style={{fontSize: '12px'}}>
                        Your organization requires setting a disposition.
                    </div>
                }
            </div>
        )
    }

    render = () => {
        if(Salesforce.isEnabled()){
            let call = Salesforce.call_states[this.props.call_id];
            if(call) return(
            <div style={{textAlign: 'center', height: '100%', width: '95%', margin: 'auto'}}>
            {
                <div>
                        {this.renderSaveInfo()}
                        {this.renderNotes()}
                        <div>
                            {this.renderSaveButton()}
                        </div>
                </div>
            }
            </div>)
        }
        return null
    }
}

export {Salesforce, SalesforcePop}