import Notifier from 'react-desktop-notification'
import notificationSound from './sounds/notifcation-sound.mp3'
import { theme } from 'get-theme'
import { isElectron, sendElectronNotification, addElectronEventListener } from 'pdc-electron-utils'
import Api from 'api'
import {addSWEventListener, isServiceWorkerSupported} from 'service-worker-utils'

const audio = new Audio(notificationSound);
const localStorageMap = {
    calls: 'dninccls',
    voicemails: 'dnmsclsvms',
    messages: 'dnmsgs',
    faxes: 'dnfxs'
}
let notificationActionCallbacks = {};

const pushNotification = (title = null, body = null, url = null, icon = null, windowName = null, actions = [], extraData = {}) => {
    console.log('pushing notificaion')
    
    if (!window.Notification || !window.Notification.requestPermission) {
        return false;
    }

    title = title || 'My Phone.com';
    body = body || 'new notification';
    icon = icon || `/icons/${theme.notificationIconName}`
    let badge = `/icons/${theme.notificationBadgeIconName}`
    url = url || 'https://my.phone.com';
    windowName = window.name || 'My Phone.com';
    window.name = windowName;


    if (window.V5PHONECOM.features.has('web_notification_sound')) {
        audio.play().then(() => {
        }).catch((e => {
            console.log('notification audio failed to played')
        }))
    }
    // try newer notifcation api first, as older one has been removed by some browsers, 
    // but newer one is not supported on all browsers yet
    if (isElectron) {
        console.log('pushing electron notification',{extraData})
        const notificationType = extraData.notificationType || 'notification'
        return sendElectronNotification(notificationType, { title, body, icon, extraData })
    } else{
        __new_notify_pusher(title, body, url, icon, badge, actions, extraData).catch((e)=>{
        console.warn('Browser does not support service worker push causing error, falling back to older notifcation API')
        console.warn(e)
            try {
                console.log('pushing deprecated notification')
                return Notifier.focus(title, body, url, icon)
            } catch (e) {
                console.warn('Browser does not support old notification push api causing error, no notifications sent')
                console.error(e)
            }
        })
    }
};

const __new_notify_pusher = (title = null, body = null, url = null, icon = null, badge = null, actions = [], extraData = {}) => {
    return new Promise(function(resolve, reject) {

    if (isServiceWorkerSupported) {
            console.log('pushing service worker notification')
            console.log({
                body: body,
                icon: icon,
                badge: badge,
                vibrate: [200, 100, 200, 100, 200, 100, 200],
                data: {
                    clientId: window.clientId,
                    extraData: extraData,
                    //todo add custom data 
                },
                actions: actions
            })
            window.navigator.serviceWorker.ready.then(function (registration) {
                registration.showNotification(title, {
                    body: body,
                    icon: icon,
                    badge: badge,
                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                    data: {
                        clientId: window.clientId,
                        extraData: extraData,
                        //todo add custom data 
                    },
                    actions: actions
                });
                resolve('success');

            }).catch((e)=>{
                reject(e)

            });
        } else {
        reject('not supported')
    }
    }); 
}


const pushVoicemailNotification = (voicemail = null, extensionId = null) => {
    if (localStorage[localStorageMap.voicemails] === 'false') return

    let voicemailId = voicemail.id
    let url = `https://${window.document.location.host}/e${extensionId}/voicemail/v${voicemailId}`
    let name = 'n/a'
    if (voicemail.from) {
        name = voicemail.from.name || voicemail.from.number
    }
    let title = `New voicemail from: ${name}`
    pushNotification(title, voicemail.transcript || ' ', url, null, 'My Phone.com')
}

const pushFaxNotification = (fax = null, extensionId = null) => {
    if (localStorage[localStorageMap.faxes] === 'false') return

    let faxId = fax.id
    let url = `https://${window.document.location.host}/e${extensionId}/faxes/f${faxId}`
    let name = 'n/a'
    if (fax.from) {
        name = fax.from.name || fax.from.number
    }
    let title = `New fax from: ${name}`
    pushNotification(title, `${fax.pages} pages`, url, null, 'My Phone.com')
}

const pushMessageNotification = (message = null, extensionId = null) => {

    if (localStorage[localStorageMap.messages] === 'false') return
    console.log('message')

    console.log(message)
    if (message.direction === 'in') {
        // Re do when we add web push
        let conv_id = message.conversation_id
        let url = `https://${window.document.location.hostname}/e${extensionId}/c${conv_id}`
        let title = 'New notification from: ' + message.from
        let actions = []

        actions.push({
            "action": "reply",
            "type": "text",
            "title": "Reply",
            "placeholder": "Enter reply ... "
        })
        __setNotificationActionCallBack('reply', __replyMessageAction)
        pushNotification(title, message.text, url, null, 'My Phone.com', actions, {
            message,
            hasReply: true,
            notificationType: "messageReply"
        })
    }

}

const pushCallNotification = (displayName, phoneNumber, answerAction = null, hangupAction = null) => {

    if (localStorage[localStorageMap.calls] === 'false') return
    let actions = []
    if (answerAction) {
        actions.push({ action: 'answerCall', title: 'Answer' })
        let answer = ()=>{
            pushNotification('Connecting to call', 'Takes up to 5 seconds.')
            answerAction()
        }
        __setNotificationActionCallBack('answerCall', answer)
    }

    if (hangupAction) {
        actions.push({ action: 'hangupCall', title: 'Hangup' })
        __setNotificationActionCallBack('hangupCall', hangupAction)
    }

    if (!displayName && !phoneNumber) {
        pushNotification('Incoming Call', `click to answer`, null, null, 'My Phone.com', actions,{
            notificationType: "incomingCall"
        })
    } else {
        pushNotification(`Incoming Call from ${displayName}`, `${phoneNumber}\nclick here to answer`, null, null, 'My Phone.com', actions,
        {
            notificationType: "incomingCall"
        })
    }
}

/**
 * sets the call backs for the notification actions
 * @param action string
 * @param callback function
 * @private
 */
const __setNotificationActionCallBack = (action, callback) => {
    notificationActionCallbacks[action] = callback
}

const __replyMessageAction = (data) => {
    console.log('data')
    let extraData = data.extraData
    console.log(data)
    if(extraData.message){ // todo align electron and the service worker return data
        extraData.message.reply = extraData.reply
        extraData = extraData.message
    }
    let payload = {
        to: extraData.from,
        from: extraData.to[0].number,
        text: extraData.reply,
        tag: Math.floor(Math.random() * 10000)

    }
    Api.sendMessage(payload)
}

addSWEventListener('message', event => {
    console.log(event);
    if (event.data && event.data.type && event.data.type == 'notificationAction' && event.data.clientId == window.clientId && event.data.action && event.data.action in notificationActionCallbacks) {
        console.log('answer event');
        notificationActionCallbacks[event.data.action](event.data)
    }
})

addElectronEventListener('electron-notification-action', (event, payload) => {
    console.log(event)
    console.log(payload)
    if (payload.action && payload.action in notificationActionCallbacks) {
        notificationActionCallbacks[payload.action](payload.data)
    }
})


export { pushNotification, pushVoicemailNotification, pushFaxNotification, pushMessageNotification, pushCallNotification }