import React from 'react'
import { avatarSingleSvg } from 'pdc-icons'
import { makeStyles } from '@material-ui/core/styles'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles(theme => ({
	container: {
		position: 'relative'
	},
	avatarWrapper: {
		// border:			'0.5px solid #8FD981',
		// borderRadius:	'50%',
		// padding:		2,
		// background:		'transparent'
	},
	imageWrapper: {
		border:			'5px solid #75D063',
		borderRadius:	'50%',
		'&.noBorder': {
			border:		'5px solid white',

		}
	},
	image: {
		borderRadius:	'50%',
		height:			55,
		width:			55
	},
	textWrapper: {
		position:		'absolute',
		display:		'flex',
		bottom:			-3,
		right:			-3,
		width:			38,
		height:			18,
		borderRadius:	4,
		'& .text': {
			borderRadius:		4,
			padding:			'6px 4px',
			color:				'white',
			fontSize:			12,
			font:				'NTR, Montserrat, Helvetica, arial, sans-serif !important',
			backgroundColor:	'#3FAE29',
			textAlign:			'center',
			letterSpacing:		0.2,
			lineHeight:			'8px',
		}
	},
	editWrapper: {
		position:		'absolute',
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center',
		bottom:			-3,
		right:			-3,
		width:			50,
		height:			50,
		borderRadius:	'50%',
		boxShadow:		'2px 3px 4px rgba(0, 0, 0, 0.25)',
		background:		'black',
		color:			'white',
		cursor:			'pointer'
	},
	cameraIcon: {
		width:	26,
		height:	26
	}
}))

const AvatarIcon = props => {
	const classes = useStyles()

	let type = props.type || 'available'
	let isUnavailable = type === 'unavailable'

	let imageUrl = props.imageUrl

	let opacity		= props.opacity || 1
	let height		= props.height || 55
	let width		= props.width || 55
	let imageStyle	= {opacity, height, width, minWidth: width, minHeight: height, maxWidth: width, maxHeight: height}
	if (!imageUrl) imageStyle.backgroundColor = 'gray'

	let style = props.style || {}

	const hiddenFileInput = React.useRef(null);
  
	const handleClick = () => {
		hiddenFileInput.current.click();
	};

	return (
		<div className={classes.container} style={style}>
			<div className={classes.avatarWrapper} style={isUnavailable ? {borderColor: '#E87D90'} : {}}>
				<div className={`${classes.imageWrapper} ${props.noBorder ? 'noBorder': ''}`} style={isUnavailable ? {borderColor: '#DC3452'} : {}}>
						<img
							src			= {imageUrl || avatarSingleSvg}
							style		= {imageStyle}
							className	= {classes.image}
							alt			= 'Avatar'
						/>
				</div>
			</div>
			{props.text ?
				<div className={classes.textWrapper}>
					<span className='text'>{props.text}</span>
				</div>
			: null}

			{props.edittable ?
				<div className={classes.editWrapper} onClick={() => handleClick()}>
						<PhotoCameraIcon classes={{root:classes.cameraIcon}}/>
						<input type="file"
							ref={hiddenFileInput}
							onChange={(e) => props.selectImage(e)}
							style={{display:'none'}} 
							accept="image/*"
						/> 
				</div>
			: null}
		</div>
	)
}

export default AvatarIcon