

/**
 * gtmDataPush pushes to event to GA and FullStory for analytics reports
 *
 * @param {json} data The event.
 *    @param {String} data.PDC_Action the events action ex: 'message-sent'
 *    @param {String} data.PDC_Label the events label ex: PDC_Label:	'type: ' + eventType
 * @public
 */
export default function gtmDataPush(data) {
	if (!window.dataLayer) return

	if(window.V5PHONECOM) {
		if (!data.event) {
			data['event'] = 'PDC_my_phone_event'
		}

		let PDC_Label = ''
		if (data.PDC_Label) {
			PDC_Label = data.PDC_Label + ', '
		}
		data['PDC_Label'] = `${PDC_Label}features: (${Array.from(window.V5PHONECOM.features).join(';')})`
    }
	window.dataLayer.push(data)

	if (!window.FS) return

	let event = data.event || 'event'
	window.FS(event, data)
	console.log(data)

}