import React, { Component } from 'react'
import SearchModal from './SearchModal'
import Api from 'api'

class SearchInbox extends Component {

	state = {
		searchResults: [],
		totalSearchResults: 0,
		queryTime: 0,
		searchLoading: false,
	}

	onClose = () => {
		this.setState({searchResults: [], totalSearchResults: {value: 0}, queryTime: 0})
		this.props.onClose()
	}

	onSearch = async (filters, type, loadMore=false) => {
		let queryTime	= 0
		let startTime	= Date.now()

		let searchResults = this.state.searchResults
		let cursor = null
		if (searchResults && searchResults.length && loadMore) cursor = searchResults[searchResults.length - 1].cursor
		if (!loadMore) this.setState({searchResults: [], totalSearchResults: {value: 0}, queryTime: 0, searchLoading: true })


		let response = await Api.listInboxes(filters, type, cursor)
 
		if (loadMore) searchResults = searchResults.concat(response.items)
		else searchResults = response.items

		let totalSearchResults = this.state.totalSearchResults
		if (loadMore) totalSearchResults += response.total
		else totalSearchResults = response.total
		if (response.total === 0) totalSearchResults = searchResults.length

		queryTime = (Date.now() - startTime) / 1000
		this.setState({searchResults, totalSearchResults, queryTime, searchLoading: false})
	}

	render() {
		return (
			<SearchModal
				isOpen					= {this.props.isOpen}
				searchLoading			= {this.state.searchLoading}
				searchResults			= {this.state.searchResults}
				totalSearchResults		= {this.state.totalSearchResults}
				queryTime				= {this.state.queryTime}
				location				= {this.props.location}
				onClose					= {this.onClose}
				onSearch				= {this.onSearch}
				goToConversation		= {this.props.goToConversation}
				isMobile				= {this.props.isMobile}
			/>
		)
	}
}

export default SearchInbox