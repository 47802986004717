import React, { Component } from 'react'
import styles from './styles'
import LoaderFull from 'loader-full'
import { DefaultArrowTooltip } from 'tooltips'
import { formatPhoneNumber, convertNumberToE164 } from 'phone-numbers'
import { AsYouType } from 'libphonenumber-js'
import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import Button from 'button'
import { theme } from 'get-theme'

import Api from 'api'
import { getEmptyContactObject } from './ContactUtil'
import ChooseANumberModal from './ChooseANumberModal'
import ConfirmModal from 'confirm-modal'
import {
	ContactAvatarIcon,
	ContactAvatarCamIcon,
	XCircleOutlinedIcon,
	PlusCircleOutlinedIcon,
	ExpandTriangle2,
	CallCircleFilledIcon,
	MessageCircleFilledIcon
} from 'pdc-svg-icons'

import TextField from 'pdc-text-field'
import Menu from 'pdc-menu'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core'

export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const MAX_LENGTHS = {
	firstName:		30,
	lastName:		30,
	middleName:		30,
	nickname:		30,
	displayName:	40,
	organization:	60,
	jobTitle:		60,
	notes:			60,
	address:		60,
	email:			60
}

class ContactEditor extends Component {

	constructor(props) {
		super(props)
		this.state = Object.assign({
			loading:				false,
			homeHidden:				true,
			businessHidden:			true,
			moreFieldsHidden:		true,
			showDeleteModal:		false,
			showAddTypeModal:		false,
			newType:				'',
			numberTypes:			['business', 'home', 'mobile', 'fax', 'custom'],
			contactGroupTypes:		[],
			chooseANumberModalType:	null,
			showValidationErrors:	false
		}, this._getEmptyContactObject())
	}

	componentDidMount() {
		this.init()
	}

	componentDidUpdate(prevProps) {

		if (Boolean(prevProps.editMode) !== Boolean(this.props.editMode)) this.setState({showValidationErrors: false})

		if (prevProps.contact !== this.props.contact ||
			!prevProps.reinitialize && this.props.reinitialize
		) return this.init()
		let hasChange = this.checkHasChange()
		if (this.hasChange !== hasChange) {
			this.hasChange = hasChange
			this.props.setEditing(hasChange)
		}
	}

	init = () => {
		let contact				= this.props.contact || this._getEmptyContactObject(true)
		let name				= JSON.parse(JSON.stringify(contact.name))
		let numbers				= JSON.parse(JSON.stringify(contact.numbers.filter(n => n.number)))
		let emails				= JSON.parse(JSON.stringify(contact.emails))
		let organization		= contact.organization
		let job_title			= contact.job_title
		let notes				= contact.notes
		let addresses			= JSON.parse(JSON.stringify(contact.addresses))
		let group				= JSON.parse(JSON.stringify(contact.group))
		let contactGroupTypes	= JSON.parse(JSON.stringify(this.props.contactGroupTypes))
		contactGroupTypes.push({id: null, name: 'custom'})

		let contactNumberTypes	= numbers.map(n => n.type)
		let numberTypes			= this.state.numberTypes
		let missingNumberTypes	= contactNumberTypes.filter(cnt => !numberTypes.includes(cnt))
		numberTypes.pop()
		numberTypes = numberTypes.concat(missingNumberTypes)
		numberTypes.push('custom')

		name.display	= name.display.trim()
		name.first		= name.first.trim()
		name.last		= name.last.trim()
		name.middle		= name.middle.trim()
		name.nick		= name.nick.trim()

		let firstAndLast			= `${name.first} ${name.last}`.trim()
		let showDisplayNameField	= Boolean(name.display && firstAndLast && name.display !== firstAndLast)
		if (!showDisplayNameField && !name.first && !name.last) name.first = name.display

		this.setState({name, numbers, emails, organization, job_title, notes, addresses, group, contactGroupTypes, numberTypes, showDisplayNameField})
	}

	_getEmptyContactObject = onInit => {
		let emptyContactObject = getEmptyContactObject()
		// Fixed number
		if (this.props.fixedNumber) {
			emptyContactObject.numbers.push({
				number:	this.props.fixedNumber,
				type:	'home',
				fixed:	true
			})
		}
		// Prefil data
		let prefillData = this.props.prefillData
		if (prefillData) {
			let prefillName = prefillData.name
			if (prefillName) {
				emptyContactObject.name.display = prefillName
				let prefilNameSplit = prefillName.split(' ')
				emptyContactObject.name.first = prefilNameSplit[0]
				if (prefilNameSplit.length === 2) emptyContactObject.name.last = prefilNameSplit[1]
				if (prefilNameSplit.length > 2) {
					prefilNameSplit.splice(0, 1)
					emptyContactObject.name.last = prefilNameSplit.join(' ')
				}
			}
			let prefillAddress = prefillData.address
			if (prefillAddress) {
				if (prefillAddress[0]) emptyContactObject.addresses.home.city = prefillAddress[0]
				if (prefillAddress[1]) emptyContactObject.addresses.home.state = prefillAddress[1]
				if (onInit) this.setState({homeHidden: false})
			} else if (onInit) this.setState({homeHidden: true})
		}
		return emptyContactObject
	}

	renderField = fieldData => {
		const { classes } = this.props
		if (this.props.editMode)
			return this.renderInput(fieldData)
		let value = fieldData.value
		let autocompleteValues = fieldData.autocompleteValues
		if (autocompleteValues && autocompleteValues.length) {
			let item = autocompleteValues.find(v => v.value === value)
			if (item) value = item.content
		}
		if (!value) return null
		return (
			<div data-test-id={fieldData.dataTestId} className={classes.fieldWrapper} style={fieldData.style || {}}>
				{!fieldData.noTitle ? <span className='title'>{fieldData.placeholder}</span> : null}
				<span className='value'>{value}</span>
			</div>
		)
	}

	renderInput = fieldData => {
		const { classes }		= this.props
		let id					= fieldData.id
		let placeholder			= fieldData.placeholder
		let value				= fieldData.value
		let autocompleteValues	= fieldData.autocompleteValues
		let error = fieldData.error
		if (typeof(value) === 'string' && fieldData.maxSize && value.length > fieldData.maxSize) {
			error = `Max size is ${fieldData.maxSize}`
		}
		return (
			<div className={classes.inputWrapper}>
				{autocompleteValues ?
					<Menu
						inputId		= {`${id}-${placeholder.split(' ').join('-').toLowerCase()}`}
						label		= {placeholder}
						fullWidth	= {true}
						variant		= 'single'
						items		= {autocompleteValues}
						default		= {value}
						controlable	= {true}
						onChange	= {fieldData.onChange}
						className	= {{itemsWrapper: classes.dropdownItemsWrapper}}
					/>
					: <TextField
						fullWidth		= {true}
						id				= {`${id}-${placeholder.split(' ').join('-').toLowerCase()}`}
						label			= {placeholder}
						content			= {value}
						editable		= {true}
						showExpandIcon	= {false}
						onInputFocus	= {fieldData.onFocus}
						onInputChange	= {value => fieldData.onChange({target: {value}})}
						disabled		= {Boolean(fieldData.disabled)}
						error			= {error}
						multiline		= {fieldData.multiline}
					/>
				}
			</div>
		)
	}

	onNameChange	= (e, type) => {
		let value	= e.target.value
		let name	= this.state.name
		name[type]	= value
		this.setState({name})
	}
	onGroupChange	= group => {
		if (!group) group = {id: null, name: ''}
		group = {
			id:		group.value,
			name:	group.content
		}
		this.setState({group})
	}
	onOrganizationChange	= e => this.setState({organization: e.target.value})
	onJobTitleChange		= e => this.setState({job_title: e.target.value})
	onNumberChange	= (e, i) => {
		let value			= e.target.value
		if (value.length === 4 && value[0] === '(' && !isNaN(value.substring(1))) value = value.substring(1, 3)
		let number			= value.replace(/[^0-9&&^+]/g, '')
		let numbers			= this.state.numbers
		numbers[i].number	= number.length > 4 ? convertNumberToE164(number) : number
		this.setState({numbers})
	}
	onNumberTypeChange = (type, i) => {
		if (!type) type = {content: ''}
		let numbers		= this.state.numbers
		numbers[i].type	= type.content
		this.setState({numbers})
	}
	onEmailChange	= (e, i) => {
		let email	= e.target.value
		let emails	= this.state.emails
		emails[i]	= email
		this.setState({emails})
	}
	onAddressChange	= (e, addressType) => {
		let address				= e.target.value
		let addresses			= this.state.addresses
		let stateAddress		= addresses[addressType]
		stateAddress.address	= address
		this.setState({addresses})
	}
	onCityChange	= (e, addressType) => {
		let city			= e.target.value
		let addresses		= this.state.addresses
		let stateAddress	= addresses[addressType]
		stateAddress.city	= city
		this.setState({addresses})
	}
	onStateChange	= (e, addressType) => {
		let state			= e.target.value
		let addresses		= this.state.addresses
		let stateAddress	= addresses[addressType]
		stateAddress.state	= state
		this.setState({addresses})
	}
	onCountryChange	= (e, addressType) => {
		let country				= e.target.value
		let addresses			= this.state.addresses
		let stateAddress		= addresses[addressType]
		stateAddress.country	= country
		this.setState({addresses})
	}
	onZipCodeChange	= (e, addressType) => {
		let zipCode				= e.target.value
		let addresses			= this.state.addresses
		let stateAddress		= addresses[addressType]
		stateAddress.zip_code	= zipCode
		this.setState({addresses})
	}

	formatNumberAsYouType = number => {
		if (number.length < 2) return number
		if (number[0] === '+' && number[1] !== '1') return formatPhoneNumber(number)
		if (number.substring(0, 2) === '+1') number = number.substring(2)
		return new AsYouType('US').input(number)
	}

	addPhoneNumberField = () => {
		let numbers		= this.state.numbers
		let newIndex	= numbers.length
		numbers.push({number: '', type: 'Home'})
		this.setState({numbers}, () => document.getElementById(`${newIndex}-phone-number`).focus())
	}

	addEmailField = () => {
		let emails		= this.state.emails
		let newIndex	= emails.length
		emails.push('')
		this.setState({emails}, () => document.getElementById(`${newIndex}-email-address`).focus())
	}

	removePhoneNumber = index => {
		let numbers = this.state.numbers
		numbers.splice(index, 1)
		this.setState({numbers})
	}

	removeEmail = index => {
		let emails = this.state.emails
		emails.splice(index, 1)
		this.setState({emails})
	}

	expandHomeAddress		= () => this.setState({homeHidden: false})
	expandBusinessAddress	= () => this.setState({businessHidden: false})

	renderBasicInfoSection = () => {
		const { classes }			= this.props
		let contactGroupTypes		= this.getContactGroupTypes()
		let group					= (this.state.group && this.state.group.id) ? this.state.group.id : ''
		let editModeClass			= this.props.editMode ? 'edit-mode' : ''
		let displayName				= this.state.name.display
		let firstName				= this.state.name.first
		let lastName				= this.state.name.last
		let organization			= this.state.organization
		let jobTitle				= this.state.job_title
		let showDisplayNameField	= this.state.showDisplayNameField
		let firstNameError			= ''
		let lastNameError			= this.state.showValidationErrors && !this.state.name.last.trim() ? 'Last name is required' : null
		let displayNameError		= ''
		if (!lastNameError && `${firstName.trim()} ${lastName.trim()}`.length > MAX_LENGTHS.displayName) {
			if (showDisplayNameField) displayNameError = `Display name max size is ${MAX_LENGTHS.displayName}`
			else firstNameError = lastNameError = `First + last name max size is ${MAX_LENGTHS.displayName}`
		}
		return (
			<div className={`${classes.dataSection} ${editModeClass}`}>
				{showDisplayNameField ? this.renderField({
					id:				0,
					placeholder:	'Contact Name',
					value:			displayName,
					onChange:		e => this.onNameChange(e, 'display'),
					onFocus:		() => {},
					required:		true,
					error:			displayNameError,
					dataTestId:		'contact-display-name'
				}) : null}
				{this.props.editMode ?
					<div className={classes.inputsGroupWrapper}>
						{this.renderField({
							id:				0,
							placeholder:	'First Name',
							value:			firstName,
							onChange:		e => this.onNameChange(e, 'first'),
							error:			firstNameError,
							maxSize:		MAX_LENGTHS.firstName,
							dataTestId:		'contact-first-name'
						})}
						{this.renderField({
							id:				0,
							placeholder:	'Last Name',
							value:			lastName,
							onChange:		e => this.onNameChange(e, 'last'),
							error:			lastNameError,
							maxSize:		MAX_LENGTHS.lastName,
							dataTestId:		'contact-last-name'
						})}
					</div>
				: null}
				{this.renderField({
					id:					0,
					placeholder:		'Group',
					value:				group,
					onChange:			v => this.onGroupChange(v),
					onFocus:			() => {},
					autocompleteValues:	contactGroupTypes,
					dataTestId:			'contact-group'
				})}
				{this.renderField({
					id:				0,
					placeholder:	'Company name',
					value:			organization,
					onChange:		this.onOrganizationChange,
					maxSize:		MAX_LENGTHS.organization,
					dataTestId:		'contact-company-name'
				})}
				{this.renderField({
					id:				0,
					placeholder:	'Title',
					value:			jobTitle,
					onChange:		this.onJobTitleChange,
					maxSize:		MAX_LENGTHS.jobTitle,
					dataTestId:		'contact-title'
				})}
				{this.renderField({
					id:				0,
					placeholder:	'Notes',
					value:			this.state.notes,
					multiline:		true,
					onChange:		e => this.setState({notes: e.target.value}),
					onFocus:		() => {},
					maxSize:		MAX_LENGTHS.notes,
					dataTestId:		'contact-notes'
				})}
			</div>
		)
	}

	renderPhoneNumbersSection = () => {
		const { classes, editMode, smallView } = this.props
		let numberTypes = this.state.numberTypes.map((value, key) => {
			let content = value ? `${value[0].toUpperCase()}${value.substring(1)}` : ''
			let e = {value: `nt-${key}`, content}
			if (value === 'custom') {
				e.notSelectable = true
				e.onClick = () => this.setState({showAddTypeModal: 'number'})
			}
			return e
		})
		let editModeClass	= editMode ? 'edit-mode' : ''
		let numbers			= this.state.numbers
		if (!numbers.map(n => n.number).filter(n => n).length && !editMode) return null
		return (
			<div className={`${classes.dataSection} ${editModeClass}`}>
				{numbers.map((n, i) => {
					let numberType				= numberTypes.find(nt => nt.content === n.type) || ''
					if (numberType) numberType	= numberType.value
					let marginBottomStyle		= i === numbers.length - 1 ? {} : {marginBottom: 9}
					let phoneNumberValue		= this.formatNumberAsYouType(n.number)
					let error					= ''
					let isValid					= isValidNumber(phoneNumberValue) || isValidNumber(phoneNumberValue, 'US')
					if (this.state.showValidationErrors && !isValid) {
						error = !phoneNumberValue ? 'Can\'t be empty' : 'Invalid number'
					}
					return (
						<div key={i} className={`${classes.phoneNumberSection} ${editMode ? 'edit-mode' : ''} ${smallView ? 'small-view' : ''}`}>
							{this.renderField({
								id:				i,
								placeholder:	'Phone Number',
								value:			phoneNumberValue,
								disabled:		n.fixed,
								onChange:		e => this.onNumberChange(e, i),
								onFocus:		() => {},
								style:			{minWidth: 130, ...marginBottomStyle},
								noTitle:		Boolean(i),
								error,
								dataTestId:		'contact-phone-number'
							})}
							{this.renderField({
								id:					i,
								placeholder:		'Phone Number Type',
								value:				numberType,
								onChange:			v => this.onNumberTypeChange(v, i),
								onFocus:			() => {},
								autocompleteValues:	numberTypes,
								noTitle:			true,
								style:				{
									color: theme.palette.secondary[0],
									WebkitTextStrokeWidth: '0.1px',
									...marginBottomStyle
								},
								dataTestId:			'contact-phone-number-type'
							})}
							{editMode && !n.fixed ?
								<DefaultArrowTooltip
									title		= {!n.fixed ? 'Remove' : ''}
									placement	= 'left'
								>
									<div
										onClick		= {() => !n.fixed ? this.removePhoneNumber(i) : null}
										className	= {`${classes.removeIconWrapper} remove-icon-wrapper`}
									>
										<XCircleOutlinedIcon/>
									</div>
								</DefaultArrowTooltip>
							: null}
						</div>
					)
				})}
				{this.props.editMode && numbers.length < 5 ?
					<div
						className	= {classes.addButton}
						onClick		= {this.addPhoneNumberField}
						data-test-id = 'contact-add-number-button'
					>
						<PlusCircleOutlinedIcon/>
						<span>Add number</span>
					</div>
				: null}
			</div>
		)
	}

	renderEmailsSection = () => {
		const { classes, editMode, smallView } = this.props
		let editModeClass	= editMode ? 'edit-mode' : ''
		let emails			= this.state.emails
		if (!emails.length && !editMode) return null
		return (
			<div className={`${classes.dataSection} ${editModeClass}`}>
				{emails.map((email, i) => {
					let marginBottomStyle = i === emails.length - 1 ? {} : {marginBottom: 9}
					let error = Boolean(!this.state.showValidationErrors || this.validateEmail(email.trim())) ? '' : 'Invalid email'
					return (
						<div key={i} className={`${classes.emailWrapper} ${smallView ? 'small-view' : ''}`}>
							{this.renderField({
								id:				i,
								placeholder:	'Email address',
								value:			email,
								onChange:		e => this.onEmailChange(e, i),
								onFocus:		() => {},
								noTitle:		Boolean(i),
								style:			marginBottomStyle,
								error,
								maxSize:		MAX_LENGTHS.email,
								dataTestId:		'contact-email'
							})}

							{this.props.editMode ?
								<DefaultArrowTooltip
									title		= 'Remove'
									placement	= 'left'
								>
									<div
										onClick		= {() => this.removeEmail(i)}
										className	= {`${classes.removeIconWrapper} remove-icon-wrapper`}
									>
										<XCircleOutlinedIcon/>
									</div>
								</DefaultArrowTooltip>
							: null}
						</div>
					)
				})}
				{this.props.editMode && this.state.emails.length < 2 ?
					<div
						className	= {classes.addButton}
						onClick		= {this.addEmailField}
						data-test-id = 'contact-add-email-button'
					>
						<PlusCircleOutlinedIcon/>
						<span>Add email</span>
					</div>
				: null}
			</div>
		)
	}

	hasAddressInfo = addressType => {
		let hasAddressInfo = Boolean(this.state.addresses[addressType].address ||
			this.state.addresses[addressType].city ||
			this.state.addresses[addressType].state ||
			this.state.addresses[addressType].zip_code ||
			this.state.addresses[addressType].country)
		return hasAddressInfo
	}

	renderAddressInfo = addressType => {
		const { classes, editMode } = this.props
		let isHidden		= this.state[`${addressType}Hidden`]
		let id				= addressType === 'home' ? 0 : 1
		let addressTypeC	= `${addressType[0].toUpperCase()}${addressType.substring(1)}`
		let editModeClass	= editMode ? 'edit-mode' : ''
		if (!this.hasAddressInfo(addressType) && !editMode) return null
		return (
			<div className={`${classes.dataSection} ${editModeClass} ${isHidden ? 'collapsed' : ''}`}>
				<DefaultArrowTooltip
					title		= {isHidden ? 'Expand' : 'Collapse'}
					placement	= 'right'
				>
					<span
						className='label clickable'
						onClick={() => {
							if (isHidden) {
								let domEl = document.getElementById(`${id}-street-address`)
								if (domEl) domEl.focus()
							}
							this.setState({[`${addressType}Hidden`]: !isHidden})
						}}
					>
						{`${addressType[0].toUpperCase()}${addressType.substring(1)}`} address <ExpandTriangle2 className={`${classes.expandIcon} ${!isHidden ? 'rotate' : ''}`}/>
					</span>
				</DefaultArrowTooltip>
				{this.renderField({
					id,
					placeholder:	'Street Address',
					value:			this.state.addresses[addressType].address,
					onChange:		e => this.onAddressChange(e, addressType),
					onFocus:		this[`expand${addressTypeC}Address`],
					style:			{paddingTop: 30},
					maxSize:		MAX_LENGTHS.address,
					dataTestId:		`contact-${addressType}-address-street`
				})}
				<div className={classes.inputsGroupWrapper}>
					{this.renderField({
						id,
						placeholder:	'City',
						value:			this.state.addresses[addressType].city,
						onChange:		e => this.onCityChange(e, addressType),
						onFocus:		this[`expand${addressTypeC}Address`],
						maxSize:		MAX_LENGTHS.address,
						dataTestId:		`contact-${addressType}-address-city`
					})}
					{this.renderField({
						id,
						placeholder:	'State',
						value:			this.state.addresses[addressType].state,
						onChange:		e => this.onStateChange(e, addressType),
						onFocus:		this[`expand${addressTypeC}Address`],
						maxSize:		MAX_LENGTHS.address,
						dataTestId:		`contact-${addressType}-address-state`
					})}
					{this.renderField({
						id,
						placeholder:	'Zip Code',
						value:			this.state.addresses[addressType].zip_code,
						onChange:		e => this.onZipCodeChange(e, addressType),
						onFocus:		this[`expand${addressTypeC}Address`],
						maxSize:		MAX_LENGTHS.address,
						dataTestId:		`contact-${addressType}-address-zip`
					})}
				</div>
				{this.renderField({
					id,
					placeholder:	'Country',
					value:			this.state.addresses[addressType].country,
					onChange:		e => this.onCountryChange(e, addressType),
					onFocus:		this[`expand${addressTypeC}Address`],
					maxSize:		MAX_LENGTHS.address,
					dataTestId:		`contact-${addressType}-address-country`
				})}
			</div>
		)
	}

	hasMoreFieldsInfo = () => {
		let hasMoreFieldsInfo = Boolean(this.state.name.nick || this.state.name.middle)
		return hasMoreFieldsInfo
	}

	renderMoreFieldsSection = () => {
		const { classes, editMode } = this.props
		let isHidden		= this.state.moreFieldsHidden
		let editModeClass	= editMode ? 'edit-mode' : ''
		if (!this.hasMoreFieldsInfo() && !editMode) return null
		return (
			<div className={`${classes.dataSection} ${editModeClass} ${isHidden ? 'collapsed' : ''}`}>
				<DefaultArrowTooltip
					title		= {isHidden ? 'Expand' : 'Collapse'}
					placement	= 'right'
				>
					<span
						className='label first-label clickable'
						onClick={() => {
							if (isHidden) {
								let domEl = document.getElementById('nickname')
								if (domEl) domEl.focus()
							}
							this.setState({moreFieldsHidden: !isHidden})
						}}
					>
						More fields <ExpandTriangle2 className={`${classes.expandIcon} ${!isHidden ? 'rotate' : ''}`}/>
					</span>
				</DefaultArrowTooltip>
				{this.renderField({
					id:				'nickname',
					placeholder:	'Nickname',
					value:			this.state.name.nick,
					onChange:		e => this.onNameChange(e, 'nick'),
					onFocus:		() => this.setState({moreFieldsHidden: false}),
					style:			{paddingTop: 30},
					maxSize:		MAX_LENGTHS.nickname,
					dataTestId:		'contact-nickname'
				})}
				{this.renderField({
					id:				'middle-name',
					placeholder:	'Middle Name',
					value:			this.state.name.middle,
					onChange:		e => this.onNameChange(e, 'middle'),
					onFocus:		() => this.setState({moreFieldsHidden: false}),
					maxSize:		MAX_LENGTHS.middleName,
					dataTestId:		'contact-middle-name'
				})}
			</div>
		)
	}

	checkHasChange = () => {
		let contact = this.props.contact || this._getEmptyContactObject()

		let checkNameChange = () => {
			let stateName	= this.state.name
			let contactName	= contact.name
			return (stateName.display.trim() !== contactName.display.trim() ||
				stateName.first.trim() !== contactName.first.trim() ||
				stateName.last.trim() !== contactName.last.trim() ||
				stateName.middle.trim() !== contactName.middle.trim() ||
				stateName.nick.trim() !== contactName.nick.trim())
		}

		let checkNumberChange = () => {
			let stateNumbers = this.state.numbers
			let contactNumbers = contact.numbers.filter(n => n.number)
			if (stateNumbers.length !== contactNumbers.length) return true
			let hasChange = false
			stateNumbers.forEach((sn, sni) => {
				if (hasChange) return
				let foundNumber = contactNumbers.find((cn, cni) => cn.number === sn.number && cn.type === sn.type && cni === sni)
				if (!foundNumber) hasChange = true
			})
			return hasChange
		}

		let checkEmailChange = () => {
			let stateEmails = this.state.emails
			let contactEmails = contact.emails
			if (stateEmails.length !== contactEmails.length) return true
			let hasChange = false
			stateEmails.forEach((se, sei) => {
				if (hasChange) return
				let foundEmail = contactEmails.find((ce, cei) => ce.trim() === se.trim() && cei === sei)
				if (!foundEmail) hasChange = true
			})
			return hasChange
		}

		let checkAddressChange = addressType => {
			let stateAddress = this.state.addresses[addressType]
			let contactAddress = contact.addresses[addressType]
			return stateAddress.address.trim() !== contactAddress.address.trim() ||
				stateAddress.city.trim() !== contactAddress.city.trim() ||
				stateAddress.state.trim() !== contactAddress.state.trim() ||
				stateAddress.country.trim() !== contactAddress.country.trim() ||
				stateAddress.zip_code.trim() !== contactAddress.zip_code.trim()
		}

		let checkJobChange = () => {
			return this.state.organization.trim() !== contact.organization.trim() ||
				this.state.job_title.trim() !== contact.job_title.trim()
		}
		
		let checkNoteChange = () => this.state.notes.trim() !== contact.notes

		let checkGroupChange = () => {
			let stateGroup = this.state.group
			return (!stateGroup && contact.group && contact.group.id) ||
				(stateGroup && stateGroup.id !== contact.group.id) ||
				(stateGroup && stateGroup.name !== contact.group.name)
		}

		let hasNameChange				= checkNameChange()
		let hasNumberChange				= checkNumberChange()
		let hasEmailChange				= checkEmailChange()
		let hasHomeAddressChange		= checkAddressChange('home')
		let hasBusinessAddressChange	= checkAddressChange('business')
		let hasJobChange				= checkJobChange()
		let hasNoteChange				= checkNoteChange()
		let hasGroupChange				= checkGroupChange()
		let isPrefill					= !this.props.contact && this.props.prefillData

		return hasNameChange || hasNumberChange || hasEmailChange || hasHomeAddressChange || 
			hasBusinessAddressChange || hasJobChange || hasNoteChange || hasGroupChange || isPrefill
	}

	validateEmail = email => {
		// Email validation regex
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase())
	}

	validate = () => {
		let { name, numbers, emails } = this.state
		let isValid = true
		// Validate name
		let displayName = this.state.showDisplayNameField ? name.display : `${name.first.trim()} ${name.last.trim()}`
		if (!name.last.trim()) isValid = false
		if (name.first.trim().length > MAX_LENGTHS.firstName)			isValid = false
		else if (name.last.trim().length > MAX_LENGTHS.lastName)		isValid = false
		else if (displayName.trim().length > MAX_LENGTHS.displayName)	isValid = false
		else if (name.nick.trim().length > MAX_LENGTHS.nickname)		isValid = false
		else if (name.middle.trim().length > MAX_LENGTHS.middleName)	isValid = false
		// Validate phone numbers (if any)
		numbers.forEach(n => {
			if (!isValid) return
			if (!isValidNumber(n.number) && !isValidNumber(n.number, 'US')) {
				isValid = false
			}
		})
		// Validate emails (if any)
		emails.forEach(email => {
			if (!this.validateEmail(email.trim())) isValid = false
			else if (email.trim().length > MAX_LENGTHS.email) isValid = false
		})
		// Validate organization length
		if (this.state.organization.trim().length > MAX_LENGTHS.organization) isValid = false
		// Validate job title length
		if (this.state.job_title.trim().length > MAX_LENGTHS.jobTitle) isValid = false
		// Validate notes length
		if (this.state.notes.trim().length > MAX_LENGTHS.notes) isValid = false
		// Validate address fields length
		Array.from(['home', 'business']).forEach(addressType => {
			if (this.state.addresses[addressType].city.trim().length > MAX_LENGTHS.address) isValid = false
			else if (this.state.addresses[addressType].state.trim().length > MAX_LENGTHS.address) isValid = false
			else if (this.state.addresses[addressType].zip_code.trim().length > MAX_LENGTHS.address) isValid = false
			else if (this.state.addresses[addressType].country.trim().length > MAX_LENGTHS.address) isValid = false
		})
		if (!isValid) this.setState({showValidationErrors: true})
		return isValid
	}

	onSaveClick = async () => {

		let isValid = this.validate()
		if (!isValid) return

		this.setState({loading: true})
		let name		= this.state.name
		Object.keys(name).forEach(key => name[key] = name[key].trim())
		name.display	= this.state.showDisplayNameField && name.display ? name.display : `${name.first} ${name.last}`.trim()
		let numbers = this.state.numbers.map(n => {
			return {
				number:	n.number,
				type:	n.type
			}
		})
		let emails			= this.state.emails.map(e => e.trim())
		let homeAddress		= this.state.addresses.home
		Object.keys(homeAddress).forEach(key => homeAddress[key] = homeAddress[key].trim())
		let businessAddress	= this.state.addresses.business
		Object.keys(businessAddress).forEach(key => businessAddress[key] = businessAddress[key].trim())
		let organization	= this.state.organization.trim()
		let job_title		= this.state.job_title.trim()
		let notes			= this.state.notes.trim()
		let data = {
			name,
			organization,
			job_title,
			emails,
			notes,
			numbers,
			addresses: {
				home: homeAddress,
				business: businessAddress
			}
		}

		let group = this.state.group
		if (!group.id && !group.name) group = null
		else if (!group.id || `${group.id}`.substring(0, 5) === 'null-') delete group.id
		if (group) data.group = group

		let contactId = this.props.contact ? this.props.contact.id : null
		let groupId = null
		if (contactId) {
			data.contact_id = contactId
			let res		= await Api.updateContact(data)
			contactId	= res.contact_id
			groupId		= res.group_id
		} else {
			let res		= (await Api.createContact(data))
			contactId	= res.contact_id
			groupId		= res.group_id
		}
		// todo we should clean up this area it is rather confusing
		if (group) group.id = groupId
		else group = {name:null, id:0}

		let contactData = {
			id: contactId, name,
			addresses: {home: homeAddress, business: businessAddress},
			emails, numbers, group, organization, job_title, notes
		}
		this.setState({loading: false})
		this.props.saveContact(contactData)
		this.props.onSave(contactId)
	}

	onDelete = async () => {
		this.setState({showDeleteModal: false, loading: true})
		let contactId = this.props.contact.id
		let response = await Api.deleteContact(contactId)
		this.setState({loading: false})
		this.props.onDelete(contactId)
	}

	renderFooter = () => {
		const { classes, contact, onDelete, editMode, onCancel } = this.props
		if (!editMode) return null
		let hasChange = this.checkHasChange()
		return (
			<div className={classes.footer} style={!onDelete || !contact ? {justifyContent: 'center'} : {}}>
				{onDelete && contact ?
					<div>
						<Button
							variant	= 'outlined'
							color	= 'attention'
							onClick	= {() => this.setState({showDeleteModal: true})}
						>Delete Contact</Button>
					</div>
				: null}
				<div className={classes.buttonsGroup}>
					<Button
						variant	= 'outlined'
						color	= 'secondary'
						onClick	= {() => onCancel(hasChange)}
					>Cancel</Button>
					<Button
						variant		= 'filled'
						color		= 'primary'
						disabled	= {!hasChange}
						onClick		= {this.onSaveClick}
					>Save</Button>
				</div>
			</div>
		)
	}

	renderConfirmDeleteModal = () => {
		return (
			<ConfirmModal
				title			= 'Are you sure you want to delete this contact?'
				isShown			= {this.state.showDeleteModal}
				content			= {null}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Confirm'
				yesButtonColor	= 'attention'
				onReject		= {() => this.setState({showDeleteModal: false})}
				onConfirm		= {this.onDelete}
				size			= 'size2'
			/>
		)
	}

	addType = () => {
		let modalType		= this.state.showAddTypeModal
		let stateTypesName	= modalType === 'number' ? 'numberTypes' : 'contactGroupTypes'
		let newType			= this.state.newType
		if (modalType === 'group') {
			newType = {
				id:		`null-${Math.random()}`,
				name:	newType
			}
		}
		let types = this.state[stateTypesName]
		let existingType = types.find(t =>
			modalType === 'group' ?
				t.name.toLowerCase() === newType.name
				: t.toLowerCase() === newType
		)
		if (existingType) return this.setState({newType: '', showAddTypeModal: false})
		types.splice(types.length - 1, 0, newType)
		this.setState({[stateTypesName]: types, newType: '', showAddTypeModal: false})

		if (modalType === 'group') {
			this.setState({group: {...newType}})
			this.props.addGroup({...newType})
		}
	}

	onNewTypeChange = value => {
		let newType = value.toLowerCase()
		if (newType.length > 30) return
		this.setState({newType})
	}

	renderAddTypeModal = () => {
		const { classes } = this.props
		let textLabel = this.state.showAddTypeModal === 'group' ? 'Group Type' : 'Phone Number Type'
		return (
			<Dialog
				open	= {Boolean(this.state.showAddTypeModal)}
				classes	= {{paper: classes.addDialog}}
			>
				<DialogContent classes={{root: classes.addDialogContent}}>
					<TextField
						id				= 'new-type'
						fullWidth		= {true}
						label			= {textLabel}
						content			= {this.state.newType}
						editable		= {true}
						showExpandIcon	= {false}
						onInputChange	= {this.onNewTypeChange}
					/>
				</DialogContent>
				<DialogActions classes={{root: classes.addDialogFooter}}>
					<Button
						className	= {classes.rejectButton}
						onClick		= {() => this.setState({showAddTypeModal: false, newType: ''})}
						color		= 'secondary'
						variant		= 'filled'
					>
						Cancel
					</Button>
					<Button
						className	= {classes.confirmButton}
						onClick		= {this.addType}
						color		= 'primary'
						variant		= 'filled'
						disabled	= {!Boolean(this.state.newType)}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	getContactGroupTypes = () => {
		return this.state.contactGroupTypes.map(gt => {
			if (gt && gt.name ) {
				let content = `${gt.name[0].toUpperCase()}${gt.name.substring(1)}`
				let e = {value: gt.id, content}
				if (gt.name === 'custom') {
					e.notSelectable = true
					e.onClick = () => this.setState({showAddTypeModal: 'group'})
				}
				return e
			} else {
				return {value: null, content: ''}
			}
		})
	}

	renderLoader = () => {
		const { classes } = this.props
		if (!this.state.loading) return null
		return <div className={classes.loadingDiv}><LoaderFull/></div>
	}

	makeCall = number => {
		this.props.makeCall(number)
	}

	startConversation = number => {
		this.props.startConversation(number)
	}

	onCallStartClick = () => {
		if (this.props.isVirtualExtension) return
		if (this.props.editMode) return
		let numbers = this.state.numbers.filter(n => n.number.trim())
		if (numbers.length === 0) return
		if (numbers.length === 1) return this.makeCall(numbers[0].number)
		this.setState({chooseANumberModalType: 'call'})
	}

	onConversationStartClick = () => {
		if (this.props.editMode) return
		let numbers = this.state.numbers.filter(n => n.number.trim())
		if (numbers.length === 0) return
		if (numbers.length === 1) return this.startConversation(numbers[0].number)
		this.setState({chooseANumberModalType: 'message'})
	}

	renderHeader = () => {
		const { classes, editMode } = this.props
		let editModeClass		= editMode ? 'edit-mode' : ''
		let hasANumber			= Boolean(this.state.numbers.filter(n => n.number.trim()).length)
		let isVirtualExtension	= this.props.isVirtualExtension
		return (
			<div className={`${classes.headerSection} ${editModeClass}`}>
				<div className='avatar-icon-wrapper'>
					<ContactAvatarIcon className='avatar-icon'/>
					{false && this.props.editMode ? <ContactAvatarCamIcon className='cam-icon'/> : null}
				</div>
				<div className='contact-name'>{this.state.name.display}</div>
				{!this.props.editMode && hasANumber ?
					<div className='actions-wrapper'>
						{!process.env.REACT_APP_IS_CALLING_DISABLED || (window.V5PHONECOM && window.V5PHONECOM.features.has('calling_enabled')) ?
							<DefaultArrowTooltip
								title		= {isVirtualExtension ? 'Virtual extension can\'t make calls' : ''}
								placement	= 'left'
							>
								<div>
									<CallCircleFilledIcon className={isVirtualExtension ? 'disabled-calls-icon' : ''} onClick={this.onCallStartClick}/>
								</div>
							</DefaultArrowTooltip>
						: null}
						<MessageCircleFilledIcon onClick={this.onConversationStartClick}/>
					</div>
				: null}
			</div>
		)
	}

	renderMainContent = () => {
		const { classes } = this.props
		return (
			<div className={classes.mainSection}>
				{this.renderBasicInfoSection()}
				{this.renderPhoneNumbersSection()}
				{this.renderEmailsSection()}
				{this.renderAddressInfo('home')}
				{this.renderAddressInfo('business')}
				{this.renderMoreFieldsSection()}
			</div>
		)
	}

	onNumberChoose = number => {
		if (this.state.chooseANumberModalType === 'call') this.makeCall(number.number)
		else if (this.state.chooseANumberModalType === 'message') this.startConversation(number.number)
		this.setState({chooseANumberModalType: null})
	}

	renderChooseANumberModal = () => {
		let { chooseANumberModalType, numbers } = this.state
		return (
			<ChooseANumberModal
				type		= {chooseANumberModalType}
				isShown		= {Boolean(chooseANumberModalType)}
				numbers		= {numbers}
				onClose		= {() => this.setState({chooseANumberModalType: null})}
				onChoose	= {this.onNumberChoose}
			/>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.wrapper}>
				{this.renderLoader()}
				{this.renderHeader()}
				{this.renderMainContent()}
				{this.renderFooter()}
				{this.renderConfirmDeleteModal()}
				{this.renderAddTypeModal()}
				{this.renderChooseANumberModal()}
			</div>
		)
	}
}

export default withStyles(styles)(ContactEditor)