import React from 'react';
import PDFUnavailable from './src/PDFUnavailable';
import PDFViewer from './src/PDFViewer';
import PDFEmbedded from './src/PDFEmbedded';
import './src/PDFEmbedded.css';

export default function PDF(props) {

    if (embeddedAvailable()) {
        return <PDFEmbedded {...props}/>;
    }

    if (workersAvailable()) {
        return <PDFViewer {...props}/>;
    }

    return <PDFUnavailable {...props}/>;
}

function workersAvailable() {
    return Boolean(window.Worker);
}

function embeddedAvailable() {
    let ua = window.navigator.userAgent;

    let isFirefox = (function (){ return (ua.indexOf("irefox") !== -1); } )()
    let isFirefoxWithPDFJS = (function (){
            //Firefox started shipping PDF.js in Firefox 19.
            //If this is Firefox 19 or greater, assume PDF.js is available
            if(!isFirefox){ return false; }
            //parse userAgent string to get release version ("rv")
            //ex: Mozilla/5.0 (Macintosh; Intel Mac OS X 10.12; rv:57.0) Gecko/20100101 Firefox/57.0
            return (parseInt(ua.split("rv:")[1].split(".")[0], 10) > 18);
        })();

    return Boolean(navigator
        && (typeof navigator.mimeTypes == 'object')
        && navigator.mimeTypes['application/pdf']
        && navigator.mimeTypes['application/pdf'].enabledPlugin
        || isFirefoxWithPDFJS
    )
}