import React from 'react'
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import { formatPhoneNumber } from 'phone-numbers'
import { checkmarkSelected } from 'pdc-icons'
import { DefaultArrowTooltip } from 'tooltips'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	pickNumberDropdownTheme: {
		fontSize:			'14px',
		fontWeight:			500,
		backgroundColor:	'#fff',
		color:				'#000',
		boxShadow:			'0 0 0 0',
		letterSpacing:		'-0.1px',
		margin:				0,
		padding:			0,
		'&:hover, &:focus, &:active': {
			boxShadow:		'0 0 0 0 !important',
			outline:		'none'
		}
	},
	dropdownMenu: {
		border:			'2px solid black',
		borderRadius:	'6px',
		padding:		'10px 0 !important',
		boxShadow:		'6px 6px 0 0 rgba(52, 64, 71, 0.5)',
		'& .dropdown-item': {
			position:		'relative',
			fontSize:		'14px',
			fontWeight:		'500',
			lineHeight:		'2.14',
			letterSpacing:	'-0.1px',
			padding:		'0 30px',
			color:			'#5a666d',
			'&:hover, &:active': {
				color:		theme.palette.primary.main,
				background:	'#f2f5f6 !important'
			},
			'&.selected': {
				color:	'#000',
				'& img': {
					position:	'absolute',
					left:		'10px',
					width:		'12px',
					top:		'10px'
				}
			},
			'&:not(.selected) img': {
				display:	'none'
			}
		}
	}
})

class PhoneNumberSelector extends React.Component {

	onNumberClick = index => {
		let numbers = this.props.numbers
		if (!numbers[index].selected) this.props.select(numbers[index].number)
	}

	render() {
		let numbers			= this.props.numbers
		let selected		= numbers.find(n => n.selected)
		const {classes}		= this.props
		let formattedNumber	= formatPhoneNumber(selected.number)
		let displayText		= (selected.nickname && selected.nickname !== selected.number) ? selected.nickname : formattedNumber

		return (
			<div>
				<Dropdown size='md'>
					<DropdownToggle className={classes.pickNumberDropdownTheme} caret color=''>
						<DefaultArrowTooltip
							title		= {formattedNumber}
							placement	= 'right'
						>
							<span className='fs-block'>{displayText}</span>
						</DefaultArrowTooltip>
					</DropdownToggle>
					<DropdownMenu className={classes.dropdownMenu} size='md'>
						{numbers.map((num, i) => {
							let formattedNumber = formatPhoneNumber(numbers[i].number)
							let displayText = (numbers[i].nickname && numbers[i].nickname !== numbers[i].number) ? numbers[i].nickname : formattedNumber
							return (
								<DefaultArrowTooltip
									key			= {i}
									title		= {formattedNumber}
									placement	= 'right'
								>
									<DropdownItem className={num.selected ? 'selected' : ''} onClick={e => this.onNumberClick(i)}>
										<span className='fs-block'>{displayText}</span>
										<img src={checkmarkSelected} alt='Check' />
									</DropdownItem>
								</DefaultArrowTooltip>
							)
						})}
					</DropdownMenu>
				</Dropdown>
			</div>
		)
	}
}

export default withStyles(styles)(PhoneNumberSelector)