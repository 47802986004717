import React from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	contentWrapper: {
		color:			'#f2f5f6',
		fontSize:		'16px',
		fontWeight:		500,
		lineHeight:		1.31,
		letterSpacing:	'-0.2px'
	},
	myPhoneComTextTheme: {
		color: theme.palette.primary.main,
	},
	emailTextTheme: {
		textDecoration:	'none',
		color:			'#fff'
	},
	pdcIconTheme: {
		width:			'50px',
		marginBottom:	'10px',
		margin:			'auto',
		content:		`url(${theme.icons.smallLogo})`
	},
	pdcTeamTheme: {
		textAlign: 'center'
	},
	logoText: {
		color: '#b6c0c5'
	}
}))

// This may need to be stateful
export default function FirstTimeUserInfoPopupContent(props) {

	const classes = useStyles()
	const theme = useTheme()

	return (
		(theme.FirstTimeUserInfoPopupContentOverride)?
			theme.FirstTimeUserInfoPopupContentOverride:
		<div className={classes.contentWrapper}>
			Introducing <span className={classes.myPhoneComTextTheme}>my.phone.com</span>, the future home of all your Phone.com web services. Here you will discover the tools that provide you with the communication power and freedom to get down to business.
			<br/><br/>
			Today, we’re excited to launch the first phase of our new Messages and Voicemail apps. These feature SMS & MMS messaging as well as group texting, and voicemail features.
			<br/><br/>
			We will be adding more features to <span className={classes.myPhoneComTextTheme}>my.phone.com</span> in the weeks and months to come and it will not change how you take advantage of all our other features and services. Simply continue using them as you have been and share your feedback with us at <a className={classes.emailTextTheme} href="mailto:feedback@phone.com?Subject=my.phone.com%20feedback" target="_top">feedback@phone.com</a>. Thank you, and enjoy!
			<br/><br/>

			<div className={classes.pdcTeamTheme}>
				<div className={classes.pdcIconTheme}></div>
				<br/>
				<span className={classes.logoText}>Your Phone.com team</span>
			</div>
		</div>
	)
}