export function smallView(state = true, action) {
	switch (action.type) {
		case 'SET_SMALL_VIEW':
			return action.smallView
		default:
			return state
	}
}

export function currentView(state = 'select', action) {
	switch (action.type) {
		case 'SWITCH_VIEW':
			let pathnameSplit = window.location.pathname.split('/')
			if (action.view === 'new_conversation' && (pathnameSplit.length < 4 || pathnameSplit[3] !== 'new-conversation')) {
				let extensionId = parseInt(pathnameSplit[1].substring(1))
				window.history.replaceState('New Conversation', 'New Converastion', `/e${extensionId}/messages/new-conversation`)
			}
			return action.view
		default:
			return state
	}
}