import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Fade from '@material-ui/core/Fade'
import TextField from '@material-ui/core/TextField'
import InboxSelector from './InboxSelector'
import SearchContactsItems from './SearchContactsItems'
import TagsInput from 'tags-input'
import PDCButton from 'pdc-button'
import Api from 'api'

import {isValidNumber as isValidNumberCustom} from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
export const isValidNumber	= (...args) => isValidNumberCustom(...args, metadata)

const styles = theme => ({
	hoverShadow: {
		'&:hover': {
			boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.05)',
		}
	},
	dialogModal: {
		position:			'relative',
		width:				'100%',
		top:				'5%',
		backgroundColor:	'rgba(0, 0, 0, 0.4)',
	},
	dialogContent: {
		display:		'flex',
		flexDirection:	'column'
	},
	filtersDiv: {
		display:		'flex',
		alignItems:		'center',
		marginBottom:	12
	},
	searchWrapper: {
		position:	'relative',
		width:		'100%',
	},
	boolFilter: {
		display:		'flex',
		flexDirection:	'column',
		margin:			'0 5px'
	},
	boolFilterItem: {
		color:			'gray',
		textAlign:		'center',
		padding:		'3px 10px',
		borderRadius:	4,
		cursor:			'pointer',
		userSelect:		'none',
		whiteSpace:		'nowrap',
		textDecoration:	'line-through',
		'&:hover': {
			background: 'rgba(0, 0, 0, 0.05)'
		},
		'&:first-child': {
			marginBottom: 3
		},
		'&.active': {
			fontWeight:		500,
			color:			theme.palette.primary.main,
			textDecoration:	'underline',
		}
	},
	searchInput: {
		extends:			'hoverShadow',
		position:			'relative',
		borderRadius:		10,
		backgroundColor:	'white',
		width:				'100%',
		marginRight:		'15px',
	},
	tags: {
		display: 'flex'
	}
})

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction='down' ref = { ref } {...props }/>
// })

class SearchModal extends Component {

	state = {
		sentFilterSelected:		true,
		receivedFilterSelected:	true,
		hasMediaFilterSelected:	true,
		noMediaFilterSelected:	true,
		contactsLoading:		false,
		searchText:		'',
		peopleItems:	[],
		peopleInput:	'',
		contactItems:	[],
	}

	componentDidMount() {
		this.setState({peopleInput: '', searchText: ''})
		// this.getUserContacts()
	}

	getUserContacts = (loadMore=false) => {
		// get all contacts that match name
		// sets contactItems
		let keyword = this.state.peopleInput
		if (!keyword && !loadMore) this.setState({contactItems: [], contactsLoading: false})

		clearTimeout(this.searchContactsTimeout)
		this.searchTimeout = setTimeout(async () => {
			this.setState({contactsLoading: true})

			let cursor			= null
			let contactItems	= this.state.contactItems
			let totalContacts	= this.state.totalContacts
			if (loadMore) cursor = contactItems[contactItems.length - 1].cursor

			let response = await Api.loadContacts({keyword}, 20, cursor)
			this.setState({contactsLoading: false})

			if (response === 'network-error') return

			if (keyword !== this.state.peopleInput) return // When typing fast the responses may not come in order

			if (loadMore) {
				contactItems = contactItems.concat(response.items)
			} else {
				contactItems = response.items
				totalContacts = response.total
			}

			this.setState({contactItems, totalContacts})
		}, 300)
	}

	onPeopleSubmitEntry = inputValue => {
		let contactItems = this.state.contactItems
		// if no contacts found for inputValue onSubmit
		if (!contactItems || contactItems.length === 0) {
			if (isValidNumber(inputValue) || isValidNumber(inputValue, 'US')) {
				contactItems = [{numbers: [{number: inputValue}]}]
			} else return
		}
		if (!contactItems || contactItems.length !== 1) return
		let formatted = contactItems[0].numbers.filter(n => n.number.trim()).map(n => {
			let f = {mainText: n.number, numbers: [n.number]}
			if (n.name && n.name.display)
				f.mainText = n.name.display
				f.additionalText = n.number
			return (f)
		})
		this.onUpdatePeopleItems(formatted)
	}

	// for removing people
	onUpdatePeopleItems = peopleItems => {
		let newItems = []
		let addedNumbers = []
		let personNumber;
		peopleItems.forEach(i => {
			personNumber = i.additionalText ? i.additionalText : i.mainText
			if (addedNumbers.includes(personNumber)) return
			newItems.push(i)
			addedNumbers.push(personNumber)

		})
		this.setState({peopleItems: newItems})
	}

	formatInputValue = inputValue => inputValue.replace(/[\u0250-\ue007]/g, '') // The database doesn't support non-latin characters

	onPeopleInputChange = inputValue => this.setState({peopleInput: this.formatInputValue(inputValue)}, this.getUserContacts)

	onKeyDown = event => {
		if (event.key === 'Escape' || event.keyCode === 27) {
			this.props.onClose()
			this.onUpdatePeopleItems([])
			this.setState({searchText: ''})
		}
		if (event.key === 'Enter' || event.keyCode === 13) {
			this.onSearch()
		}
	}

	onModalKeyDown = event => (event.key === 'Escape' || event.keyCode == '27') ? this.props.onClose() : null

	onSearchContactClick = (numbers, name=null) => {
		let formatted = this.state.peopleItems.concat(numbers.map(n => {
			let contact = {mainText: n, numbers: [n]}
			if (name) {
				contact.additionalText = n
				contact.mainText = name
			}
			return (contact);
		}))
		this.setState({peopleInput: ''})
		this.onUpdatePeopleItems(formatted)
	}

	toggleHasMediaFilter = () => {
		let hasMediaFilterSelected = !this.state.hasMediaFilterSelected
		let noMediaFilterSelected = hasMediaFilterSelected ? this.state.noMediaFilterSelected : true
		this.setState({hasMediaFilterSelected, noMediaFilterSelected})
	}
	toggleNoMediaFilter = () => {
		let noMediaFilterSelected = !this.state.noMediaFilterSelected
		let hasMediaFilterSelected = noMediaFilterSelected ? this.state.hasMediaFilterSelected : true
		this.setState({hasMediaFilterSelected, noMediaFilterSelected})
	}

	toggleSentFilter = () => {
		let sentFilterSelected = !this.state.sentFilterSelected
		let receivedFilterSelected = sentFilterSelected ? this.state.receivedFilterSelected : true
		this.setState({sentFilterSelected, receivedFilterSelected})
	}
	toggleReceivedFilter = () => {
		let receivedFilterSelected = !this.state.receivedFilterSelected
		let sentFilterSelected = receivedFilterSelected ? this.state.sentFilterSelected : true
		this.setState({sentFilterSelected, receivedFilterSelected})
	}

	onSearchTextChange = event => {
		this.setState({searchText: event.target.value})
	}

	onSearch = (loadMore=false) => {
		if (loadMore && this.lastFilters) return this.props.onSearch(this.lastFilters, this.lastInboxType, true)

		// Inbox type
		let location = this.props.location
		let inboxType = {
			messages:	'message',
			voicemail:	'voicemail',
			faxes:		'fax',
			all:		'all'
		}[location || 'all']
		inboxType = 'all'

		let filters = {}
		//  Direction filter
		let directions = ['in', 'out']
		/*
		if (this.state.sentFilterSelected) directions.push('out')
		if (this.state.receivedFilterSelected) directions.push('in')
		#if (inboxType === 'message')
		filters.direction = {in: directions} // Back-end not done/tested for other types


		// Media filter
		let hasMediaFilterSelected = this.state.hasMediaFilterSelected
		let noMediaFilterSelected = this.state.noMediaFilterSelected
		if (!hasMediaFilterSelected || !noMediaFilterSelected) {
			if (hasMediaFilterSelected) {
				filters.media = {length: {gt: 0}}
			} else {
				filters.media = {length: 0}
			}
		}
		if (inboxType !== 'message') delete filters.media // Back-end not done/tested for other types
		*/

		// Text filter
		if (this.state.searchText.trim()) filters.text = this.state.searchText.trim() // Back-end not done/tested for other types

		// Members filter
		let numbers = []
		this.state.peopleItems.forEach(p => numbers.push(...p.numbers))
		if (numbers.length) filters.members = {in: numbers} // Back-end not done/tested for other types

		this.lastFilters	= JSON.parse(JSON.stringify(filters))
		this.lastInboxType	= inboxType

		this.props.onSearch(filters, inboxType)
	}

	renderPeopleFilter = () => {
		if (window.V5PHONECOM.features.has('search-people')) {
			if (this.state.contactItems && !this.props.isMobile) {
				return (
					<>
						<div className={this.props.classes.tags}>
							<TagsInput
								prompt				= 'People'
								notAllowed			= {false}
								inputPlaceholder	= 'John Adams...'
								items				= {this.state.peopleItems}
								updateItems			= {this.onUpdatePeopleItems}
								onChangeInput		= {this.onPeopleInputChange}
								submitEntry			= {this.onPeopleSubmitEntry}
								inputValue			= {this.props.peopleInput}
							/>
						</div>
						<SearchContactsItems
							contactItems	= {this.state.contactItems}
							totalContacts	= {this.state.totalContacts}
							onClick			= {this.onSearchContactClick}
							getUserContacts	= {this.getUserContacts}
						/>
					</>
				)
			}
		}
	}

	renderSearchResults = () => {
		if (this.props.searchResults){
			return (
				<InboxSelector
					resultId			= {this.props.resultId}
					inboxes				= {this.props.searchResults || []}
					totalItems			= {this.props.totalSearchResults}
					queryTime			= {this.props.queryTime}
					searchLoading		= {this.props.searchLoading}
					goToConversation	= {this.props.goToConversation}
					peopleItems			= {this.state.peopleItems}
					onSearch			= {this.onSearch}
					isMobile			= {this.props.isMobile}
				/>
			)
		}
	}

	render() {
		let { classes } = this.props

		return (

			<Dialog
				className			= {classes.dialogModal}
				open				= {this.props.isOpen}
				onClose				= {this.props.onClose}
				TransitionComponent	= {Fade}
				fullWidth			= {true}
				maxWidth			= {this.props.isMobile ? 'lg' : 'md'}
				onKeyDown			= {this.onModalKeyDown}
			>
				<DialogContent classes={{root: classes.dialogContent}}>
					<div className = {classes.searchWrapper}>
						<div className={classes.filtersDiv}>
							<TextField
								className	= {classes.searchInput}
								variant		= 'outlined'
								onKeyDown	= {this.onKeyDown}
								onInput		= {this.onSearchTextChange}
								label		= 'Search Inbox'
								autoFocus
							/>

							<PDCButton variant='outlined' onClick={() => this.onSearch()}>Search</PDCButton>

						</div>

						{this.renderPeopleFilter()}

					</div>

					{this.renderSearchResults()}

				</DialogContent>
			</Dialog>
		)
	}
}

/*
BOOLEAN FILTERS ( media, sent/recieved )

<div className={classes.boolFilter}>
	<span
		className	= {`${classes.boolFilterItem} ${this.state.hasMediaFilterSelected ? 'active' : ''}`}
		onClick		= {this.toggleHasMediaFilter}
	>Has media</span>
	<span
		className	= {`${classes.boolFilterItem} ${this.state.noMediaFilterSelected ? 'active' : ''}`}
		onClick		= {this.toggleNoMediaFilter}
	>No media</span>
</div>
<div className={classes.boolFilter}>
	<span
		className	= {`${classes.boolFilterItem} ${this.state.sentFilterSelected ? 'active' : ''}`}
		onClick		= {this.toggleSentFilter}
	>Sent</span>
	<span
		className	= {`${classes.boolFilterItem} ${this.state.receivedFilterSelected ? 'active' : ''}`}
		onClick		= {this.toggleReceivedFilter}
	>Received</span>
</div>
 */

export default withStyles(styles)(SearchModal)
