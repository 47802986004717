export function addTeammates(teammates) {
	return {
		type: 'ADD_TEAMMATES',
		teammates
	}
}

export function switchContact(contactId) {
	return {
		type: 'SWITCH_CONTACT',
		contactId
	}
}

export function updateTeammates(teammates) {
	return {
		type: 'UPDATE_TEAMMATES',
		teammates
	}
}

export function switchTeammate(teammateId) {
	return {
		type: 'SWITCH_TEAMMATE',
		teammateId
	}
}