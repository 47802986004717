import React, { Component } from 'react'
import Spinner from 'spinner'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	loaderFull: theme.loaderFull
})

class LoaderFull extends Component {
	render() {
		let { classes } = this.props
		let styles = this.props.styles || {}
		return (
			<div className={classes.loaderFull} style={styles.loaderFull}>
				<Spinner size={this.props.size}/>
			</div>
		)
	}
}

export default withStyles(styles)(LoaderFull)