import React, { Component } from "react"
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk"
import Timer from "timer"
import { formatPhoneNumber } from "phone-numbers"
import CallEndIcon from "@material-ui/icons/CallEnd"
import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import CallMergeIcon from "@material-ui/icons/CallMerge"

const styles = (theme) => ({
	container: {
		width: 265,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		cursor: "pointer",

		// "&:hover": {
		// 	backgroundColor: "#26859D",
		// 	opacity: 0.65,
		// },
		// "&:active": {
		// 	opacity: 1,
		// },
	},
	callMenuSelector: {
		height: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.tertiary["-100"],
			opacity: 0.65,
		},
		"&:active": {
			opacity: 1,
		},
	},
	endCallBtn: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		//todo: NOT IN PALETTE
		// background: "#FF6352",
		background: theme.palette.attention["-100"],
		borderRadius: "50%",
		cursor: "pointer",
		width: 50,
		height: 50,
		borderStyle: "solid",
		"&:hover": {
			backgroundColor: theme.palette.attention["+100"],
			opacity: 0.65,
		},
		"&:active": {
			opacity: 1,
		},
	},
})
class CallBarActiveCallArea extends Component {
	render() {
		const { classes } = this.props
		return (
			<div className={classes.container}>
				{this.props.currentCall ? (
					<>
						<div
							className={classes.callMenuSelector}
							onClick={this.props.toggleShowCallSessionSelector}
							data-test-id={"call-session-toggle-button-desktop"}
						>
							{this.props.currentCall.participants.length > 1 ? (
								<CallMergeIcon style={{ marginRight: 10 }} />
							) : (
								<PhoneInTalkIcon style={{ marginRight: 10 }} />
							)}
							{this.props.currentCall && this.props.currentCall.callState === "ACTIVE" ? (
								<Timer startTime={this.props.currentCall.callStartTime} />
							) : null}
							<span style={{ marginLeft: 5 }}>
								{this.props.currentCall.participants.length > 1
									? `${this.props.currentCall.participants.length - 1} CALLERS`
									: this.props.currentCall.participants.length > 0
									? formatPhoneNumber(this.props.currentCall.participants[0].callerId)
									: "NO CALLER ID"}
							</span>
						</div>
						{/*
						<button
							className={classes.endCallBtn}
							onClick={this.props.onEndCallClick}
							data-test-id="calls-callbar-end-btn"
						>
							<CallEndIcon style={{ width: 24, height: 24, color: "white" }} />
							<div style={{ fontSize: 9, marginTop: 3, color: "white" }}>END</div>
						</button>
						*/}
					</>
				) : (
					<div className={classes.callMenuSelector} onClick={this.props.toggleShowCallSessionSelector}>
						{"Pending Calls"}
					</div>
				)}
			</div>
		)
	}
}
export default withStyles(styles)(CallBarActiveCallArea)
