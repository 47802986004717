import React, { Component } from "react"
import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import Dialpad from "dialpad"
import DialpadIcon from "@material-ui/icons/Dialpad"
import ringSound from "./sounds/ring.mp3"
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import AccountBoxTwoToneIcon from "@material-ui/icons/AccountBoxTwoTone"
import PersonIcon from "@material-ui/icons/Person"
import MicIcon from "@material-ui/icons/Mic"
import MicOffIcon from "@material-ui/icons/MicOff"
import { DefaultArrowTooltip } from "tooltips"
import { formatPhoneNumber } from "phone-numbers"
import SignalInfo from "signal-info"
import CallSessionSelector from "call-session-selector"
import { pushCallNotification } from "notification-pusher"
import CallBarActiveCallArea from "./CallBarActiveCallArea"
import CallBarActiveCallBar from "./CallBarActiveCallBar"

const styles = (theme) => ({
	notificationContainer: {
		width: "100%",
		// height: "fit-content",
		minHeight: 36,
		// padding: 10,
		// backgroundImage: "linear-gradient(-315deg, #4f5e69, #212527)",
		zIndex: 3,
		display: "flex",
		// alignItems: "center",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		backgroundColor: theme.palette.secondary["-300"],
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	notificationContainerDesktop: {
		width: "100%",
		height: "fit-content",
		minHeight: 65,
		zIndex: 3,
		display: "flex",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		background: `linear-gradient(90deg, ${theme.palette.tertiary["+100"]} 0%, ${theme.palette.tertiary["-100"]} 99.6%)`,
		// TODO: not in palette
		// background: "linear-gradient(90deg, #278DA7 0%, #34A4C0 99.6%)",
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	notificationBarContent: {
		width: "100%",
		height: "100%",
		minHeight: 36,
		// padding: 10,
		// backgroundImage: "linear-gradient(-315deg, #4f5e69, #212527)",
		zIndex: 3,
		display: "flex",
		// alignItems: "center",
		justifyContent: "space-between",
		textAlign: "center",
		color: "white",
		position: "relative",
		"&.secondary": {
			padding: 10,
		},
		"& .link": {
			textDecoration: "underline",
			color: "white",
			cursor: "pointer",
		},
	},
	callBarContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
	},
	section: {
		flex: 1,
		fontSize: 18,
		"&.mobile": {
			fontSize: 14,
		},
	},
	leftSection: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: 500,
		fontSize: 22,
		"& > span:first-child": {
			marginRight: 10,
		},
		"&.mobile": {
			flexDirection: "column",
			fontSize: 14,
		},
		"&.tablet": {
			fontSize: 18,
		},
	},
	middleSection: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
	},
	dialpadWrapper: {
		height: 24,
		width: 24,
		float: "left",
		// marginRight: 10,
	},
	linkSeparator: {
		padding: "0 5px",
	},
	closeNotificationBar: {
		position: "absolute",
		right: 15,
		top: "50%",
		transform: "translateY(-50%)",
		fontSize: 26,
		cursor: "pointer",
	},
	goToCallIcon: {
		width: 24,
		height: 24,
		cursor: "pointer",
		// marginLeft: 10,
		color: theme.palette.secondary["+200"],
	},
	goToCallIconMobile: {
		width: 24,
		height: 24,
		cursor: "pointer",
		// marginLeft: 10,
		color: "white",
	},
	selectorToggle: {
		alignSelf: "end",
		borderLeftColor: "black",
		borderLeftStyle: "solid",
		borderLeftWidth: "0.25px",
		height: "100%",
		width: "60px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.secondary["-200"],
	},
	callControlCallBarButton: {
		width: "40px",
		height: "40px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// opacity: "0.65",
		borderRadius: "5px",
		backgroundColor: theme.palette.tertiary["-100"],
		"&:hover": {
			backgroundColor: theme.palette.tertiary["-100"],
			opacity: 0.6,
		},
		"&:active": {
			backgroundColor: theme.palette.tertiary["-100"],
			opacity: 0.3,
		},
		cursor: "pointer",
	},
	// mobile version of above
	callControlCallBarContainer: {
		width: "28px",
		height: "28px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&:active": {
			opacity: 1,
			backgroundColor: theme.palette.secondary["-200"],
		},
		cursor: "pointer",
	},
	callMenuContainerMobile: {
		maxWidth: 450,
		position: "absolute",
		zIndex: 7,
		bottom: 27,
		left: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		overflow: "hidden",
		marginBottom: 10,
	},
	callMenuContainer: {
		maxWidth: 450,
		position: "absolute",
		zIndex: 7,
		bottom: 55,
		left: "25%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		borderTopLeftRadius: 7,
		borderTopRightRadius: 7,
		overflow: "hidden",
		marginBottom: 10,
	},
	callMenuContainerTail: {
		borderLeft: "20px solid transparent",
		borderRight: "20px solid transparent",
		borderTop: "20px solid white",
		boxSizing: "border-box",
		// border: "1em solid black",
		borderColor: "transparent transparent white white",
		transformOrigin: "0 0",
		transform: "rotate(-45deg)",
		//TODO: do these count towards palette? diff random black lvls
		boxShadow: "-28px 12px 24px rgba(0, 0, 0, 0.2)",
	},
	callMenuContent: {
		backgroundColor: "white",
		width: "100%",
		minHeight: "51px",
		boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 12px, rgba(0, 0, 0, 0.25) 0px 4px 20px",
		borderBottomRightRadius: "7px",
		borderBottomLeftRadius: "7px",
		overflow: "hidden",
	},
	callBarControlsContainer: {
		maxWidth: "80px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		width: "100%",
		alignItems: "center",
		height: "100%",
	},
	holdAreaEmpty: {
		backgroundColor: theme.palette.tertiary["0"],
		// backgroundColor: "#26859D",
		maxWidth: 275,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
	},
	holdArea: {
		backgroundColor: theme.palette.attention["-100"],
		//TODO: not in palette
		// backgroundColor: "#FF7A6B",
	},
	desktopOnHoldCallContainer: {
		width: 40,
		height: 46,
		"&:hover": {
			backgroundColor: theme.palette.attention["+100"],
		},
		"&:active": {
			opacity: 1,
		},
		cursor: "pointer",
	},
	activeMic: { width: 24, height: 24, color: theme.palette.attention["0"] },
	inactiveMic: { width: 24, height: 24, color: "white" },
	inactiveMicMobile: { width: 24, height: 24, color: theme.palette.secondary["-100"] },
})

const CallState = {
	INACTIVE: "INACTIVE",
	ACTIVE: "ACTIVE",
	INCOMING: "INCOMING",
	CONNECTING: "CONNECTING",
}
class CallBar extends Component {
	state = {
		isDialpadShown: false,
		isExpanded: false,
	}
	name = theme.appName

	componentDidMount = async () => {
		console.log(this.props.callContext)

		if (
			process.env.REACT_APP_IS_CALLING_DISABLED &&
			!(window.V5PHONECOM && window.V5PHONECOM.features.has("calling_enabled"))
		) {
			return
		}
	}

	componentDidUpdate(oldProps, oldState) {
		// if (!this.props.showCallSessionSelector && this.state.isExpanded) {
		// 	this.setState({ isExpanded: false })
		// }
		if (this.props.incomingCallsCnt > oldProps.incomingCallsCnt) {
			let calls = this.props.getIncomingCalls()
			let call = calls[calls.length - 1]
			let participant = call.participants[0]
			let callerId = participant.callerId || participant.phoneNumber
			let phoneNumber = participant.phoneNumber
			let answerAction = () => {
				this.props.answerById(phoneNumber)
			}
			let hangupAction = () => {
				this.props.hangupById(phoneNumber)
			}

			pushCallNotification(callerId, phoneNumber, answerAction, hangupAction)
		}
		//if there is no longer a reason to have controls expanded, collapse so icon changes.
		if ((!this.props.backgroundCalls || this.props.backgroundCalls.length < 0) && !this.props.currentCall) {
			this.setState({ isExpanded: false })
		}
	}

	logConnectionStats() {
		this.setState({ connectionStatusActive: true })
	}

	answerCall = async () => {
		//specific to callbar, should not be auto answering without ID anywhere else.
		//TODO: could we auto assign current call when there isnt one or would that conflict with another scenario?
		if (this.props.currentCall) {
			//change answerbyId
			await this.props.answerById(this.props.currentCall.callId)
		} else if (this.props.incomingCallsCnt > 0) {
			let info = null
			let calls = this.props.getIncomingCalls()
			let call = calls[0]
			if (call) {
				let participant = call.participants[0]
				info = participant.phoneNumber
			}
			if (info) {
				await this.props.answerById(info)
			}
		}
	}

	openDialer = () => {
		//should this be added to openDialer() instead?
		if (this.props.currentCall) {
			this.props.openDialer()
		} else {
			console.log("no active callID, dialer route blocked")
		}
	}

	renderDialpad = () => {
		const { classes, screenViewType } = this.props
		return (
			<div
				className={`${classes.dialpadWrapper} ${
					!screenViewType.isMobileView
						? classes.callControlCallBarButton
						: classes.callControlCallBarContainer
				}`}
			>
				<DialpadIcon
					id="dialpad-button"
					style={{ height: 30, width: 25, cursor: "pointer", color: "white" }}
					classes={{ root: classes.dialpadIcon }}
					onClick={this.showDialpad}
					data-test-id={"call-bar-dialer-button"}
				/>
				<Dialpad
					enableDTMF={true}
					style={{ zIndex: 10001, position: "relative" }}
					open={this.state.isDialpadShown}
					anchorEl={document.getElementById("dialpad-button")}
					onClickAway={() => this.setState({ isDialpadShown: false })}
					// onClick		= {value => this.props.onChangeInput(`${this.props.inputValue}${value}`)}
					onSubmit={() => this.props.submitEntry(this.props.inputValue)}
				/>
			</div>
		)
	}

	showDialpad = () => this.setState({ isDialpadShown: true })

	toggleShowCallSessionSelector = () => {
		if ((this.props.backgroundCalls && this.props.backgroundCalls.length > 0) || this.props.currentCall)
			this.setState({ isExpanded: !this.state.isExpanded })
	}

	onMuteToggle = () => {
		if (this.props.currentCall) {
			this.props.muteLocal(!this.props.isMutedLocal)
		}
	}

	onEndCallClick = (e) => {
		// ignore toggling call menu when clicking end call
		e.stopPropagation()
		const { currentCall } = this.props
		if (this.state.isExpanded) this.toggleShowCallSessionSelector()
		this.props.hangupById(currentCall.callId)
	}

	renderCallSelector = () => {
		const { activeCallId, switchCall, mergeCall, backgroundCalls, currentCall } = this.props
		if (this.state.isExpanded) {
			return (
				<CallSessionSelector
					sessions={backgroundCalls}
					currentCall={currentCall}
					activeCallId={activeCallId}
					switchCall={switchCall}
					mergeCall={mergeCall}
					answerById={this.props.answerById}
					hangupById={this.props.hangupById}
					isMobile={this.props.screenViewType.isMobileView}
				/>
			)
		}
	}

	renderCallerInfo = () => {
		let phoneNumber = ""
		let callerId = ""
		const { currentCall, incomingCallsCnt, getIncomingCalls } = this.props
		if (currentCall && currentCall.participants[0]) {
			let participant = currentCall.participants[0]
			callerId = participant.callerId || participant.phoneNumber
			phoneNumber = participant.phoneNumber
		} else if (incomingCallsCnt > 0) {
			let calls = getIncomingCalls()
			let call = calls[0]
			if (call) {
				let participant = call.participants[0]
				callerId = participant.callerId || participant.phoneNumber
				phoneNumber = participant.phoneNumber
			}
		}
		return (
			<DefaultArrowTooltip title={formatPhoneNumber(phoneNumber)} placement="top">
				<span>{callerId}</span>
			</DefaultArrowTooltip>
		)
	}

	renderCallBarSessionIndicators = () => {
		// const length = 4
		const { backgroundCalls } = this.props
		// TODO: should sipCallManager just do the locking? the ui shouldnt have to confirm if its less than 4
		if (backgroundCalls)
			return backgroundCalls.map((c) => <AccountBoxIcon style={{ width: 22, height: 22, color: "red", display:'inline-block' }} />)
	}

	renderHoldCalls = () => {
		if (this.props.backgroundCalls.length > 0) {
			return (
				<>
					<span style={{ marginLeft: 20, marginRight: 20 }}>{"On Hold"}</span>
					{this.props.backgroundCalls.map((c) => (
						<DefaultArrowTooltip title={c.participants[0] ? c.participants[0].callerId : "not available"}>
							<span className={this.props.classes.desktopOnHoldCallContainer}>
								<PersonIcon
									style={{ width: 30, height: 30, color: "white", marginLeft: 10, marginRight: 10 }}
								/>
							</span>
						</DefaultArrowTooltip>
					))}
				</>
			)
		}
		return "No callers on hold"
	}

	renderCallBar = () => {
		const { classes } = this.props
		return (
			<div className={classes.notificationContainerDesktop} data-test-id={"callbar"}>
				<div className={classes.callBarContainer}>
					{/* overlay div */}
					{this.state.isExpanded ? (
						<div className={classes.callMenuContainer}>
							{/* main content */}
							<div className={classes.callMenuContent}>{this.renderCallSelector()}</div>

							{/* bubble tail */}
							<div className={classes.callMenuContainerTail}></div>
						</div>
					) : null}
					{/* callbar content */}
					<div className={`${classes.notificationBarContent}`} id="notification-bar">
						{/* call controls */}
						<div className={classes.callBarControlsContainer}>
							{/* TODO: move to a call control button class */}
							{/*
							<div className={classes.callControlCallBarButton}>
								<SignalInfo callStats={this.props.callStats} />
							</div>	
							*/}
							{/* <div>{this.renderCallBarSessionIndicators()}</div> */}

							<DefaultArrowTooltip title="Go to the call" placement="top">
								<div className={classes.callControlCallBarButton}>
									<ZoomOutMapIcon
										className={classes.goToCallIconMobile}
										onClick={this.openDialer}
										data-test-id={"go-to-dialer-btn"}
									/>
								</div>
							</DefaultArrowTooltip>

							{/* {this.renderDialpad()} */}

							{/* TODO: insert mic control */}
							{/*
							<div className={classes.callControlCallBarButton} onClick={this.onMuteToggle}>
								{this.props.currentCall ? (
									!this.props.isMutedLocal ? (
										<MicIcon style={{ width: 30, height: 30, color: "white" }} />
									) : (
										<MicOffIcon style={{ width: 30, height: 30, color: "white", opacity: 0.5 }} />
									)
								) : (
									<MicIcon style={{ width: 30, height: 30, color: "white" }} />
								)}
							</div>
							*/}
						</div>

						{/* active call center toggle area */}
						{this.props.currentCall || this.props.backgroundCalls.length > 0 ? (
							<CallBarActiveCallArea
								toggleShowCallSessionSelector={this.toggleShowCallSessionSelector}
								onEndCallClick={this.onEndCallClick}
								currentCall={this.props.currentCall}
								data-test-id={"callbar-session-list-expand"}
							/>
						) : null}

						{/* calls on hold area */}
						<div
							className={`${classes.holdAreaEmpty} ${
								this.props.backgroundCalls.length > 0 ? classes.holdArea : ""
							}`}
						>
							{this.renderHoldCalls()}
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderMobileCallBar = () => {
		const { classes, isDialerOpen } = this.props
		return (
			<div className={classes.notificationContainer} data-test-id={"callbar"}>
				<div className={classes.callBarContainer}>
					<CallBarActiveCallBar
						currentCall={this.props.currentCall}
						visible={!this.state.isExpanded && this.props.currentCall}
					/>

					{/* overlay div */}
					{this.state.isExpanded ? (
						<div className={`${classes.callMenuContainerMobile}`}>
							{/* main content */}
							<div className={classes.callMenuContent}>{this.renderCallSelector()}</div>
						</div>
					) : null}

					{/* callbar content */}
					<div className={`${classes.notificationBarContent}`} id="notification-bar">
						{/* grp 1 */}
						{/*
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<div className={classes.callControlCallBarContainer}>
								<DefaultArrowTooltip title="Put signal info here?">
									<SignalInfo callStats={this.props.callStats} />
								</DefaultArrowTooltip>
							</div>
						</div>
						*/}

						{/* grp 2 */}
						{ !isDialerOpen ?
						<div style={{ paddingLeft:12, display: "flex", flexDirection: "row", alignItems: "center" }}>
							<div>
								{this.props.currentCall ? (
									<DefaultArrowTooltip title="Go to the call" placement="top">
										<ZoomOutMapIcon
											className={classes.goToCallIcon}
											onClick={this.openDialer}
											data-test-id={"go-to-dialer-btn"}
										/>
									</DefaultArrowTooltip>
								) : null}

								{/*this.renderDialpad()*/}
							</div>
						</div>
						: null}


						{/* grp 3 */}
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center", float:'right' }}>
						{/*
							<div onClick={this.onMuteToggle} className={classes.callControlCallBarContainer}>
								{this.props.currentCall ? (
									!this.props.isMutedLocal ? (
										<MicIcon className={classes.activeMic} />
									) : (
										<MicOffIcon className={classes.activeMic} />
									)
								) : (
									<MicIcon className={classes.inactiveMicMobile} />
								)}
							</div>
						*/}
							<div
								className={classes.selectorToggle}
								data-test-id={"callbar-session-list-expand-mobile"}
								onClick={this.toggleShowCallSessionSelector}
							>
								<div>{this.renderCallBarSessionIndicators()}</div>
								{this.state.isExpanded ? (
									<ArrowDropDownIcon className={classes.goToCallIcon} />
								) : (
									<ArrowDropUpIcon className={classes.goToCallIcon} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { classes, isDialerOpen, currentCall, backgroundCalls, screenViewType } = this.props

		let smallView = this.props.screenViewType.isMobileView
		let middleView = this.props.screenViewType.isTabletView
		// let showCallStatus = currentCall && currentCall.callState === CallState.ACTIVE
		if((!isDialerOpen && currentCall) || backgroundCalls.length >0) return (
			<div>
				{!screenViewType.isMobileView ? this.renderCallBar() : this.renderMobileCallBar()}
				{/*this.props.isRinging ? <audio id="ringtone" src={ringSound} loop autoPlay></audio> : ""*/}
			</div>
		)
		return <div></div>
	}
}

export default withStyles(styles)(CallBar)
