import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './teammates-styles'
import LoaderFull from 'loader-full'
import TextField from 'pdc-text-field'
import api from '../../util/api_v5'
import { addTeammates, switchTeammate } from '../../actions/users'
import { switchView } from '../../actions/people'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import { withStyles } from '@material-ui/core'

const mapStateToProps = state => ({
	teammates:			state.teammates,
	currentTeammate:	state.teammates.items.find(t => t.selected)
})

const mapDispatchToProps = dispatch => ({
	addTeammates:	teammates	=> dispatch(addTeammates(teammates)),
	switchTeammate:	teammateId	=> dispatch(switchTeammate(teammateId)),
	switchView:		view		=> dispatch(switchView(view))
})

class TeammateSelector extends Component {

	constructor(props) {
		super(props)
		let areTeammatesLoaded = this.props.teammates.items.length || !this.props.teammates.hasMore
		this.state = {
			searchValue: '',
			loaded: areTeammatesLoaded
		}
		this.loadTeammates(true)
	}

	loadTeammates = async isInitial => {
		let  { teammates } = this.props
		if (teammates && teammates.items.length && isInitial) return setTimeout(() => this.setState({loaded: true}), 1)
		let limit	= 10
		let offset	= teammates.items.length
		teammates	= await api.loadUsers(limit, offset)
		teammates	= teammates.filter(t => t.status !== 'new')
		let teammatesRedux = {
			hasMore:	teammates.length === limit,
			items:		teammates
		}
		this.props.addTeammates(teammatesRedux)
		this.setState({loaded: true})
	}

	onSearchValueChange = searchValue => this.setState({searchValue})

	renderSearchField = () => {
		const { classes } = this.props
		return (
			<div className={classes.searchFieldWrapper}>
				<TextField
					label			= 'Search'
					content			= {this.state.searchValue}
					editable		= {true}
					className		= {{wrapper: classes.searchField}}
					onInputChange	= {this.onSearchValueChange}
				/>
			</div>
		)
	}

	onTeammateClick = teammate => {
		this.props.switchTeammate(teammate.id)
		this.props.switchView('content')
	}

	getFilteredTeammateItems = () => {
		let searchValue		= this.state.searchValue
		let teammateItems	= this.props.teammates.items.filter(user => {
			let name = `${user.first_name} ${user.last_name}`.toLowerCase()
			let personalPhoneNumber = user.personal_phone_number
			let email = `${user.email}`.toLowerCase()
			return name.includes(searchValue.toLowerCase()) ||
				personalPhoneNumber.includes(searchValue.toLowerCase()) ||
				email.includes(searchValue.toLowerCase())
		})
		return teammateItems
	}

	renderTeammateItems = () => {
		let { classes, teammates, currentTeammate }	= this.props
		let currentTeammateId		= currentTeammate ? currentTeammate.id : null
		let teammateItems			= this.getFilteredTeammateItems()
		let formattedItems			= []
		let lastLetter				= ''
		teammateItems.forEach(t => {
			if (lastLetter !== t.first_name[0].toUpperCase()) {
				lastLetter = t.first_name[0].toUpperCase()
				formattedItems.push({letterHeader: true, letter: lastLetter})
			}
			formattedItems.push(t)
		})
		return (
			<InfiniteScroller
				reverseScroll	= {false}
				loadMore		= {this.loadTeammates}
				hasMore			= {teammates.hasMore}
				loader			= {<Spinner/>}
				// onScroll		= {this.onScrollX}
			>
				{formattedItems.map((t, index) => {
					let isPending = t.status === 'invite_pending'
					let selectedClass = t.id === currentTeammateId ? 'selected' : ''
					return (
						t.letterHeader ?
							<div key={`${t.id}-${index}`} className={classes.letterHeader}>{t.letter}</div> :
							<div key={`${t.id}-${index}`} className={`${classes.teammateItem} ${selectedClass}`} onClick={() => this.onTeammateClick(t)}>
								<span>{t.first_name} {t.last_name}</span>
								{isPending ? <span className='teammate-pending'>Has not joined yet</span> : null}
							</div>
					)
				})}
			</InfiniteScroller>
		)
	}

	renderLoader = () => {
		const { classes } = this.props
		if (this.state.loaded) return null
		return <div className={classes.loadingDiv}><LoaderFull/></div>
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.wrapper}>
				{this.renderSearchField()}
				{this.renderTeammateItems()}
				{this.renderLoader()}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeammateSelector))