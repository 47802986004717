import React, { Component } from 'react'
import App from './src/components/App.js'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

const store = createStore(rootReducer)

class PersonalSettings extends Component {
	render() {
		return (
			<Provider store={store}>
				<App
					extension		= {this.props.extension}
					contactsUtil	= {this.props.contactsUtil}
					screenViewType	= {this.props.screenViewType}
					standalone		= {this.props.standalone}
					onLoaded		= {this.props.onLoaded}
					redirect		= {this.props.redirect}
				/>
			</Provider>
		)
	}
}

export default PersonalSettings