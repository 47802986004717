import {theme} from 'get-theme'
let mainTheme=theme


const styles = (t) => {
	let theme = (t.palette.secondary['-300'])? t : mainTheme
return {
	pdcButton: {
		position:		'relative',
		display:		'inline-flex',
		justifyContent:	'center',
		alignItems:		'center',
		border:			'solid 1.5px',
		background:		'transparent',
		letterSpacing:	-0.1,
		textAlign:		'center',
		fontFamily:		'NTR !important',
		borderWidth:	2,
		borderRadius:	22,
		cursor:			'pointer',
		padding:		'13px 28px 11px',
		lineHeight:		'16px',
		fontSize:		20,
		minWidth:		100,
		'-webkit-text-stroke-width': '0.3px',
		'&:focus': {
			outline: 'none'
		},
		'&.icon-space': {
			paddingLeft: 49
		},
		'&.outlined-button': {
			'&.disabled': {
				color:			theme.palette.secondary['-300'],
				background:		'white',
				borderColor:	theme.palette.secondary['-300']
			}
		},
		'&.primary-outlined-button': {
			borderColor:	theme.palette.primary['0'],
			color:			theme.palette.primary['0'],
			'&:hover': {
				borderColor:	theme.palette.primary['+100'],
				color:			theme.palette.primary['+100'],
				background:		'white'
			}
		},
		'&.secondary-outlined-button': {
			borderColor:	theme.palette.secondary['0'],
			color:			theme.palette.secondary['0'],
			'&:hover': {
				borderColor:	theme.palette.secondary['+100'],
				color:			theme.palette.secondary['+100']
			}
		},
		'&.attention-outlined-button': {
			borderColor:	theme.palette.attention['0'],
			color:			theme.palette.attention['0'],
			'&:hover': {
				borderColor:	theme.palette.attention['+100'],
				color:			theme.palette.attention['+100']
			}
		},
		'&.important-outlined-button': {
			borderColor:	theme.palette.important['0'],
			color:			theme.palette.important['0'],
			'&:hover': {
				borderColor:	theme.palette.important['+100'],
				color:			theme.palette.important['+100']
			}
		},
		'&.tertiary-outlined-button': {
			borderColor:	theme.palette.tertiary['0'],
			color:			theme.palette.tertiary['0'],
			'&:hover': {
				borderColor:	theme.palette.tertiary['+100'],
				color:			theme.palette.tertiary['+100'],
				background:		'white'
			}
		},
		'&.filled-button': {
			borderColor:	'transparent',
			color:			'white',
			'&.disabled': {
				color:		'white',
				background:	theme.palette.secondary['-300']
			}
		},
		'&.primary-filled-button': {
			background: theme.palette.primary['0'],
			'&:hover': {
				background: theme.palette.primary['+100']
			}
		},
		'&.secondary-filled-button': {
			background: theme.palette.secondary['0'],
			'&:hover': {
				background: theme.palette.secondary['+100']
			}
		},
		'&.tertiary-filled-button': {
			background: theme.palette.tertiary['0'],
			'&:hover': {
				background: theme.palette.tertiary['+100']
			}
		},
		'&.attention-filled-button': {
			background: theme.palette.attention['0'],
			'&:hover': {
				background: theme.palette.attention['+100']
			}
		},
		'&.important-filled-button': {
			background: theme.palette.important['0'],
			'&:hover': {
				background: theme.palette.important['+100']
			}
		},
		'&.graphic-button': {
			minWidth:		60,
			borderColor:	'transparent',
			'&.add-graphic-button': {
				width:		74,
				height:		44,
				padding:	'14px 29px',
				'& .pdc-button-icon': {
					width:		23,
					height:		23,
					top:		'50%',
					left:		'50%',
					transform:	'translate(-50%, -50%)'
				}
			},
			'&.play-graphic-button': {
				width:			60,
				height:			60,
				padding:		'19px 19px 19px 24px',
				borderRadius:	30,
				'& .pdc-button-icon': {
					width:		17,
					height:		22,
					top:		'50%',
					left:		22,
					transform:	'translateY(-50%)'
				}
			},
			'&.primary-graphic-button': {
				background: theme.palette.primary['-400'],
				'&:hover': {
					background: theme.palette.primary['-300']
				},
				'& svg': {
					color: theme.palette.primary['0']
				}
			},
			'&.secondary-graphic-button': {
				background: theme.palette.secondary['-400'],
				'&:hover': {
					background: theme.palette.secondary['-300']
				},
				'& svg': {
					color: theme.palette.secondary['0']
				}
			},
			'&.tertiary-graphic-button': {
				background: theme.palette.tertiary['-400'],
				'&:hover': {
					background: theme.palette.tertiary['-300']
				},
				'& svg': {
					color: theme.palette.tertiary['0']
				}
			},
			'&.disabled': {
				background: theme.palette.secondary['-400'],
				'& svg': {
					color: theme.palette.secondary['-200']
				}
			}
		},
		'& .pdc-button-icon': {
			position:	'absolute',
			left:		20,
			top:		'50%',
			transform:	'translateY(-50%)',
			width:		20,
			height:		20,
			display:	'flex',
			alignItems:	'center',
			'& > svg': {
				maxHeight:	'100%',
				maxWidth:	'100%'
			}
		}
	}
}};

export default styles