import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './teammate-styles'
import ChooseANumberToCallModal from './ChooseANumberToCallModal'
import { convertNumberToE164 } from 'phone-numbers'
import { switchTeammate } from '../../actions/users'
import {
	ContactAvatarIcon,
	ContactAvatarCamIcon,
	CallCircleFilledIcon,
	MessageCircleFilledIcon
} from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'

const mapStateToProps = state => ({
	teammates:			state.teammates,
	currentTeammate:	state.teammates.items.find(t => t.selected)
})
const mapDispatchToProps = dispatch => ({
	switchTeammate: teammateId => dispatch(switchTeammate(teammateId))
})

class TeammateContent extends Component {

	state = {isChooseANumberModalShown: false}

	renderHeader = () => {
		const { classes, currentTeammate } = this.props
		let teammateName = currentTeammate ? `${currentTeammate.first_name} ${currentTeammate.last_name}` : ''
		return (
			<div className={classes.panelHeader}>
				<div className='name-section'>{teammateName}</div>
			</div>
		)
	}

	renderTeammateDetailsSection = () => {
		const { classes, currentTeammate } = this.props
		if (!currentTeammate) return this.renderNoTeammateSelectedView()
		return (
			<div className={classes.teammateDetailsSection}>
				{this.renderTitleSection()}
				{this.renderTeammateDetails()}
			</div>
		)
	}

	makeCall = number => {
		console.log('number', number)
		this.props.makeCall(number)
	}

	onCallStartClick = () => {
		let { currentTeammate }	= this.props
		let phoneNumber			= currentTeammate.personal_phone_number.trim()
		let extension			= null
		console.log(currentTeammate)
		if (currentTeammate.relationships.extension &&
			currentTeammate.relationships.extension.data &&
			currentTeammate.relationships.extension.data.extension_number
		) extension = currentTeammate.relationships.extension.data.extension_number.toString()
		console.log(extension)
		if (!phoneNumber && !extension) return
		if (!phoneNumber) return this.makeCall(extension)
		if (!extension) return this.makeCall(convertNumberToE164(phoneNumber))
		this.setState({isChooseANumberModalShown: true})
	}

	onConversationStartClick = () => {
		let { currentTeammate }	= this.props
		let phoneNumber			= currentTeammate.personal_phone_number.trim()
		if (!phoneNumber) return
		this.props.startConversation(convertNumberToE164(phoneNumber))
	}

	renderTitleSection = () => {
		const { classes, currentTeammate } = this.props
		let teammateName	= currentTeammate ? `${currentTeammate.first_name} ${currentTeammate.last_name}` : ''
		let phoneNumber		= currentTeammate.personal_phone_number.trim()
		let extension		= null
		if (currentTeammate.relationships.extension &&
			currentTeammate.relationships.extension.data &&
			currentTeammate.relationships.extension.data.extension_number
		) extension = currentTeammate.relationships.extension.data.extension_number
		return (
			<div className={classes.titleSection}>
				<div className='avatar-icon-wrapper'>
					<ContactAvatarIcon className='avatar-icon'/>
					{/* <ContactAvatarCamIcon className='cam-icon'/> */}
				</div>
				<div className='contact-name'>{teammateName}</div>
				{phoneNumber || extension ?
					<div className='actions-wrapper'>
						{!this.props.isVirtualExtension ?
							<CallCircleFilledIcon onClick={this.onCallStartClick}/>
						: null}
						<MessageCircleFilledIcon onClick={this.onConversationStartClick}/>
					</div>
				: null}
			</div>
		)
	}

	renderNoTeammateSelectedView = () => <div className={this.props.classes.noSelectionView}>No teammate selected.</div>

	renderTeammateDetails = () => {
		const { classes }	= this.props
		let currentTeammate	= this.props.currentTeammate
		let extension		= currentTeammate.relationships.extension.data
		return (
			<div className={classes.detailsWrapper}>
				<div className='details-wrapper'>
					<div className='field-wrapper'>
						<span className='title'>Personal phone number</span>
						<span className='value'>{currentTeammate.personal_phone_number}</span>
					</div>
					<div className='field-wrapper'>
						<span className='title'>Email</span>
						<span className='value'>{currentTeammate.email}</span>
					</div>
					<div className='field-wrapper'>
						<span className='title'>Extension</span>
						<span className='value'>{extension.extension_name} - {extension.extension_number}</span>
					</div>
				</div>
			</div>
		)
	}

	onNumberChoose = number => {
		this.makeCall(number)
		this.setState({isChooseANumberModalShown: false})
	}

	renderChooseANumberToCallModal = () => {
		let { isChooseANumberModalShown } = this.state
		let { currentTeammate }	= this.props
		if (!currentTeammate) return null
		let phoneNumber			= currentTeammate.personal_phone_number.trim()
		let extension			= null
		if (currentTeammate.relationships.extension &&
			currentTeammate.relationships.extension.data &&
			currentTeammate.relationships.extension.data.extension_number
		) extension = currentTeammate.relationships.extension.data.extension_number.toString()
		return (
			<ChooseANumberToCallModal
				isShown		= {isChooseANumberModalShown}
				numbers		= {{phoneNumber, extension}}
				onClose		= {() => this.setState({isChooseANumberModalShown: false})}
				onChoose	= {this.onNumberChoose}
			/>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.wrapper}>
				{this.renderHeader()}
				{this.renderTeammateDetailsSection()}
				{this.renderChooseANumberToCallModal()}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeammateContent))