import React, { Component } from 'react'
import api from '../../util/api_v5'
import LoaderFull from 'loader-full'
import Button from 'button'
import SelectionControl from 'selection-control'
import { DefaultArrowTooltip } from 'tooltips'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
	settingWrapper:	{
		...theme.personalSettingsApp.settingWrapper,
		flexDirection: 'column'
	},
	loadingDiv: theme.loadingDiv,
	section: {
		marginBottom:	30,
		width:			500
	},
	sectionTitle: {
		display:		'inline-block',
		padding:		15,
		fontSize:		18,
		background:		'#F2F5F6',
		fontWeight:		'bold',
		lineHeight:		1,
		borderRadius:	4,
		color:			'gray',
		marginBottom:	10
	},
	notificationItem: {
		display:	'flex',
		alignItems:	'center',
		padding:	'7px 0'
	},
	notificationText: {
		display:		'flex',
		flexDirection:	'column',
		'& .main': {
			fontSize:	18
		},
		'& .secondary': {
			fontSize:	14,
			color:		'gray'
		},
		'&.small .main': {
			fontSize:	16
		}
	},
	actionButton: {
		cursor:			'pointer',
		width:			60,
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center'
	},
	saveButton: {
		width: 200
	}
})

const localStorageMap = {
	calls:		'dninccls',
	voicemails:	'dnmsclsvms',
	messages:	'dnmsgs',
	faxes:		'dnfxs'
}

class Notifications extends Component {

	constructor(props) {
		super(props)
		this.state = {loaded: false, saving: false}
		this.loadNotificationSettings()
		this.setLocalStorage()
	}

	setLocalStorage = () => {
		localStorage.dnmsgs		= localStorage.dnmsgs		|| 'false'
		localStorage.dninccls	= localStorage.dninccls		|| 'false'
		localStorage.dnmsclsvms	= localStorage.dnmsclsvms	|| 'false'
	}

	loadNotificationSettings = async () => {
		let notificationsSettings = await api.getNotificationSettings()
		this.setState({loaded: true, notificationsSettings, notificationsSettingsTemp: JSON.parse(JSON.stringify(notificationsSettings))})
	}

	renderNotificationItem = (type, checked, mainText, secondaryText, onClick, disabledMessage) => {
		const { classes } = this.props
		return (
			<div className={classes.notificationItem}>
				<DefaultArrowTooltip
					title		= {disabledMessage}
					placement	= 'top'
				>
					<div>
						<SelectionControl
							variant		= {type}
							checked		= {checked}
							onClick		= {onClick}
							name		= {`checked-${mainText}`}
							value		= {`checked-${mainText}`}
							disabled	= {Boolean(disabledMessage)}
							// className	= {classes.actionButton}
						/>
					</div>
				</DefaultArrowTooltip>
				<div className={`${classes.notificationText} ${type === 'check' ? 'small' : ''}`}>
					<span className='main'>{mainText}</span>
					{secondaryText ? <span className='secondary'>{secondaryText}</span> : null}
				</div>
			</div>
		)
	}

	toggleDesktopNotifications = async type => {
		if (type === 'main') {
			if (Notification.permission === 'default') {
				let response = await Notification.requestPermission()
				if (response === 'granted') {
					Object.values(localStorageMap).forEach(localStorageKey => localStorage[localStorageKey] = 'true')
				}
				this.forceUpdate()
			}
			return
		}
		let currentState = localStorage[localStorageMap[type]]
		localStorage[localStorageMap[type]] = `${'true' !== currentState}`
		this.forceUpdate()
	}

	getDesktopNotificationsStatuses = () => {
		let main		= Notification.permission === 'granted'
		let calls		= main && localStorage[localStorageMap.calls] === 'true'
		let voicemails	= main && localStorage[localStorageMap.voicemails] === 'true'
		let messages	= main && localStorage[localStorageMap.messages] === 'true'
		let faxes		= main && localStorage[localStorageMap.faxes] === 'true'
		return {main, calls, voicemails, messages, faxes}
	}

	renderDesktopNotificationsSection = () => {
		const { classes } = this.props
		let statuses = this.getDesktopNotificationsStatuses()
		let mainDisabled = ''
		if (Notification.permission === 'granted') mainDisabled = 'To disable the notifications do it from the browser settings.'
		if (Notification.permission === 'denied') mainDisabled = 'To enable the notifications do it from the browser settings.'
		let othersDisabled = statuses.main === false ? 'Firstly enable the desktop notifications' : ''
		return (
			<div className={classes.section}>
				<div className={classes.sectionTitle}>DESKTOP NOTIFICATIONS</div>
				{this.renderNotificationItem('switch', statuses.main, 'Desktop notifications', 'Browser permission is needed for desktop notifications.', () => this.toggleDesktopNotifications('main'), mainDisabled)}
				{this.renderNotificationItem('switch', statuses.calls, 'Incoming calls', null, () => this.toggleDesktopNotifications('calls'), othersDisabled)}
				{this.renderNotificationItem('switch', statuses.voicemails, 'New voicemails', null, () => this.toggleDesktopNotifications('voicemails'), othersDisabled)}
				{this.renderNotificationItem('switch', statuses.messages, 'New text messages', null, () => this.toggleDesktopNotifications('messages'), othersDisabled)}
				{this.renderNotificationItem('switch', statuses.faxes, 'Incoming faxes', null, () => this.toggleDesktopNotifications('faxes'), othersDisabled)}
			</div>
		)
	}

	toggleEmailForMessagesFaxesAndVoicemails = () => {
		let notificationsSettingsTemp				= this.state.notificationsSettingsTemp
		let newState								= !notificationsSettingsTemp.messages.email
		notificationsSettingsTemp.messages.email	= newState
		notificationsSettingsTemp.faxes.email		= newState
		notificationsSettingsTemp.voicemails.email	= newState
		this.setState({notificationsSettingsTemp})
	}

	toggleAttachFax = () => {
		let notificationsSettingsTemp				= this.state.notificationsSettingsTemp
		notificationsSettingsTemp.faxes.attach_fax	= !notificationsSettingsTemp.faxes.attach_fax
		this.setState({notificationsSettingsTemp})
	}

	toggleEmailForCalls = () => {
		let notificationsSettingsTemp				= this.state.notificationsSettingsTemp
		notificationsSettingsTemp.calls.send_email	= !notificationsSettingsTemp.calls.send_email
		this.setState({notificationsSettingsTemp})
	}

	getValue = (type, app) => this.state.notificationsSettingsTemp[app][type]

	renderEmailNotificationsSection = () => {
		const { classes } = this.props
		return (
			<div className={classes.section}>
				<div className={classes.sectionTitle}>EMAIL NOTIFICATIONS</div>
				{this.renderNotificationItem('switch', this.getValue('send_email', 'calls'), 'Incoming calls', null, this.toggleEmailForCalls)}
				{this.renderNotificationItem('switch', this.getValue('email', 'messages'), 'Messages, Faxes and Voicemails', null, this.toggleEmailForMessagesFaxesAndVoicemails)}
				{this.renderNotificationItem('checkbox', undefined, 'Include voicemail audio and transcription', null, () => {})}
				{this.renderNotificationItem('checkbox', this.getValue('attach_fax', 'faxes'), 'Attach fax to email (PDF)', null, this.toggleAttachFax)}
			</div>
		)
	}

	areObjectsSame = (o1, o2) => {
		let hasChange = false
		Object.keys(o1).forEach(key => {
			if (hasChange) return
			if (o1[key] !== o2[key]) hasChange = true
		})
		return !hasChange
	}

	checkHasChange = () => {
		// NOTE: Currently messages, faxes and voicemails are same so we can check only for one of them
		let { notificationsSettings, notificationsSettingsTemp } = this.state
		let callsChange	= !this.areObjectsSame(notificationsSettings.calls, notificationsSettingsTemp.calls)
		let faxesChange	= !this.areObjectsSame(notificationsSettings.faxes, notificationsSettingsTemp.faxes)
		return callsChange || faxesChange
	}

	saveSettings = async () => {
		if (this.state.saving) return
		let notificationsSettingsTemp = this.state.notificationsSettingsTemp
		let data = {
			messages_voicemails_faxes:	notificationsSettingsTemp.messages,
			voicemail_attachment_type:	notificationsSettingsTemp.voicemails.attachment_type,
			email_attach_fax:			notificationsSettingsTemp.faxes.attach_fax,
			calls:						notificationsSettingsTemp.calls
		}
		this.setState({saving: true})
		await api.setNotificationSettings(data)
		let updatedNotificationSettings = JSON.parse(JSON.stringify(notificationsSettingsTemp))
		this.setState({notificationsSettings: updatedNotificationSettings, saving: false})
	}

	renderSaveButton = () => {
		const { classes } = this.props
		let hasChange = this.checkHasChange()
		if (this.hasChange !== hasChange) this.props.setBusy(hasChange)
		this.hasChange = hasChange
		return (
			<Button
				disabled	= {!hasChange || this.state.saving}
				onClick		= {this.saveSettings}
				className	= {classes.saveButton}
			>Save</Button>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
				{!this.state.loaded ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div> :
					<>
						{this.state.saving ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div> : null}
						{this.renderDesktopNotificationsSection()}
						{this.renderEmailNotificationsSection()}
						{this.renderSaveButton()}
					</>
				}
			</div>
		)
	}
}

export default withStyles(styles)(Notifications)