import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5'
import { switchView } from '../actions/view.js'
import { addFax, switchFax } from '../actions/faxes.js'
import { generateRandomString } from 'random-generator'

import StartNewPanel from 'start-new-panel'
import AddNoteDialog from './AddNoteDialog.js'
import FaxUploadItem from './FaxUploadItem'
import PDCButton from 'pdc-button'
import Dropzone from 'react-dropzone'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import uploadFaxIcon from '../images/icon-add-file.svg'
import addNoteIcon from '../images/icon-note.svg'

import dotTexture from '../images/dot-texture.svg'
import dragAndDropImage from '../images/drag-n-drop-image.svg'

import jsPDF from 'jspdf'
import { PDFDocument } from 'pdf-lib'
import { uploadSteps } from '../util/uploadUtil.js'

import { Base64 } from 'js-base64'

import { withStyles } from '@material-ui/core'

let styles = theme => ({
	sendFaxWrapper: {
		position:		'relative',
		padding:		'33px 40px',
		display:		'flex',
		flexDirection:	'column',
		height:			'100%',
		overflow:		'auto',
		'&:focus': {
			outline:	'none'
		},
		'&.small-view': {
			padding:	'20px 20px 33px'
		}
	},
	sendingPlaceholder: {
		position:	'absolute',
		top:		0,
		left:		0,
		right:		0,
		bottom:		0,
		background:	'rgba(255, 255, 255, 0.4)',
		cursor:		'not-allowed'
	},
	actionButtonsWrapper: {
		display:			'flex',
		justifyContent:		'center',
		width:				'100%',
		marginBottom:		50,
		'&.small-view': {
			flexDirection:	'column',
			marginBottom:	20,
			'& .action-button': {
				width:		'100%',
				boxSizing:	'border-box',
				padding:	'7px 30px',
				'&:last-child': {
					marginTop: 19
				},
				'&:hover': {
					outline: 'none'
				}
			}
		},
		'& .action-button': {
			display:		'flex',
			cursor:			'pointer',
			padding:		10,
			width:			'50%',
			borderRadius:	5,
			boxSizing:		'content-box',
			'&:hover': {
				outline:		'1px auto',
				outlineColor:	theme.palette.primary.main
			},
			'& .image-wrapper': {
				marginRight:	10,
				width:			70,
				height:			70,
				minWidth:		70
			},
			'& .action-info': {
				display:		'flex',
				flexDirection:	'column',
				justifyContent:	'center',
				'& .action-title': {
					fontSize:		20,
					fontWeight:		600,
					lineHeight:		1.25,
					letterSpacing:	-0.3,
					color:			theme.palette.primary.main,
					whiteSpace:		'nowrap'
				},
				'& .action-description': {
					fontSize:	12,
					lineHeight:	1.33,
					color:		theme.faxesApp.newFaxPanel.buttonDescriptionColor
				}
			}
		}
	},
	errorMessage: {
		color:		'#da0000',
		margin:		'10px 0',
		fontWeight:	'bold',
	},
	separator: {
		width:		'100%',
		height:		3,
		background:	theme.faxesApp.newFaxPanel.separatorColor
	},
	dropArea: {
		position:		'absolute',
		top:			0,
		bottom:			0,
		left:			0,
		right:			0,
		border:			'5px solid',
		borderColor:	theme.faxesApp.newFaxPanel.dropAreaBorderColor,
		background:		theme.faxesApp.newFaxPanel.dropAreaBackgroundColor,
		zIndex:			1,
		'& img': {
			position:	'absolute',
			bottom:		100,
			left:		'50%',
			transform:	'translateX(-50%)'
		}
	},
	filesWrapper: {
		backgroundImage: `url('${dotTexture}')`,
		overflow: 'auto',
		'&>*:not(.dragged)': {
			boxShadow: theme.palette.primary.flatBottomShadow
		}
	},
	sendFaxButton: {
		marginTop:	30,
		width:		'fit-content'
	}
})

const mapStateToProps = state => ({
	smallView: state.smallView
})
const mapDispatchToProps = dispatch => ({
	switchView:	view	=> dispatch(switchView(view)),
	addFax:		fax		=> dispatch(addFax(fax)),
	switchFax:	fax		=> dispatch(switchFax(fax))
})

const MAX_MEDIA_SIZE		= 4000000 // 4MB
const MAX_NUMBER_OF_FILES	= 10

class NewFaxPanel extends Component {

	state = {
		media:				[],
		recipient:			null,
		sending:			false,
		sizeError:			false,
		totalFilesError:	false,
		fileTypeError:		'',
		addNote:			false,
		updateNote:			false
	}

	onSendClick = async () => {
		this.setState({sending: true})
		let media = this.state.media.map(m => m.uploadId ? {upload_id: m.uploadId} : m) // Uncomment once the upload api works fine
		let response = await api.sendFax(this.state.recipient, media)
		this.setState({sending: false})
		if (response.errors) {
			this.setState({sentError: true})
			console.error(response.errors)
		} else if (response.message === 'Internal server error') {
			this.setState({sentError: true})
			console.error(response)
		} else {
			this.setState({media: []})
			this.setState({sentError: false})
			response.justSent = true
			this.props.addFax(response)
			this.props.switchView('content')
			this.props.switchFax(response)
			this.props.switchTab('received')
			this.props.switchTab('sent')
		}
		this.props.loadExtraContacts()
	}

	onDrop = e => {
		let files = e
		for (let i = 0; i < files.length; i++) {
			let file		= files[i]
			let reader		= new FileReader()
			let media		= this.state.media

			let fileId	= generateRandomString(30)
			file.id		= fileId
			let tempFile = {
				id:				fileId,
				progressInfo:	{fillPercentage: 0, interval: null, intervalPeriod: 150},
				uploadStep:		uploadSteps.UPLOAD_TO_BROWSER
			}
			this.setState({media: media.concat(tempFile)})

			reader.onload = this.addFile.bind(this, reader, file)
			reader.readAsDataURL(file)
		}
	}

	addFile = async (reader, file) => {
		let media			= this.state.media
		let mediaFileIndex	= media.findIndex(m => m.id === file.id)
		let url				= reader.result
		let data			= url.split('data:')[1]
		let fileObject		= {
			url:			url,
			filename:		file.name,
			type:			data.split(';')[0],
			encoding:		data.split(';')[1].split(',')[0], 
			data:			data.split(';')[1].split(',')[1],
			size:			file.size,
			progressInfo:	media[mediaFileIndex].progressInfo,
			id:				media[mediaFileIndex].id
		}
		let fileType = fileObject.type.split('/')
		if (fileType[1] !== 'pdf' && fileType[0] !== 'image') {
			let fileIndex = media.findIndex(m => m.id === file.id)
			media.splice(fileIndex, 1)
			return this.setState({media, fileTypeError: 'Currently only PDF and images are supported'})
		}

		if (this.state.fileTypeError) this.setState({fileTypeError: ''})

		let fileBlob = null
		// If it's image then it should be converted to PDF
		if (fileType[0] === 'image') {
			media[mediaFileIndex].uploadStep = uploadSteps.CONVERT_TO_PDF
			this.setState({media})
			let response = await this.convertImageToPDF(fileObject)
			fileObject	= response.fileObject
			fileBlob	= response.fileBlob
		} else {
			let newReader = new FileReader()
			let numPages = await (new Promise(resolve => {
				newReader.readAsArrayBuffer(file)
				newReader.onload = async () => {
					let thePdf = await PDFDocument.load(newReader.result)
					resolve(thePdf.getPageCount())
				}
			}))
			fileObject.numPages = numPages
		}

		fileObject.uploadStep	= uploadSteps.AUTHORIZE
		mediaFileIndex			= media.findIndex(m => m.id === file.id)
		if (!mediaFileIndex === -1) return
		Object.assign(media[mediaFileIndex], fileObject)
		this.setState({media})
		this.validateMedia()

		let uploadAuthorization	= await api.authorizeUpload() // await this.fakeAuthorizeUpload()
		fileObject.uploadStep	= uploadSteps.UPLOAD_TO_SERVER
		mediaFileIndex			= media.findIndex(m => m.id === file.id)
		if (!mediaFileIndex === -1) return
		Object.assign(media[mediaFileIndex], fileObject)
		this.setState({media})
		console.log('uploadAuthorization:', uploadAuthorization)

		let uploadResponse		= await this.uploadFile(fileBlob || file, uploadAuthorization) // await this.fakeUploadFile()
		fileObject.uploadStep	= uploadSteps.FINISHED
		fileObject.uploadId		= uploadAuthorization.upload_id
		mediaFileIndex			= media.findIndex(m => m.id === file.id)
		if (!mediaFileIndex === -1) return
		Object.assign(media[mediaFileIndex], fileObject)
		this.setState({media})
		console.log('uploadResponse:', uploadResponse)
	}

	fakeAuthorizeUpload	= () => new Promise(resolve => setTimeout(resolve, 1000))
	fakeUploadFile		= () => new Promise(resolve => setTimeout(resolve, 2500))

	uploadFile = (file, params) => {
		let { url, fields } = params
		let xhr = new XMLHttpRequest()
		xhr.open('POST', url)

		let postData = new FormData()
		for (let key in fields) {
			postData.append(key, fields[key])
		}
		postData.append('file', file)

		xhr.onreadystatechange = function() {
			if (xhr.readyState !== 4) return
			if ([200, 204].includes(xhr.status)) console.log('Success!')
			else console.log('Could not upload file.')
		}

		xhr.send(postData)
	}

	convertImageToPDF = fileObject => {
		return new Promise((resolve, reject) => {
			let fileType	= fileObject.type.split('/')
			let image		= new Image()
			image.src		= fileObject.url
			image.onload	= () => {
				let imageWidth	= image.width
				let imageHeight	= image.height

				let doc			= new jsPDF()
				let docWidth	= doc.internal.pageSize.getWidth()
				let docHeight	= doc.internal.pageSize.getHeight()
				let pdfValues	= this.getPdfValues(imageWidth, imageHeight, docWidth, docHeight)
				// console.log('pdfValues:', pdfValues)

				doc.addImage(fileObject.data, fileType[1].toUpperCase(), pdfValues[0], pdfValues[1], pdfValues[2], pdfValues[3], 'MEDIUM')
				// doc.save('test.pdf')
				let docData		= doc.output('datauristring')
				let fileBlob	= doc.output('blob')
				let newFileObject = {
					url:			docData,
					filename:		`${fileObject.filename}.pdf`,
					type:			'application/pdf',
					encoding:		'base64',
					data:			docData.split(';')[2].split(',')[1],
					size:			docData.split(';')[2].split(',')[1].length,
					id:				fileObject.id,
					progressInfo:	fileObject.progressInfo,
					numPages:		doc.internal.getNumberOfPages()
				}
				resolve({fileObject: newFileObject, fileBlob})
			}
		})
	}

	getPdfValues = (imageWidth, imageHeight, docWidth, docHeight) => {
		let offsetTop		= 10
		let offsetLeft		= 10
		let width			= 150
		let height			= 150
		let minOffsetTop	= 10
		let minOffsetLeft	= 10
		let fitWidth		= docWidth - (minOffsetLeft * 2) // Maximum width of the image (included padding to the full pdf doc width)
		let fitHeight		= docHeight - (minOffsetTop * 2) // Maximum height of the image (included padding to the full pdf doc height)

		// 1. Image is narrower than the fitWidth and shorter than the fitHeight
		if (imageWidth <= fitWidth && imageHeight <= fitHeight) {
			width		= imageWidth
			height		= imageHeight
			offsetTop	= (docHeight - height) / 2
			offsetLeft	= (docWidth - width) / 2
		}

		// 2. Image is wider than the fitWidth and shorter than the fitHeight
		else if (imageWidth >= fitWidth && imageHeight <= fitHeight) {
			width		= fitWidth
			height		= parseInt(width * imageHeight / imageWidth)
			offsetTop	= (docHeight - height) / 2
		}

		// 3. Image is narrower than the fitWidth and higher than the fitHeight
		else if (imageWidth <= fitWidth && imageHeight >= fitHeight) {
			height		= fitHeight
			width		= parseInt(height * imageWidth / imageHeight)
			offsetLeft	= (docWidth - width) / 2
		}

		// 4. Image is wider than the fitWidth and higher than the fitHeight
		else if (imageWidth >= fitWidth && imageHeight >= fitHeight) {
			if (imageWidth > imageHeight) {
				width			= docWidth - 20
				height			= parseInt(width * imageHeight / imageWidth)
				let newvalues	= this.getPdfValues(width, height, docWidth, docHeight)
				offsetLeft		= newvalues[0]
				offsetTop		= newvalues[1]
				width			= newvalues[2]
				height			= newvalues[3]
			}

			if (imageWidth <= imageHeight) {
				height			= docHeight - 20
				width			= parseInt(height * imageWidth / imageHeight)
				let newvalues	= this.getPdfValues(width, height, docWidth, docHeight)
				offsetLeft		= newvalues[0]
				offsetTop		= newvalues[1]
				width			= newvalues[2]
				height			= newvalues[3]
			}
		}

		return [offsetLeft, offsetTop, width, height]
	}

	removeFile = index => {
		let media = this.state.media
		if (media[index].progressInfo) clearInterval(media[index].progressInfo.interval)
		media.splice(index, 1)
		this.setState({media})
		this.validateMedia()
	}

	validateMedia = () => {
		let media = this.state.media
		let totalSize = 0
		media.forEach(m => totalSize += m.size)
		// this.setState({totalFilesError: media.length > MAX_NUMBER_OF_FILES, sizeError: totalSize > MAX_MEDIA_SIZE})
		if (!media.length) setTimeout(() => this.setState({sentError: false}), 1000)
	}

	onDragStart = dragInfo => {
		this.setState({draggingId: dragInfo.draggableId})
	}

	onDragEnd = result => {
		let { destination, source } = result
		this.setState({draggingId: null})
		if (!destination) return
		if (destination.index === source.index) return
		let media			= this.state.media
		let updatedMedia	= Array.from(media)
		let draggedItem		= updatedMedia.splice(source.index, 1)[0]
		updatedMedia.splice(destination.index, 0, draggedItem)
		this.setState({media: updatedMedia})
	}

	renderActionButtons = attachFiles => {
		const { classes } = this.props
		return (
			<div className={`${classes.actionButtonsWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
				<div className='action-button' onClick={() => this.setState({addNote: true})}>
					<div className='image-wrapper' style={{backgroundImage: `url(${addNoteIcon})`}}></div>
					<div className='action-info'>
						<span className='action-title'>Add note</span>
						<span className='action-description'>Include a message with your document</span>
					</div>
				</div>
				<div className='action-button' onClick={attachFiles}>
					<div className='image-wrapper' style={{backgroundImage: `url(${uploadFaxIcon})`}}></div>
					<div className='action-info'>
						<span className='action-title'>Add file</span>
						<span className='action-description'>or drag-n-drop your document here</span>
					</div>
				</div>
			</div>
		)
	}

	onItemClick = file => {
		if (file.type !== 'text/plain') return
		this.setState({updateNote: {id: file.id, content: Base64.decode(file.data)}})
	}

	renderFileItemWrapper = (file, index) => {
		const { classes } = this.props
		let isDragged = file.id === this.state.draggingId
		return (
			<Draggable
				draggableId	= {file.id}
				index		= {index}
				key			= {file.id}
			>
				{provided => (
					<FaxUploadItem
						isDragged	= {isDragged}
						provided	= {provided}
						onClick		= {() => this.onItemClick(file)}
						file		= {file}
						index		= {index}
						removeFile	= {this.removeFile}
					/>
				)}
			</Draggable>
		)
	}

	updateRecipient = recipients => this.setState({recipient: recipients.length ? recipients[0].number : null})

	addNote = (note, type) => {
		let media = this.state.media
		let newMedia = type === 'new' ? media.concat({
			type:		'text/plain',
			filename:	'Note',
			id:			`${note.split(' ').join('').substring(0, 15)}${(new Date()).getTime()}`,
			data:		Base64.encode(note)
		}) : media.map(m => {
			if (m.id === note.id) m.data = Base64.encode(note.content)
			return m
		})
		this.setState({media: newMedia})
		if (this.state.fileTypeError) this.setState({fileTypeError: ''})
	}

	checkIfIsUploading = () => Boolean(this.state.media.find(m => m.uploadStep !== uploadSteps.FINISHED && m.type !== 'text/plain'))

	render() {

		const { classes } = this.props
		let hasValidationError = this.state.fileTypeError || this.state.totalFilesError || this.state.sizeError
		let isUploading = this.checkIfIsUploading()

		return (
			<StartNewPanel
				extension						= {this.props.extension}
				contactsUtil					= {this.props.contactsUtil}
				startChatButton					= {null}
				startChatButtonDisabled			= {null}
				singleContact					= {true}
				renderChildrenIfContactSelected	= {true}
				allowShortCodes					= {false}
				inputPlaceholder				= {'Add a fax number'}
				inputNotAllowed					= {false}
				goBackText						= 'New Fax'

				updateContacts					= {this.props.updateContacts}
				goBack							= {() => this.props.switchView('select')}
				updateRecipients				= {this.updateRecipient}
			>
				<Dropzone onDrop={this.onDrop} disabled={this.state.sending ? true : false}>
					{({getRootProps, getInputProps, isDragActive, open}) => {
						return (
							<div
								className={`${classes.sendFaxWrapper} ${this.props.smallView ? 'small-view' : ''}`}
								{...getRootProps({onClick: e => e.preventDefault()})}
								onClick={e => e.preventDefault()}
							>
								{isDragActive ? 
									<div className={classes.dropArea}>
										<img src={dragAndDropImage} alt='Drop your file to add it to your fax'/>
									</div>
								: null }
								{this.renderActionButtons(open)}
								{hasValidationError || this.state.sentError ? 
									<div className={classes.errorMessage}>
										{this.state.totalFilesError ? 'Max number of files is 5' : ''}
										{this.state.sizeError ? 'Files total size can not be more than 4MB' : ''}
										{this.state.sentError ? 'Error occured. The fax was not sent. Please try again.' : ''}
										{this.state.fileTypeError}
									</div>
								: null}
								<div className={classes.separator}></div>
								<input type='file' {...getInputProps()} />
								{this.state.media.length ?
									<DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
										<Droppable droppableId='items-wrapper'>
											{provided => (
												<div
													className={classes.filesWrapper}
													ref={provided.innerRef}
													{...provided.droppableProps}
												>
													{this.state.media.map(this.renderFileItemWrapper)}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								: null}
								<PDCButton
									className	= {classes.sendFaxButton}
									onClick		= {this.onSendClick}
									disabled	= {hasValidationError || !this.state.media.length || this.state.sending || isUploading}
								>
									{this.state.sending ? 'Sending fax ...' : 'Send Fax'}
								</PDCButton>
								{this.state.sending ? <div className={classes.sendingPlaceholder}></div> : null}
							</div>
						)
					}}
				</Dropzone>
				<AddNoteDialog
					open	= {Boolean(this.state.addNote || this.state.updateNote)}
					note	= {this.state.addNote ? '' : this.state.updateNote ? this.state.updateNote : ''}
					onClose = {() => this.setState({addNote: false, updateNote: false})}
					addNote	= {this.addNote}
				/>
			</StartNewPanel>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NewFaxPanel))