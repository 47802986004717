/* react-linkify but I modified the decorators */
import LinkifyFork from 'linkify'
import React, { Component } from 'react'
import customComponentDecorator from 'custom-component-decorator'

class Linky extends Component {
    render() {
        return (
            <LinkifyFork componentDecorator={customComponentDecorator}>
                {this.props.children}
            </LinkifyFork>
        )
    }
}

export default Linky