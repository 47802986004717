import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectorItem from 'selector-item'
import FaxIconLite from '../images/icon-fax-lite.svg'
import ConfirmDeleteModal from 'confirm-delete-modal'
import FaxHeaderDropdownMenu from './FaxHeaderDropdownMenu'
import sentIcon from '../images/icon-sent-blk-small.svg'
import failedIcon from '../images/icon-failed-small.svg'
import inProgressIcon from '../images/icon-in-progress-small.svg'
import { withStyles } from '@material-ui/core'

const mapStateToProps = state => ({
	faxes:		state.faxes,
	currentFax:	state.currentFax
})

const mapDispatchToProps = dispatch => ({})

const styles = theme => ({
	beforeMainText: {
		marginRight: '7px',
		'&>svg': {
			width:	'20px',
			height:	'20px'
		}
	},
	additionalText: {
		display:	'flex',
		alignItems:	'center',
		fontWeight:	'bold',
		color:		'black',
		'& > span': {
			marginLeft:	3,
			whiteSpace:	'nowrap'
		}
	}
})

class FaxSelectorItem extends Component {

	state = {showDeleteFaxDialog: false}

	constructor(props) {
		super(props)
		this.expandableAreaRef = React.createRef()
	}

	renderFaxIcon = () => {
		let { classes } = this.props
		return <span className={classes.beforeMainText}><img src={this.props.fax.is_new ? FaxIconLite : FaxIconLite} alt='Fax' /></span>
	}

	onDeleteClick = () => this.setState({showDeleteFaxDialog: true})

	hideDialog = () => this.setState({showDeleteFaxDialog: false})

	deleteAndSwitchFax = () => {
		this.props.deleteFax()
		this.hideDialog()
	}

	renderDropdownMenu = () => {
		return (
			<FaxHeaderDropdownMenu
				deleteFax			= {this.onDeleteClick}
				changeReadStatus	= {{call: this.props.changeReadStatus, text: `Mark as ${this.props.fax.is_new ? '' : 'un'}read`}}
				downloadFax			= {{url: this.props.fax.link}}
			/>
		)
	}

	getExpandedAreaMaxHeigth = () => {
		return this.expandableAreaRef ? `${this.expandableAreaRef.current.scrollHeight + 56}px` : 0
	}

	getParticipants = () => {
		let fax = this.props.fax
		let number = fax.direction === 'in' ? fax.fromNumber : fax.to.number
		let name = fax.direction === 'in' ? fax.fromName : fax.to.name
		this.props.extraContacts.forEach(cd => {
			let foundNumber = cd.numbers.find(n => n.number === number)
			if (!foundNumber) return
			name = cd.name.display
		})
		return [{number, name}]
	}

	render() {
		let { classes, fax }	= this.props
		let isExpanded			= fax ? Boolean(fax.expanded) : false
		let text				= <span>{`${fax.pages} pgs`}</span>
		let timestamp			= !isExpanded ? fax.created_at : ''
		let isUnread			= fax.is_new
		let styles				= {}
		if (isUnread) {
			styles.mainText = {
				color: 'black',
				fontWeight: 500
			}
		}

		let additionalText = null
		if (fax.direction === 'out') {
			let statusIcons = {
				sent:			sentIcon,
				in_progress:	inProgressIcon,
				failed:			failedIcon
			}
			additionalText = fax.delivery_status || fax.justSent ?
				<div className={classes.additionalText}>
					<img src={statusIcons[fax.delivery_status || 'in_progress']} alt='Sent' />
					<span>{fax.delivery_status ? fax.delivery_status.split('_').join(' ') : 'in progress'}</span>
				</div>
			: null
		}
		return (
			<>
				<SelectorItem
					mainText		= {text}
					additionalText	= {additionalText}
					beforeMainText	= {this.renderFaxIcon()}
					participants	= {this.getParticipants()}
					timestamp		= {timestamp}
					showMyNums		= {false}
					active			= {this.props.active}
					isNew			= {false}
					bold			= {isUnread || fax.justSent || (fax.delivery_status === 'failed' && fax.is_new)}
					onClick			= {this.props.onClick}
					styles			= {styles}
					renderDropdownMenu = {isExpanded ? this.renderDropdownMenu : null}
					setRef			= {this.props.setRef}
				/>
				<ConfirmDeleteModal
					itemType					= 'fax'
					isShown						= {this.state.showDeleteFaxDialog}
					acknowledgedTitle			= 'Permanently delete fax'
					notAcknowledgedTitle		= 'Permanently delete fax'
					acknowledgedMainContent		= ''
					notAcknowledgedMainContent	= ''
					onClose 					= {this.hideDialog}
					onDelete					= {this.deleteAndSwitchFax}
				/>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FaxSelectorItem))