import React, { Component } from 'react'
import { generateRandomString } from 'random-generator'

// In miliseconds
const intervalSpeeds = {
	SLOW:	80,
	MEDIUM:	16,
	FAST:	8,
	FASTER:	3
}

/**
 * Props:
 *	progressThresholds	- Required
 *						- Object
 *						- Key is the step
 *						- Value is an object with 'min' and 'max' values which tell the boundaries for that step
 *
 *	intervalSpeeds		- Optional
 *						- Object
 *						- Keys: 'SLOW', 'MEDIUM', 'FAST' and 'FASTER' and the value of each is miliseconds.
 *						- Default: 80, 16, 8, 3 respectively.
 *						- It goes with SLOW speed when the progress is between the current step's boundaries.
 *						- It goes with MEDIUM speed when the pogress is less than the min boundary of the current step.
 *						- It goes with FAST speed when the progress is > 80% and the currentStep is the lastStep.
 *						- It goes with FASER speed when the progress is <= 80% and the currentStep is the lastStep.
 *
 *	currentStep			- Required
 *						- Int or string
 *						- Identification of the current step.
 *
 *	lastStep			- Optional
 *						- Int or string
 *						- Default: 'LAST'
 *						- Identification of the last step which tells to the progress bar to fill to 100%.
 *
 *	onProgress			- Optional
 *						- Function
 *						- Default: null
 *						- Called when the progress bar changes its width
 *
 *	children			- Optional
 *						- An element whose width will be changed percentually
 *						- If not given then you can do it yourself in the parent component
 *							by getting the progress width percentage information using the onProgress prop
 *
 *	showSlider			- Optional
 *						- Boolean
 *						- If yes then it will show a white slider over the progress bar which will go from left to right repeatedly
 */

class ProgressBar extends Component {

	intervalPeriod			= 5
	progressWidthPercentage	= 0
	id						= generateRandomString(30)

	componentDidMount() {
		this._mounted = true
		this.processProgress()
		if (this.props.showSlider) this.addSlider()
	}

	addSlider = () => {
		let progressBarElement				= document.getElementById(this.id)
		let sliderDiv						= document.createElement('div')
		progressBarElement.style.position	= 'relative'
		progressBarElement.style.overflow	= 'hidden'
		progressBarElement.appendChild(sliderDiv)
		let sliderId						= `${this.id}_slider`
		sliderDiv.setAttribute('id', sliderId)
		sliderDiv.style.position			= 'absolute'
		sliderDiv.style.left				= '-100%'
		sliderDiv.style.height				= '100%'
		sliderDiv.style.width				= '60px'
		sliderDiv.style.backgroundImage		= 'linear-gradient(to right, rgba(0,0,0,0), rgba(255,255,255,0.5), rgba(0,0,0,0))'
		this.sliderInterval = setInterval(() => {
			if (!this._mounted) return clearInterval(this.sliderInterval)
			let sliderDiv			= document.getElementById(`${this.id}_slider`)
			let sliderPosition		= parseInt(sliderDiv.style.left.split('%')[0])
			sliderPosition			= sliderPosition > 100 ? -100 : sliderPosition + 1
			sliderDiv.style.left	= `${sliderPosition}%`
		}, 8)
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate() {
		if (!this._mounted) return
		this.processProgress()
	}

	processProgress = () => {
		let progressBarElement	= document.getElementById(this.id)
		let currentStep			= this.props.currentStep
		let lastStep			= this.props.lastStep || 'LAST'
		let progressThresholds	= this.props.progressThresholds
		Object.assign(intervalSpeeds, this.props.intervalSpeeds || {})

		let getIntervalSpeed = () => {
			let intervalSpeed = intervalSpeeds.SLOW
			if (progressThresholds[currentStep].min > this.progressWidthPercentage) intervalSpeed = intervalSpeeds.MEDIUM
			if (progressThresholds[currentStep].min <= this.progressWidthPercentage
				&& this.intervalPeriod !== intervalSpeeds.SLOW
			) intervalSpeed = intervalSpeeds.SLOW
			if (currentStep === lastStep) {
				intervalSpeed = this.progressWidthPercentage > 80 ? intervalSpeeds.FAST : intervalSpeeds.FASTER
			}
			return intervalSpeed
		}

		let newIntervalPeriod = getIntervalSpeed()

		if (this.uploadStep
			&& this.uploadStep === currentStep
			&& this.intervalPeriod === newIntervalPeriod
			&& this.progressBarInterval
		) return

		this.uploadStep = currentStep

		this.intervalPeriod = newIntervalPeriod
		clearInterval(this.progressBarInterval)
		this.progressBarInterval = setInterval(() => {
			if (!this._mounted) {
				clearInterval(this.sliderInterval)
				return clearInterval(this.progressBarInterval)
			}
			let progressWidthPercentage = this.progressWidthPercentage
			if (progressWidthPercentage === 100) {
				clearInterval(this.progressBarInterval)
				clearInterval(this.sliderInterval)
				return this.progressBarInterval = null
			}
			if (progressThresholds[currentStep].max <= progressWidthPercentage) return
			progressWidthPercentage += currentStep !== lastStep ? 0.5 : progressWidthPercentage > 80 ? 1 : 2
			if (progressWidthPercentage > 100) progressWidthPercentage = 100
			this.progressWidthPercentage = progressWidthPercentage
			if (progressBarElement) progressBarElement.style.width = `${progressWidthPercentage}%`
			if (this.props.onProgress) this.props.onProgress(progressWidthPercentage)
		}, newIntervalPeriod)
	}

	render() {
		return React.Children.map(this.props.children, (element, index) => {
			if (index > 0) return null
			return React.cloneElement(element, {ref: this.id, id: this.id})
		}) || <></>
	}
}

export default ProgressBar