export function currentVoicemail(state=null, action) {
	switch (action.type) {
		case 'SWITCH_VOICEMAIL':
			let voicemailId = action.voicemail ? action.voicemail.id : null
			sessionStorage.setItem('session_voicemail_id', voicemailId)
			let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
			if (voicemailId) {
				window.history.replaceState(`VoicemailId: ${voicemailId}`, 'Voicemail Switched', `/e${extensionId}/voicemail/v${voicemailId}`)
			} else {
				window.history.replaceState(`No voicemailId`, 'Voicemail Switched', `/e${extensionId}/voicemail`)
			}
			return action.voicemail ? {...action.voicemail} : null
		default:
			return state
	}
}

export function voicemails(state = {}, action) {
	let voicemails		= state
	let voicemail		= null
	let voicemailItems	= null
	switch (action.type) {
		case 'UPDATE_VOICEMAILS':
			voicemails		= action.voicemails
			voicemailItems	= (voicemails && voicemails.items) ? voicemails.items : [] // ran into cases where voicemails was undified
			voicemailItems.forEach(voicemailItem => {
				if (voicemailItem.direction) return
				formatVoicemail(voicemailItem)
			})
			voicemails.items = [...voicemailItems]
			return {...voicemails}
		case 'UPDATE_VOICEMAIL':
			voicemail = action.voicemail
			let voicemailIndex = voicemails.items.findIndex(v => v.id === voicemail.id)
			if (voicemailIndex >= 0) {
				formatVoicemail(voicemail)
				voicemails.items[voicemailIndex] = voicemail
			}
			return {...voicemails}
		case 'EXPAND_VOICEMAIL':
			voicemails.items.forEach(v => {
				if (action.voicemailId === null) return v.expanded = false
				if (v.id === action.voicemailId) return v.expanded = true
				v.expanded = false
			})
			return {...voicemails}
		case 'DELETE_VOICEMAIL':
			voicemails.total--
			if (action.voicemail.is_new) voicemails.unread--
			voicemails.items = voicemails.items.filter(v => v.id !== action.voicemail.id)
			return {...voicemails}
		case 'ADD_VOICEMAIL':
			voicemail = action.voicemail
			voicemails.total++
			if (voicemail.is_new) voicemails.unread++
			formatVoicemail(voicemail)
			voicemails.items.unshift(voicemail)
			return {...voicemails}
		case 'ADD_CONTACTS_TO_VOICEMAILS':
			voicemailItems = voicemails.items
			voicemailItems.forEach(v => {
				if (v.from.contact_id) return
				let foundContact = action.contacts.find(cd => cd.numbers.find(n => n.number === v.from.number))
				if (!foundContact) return
				v.from.contact_id = foundContact.id
			})
			return {...voicemails}
		case 'REMOVE_CONTACT_FROM_VOICEMAILS':
			let contactId	= action.contactId
			voicemailItems	= voicemails.items
			voicemailItems.forEach(v => v.from.contact_id === contactId ? delete v.from.contact_id : null)
			return {...voicemails}
		default:
			return state
	}
}

let formatVoicemail = voicemail => {
	voicemail.direction		= 'in'
	voicemail.fromNumber	= (voicemail.from && voicemail.from.number)? voicemail.from.number: voicemail.fromNumber
	voicemail.from			= (voicemail.from)? voicemail.from : voicemail.fromNumber;
	voicemail.fromInfo		= formatFromInfo((voicemail.from)? voicemail.from : voicemail.fromNumber)
}

let formatFromInfo = fromData => {

	let formatted = []
	for (let i of ['name', 'city', 'state']) {
		if (fromData[i] && fromData[i] !== 'Unavailable') {
			formatted.push(fromData[i])
		}
	}

	formatted = formatted.join(' ')
	if (formatted.length === 0) {
		formatted = fromData['number']
	}

	return formatted
}