import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import InboxSelectorItem from './InboxSelectorItem'

const styles = theme => ({
	infiniteScroller: {
		height:			'100%',
		marginBottom:	10,
		overflow:		'hidden',
		display:		'flex',
		flexDirection:	'column'
	},
	resultsFound: {
		margin:		'0px 5px 4px',
		fontStyle:	'italic',
		color:		'gray'
	}
})

class InboxSelector extends Component {

	removeDuplicates(items) {
		let start = Date.now()
		let keys = ['to', 'text', 'created_at']
		// let unique = []
		/*
		&& Math.abs(u.created_at - i.created_at) < 60
				items.map(i => unique.filter(u => i.to == u.to && i.text == u.text ).length > 0 ? null : console.log(i))

		 */

		// BELOW WORKS,
		// EDGE CASE: except if created_at off by one second, need a way to Math.abs() the created time
		let unique = items.filter(
			(s => o =>
					(k => !s.has(k) && s.add(k))
					(keys.map(k => o[k]).join('|'))
			)
			(new Set)
		);

		//listOfTags.reduce((newListOfTags, current) => {
		//     if (!newListOfTags.some(x => x.label == current.label && x.color == current.color)) {
		//         newListOfTags.push(current);
		//     }
		//     return newListOfTags;
		// }, []);

		// let unique = items.reduce((newItems, current) => {
		// 	if (!newItems.some(i => i.to == current.to && i.text == current.text && Math.abs(parseInt(i.created_at, 10) - parseInt(current.created_at, 10)) < 10)) {
		// 		console.log(i.to, current.to)
		// 		console.log(i.text, current.text)
		// 		console.log(i.created_at, current.created_at, Math.abs(parseInt(i.created_at, 10) - parseInt(current.created_at, 10)))
		// 		console.log(current)
		// 		newItems.push(current)
		// 	}
		// 	return newItems;
		// }, []);


		console.log(items.length, ' -> ', unique.length, `in ${Date.now() - start} ms` )
		console.log(unique)
		return unique
	}


	renderResultsStatus(inboxes, totalItems) {
		if (this.props.searchLoading) {
			return <span>Loading...</span>
		}
		if (this.props.queryTime > 0) {
			if (totalItems && totalItems.value > 0 && inboxes && inboxes.length > 0)
				return <span>Showing {inboxes.length} of {totalItems.value} results ({this.props.queryTime} seconds)</span>
		else return <span>No results found</span>
		}

		return null
	}

	render() {
		let { classes } = this.props

		let inboxes = this.props.inboxes.filter(item => item.text && item.text.trim().length > 0)
		inboxes = this.removeDuplicates(inboxes)
		let totalItems = {value: this.props.totalItems.value - (this.props.inboxes.length - inboxes.length)}

		return (
			<>
				<div className={classes.infiniteScroller}>

					<div className={classes.resultsFound}>
						{this.renderResultsStatus(inboxes, totalItems)}
					</div>


				{inboxes.length > 0 ?
					<InfiniteScroller
						loader			= {<Spinner/>}
						hasMore			= {totalItems > inboxes.length}
						loadMore		= {() => this.props.onSearch(true)}
					>
						{inboxes.map((inbox, i) => {
							if (!(inbox && inbox.text && inbox.text.trim().length > 0)) return
							return (
								<InboxSelectorItem
									key					= {`${inbox.created_at}${i}`}
									inbox				= {inbox}
									mainText			= {inbox.text}
									participants		= {inbox.direction === 'in' ? [{number: inbox.from}] : [{number: inbox.to}]}
									timestamp			= {inbox.created_at}
									showMyNums			= {false}
									active				= {true}
									bold				= {true}
									isNew				= {false}
									onClick				= { () => {} }
									goToConversation	= {this.props.goToConversation}
									peopleItems			= {this.props.peopleItems}
									isMobile			= {this.props.isMobile}
								/>
						)})}
					</InfiniteScroller>
			: null }
				</div>
			</>
		)
	}
}

export default withStyles(styles)(InboxSelector)