import React, { Component } from 'react'
import Spinner from 'spinner'

import { RemovePillIcon } from 'pdc-svg-icons'

import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import Dialpad from 'dialpad'
import DialpadIcon from '@material-ui/icons/Dialpad'
import { withStyles } from '@material-ui/core'
import {detectOS} from "os-detector";
export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const styles = theme => ({
	toStyle: {
		fontSize:		'14px',
		color:			'#000000',
		fontWeight:		500,
		marginRight:	10
	},
	tagsInput:{
		width:		'100%',
		display:	'flex',
		flexWrap:	'wrap',
		position:	'relative',
		alignItems:	'center',
		cursor:		'text',
		'&.no-input': {
			cursor: 'default'
		}
	},
	dialpad: {
		marginRight:	5,
		opacity:		0.7,
		cursor:			'default',
		'&:not(.not-allowed)': {
			cursor:		'pointer',
			'&:hover': {
				opacity: 1
			}
		}
	},
	dialpadIcon: {
		color:	theme.palette.primary.main
	},
	removeContactButton: {
		width:		'27px',
		height:		'27px',
		marginLeft:	'10px',
		cursor:		'pointer'
	},
	tagsInputField: {
		minWidth: 		200,
		fontSize:		'16px',
		fontWeight:		500,
		background:		'white',
		border:			'none',
		outline:		'none',
		letterSpacing:	'-0.1px',
		color:			'#88969e',
		'&.text-error': {
			color:		'#df1d08',
			fontWeight:	'bold'
		},
		'&::placeholder': {
			fontWeight:	500,
			color:		'#88969e'
		},
		'&.not-allowed': {
			color:		'#ccd6db',
			cursor:		'not-allowed',
			'&::placeholder': {
				color:	'#ccd6db'
			}
		}
	},
	tagItem: {
		display:		'flex',
		alignItems:		'center',
		background:		'#f2f5f6',
		border:			'1px solid #b6c0c5',
		borderRadius:	'20px',
		boxShadow:		'-1.5px 1.5px 0 0 #b6c0c5',
		margin:			'3px 7px 3px 0px',
		padding:		'6px 6px 6px 15px',
		'& .main-text': {
			fontSize:		'12px',
			fontWeight:		500,
			lineHeight:		1.33,
			letterSpacing:	'-0.1px',
			color:			'#2b3a43'
		},
		'&.only-main .main-text': {
			fontSize:	'14px',
			lineHeight:	1.29,
			color:		'#364047'
		}
	},
	content: {
		display:		'flex',
		flexDirection:	'column'
	},
	additionalText: {
		fontSize:		'10px',
		fontWeight:		500,
		lineHeight:		1.2,
		letterSpacing:	'-0.1px',
		color:			'#88969e'
	},
	pdcLoader: {
		position:	'absolute',
		right:		'10px',
		top:		'3px',
		width:		'35px !important',
		height:		'30px !important'
	}
})

class TagsInput extends Component {

	state = {
		selectInput:	false,
		isDialpadShown:	false
	}

	componentDidUpdate() {
		let tagsInputElement = document.getElementById('tags-input')
		if (this.state.selectInput) {
			this.setState({selectInput: false})
			if (this.props.textError) this.props.onChangeInput('')
			let tagsInputElement = document.getElementById('tags-input')
			if (tagsInputElement && detectOS() !=='iOS') tagsInputElement.focus()
		}
		if (this.props.textError && tagsInputElement) tagsInputElement.blur()
	}

	renderLoader() {
		const { classes } = this.props
		return (
			<div className={classes.pdcLoader} style={this.props.items.length ? {top: '9px'} : {}}>
				<Spinner/>
			</div>
		)
	}

	showDialpad = () => !this.props.notAllowed ? this.setState({isDialpadShown: true}) : null

	renderDialpad = () => {
		const { classes } = this.props
		return (
			<div className={`${classes.dialpad} ${this.props.notAllowed ? 'not-allowed' : ''}`}>
				<DialpadIcon id='dialpad-button' classes={{root: classes.dialpadIcon}} onClick={this.showDialpad}/>
				<Dialpad
					open		= {this.state.isDialpadShown}
					anchorEl	= {document.getElementById('dialpad-button')}
					onClickAway	= {() => this.setState({isDialpadShown: false})}
					onClick		= {value => this.props.onChangeInput(`${this.props.inputValue}${value}`)}
					onSubmit	= {() => this.props.submitEntry(this.props.inputValue)}
				/>
			</div>
		)
	}

	render() {
		const { classes }	= this.props
		let placeholder		= this.props.inputPlaceholder
		let showInput		= !this.props.singleSelect || !this.props.items.length
		let prompt			= `${this.props.prompt}:`
		return (
			<div className={`${classes.tagsInput} ${!showInput ? 'no-input' : ''}`} onClick={this.focusInput}>
				<span className={classes.toStyle}>{prompt}</span> {this.renderTagItems()}
				{showInput ?
					<input
						className	= {`${classes.tagsInputField} ${this.props.textError ? 'text-error' : ''} ${this.props.notAllowed ? 'not-allowed' : ''} fs-block`}
						id			= 'tags-input'
						type		= 'text'
						placeholder	= {placeholder}
						onChange	= {this.onInputChange}
						value		= {this.props.inputValue}
						onKeyDown	= {this.onKeyPress} // onKeyPress didn't work with 'Enter'
						size		= {placeholder.length}
						disabled	= {this.props.notAllowed}
					/>
				: null}
				{showInput && this.props.showDialpad ? this.renderDialpad() : null}
				{this.props.showLoading && this.renderLoader()}
			</div>
		)
	}

	focusInput = () => this.setState({selectInput: true})

	onInputChange = e => this.props.onChangeInput(e.target.value)

	renderTagItems() {
		const { classes } = this.props
		return this.props.items.map((value, i) => {
			return (
				<div key={`${i}${value.mainText}`} className={`${classes.tagItem} ${!value.additionalText ? 'only-main' : ''}`}>
					<div
						className		= {`${classes.content} fs-block`}
						onDoubleClick	= {this.onDoubleClick.bind(this, value.mainText)}
						title			= 'Doubleclick to edit'
					>
						{value.additionalText ? <span className={classes.additionalText}>{value.additionalText}</span> : null}
						<span className='main-text'>{value.mainText}</span>
					</div>
					<div className={classes.removeContactButton} onClick={this.removeItem.bind(this, value.mainText)}>
						<RemovePillIcon style={{width: '100%', height: '100%'}}/>
					</div>
				</div>
			)
		})
	}

	onDoubleClick(value, e) {
		e.persist()
		this.props.onChangeInput(value.substring(0, value.length - 1))
		this.setState({selectInput: true})
		this.removeItem(value)
	}

	removeItem = value => {
		let newItems = this.props.items.filter(i => i.mainText !== value)
		this.props.updateItems(newItems)
	}

	onKeyPress = e => {
		if (e.key === ',') {
			this.props.submitEntry(e.target.value)
			e.preventDefault()
		} else if (e.key === 'Backspace' && e.target.value === '' && this.props.items.length) {
			let inputValue = this.props.items[this.props.items.length - 1].mainText
			this.props.onChangeInput(inputValue)
			this.removeItem(inputValue)
		} else if (e.key === ' ' && e.target.value) {
			if (isValidNumber(e.target.value) || isValidNumber(e.target.value, 'US')) {
				this.props.submitEntry(e.target.value)
				e.preventDefault()
			}
		}
	}
}

export default withStyles(styles)(TagsInput)