import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './contacts-styles'
import LoaderFull from 'loader-full'
import TextField from 'pdc-text-field'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import { switchContact } from '../../actions/users'
import { switchView } from '../../actions/people'
import ConfirmModal from 'confirm-modal'
import { withStyles } from '@material-ui/core'

const CONTACTS_PAGE_LIMIT = 20

const mapStateToProps = state => ({
	currentContactId: state.currentContactId
})
const mapDispatchToProps = dispatch => ({
	switchContact:	contactId	=> dispatch(switchContact(contactId)),
	switchView:		view		=> dispatch(switchView(view))
})

class ContactSelector extends Component {

	constructor(props) {
		super(props)
		this.state = {
			searchValue:				'',
			editContact:				null,
			tempContactsInfo:			{items: [], total: 0, hasMore: false},
			loadingSearchContacts:		false,
			deleteContactId:			null,
			updating:					false,
			discardChangesContactId:	null
		}
	}

	componentDidUpdate(prevProps) {
		// If there is a change in the number of loaded contacts or a change in the display name in any of the contacts
		// and if there is a searchValue in state then redo the searchContacts
		if (prevProps.contactsUtil.contacts && this.state.searchValue) {
			let prevContacts			= prevProps.contactsUtil.contacts.items
			let currentContacts			= this.props.contactsUtil.contacts.items
			let prevExtraContacts		= prevProps.contactsUtil.extraContacts
			let currentExtraContacts	= this.props.contactsUtil.extraContacts
			let hasNameChange			= false
			currentContacts.forEach(c1 => {
				let contact = prevContacts.find(c2 => c2.id === c1.id)
				if (!contact) return
				if (contact.name.display !== c1.name.display) hasNameChange = true
			})
			currentExtraContacts.forEach(c1 => {
				let contact = prevExtraContacts.find(c2 => c2.id === c1.id)
				if (!contact) return
				if (contact.name.display !== c1.name.display) hasNameChange = true
			})
			if (prevContacts.length !== currentContacts.length ||
				prevExtraContacts.length > currentExtraContacts.length ||
				hasNameChange
			) this.searchContacts()
		}
	}

	onSearchValueChange = searchValue => this.setState({loadingSearchContacts: true, searchValue}, this.searchContacts)

	searchContacts = () => {

		clearTimeout(this.searchContactsTimeout)
		let query = this.formatSearchValueForSearch()
		if (!query || !query.trim()) {
			return this.setState({
				tempContactsInfo:		{items: [], total: 0, hasMore: false},
				loadingSearchContacts:	false
			})
		}

		let num_filtered = this.filterDisplayedContacts(query) // While waiting for the backend filter the already generated contacts
		if (num_filtered >= CONTACTS_PAGE_LIMIT) return

		this.searchContactsTimeout = setTimeout(this.backendSearchContacts.bind(this, query), 300)
	}

	filterDisplayedContacts = query => {

		let tempContactsInfo = JSON.parse(JSON.stringify(this.props.contactsUtil.contacts)) || {}
		tempContactsInfo.hasMore = false

		let query2 = query.replace(/\D/g,'')
		if (query2[0] === '1') {
			query2 = `+${query2}`
		} else if (['2', '3', '4', '5', '6', '7', '8', '9'].includes(query2[0])) {
			query2 = `+1${query2}`
		}

		tempContactsInfo.items = tempContactsInfo.items.map(c => {
			if (c.name.display.toLowerCase().includes(query.toLowerCase())) return c
			let numbers = []
			c.numbers.forEach(pn => {
				if (pn.number.includes(query) || (query2 && pn.number.includes(query2))) {
					numbers.push(pn)
				}
			})
			if (!numbers.length) return null
			let contact = JSON.parse(JSON.stringify(c))
			contact.numbers = numbers
			return contact
		}).filter(c => c)

		if (tempContactsInfo.items.length === 0) {
			tempContactsInfo = {items: [], total: 0, hasMore: false}
		} else if (tempContactsInfo.items.length >= CONTACTS_PAGE_LIMIT) {
			tempContactsInfo.hasMore = true
		}

		this.setState({tempContactsInfo})

		return tempContactsInfo.items.length
	}

	backendSearchContacts = async query => {
		this.setState({loadingSearchContacts: true})
		let filters = {keyword: query}
		let response = await this.props.contactsUtil.loadExtraContacts(filters, CONTACTS_PAGE_LIMIT, null, true)

		if (query !== this.formatSearchValueForSearch()) return // When typing fast the responses may not come in order

		this.setState({loadingSearchContacts: false})

		let tempContactsInfo = {
			items:		response.items,
			total:		response.total,
			hasMore:	response.items.length < response.total
		}

		this.setState({tempContactsInfo})
	}

	renderSearchField = () => {
		const { classes, contactsUtil } = this.props
		if (!contactsUtil.contacts || !contactsUtil.contacts.items.length) return null
		return (
			<div className={classes.searchFieldWrapper}>
				<TextField
					label			= 'Search'
					content			= {this.state.searchValue}
					editable		= {true}
					className		= {{wrapper: classes.searchField}}
					onInputChange	= {this.onSearchValueChange}
				/>
			</div>
		)
	}

	formatSearchValueForSearch = () => {
		let query = this.state.searchValue

		// check if it could possibly be a phone number, if it is remove formatting
		let phoneno =/^[(\-+)0-9 ]+$/;
		if(query && (query.match(phoneno))){
			query = query.replace(/\D/g,'');
		}

		if (query.substring(0, 2) === '+1') {
			query = query.substring(2)
		} else if (query.substring(0, 1) === '1' || query === '+') {
			query = query.substring(1)
		}
		return query
	}

	loadMoreContacts = async () => {

		let cursor = null
		let contactsLength = this.state.tempContactsInfo.items.length
		if (contactsLength) {
			cursor = this.state.tempContactsInfo.items[contactsLength - 1].cursor
		} else {
			let contactItems = this.props.contactsUtil.contacts.items
			cursor = contactItems[contactItems.length - 1].cursor
		}

		let query = this.formatSearchValueForSearch()
		if (!query) return this.props.contactsUtil.loadMore()

		let filters = {keyword: query}
		let response = await this.props.contactsUtil.loadExtraContacts(filters, CONTACTS_PAGE_LIMIT, cursor, true)
		let tempContactsInfo = query ? this.state.tempContactsInfo : this.props.contactsUtil.contacts
		tempContactsInfo = JSON.parse(JSON.stringify(tempContactsInfo))
		tempContactsInfo.items.push(...response.items)
		tempContactsInfo.hasMore = response.items.length < response.total
		this.setState({tempContactsInfo})
	}

	switchContact = contactId => {
		this.props.setEditing(false)
		this.props.switchContact(contactId)
	}

	onContactClick = contact => {
		let isEditing = this.props.isEditing
		if (isEditing) {
			if (contact.id === this.props.currentContactId) return
			return this.setState({discardChangesContactId: contact.id})
		}
		this.switchContact(contact.id)
		this.props.switchView('content')
	}

	renderContactItems = () => {
		const { classes, contactsUtil } = this.props
		let areContactsLoaded	= contactsUtil.contactsLoaded
		let mainContacts		= contactsUtil.contacts
		let tempContactsInfo	= this.state.tempContactsInfo
		if ((!tempContactsInfo || !tempContactsInfo.items.length) && !this.formatSearchValueForSearch()) {
			tempContactsInfo	= mainContacts
		}
		if (!tempContactsInfo) tempContactsInfo = {}
		let contactItems	= tempContactsInfo.items || []
		let formattedItems	= []
		let lastLetter		= ''
		contactItems.forEach(contact => {
			let displayName = contact.name.display
			if (lastLetter !== (displayName ? displayName[0].toUpperCase() : 'Unnamed')) {
				lastLetter = displayName ? displayName[0].toUpperCase() : 'Unnamed'
				formattedItems.push({letterHeader: true, letter: lastLetter})
			}
			formattedItems.push(contact)
		})
		return (
			<>
				<InfiniteScroller
					reverseScroll	= {false}
					loadMore		= {this.loadMoreContacts}
					hasMore			= {tempContactsInfo.hasMore}
					loader			= {<Spinner/>}
					// onScroll		= {this.onScrollX}
				>
					{formattedItems.map((contact, i) => {
						let selectedClass	= contact.id === this.props.currentContactId ? 'selected' : ''
						let displayName		= !contact.letterHeader && (contact.name.display || 'Unnamed contact')
						return (
							contact.letterHeader ?
								<div key={contact.id || i} className={`${classes.letterHeader} ${contact.letter === 'Unnamed' ? 'unnamed' : ''}`}>{contact.letter}</div> :
								<div key={contact.id || i} className={`${classes.contactItem} ${selectedClass}`} onClick={() => this.onContactClick(contact)}>{displayName}</div>
						)
					})}
				</InfiniteScroller>
				{this.state.loadingSearchContacts ?
					<div className={classes.contactsLoader}><Spinner/></div>
				: null}
			</>
		)
	}

	renderLoader = () => {
		const { classes }		= this.props
		let loadingMainContacts	= !this.props.contactsUtil.contactsLoaded
		let tempContactsInfo	= this.state.tempContactsInfo
		let isFiltering			= (tempContactsInfo && tempContactsInfo.items.length) || this.formatSearchValueForSearch()
		let isLoading			= loadingMainContacts && !isFiltering
		if (!isLoading) return null
		return <div className={classes.loadingDiv}><LoaderFull/></div>
	}

	discardChanges = () => {
		this.switchContact(this.state.discardChangesContactId)
		this.setState({discardChangesContactId: null})
		this.props.switchView('content')
	}

	renderDiscardChangesModal = () => {
		return (
			<ConfirmModal
				title			= 'You have unsaved changes'
				isShown			= {Boolean(this.state.discardChangesContactId)}
				content			= 'Do you wish to continue? All changes will be lost. '
				noButtonText	= 'Cancel'
				yesButtonText	= 'Continue'
				onReject		= {() => this.setState({discardChangesContactId: null})}
				onConfirm		= {this.discardChanges}
				size			= 'size550'
			/>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.wrapper}>
				{this.renderSearchField()}
				{this.renderContactItems()}
				{this.renderLoader()}
				{this.renderDiscardChangesModal()}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactSelector))