import React, { Component } from 'react'

import Button from 'button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	dialog:			theme.confirmDialog.paper,
	dialogTitle:	theme.confirmDialog.dialogTitle,
	dialogContent:	theme.confirmDialog.dialogContent,
	dialogFooter:	theme.confirmDialog.dialogFooter,
	rejectButton: {
		cursor:	'pointer',
		width:	'fit-content',
		'&:not(:hover) .hover': {
			display: 'none'
		},
		'&:hover .enabled': {
			display: 'none'
		}
	},
	confirmButton: {
		cursor:		'pointer',
		marginLeft:	'20px !important',
		width:		'fit-content',
		'&:not(:hover) .hover': {
			display: 'none'
		},
		'&:not(.disabled):hover  .enabled': {
			display: 'none'
		}
	}
})

class ConfirmModal extends Component {

	constructor(props) {
		super(props)
		this.state = {
			title:		props.title,
			content:	props.content
		}
	}

	componentDidUpdate() {
		if (!this.props.isShown) return
		if (this.props.title === this.state.title && this.props.content === this.state.content) return
		this.setState({
			title:		this.props.title,
			content:	this.props.content
		})
	}

	render() {
		const { classes, isShown } = this.props
		return (
			<Dialog
				open	= {isShown}
				classes	= {{paper: `${classes.dialog} ${this.props.size || ''}`}}
			>
				<DialogTitle classes={{root: classes.dialogTitle}}>
					{this.state.title}
				</DialogTitle>
				<DialogContent classes={{root: classes.dialogContent}}>
					{this.state.content}
				</DialogContent>
				<DialogActions classes={{root: classes.dialogFooter}}>
					<Button
						className	= {classes.rejectButton}
						onClick		= {this.props.isShown ? this.props.onReject : null}
						color		= {this.props.noButtonColor || 'secondary'}
					>
						{this.props.noButtonText || 'No'}
					</Button>
					<Button
						className	= {classes.confirmButton}
						onClick		= {this.props.isShown ? this.props.onConfirm : null}
						color		= {this.props.yesButtonColor || 'primary'}
					>
						{this.props.yesButtonText || 'Yes'}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withStyles(styles)(ConfirmModal) 