import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'

class API {

    static loadMessages = (offset = 0, limit = 30, filters = {}, sort = {}) => {
        return ajax.post(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/list-messages',
            {filters, sort, limit, offset}
        ).then(
            response => {
                if (response && 'code' in response && response.code !== 200) {
                    console.error('list-messages response:', response);
                    return {messages: {}, total: 0};
                }

                response = response.data;
                return {
                    messages: response.items.reverse(),
                    mediaIndexes: response.media,
                    filters: response.filters,
                    total: response.total
                }
            },
            error => {
                console.error('Error:', error)
            }
        );
    };

    static loadConversations = (offset = 0, limit = 0, filters = {}, sort = {}) => {
        let body = {
            filters,
            offset,
            limit
        }

        return ajax.post(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/list-conversations',
            body
        )
            .then(
                response => {
                    if (response && 'code' in response && response.code !== 200) return { conversations: {}, total: 0 };

                    response = response.data;

                    return {
                        conversations: response.items,
                        total: response.total,
                        filters: response.filters
                    }
                },
                error => {
                    console.error('Error loading conversations:', error)
                }
            );
    };

    static loadContacts = (offset = 0, limit = 30) => {
        let body = {
            offset,
            limit
        }

        return ajax.post(
            PhoneComUser.getv5ApiRoot() + '/contacts/get-contacts',
            body
        ).then(
            response => {
                response = response.data;
                return {
                    contacts: response.items,
                    total: response.total
                }
            },
            error => {
                console.error('Error:', error)
            }
        );
    }

    static sendMessage = payload => {
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/send-message', payload)
            .then(response => response);
    };

    static deleteMessage = payload => {
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/delete-message', payload)
            .then(response => response);
    };

    static deleteConversation = (conversation_id) => {

        let body = {
            conversation_id: conversation_id
        }

        return ajax.post(
            PhoneComUser.getv5ApiRoot() + '/messages/delete-conversation',
            body
        ).then(response => response);
    }

    static markRead = (conversation_id, read_at) => {
        let body = {
            conversation_id: conversation_id
        }

        if (read_at) {
            body.read_at = read_at;
        }

        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/mark-conversation-read', body)
    }

    static markMessageUnread = message_id => {
        let body = { message_id }
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/mark-message-unread', body)
    }

	static getExtension = async extensionId => {
        // if(window.APP_CONFIG.stage == 'STAGE') return // new v4 api only works in prod at the moment, should be fixed in a week or so
		let voip_id		= PhoneComUser.getAPIAccountId()
        let base_url = PhoneComUser.getv4ApiRoot();
		let requestUrl	= `${base_url}/accounts/${voip_id}/extensions/${extensionId}`
		let response	= await ajax.get(requestUrl, 'Bearer')
		return response.data
	}

    static getExtensions = () => {
        let voip_id = PhoneComUser.getAPIAccountId();
        let base_url = PhoneComUser.getv4ApiRoot();
        return ajax.get(
            `${base_url}/accounts/${voip_id}/extensions?limit=500`, 'Bearer'
        ).then(response => response);
    }

    static getPhoneNumbers = () => {
        let voip_id = PhoneComUser.getAPIAccountId();
        let base_url = PhoneComUser.getv4ApiRoot();
        return ajax.get(
            `${base_url}/accounts/${voip_id}/phone-numbers`, 'Bearer'
        ).then(response => response);
    }

    static getExtensionsAndPhoneNumbers = () => {
        let url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com');
        let body = {
            account_id: PhoneComUser.getAPIAccountId(),
            type: 'extensions-and-phone-numbers'
        }
        return ajax.post(url, body).then(response => response);
    }

    static getAccountContactInfo = () => {
        let url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com');
        let body = {
            account_id: PhoneComUser.getAPIAccountId(),
            type: 'account-contact-info'
        }
        return ajax.post(url, body).then(response => response);
    }

    static getConferenceInfo = () => {
        let url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com');
        let body = {
            extension_id: PhoneComUser.getExtensionId(),
            type: 'conference-info'
        }
        return ajax.post(url, body).then(response => response);
    }

    static loadMedia = async (conversationId, filters = null, lastCursor = null, firstCursor = null, offset = 0, limit = 25) => {
        let url = `${PhoneComUser.getv5ApiRoot()}/messages/list-media`
        let data = {
            conversation_id: conversationId,
            filters: filters,
            last_cursor: lastCursor,
            first_cursor: firstCursor,
            limit,
            offset
        }
        let response = await ajax.post(url, data)
        console.log(response)
        try {
            response = response.data
            console.log(response) //added to debug error https://rollbar.com/phone-2/My-Phone.com-Errors/items/66/
            return {
                media: response.items,
                total: response.total,
                firstCursor: response.first_cursor,
                lastCursor: response.last_cursor
            }
        } catch (e) {
            console.error(e)
            // hack because of list media returning internal server error
            return {
                media: [],
                total: 0,
                firstCursor: null,
                lastCursor: null
            }
        }
    }
    static uploadMedia = (media) => {
        return ajax.post(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/upload-media',
            { media }
        ).then(response => response)
    }
}

export default API;