import React from 'react'
import App from './src/components/App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

import withTheme from '@material-ui/core/styles/withTheme'
// import {CallState} from './src/enums/CallState'
import CallProvider, { PdcCallConsumer } from "../../pages/communicator-app/src/PdcCallProvider"

const store = createStore(rootReducer)
class Calls extends React.Component {

	render() {
		return (
			<Provider store={store}>
				<PdcCallConsumer>
					{
						(context) => (
							<App
								isOffline={this.props.isOffline}
								extension={this.props.extension}
								contactsUtil={this.props.contactsUtil}
								screenViewType={this.props.screenViewType}
								standalone={this.props.standalone}
								onLoaded={this.props.onLoaded}
								subscribeForNotifications={this.props.subscribeForNotifications}
								resetSubscription={this.props.resetSubscription}
								updateUnreadCounts={this.props.updateUnreadCounts}
								changeVoicemailReadStatus={this.props.changeVoicemailReadStatus}
								redirect={this.props.redirect}
								setIsDialerOpen={this.props.setIsDialerOpen}
								appData={this.props.appData}
								makeCall={this.props.makeCall}
								currentCall={context.currentCall}
								openDialer={this.props.openDialer}
								openMakeACall={this.props.openMakeACall}
								openCallLogList={this.props.openCallLogList}
							/>
						)
					}

				</PdcCallConsumer>
			</Provider>
		)
	}
}

export default withTheme(Calls)