import React, { Component } from 'react'
import VoicemailSelectorItem from './VoicemailSelectorItem.js'
import NoSetup from 'no-setup'
import PhoneComUser from 'phone-com-user'
import { connect } from 'react-redux'
import api from '../util/api_v5'
import { withStyles } from '@material-ui/core'
import { updateVoicemails, switchVoicemail, updateVoicemail } from '../actions/voicemails'

import ScrollerWrapper from 'scroller-wrapper'

const mapStateToProps = state => ({
	voicemails:			state.voicemails,
	currentVoicemail:	state.currentVoicemail
})

const mapDispatchToProps = dispatch => ({
	updateVoicemail:	voicemail	=> dispatch(updateVoicemail(voicemail)),
	updateVoicemails:	voicemails	=> dispatch(updateVoicemails(voicemails)),
	switchVoicemail:	voicemail	=> dispatch(switchVoicemail(voicemail))
})

const styles = theme => ({
	newVoicemailInfoWrapper: {
		position:		'absolute',
		top:			0,
		left:			0,
		right:			0,
		height:			'60px',
		display:		'flex',
		zIndex:			1,
		background:		'linear-gradient(gray, transparent)',
		justifyContent:	'center',
		alignItems:		'center',
		transition:		'top .25s',
		'&>span': {
			fontWeight:		'bold',
			color:			'green',
			background:		'rgba(255,255,255,0.7)',
			padding:		'3px 10px',
			border:			'2px solid green',
			borderRadius:	'17px'
		},
		'&.disappear': {
			top: '-60px'
		}
	},
	noVoicemailMessage: {
		marginTop:		'15px',
		padding:		'0 10px',
		display:		'inline-block',
		width:			'100%',
		height:			'100%',
		verticalAlign:	'middle',
		textAlign:		'center',
		'&>span': {
			color:		'#b6c0c5',
			fontWeight:	'bold',
			lineHeight:	1.29
		}
	}
})

class VoicemailSelector extends Component {

	state = {
		newVoicemail:		false,
		loadingVoicemails:	false
	}
	lastNewVoicemail = null

	componentDidMount() {
		this.initLinks()
	}

	componentDidUpdate(prevProps) {
		if (this.props.newVoicemail !== null && this.props.newVoicemail !== this.lastNewVoicemail) {
			this.lastNewVoicemail = this.props.newVoicemail
			this.props.setNoNewVoicemail()
			this.setState({newVoicemail: true})
			setTimeout(() => {this.setState({newVoicemail: false})}, 2000)
		}

		this.initLinks()

		if (
			this.props.deletedVoicemails.num && 
			this.props.deletedVoicemails.randomString !== prevProps.deletedVoicemails.randomString
		) this.loadMore(this.props.deletedVoicemails.num)

		this.checkForExpandChange()
	}

	checkForExpandChange = () => {
		let expandedVoicemailIndex = this.props.voicemails.items.findIndex(v => v.expanded)
		let hasChange = false

		if (expandedVoicemailIndex === -1) {
			// console.log(`#### Will set this (${this.expandedVoicemailId}) to null`)
			this.expandedVoicemailId = null
			this.willCollapseVoicemailId = null
			if (this.state.willCollapseIndex || this.state.willCollapseIndex === 0) this.setState({willCollapseIndex: null})
			return
		}

		let expandedVoicemail = this.props.voicemails.items[expandedVoicemailIndex]

		if (expandedVoicemail.id !== this.expandedVoicemailId) hasChange = true
		if (expandedVoicemail.willCollapse && this.willCollapseVoicemailId !== expandedVoicemail.id) hasChange = true
		this.expandedVoicemailId = expandedVoicemail.id
		if (expandedVoicemail.willCollapse) this.willCollapseVoicemailId = expandedVoicemail.id

		if (hasChange) {
			// console.log('#### hasChange is true')
			if (expandedVoicemail.willCollapse) {
				this.setState({willCollapseIndex: expandedVoicemailIndex})
			}
		}
	}

	initLinks = () => {
		let voicemails		= this.props.voicemails
		let voicemailIds	= voicemails.items.filter(v => !v.link).map(v => v.id)
		if (voicemailIds.length && !this.gettingLinks) {
			this.gettingLinks = true
			let extensionId = PhoneComUser.getExtensionId()
			api.getVoicemailLink(voicemailIds).then(response => {
				if (extensionId !== PhoneComUser.getExtensionId()) {
					// This may happen if you change the extension while the voicemails links are being loaded
					return // console.log('The extension got changed so stop.')
				}
				this.gettingLinks = false
				voicemails.items.forEach(v => {
					let res = response.find(r => r.uuid === v.id)
					if (!res) return
					v.link = res.download_link
					this.props.updateVoicemail(v)
				})
			})
		}
	}

	isActive = voicemail => this.props.currentVoicemail ? voicemail.id === this.props.currentVoicemail.id : false

	clickedVoicemail = (voicemail, shouldExpand) => {
		if (voicemail.is_new && shouldExpand) this.props.changeReadStatus(voicemail)
		this.props.switchVoicemail(shouldExpand ? voicemail : null)
	}

	loadMore = async (limit=15) => {
		if (this.state.loadingVoicemails) return
		let voicemails	= this.props.voicemails
		let offset		= voicemails.items.length
		this.setState({loadingVoicemails: true})
		let response	= await api.loadVoicemails(offset, limit)
		if (response === 'newtork-error') return
		voicemails		= this.props.voicemails
		voicemails.items.push(...response.items)
		response.items	= voicemails.items
		response.items.forEach(v => {
			delete v.dontShow
			delete v.placeholderHeight
		})
		this.props.updateVoicemails(response)
		this.setState({loadingVoicemails: false})

		// Load the new storred contacts of each newly-loaded voicemail and store them in redux
		this.props.loadExtraContacts()
	}

	scrollerUpdateVoicemails = voicemailItems => {
		voicemailItems = voicemailItems
		let voicemails = this.props.voicemails
		voicemails.items = voicemailItems
		this.props.updateVoicemails(voicemails)
	}

	recalculateScroll = () => this.setState({recalculateScroll: Math.random()})

	render() {

		let { classes }		= this.props
		let voicemails		= this.props.voicemails
		let voicemailItems	= voicemails.items || []
		let hasMore			= voicemailItems.length ? voicemails.total - voicemails.items.length > 0 : false

		return (
			<div style={{minHeight: 0, height: '100%'}}>
				{voicemails.items.length === 0 ?
					this.props.isVoicemailConfigured ? 
						<div className={classes.noVoicemailMessage}><span>You have no voicemails</span></div> :
						<NoSetup type='voicemail' />
					:
					<>
					<div className={`${classes.newVoicemailInfoWrapper} ${this.state.newVoicemail ? '' : 'disappear'}`}>
						<span>New Voicemail Received</span>
					</div>
					<ScrollerWrapper
						reverseScroll		= {false}
						loadMore			= {this.loadMore}
						hasMore				= {hasMore}
						items				= {this.props.voicemails.items}
						updateItems			= {this.scrollerUpdateVoicemails}
						data-test-id		= 'VoicemailSelector'
						smallView			= {this.props.smallView}
						willCollapseIndex	= {this.state.willCollapseIndex}
						recalculateScroll	= {this.state.recalculateScroll}
					>
						{voicemailItems.map(voicemail =>
							<VoicemailSelectorItem
								key					= {voicemail.id}
								voicemail			= {voicemail}
								onClick				= {isExpanded => this.clickedVoicemail(voicemail, isExpanded)}
								active				= {this.isActive(voicemail)}
								screenViewType		= {this.props.screenViewType}
								deleteVoicemail		= {this.props.deleteVoicemail}
								changeReadStatus	= {this.props.changeReadStatus}
								extraContacts		= {this.props.extraContacts}
								editContact			= {this.props.editContact}
								extension			= {this.props.extension}
								makeCall			= {this.props.makeCall}
								placeholderHeight	= {voicemail.placeholderHeight}
								dontShow			= {voicemail.dontShow}
								recalculateScroll	= {this.recalculateScroll}
							/>
						)}
					</ScrollerWrapper>
					</>
				}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(VoicemailSelector))