import React, { Component } from 'react'

import Draggable from 'react-draggable'

import { AudioPlayerControlIcon } from 'pdc-svg-icons'
import dloadIcon from './images/icon_in_dload.png'
import { AudioPlayerControlSliderIcon } from 'pdc-svg-icons'
import { DefaultArrowTooltip } from 'tooltips'

import { withStyles } from '@material-ui/styles'

import './VoicemailAudioController.css'
import { mainTheme } from 'themes'
import Spinner from 'spinner'

const styles = theme => ({
	audioControlContainer: {
		width:		'100%',
		display:	'flex',
		alignItems:	'flex-end',
		minHeight:	30
	},
	progressAndTimingWrapper: {
		width:		'85%',
		marginLeft:	20
	},
	audioControlSeekbar: {
		cursor:			'pointer',
		height:			9,
		borderRadius:	6,
		position:		'relative',
		display:		'flex',
		background:		theme.palette.primary.borderColor,
		'& .progress-bar': {
			background:		theme.audioPlayer.progressFillColor,
			borderRadius:	6,
			position:		'relative',
			overflow:		'unset',
			height:			'100%',
			width:			0,
			paddingBottom:	1,
			transition:		'background .1s',
			'& .player-control': {
				position:		'absolute',
				display:		'flex',
				justifyContent:	'center',
				opacity:		0,
				zIndex:			1,
				width:			'40px !important',
				'&.force-show': {
					opacity:	1,
					'& svg': {
						width:	40
					}
				},
				'& svg': {
					position:	'absolute',
					top:		'50%',
					transform:	'translateY(-50%)',
					display:	'block',
					userSelect:	'none',
					userDrag:	'none',
					width:		0,
					transition:	'width .1s'
				}
			}
		},
		'&:hover .progress-bar': {
			'& .player-control': {
				display:	'block',
				width:		'fit-content',
				opacity:	1,
				'& svg': {
					width:	40
				}
			}
		},
		'& .temp-progress-bar': {
			background:		'white',
			opacity:		0.35,
			borderRadius:	6,
			position:		'absolute',
			left:			0,
			top:			0,
			height:			9,
			width:			0
		}
	},
	timeInfo: {
		display:		'flex',
		justifyContent:	'space-between',
		marginTop:		15,
		'& span': {
			fontSize:	12,
			fontWeight:	600,
			lineHeight:	1.33
		}
	},
	seekBarErrorBorder:{
		border: theme ? theme.palette.error.flatBorder : mainTheme.palette.error.flatBorder,
	},
	controlButtonWrapper: {
		cursor: 'pointer'
	},
	controlButtonImg:{
		maxHeight:	50,
		maxWidth:	50,
		height:		'100%',
		width:		'100%'
	},
	downloadStyle:{
		marginLeft:5,
		marginRight:5
	}
})

function formatSecondsToMinutes(seconds) {
	let minutes	= Math.floor(seconds / 60)
	seconds		= (seconds - (minutes * 60)).toFixed(0)
	if (seconds < 10) seconds = '0' + seconds
	return (`${minutes}:${seconds}`)
}

class AudioPlayer extends Component {

	constructor(props) {
		super(props)

		this.seekRef		= React.createRef()
		this.progressRef	= React.createRef()
		this.audio			= new Audio(props.url)
		this.audio.preload	= props.preload || 'auto'
		this.state = {
			progress:				0,
			selected:				false,
			play:					false,
			duration:				props.duration || 0,
			volume:					{value: 50},
			error:					false,
			playerControlPosition:	null,
			dragState:				'inactive',
			controlButtonHovered:	false
		}

		this.audio.onerror = e => {
			if (!this._mounted) return
			if (this.props.onError) this.props.onError()
			this.setState({error: true})
		}

		this.controlSeek = event => {
			if (Array.from(event.target.classList).includes('player-control')) return
			let seekbarRect			= this.seekRef.current.getBoundingClientRect()
			let left				= event.pageX - seekbarRect.left
			let percentage			= left / seekbarRect.width
			let seekTime			= this.getDuration() * percentage
			this.audio.currentTime	= seekTime
			this.setState({progress: percentage * 100, playerControlPosition: {x: left, y: 0}})
		}

		this.toggleAudio = event => {
			if (this.state.play === true) {
				this.setState({play: false})
				this.audio.pause()
			} else {
				this.setState({loading: true})
				this.audio.play().then(() => {
					this.setState({play: true, loading: false}, () => {
						if (this.props.onPlay) this.props.onPlay()
					})
				}).catch((e => {
					if (this.props.onError) this.props.onError()
					this.setState({error:true, loading:false})
					window.alert('unable to play audio')
				}))
			}
		}

		this.initializeAudioState = event => {
			this.setState({
				error:		false,
				duration: this.getDuration().toFixed(0)
			})
			if (this.props.onReadyToPlay) {
				this.props.onReadyToPlay()
			}
		}

		this.updateCurrentTime = event => {
			this.setState({progress: this.audio.currentTime / this.getDuration() * 100})
			if (this.state.dragState === 'inactive') {
				let playerControlPosition = {x: this.progressRef.current.offsetWidth, y: 0}
				this.setState({playerControlPosition})
			}
			if (this.state.progress >= 100) {
				this.setState({play: false})
			}
		}

		this.adjustVolume = event => {
			this.setState({
				volume: {
					value: event.target.value
				}
			})
			this.audio.volume = this.state.volume.value / 100
		}
	}

	getDuration = () => (this.props.duration || this.props.duration === 0) ? this.props.duration : this.audio.duration

	componentDidMount() {
		this._mounted = true
		this.resetEventListeners()
	}

	resetEventListeners = () => {
		this.audio.removeEventListener('loadedmetadata', this.initializeAudioState)
		this.audio.removeEventListener('timeupdate', this.updateCurrentTime)
		this.audio.addEventListener('loadedmetadata', this.initializeAudioState)
		this.audio.addEventListener('timeupdate', this.updateCurrentTime)
	}

	componentWillUnmount() {
		this.audio.removeEventListener('loadedmetadata', this.initializeAudioState)
		this.audio.removeEventListener('timeupdate', this.updateCurrentTime)
		this.audio.pause()
		this._mounted = false
	}

	// Pause player when disable flag is triggered
	componentDidUpdate(prevProps) {
		if (!prevProps.duration && this.props.duration && this.props.onReadyToPlay) this.props.onReadyToPlay()
		if (prevProps.url !== this.props.url) {
			this.audio.pause();
		}
		if (this.props.disabled && this.state.play) {
			this.setState({play: false})
			this.audio.pause()
		}
		if (!prevProps.url && this.props.url) {
			this.audio = new Audio(this.props.url)
			this.resetEventListeners()
		}
	}

	onDragStart = () => this.setState({dragState: 'active'})

	onDragStop = (e, info) => {
		this.setState({dragState: 'inactive'})
		let parentWidth		= this.seekRef.current.offsetWidth
		let positionX		= info.x
		let progressWidth	= 100 * positionX / parentWidth
		this.setState({progress: progressWidth, dragTime: null})

		let seekTime			= this.getDuration() * progressWidth / 100
		this.audio.currentTime	= seekTime
	}

	controlDragged = (e, info) => {
		this.setState({playerControlPosition: null})

		let parentWidth		= this.seekRef.current.offsetWidth
		let positionX		= info.x
		let progressWidth	= 100 * positionX / parentWidth

		if (!this.state.play) {
			this.setState({progress: progressWidth})
		}

		let dragTime = formatSecondsToMinutes(this.getDuration() * progressWidth / 100)
		this.setState({dragTime, tempProgress: progressWidth})
	}

	setControlButtonHovered = controlButtonHovered => this.setState({controlButtonHovered})

	render() {
		const { classes } = this.props
		const styles = {
			seek: {
				width: `${this.state.progress}%`
			}
		}
		let currentTime	= formatSecondsToMinutes(this.audio.currentTime <= this.getDuration() ? this.audio.currentTime : this.getDuration())
		let totalTime	= formatSecondsToMinutes(this.state.duration)

		return (
			<div className={classes.audioControlContainer}>
				{(this.state.loading) ?
					<Spinner/>
					:
					<div
						className		= {classes.controlButtonWrapper}
						onMouseEnter	= {() => this.setControlButtonHovered(true)}
						onMouseLeave	= {() => this.setControlButtonHovered(false)}
					>
						<AudioPlayerControlIcon
							data-test-id= 'audio-player-play-btn'
							className	= {classes.controlButtonImg}
							type		= {!this.state.play ? 'play' : this.state.controlButtonHovered ? 'pauseHover' : 'pause'}
							onClick		= {this.toggleAudio}
						/>
					</div>
				}
				<div className={classes.progressAndTimingWrapper}>
					<div
						className	= {`${classes.audioControlSeekbar} ${this.state.error ? classes.seekBarErrorBorder: classes.seekBarBorder}`}
						onClick		= {this.controlSeek}
						ref			= {this.seekRef}
					>
						<span className='progress-bar' style={styles.seek} ref={this.progressRef}>
							<Draggable
								axis			= 'x'
								grid			= {[1, 0]}
								bounds			= {{left: 0, right: this.seekRef.current ? this.seekRef.current.offsetWidth : 0}}
								positionOffset	= {{x: -17.5, y: 0}}
								onStart			= {this.onDragStart}
								onStop			= {this.onDragStop}
								onDrag			= {this.controlDragged}
								position		= {this.state.playerControlPosition}
							>
								<DefaultArrowTooltip
									title		= {this.state.dragTime || currentTime}
									placement	= 'top'
								>
									<div className={`player-control ${this.state.dragState === 'active' ? 'force-show' : ''}`}><AudioPlayerControlSliderIcon/></div>
								</DefaultArrowTooltip>
							</Draggable>
						</span>
						{this.state.dragState === 'active' && this.state.play ?
							<span style={{width: `${this.state.tempProgress}%`}} className='temp-progress-bar'></span>
						: null}
					</div>
					<div className={classes.timeInfo}>
						<span>{currentTime}</span>
						<span>{totalTime}</span>
					</div>
				</div>

				{ (this.props.downloadable)?
					<a href={this.props.url} download='newname' target='_blank' className={classes.downloadStyle}>
					<img src={dloadIcon} alt='Download' className={classes.voicemailDownload} onClick={this.download}/>
				</a> : ''}
			</div>
		)
	}
}


export default withStyles(styles)(AudioPlayer)