import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import SearchInbox from 'search-inbox'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	searchButton: {
		cursor:			'pointer',
		fontSize:		16,
		padding:		'7px 12px',
		margin:			'0 10px',
		borderRadius:	8,
		border:			'1px solid gray',
		'&:hover': {
			background:	'rgba(136, 150, 158, 0.35)'
		}
	}
})

class Search extends Component {

	state = {showSearchModal: false}

	onSearchButtonClick	= () => {this.props.setMobileDrawerOpen(false); this.setState({showSearchModal: true})}
	onModalClose		= () => this.setState({showSearchModal: false})

	goToConversation = inbox => {
		let app = inbox.type
		if (app === 'sms' || app === 'mms') app = 'messages'
		else if (app === 'fax') app = 'faxes'
		else if (app === 'voicemail') app = 'voicemail'
		this.onModalClose()
		let appData = {message: inbox}
		// for (let i=0; i < 2; ++i) {
		this.props.goTo(app, appData)
		// }

	}

	render() {
		const { classes } = this.props
		return (
			<>
				<div
					className	= {classes.searchButton}
					onClick		= {this.onSearchButtonClick}
				>
					<SearchIcon/><span style={{marginLeft: 10}}>Search</span>
				</div>
				<SearchInbox
					isOpen				= {this.state.showSearchModal}
					onClose				= {this.onModalClose}
					goToConversation	= {this.goToConversation}
					location			= 'navigation'
					isMobile			= {this.props.isMobile}
				/>
			</>
		)
	}
}

export default withStyles(styles)(Search)