import React, { Component, createContext } from 'react';
import SipCallManager from './pdc-calls/SipCallManager';
import Click2CallManager from './pdc-calls/Click2CallManager';
import { CallManager } from './pdc-calls/CallManager';
import { CallerInfo } from './interfaces/CallerInfo';
import { CallManagerEvents } from './enums/CallManagerEvents';
import { CallType } from "./enums/CallType"
import { CallState } from "./enums/CallState"
interface Props {
	//do we need to receive anything? user-related info?
	disableAutoConnect?: boolean
	someAuthToken?: string
	callMode: string
}

interface CallManagerState {
	callState: string
	callInfo: CallerInfo
	callAnswered: false
	callStartTime?: number
	topicCallbacks: Array<Object>
	recentConnectionStats: Array<Object>
}

interface SipCallManagerState extends CallManagerState {}

//PdcCallProvider??
const MyContext = createContext({})
export const PdcCallConsumer = MyContext.Consumer

//todo:  PDcCallcprovider - call-agnostic. change on configuration settings
const CALLMODE = ""

declare global {
	interface Window {
		pdcCall: CallManager | null
	}
}
class PdcCallProvider extends Component<any, any> {
	//singleton observable.
	callManager: CallManager

  constructor(props: Props) {
		super(props)

		this.state = {
			didConnect: false,
			callStats: null,
		}

    switch (props.callMode) {
          case CallType.SIP:
              this.callManager = new SipCallManager()
              break
          case CallType.CLK2C:
              this.callManager = new Click2CallManager()
              break
          default:
              this.callManager = new SipCallManager()
              break
     }
	}

	public async componentDidMount() {
		window.pdcCall = this.callManager
		this.callManager.on(CallManagerEvents.CALL_CREATED, () => {
			this.setState({ calls: this.callManager.calls })
			this.forceUpdate()
		})
		this.callManager.on(CallManagerEvents.CALL_RECEIVED, (e: any) => this.forceUpdate())
		this.callManager.on(CallManagerEvents.CONNECTING, (e: any) => this.forceUpdate())
		this.callManager.on(CallManagerEvents.CALL_ANSWERED, (e: any) => this.forceUpdate())
		this.callManager.on(CallManagerEvents.CALL_HANGUP, (e: any) => {
			if (!this.callManager.activeCallId) {
				this.setState({ callStats: null })
			}
			this.forceUpdate()
		})

		this.callManager.on(CallManagerEvents.CALL_STAT_UPDATE, (e: any) => {
			const { callId, stat } = e
			//call stats are being tracked every n seconds from each session. but only update the ui if its the active call.
			if (this.callManager.activeCallId === callId) this.setState({ callStats: stat })
		})

		this.callManager.on(CallManagerEvents.SWITCH_CALL, (e: any) => this.forceUpdate())

		this.callManager.on(CallManagerEvents.MERGE_CALL, (e: any) => this.forceUpdate())

		this.callManager.on(CallManagerEvents.STATE_UPDATE, (e: any) => this.forceUpdate())

		if (!this.props.disableAutoConnect) {
			await this.connect()
		}
	}

	public componentWillUnmount() {
		window.pdcCall = null
	}

	componentDidUpdate() {
		console.log("detected update")
	}

	public connect = async () => {
		//TODO: await this and return empty resolved promise to not expose simpleuser.
		if (!this.state.didConnect) {
			return await this.callManager.connect().then(() => {
				this.setState({ didConnect: true })
			})
		}
	}

	public call = (callee: string) => {
		this.callManager.call(callee)
	}

	public hold = () => {
		this.callManager.hold()
	}

	public unhold = () => {
		this.callManager.unhold()
	}

	public hangupById = (id: string, endAll: boolean = false): void => {
		this.callManager.hangupById(id, endAll)
	}
	public answerById = async (id: string): Promise<any> => {
		return this.callManager.answerById(id)
	}

	public switchCall = (id: string): void => {
		this.callManager.switchCall(id)
	}

	public mergeCall = (id: string) => {
		this.callManager.mergeCall(id)
	}

	public muteLocal = (isMuted: boolean) => {
		this.callManager.muteCurrentLocal(isMuted)
	}

	public muteRemote = (isMuted: boolean) => {
		this.callManager.muteCurrentRemote(isMuted)
	}

	public sendDTMF = (tone: string) => {
		this.callManager.sendDTMF(tone)
	}

	public getIncomingCalls = (): Array<any> => {
		return Object.values(this.callManager.calls)
			.filter((call) => call.callState === CallState.INCOMING)
			.sort((a, b) => b.callStartTime! - a.callStartTime!)
	}

	public getActiveCalls = (): Array<any> => {
		return Object.values(this.callManager.calls).filter((call) => call.callState === CallState.ACTIVE || call.callState === CallState.POSTCALL)
	}

	public getIsMutedLocal = () => {
		let isMutedLocal = true
		const id = this.callManager.activeCallId
		//is sip the exception to rule or is c2c?  && this.callManager.callMode !== CallType.C2C
		if (id && this.callManager.calls[id] && this.callManager.callMode === CallType.SIP) {
			const currentCall = this.callManager.calls[id]
			currentCall.participants.forEach((p: CallerInfo) => {
				const id = p.phoneNumber
				if (!this.callManager.calls[id].isMutedLocal) isMutedLocal = false
			})
		} else {
            isMutedLocal = false
		}
		
		return isMutedLocal
	}

	public getIsMutedRemote = () => {
		let isMutedRemote = true
		const id = this.callManager.activeCallId
		//is sip the exception to rule or is c2c?  && this.callManager.callMode !== CallType.C2C
		if (id && this.callManager.calls[id] && this.callManager.callMode === CallType.SIP) {
			const currentCall = this.callManager.calls[id]
			currentCall.participants.forEach((p: CallerInfo) => {
				const id = p.phoneNumber
				if (!this.callManager.calls[id].isMutedRemote) isMutedRemote = false
			})
		} else {
            isMutedRemote = false
		}
		
		return isMutedRemote
	}

	static getWindowGlobal() {}

	render() {
		const {
			callManager,
			call,
			hangupById,
			hold,
			unhold,
			answerById,
			switchCall,
			connect,
			muteLocal,
			muteRemote,
			mergeCall,
			sendDTMF,
			getActiveCalls,
			getIncomingCalls,
		} = this
		const activeCallId = callManager.activeCallId
		const nonStateCalls = { ...callManager.calls } //hard copy to detect updates on nested object, works for now
		//creating calls obj this way allows calls[null] to return truthful for some reason

		const incomingCallsCnt = this.getIncomingCalls().length
		const activeCallsCnt = this.getActiveCalls().length
		const currentCall = activeCallId ? this.callManager.calls[this.callManager.activeCallId!] : null
		const currentCallState = activeCallId ? this.callManager.calls[this.callManager.activeCallId!] : null
		const backgroundCalls = currentCall ? this.getActiveCalls().filter(c => (c.callId !== currentCall.callId)) : this.getActiveCalls()
		const incomingCalls = this.getIncomingCalls()
		let isMutedLocal = this.getIsMutedLocal()
		let isMutedRemote = this.getIsMutedRemote()
				return (
			<MyContext.Provider
				value={{
					...this.state,
					//methods
					call,
					hangupById,
					connect,
					answerById,
					muteLocal,
					muteRemote,
					switchCall,
					mergeCall,
					sendDTMF,
					getActiveCalls,
					getIncomingCalls,
					hold,
					unhold,

					activeCallId,
					currentCall,
					callsCnt: Object.keys(nonStateCalls).length,
					calls: nonStateCalls,
					incomingCallsCnt,
					activeCallsCnt,
					backgroundCalls,
					incomingCalls,
                    isMutedLocal,
					isMutedRemote,
				}}
			>
				{this.props.children}
			</MyContext.Provider>
		)
	}
}
export default PdcCallProvider
