import React, { Component } from 'react'
import WifiIcon from '@material-ui/icons/Wifi';
import { DefaultArrowTooltip } from 'tooltips'

class SignalInfo extends Component {
    signalLevelStyles = {
        5: 'deepskyblue', //great
        4: 'springgreen', //good
        3: 'yellow', //ok
        2: 'orange', //bad
        1: 'red', //awful
        0: 'white' //not connected
    }

    signalLevels = {
        5: 'GREAT',
        4: 'GOOD',
        3: 'OK',
        2: 'BAD',
        1: 'AWFUL',
        0: 'Not Connected'
    }

    state = {
        callStats: {
            jitter: 0,
            packetsLost: 0,
            avgLatency: 0,
            signalStr: this.signalLevels[0],
            sendCodec: 'N/A',
            recvCodec: 'N/A'
        }
    }

    render() {

        const { jitter, packetsLost, avgLatency, signalStr, sendCodec, recvCodec } = this.props.callStats ? this.props.callStats : this.state.callStats
        return (
            <div>
                <DefaultArrowTooltip
                    title={(<div>Quality: {this.signalLevels[signalStr]}<br />latency: {avgLatency.toFixed(2) || 0}<br />jitter: {jitter.toFixed(2) || 0}<br />packet loss: {packetsLost}%<br />send codec: {sendCodec}
                        <br />recv codec: {recvCodec}</div>)}
                    placement='top'
                >
                    <WifiIcon style={{ fill: this.signalLevelStyles[0], height: 28, width: 28 }} data-test-id={'signal-strength-indicator-icon'} />
                </DefaultArrowTooltip>

            </div>
        )
    }
}

export default SignalInfo