import Api from 'api'

const isElectron = window.ipcRenderer ; 

const sendElectronNotification = (notificationType, payload=[]) => {
    if (isElectron) {
        window.ipcRenderer.send('asynchronous-message', {
            eventName: notificationType,
            payload: payload
        })
    }
};

const electronNotificationListener = () => {
    if (isElectron) {
        window.ipcRenderer.on('asynchronous-message', (event,res) => {
            let response;
            switch (res.type) {
                case 'replyNotification':
                    //TODO: handle error of message
                    response = Api.sendMessage(res.payload)
                    return
                default:
                    return
            }
        });
       
    }
};

const addElectronEventListener = (type, callback) => {
    if (isElectron) {
        return window.ipcRenderer.on(type, callback);
    }
}

export {isElectron, sendElectronNotification, electronNotificationListener, addElectronEventListener}