import { SimpleUser } from "sip.js/lib/platform/web"
import { CallManager } from "./CallManager"
import { CallState } from "../enums/CallState"
import { CallerInfo } from "../interfaces/CallerInfo"
import { SipCallSession } from "./SipCallSession"
import { CallType } from "../enums/CallType"
import { UserAgent, RegistererState, Registerer, RegistererOptions, RegistererRegisterOptions, Session } from "sip.js"
import CallSession from "./CallSession"
//@ts-ignore
import { formatPhoneNumber } from "phone-numbers"
//@ts-ignore
import PDCOpenConnection from "pdc-open-connection"
//@ts-ignore
import PhoneComUser from "phone-com-user"
//@ts-ignore
import api from "./util/api_v2"
//@ts-ignore
import Api from 'api'

import { CallManagerEvents } from "../enums/CallManagerEvents"
import { register } from "../serviceWorker"
import { CallEventsDelegate } from "../interfaces/CallEventsDelegate"

//TODO: onDisconnecT() of simpleUser, send bye to all sessions
class SipCallManager extends CallManager {
	callMode: CallType = CallType.SIP
	calls: { [key: string]: SipCallSession } = {}
	activeCallId: string | null = null
	topicCallbacks: any[] = []
	// private userAgent: any = {};

	simpleUser?: SimpleUser
	callEventsDelegate: CallEventsDelegate = {
		onCallReceived: (e: any) => {
			console.log(this)
			const { notification } = e
			const callId = notification.call.from
			const call = this.calls[callId]
			if (!call) return
			call.callState = CallState.INCOMING
			call.callAnswered = false
			call.callInviteEvent = notification
			call.callStartTime = Date.now() / 1000 //this is a temp value until answered to handle order of multiple incoming calls
			this.emit(CallManagerEvents.CALL_RECEIVED, e)
		},
		onCallConnecting: (callId: string) => {
			const call = this.calls[callId]
			if (!call) return
			call.callState = CallState.CONNECTING
			this.emit(CallManagerEvents.CONNECTING, null)
		},
		onCallAnswered: (callId: string) => {
			const call = this.calls[callId]
			if (!call) return
			call.callState = CallState.ACTIVE
			call.callAnswered = true
			call.callStartTime = Date.now() / 1000
			//session is private, is it time to remove simpleUser?
			this.setupRemoteMedia(call.session)
			this.simpleUser!["session"] = call.session
			this.emit(CallManagerEvents.CALL_ANSWERED, null)
			this.calls[callId].showCallStats()
		},
		onCallHangup: (callId: string) => {
			const call = this.calls[callId]
			if (!call) return
			this.cleanupMedia()
			if (this.getCallsArray().length === 1) {
				//this is last call
				this.simpleUser!.unregister()
			}
			call.session = undefined
			call.callState = null
			// this.callInfo = null
			call.callAnswered = false
			delete this.calls[callId]
			this.activeCallId = null

			if (call.participants.length > 1) {
				call.participants.forEach((p: CallerInfo, index: number) => {
					if (index !== 0) {
						this.calls[p.phoneNumber].isMerged = false
						this.emit(CallManagerEvents.STATE_UPDATE, {})
					}
				})
			}
			this.emit(CallManagerEvents.CALL_HANGUP, null)
		},
		onCallCreated: (callId: string) => {
			const call = this.calls[callId]
			if (!call) return
			call.callState = CallState.INACTIVE
			this.emit(CallManagerEvents.CALL_CREATED, null)
		},
		onCallDTMFReceived: async (tone: any, duration: any) => {
			this.emit("callDTMFReceived", [tone, duration])
		},
		onCallHold: async (held: any) => {
			this.emit("callHold", held)
		},
		onCallStatUpdate: (callId: string, stat: any) => {
			this.emit(CallManagerEvents.CALL_STAT_UPDATE, { callId, stat })
		},
		onManagerStateUpdate: (callId: string) => {
			this.emit(CallManagerEvents.STATE_UPDATE, {})
		}
	}

	constructor() {
		super()
		// this.userAgent = {};
	}
	public connect = async (): Promise<null> => {
		if (this.simpleUser) {
			return new Promise((resolve, reject) => {
				resolve(null)
			})
		}
		Api.registerSipDevice()

		let current_ext = parseInt(PhoneComUser.getExtensionId())
		console.log(current_ext)
		const extension = await api.getExtension(current_ext)
		if (
			!extension ||
			!extension.device_membership ||
			!extension.device_membership.device ||
			!extension.device_membership.device.sip_authentication
		) {
			console.log("no device")
			return null
		}

		let auth = extension.device_membership.device.sip_authentication
		let username = auth.username + 'x0'
		let host = "ipv6.sip.phone.com"
		let port = "9998"
		let password = auth.password
		let ua = {
			traceSip: true,
			// uri: username + '@' + host,
			uri: username + "@" + "phone.com",
			wsServers: ["wss://" + host + ":" + port],
			authorizationUser: username,
			password: password,
			realm: "phone.com",
			userAgentString: "CommunicatorWeb/" + "v1",
			log: {
				level: "warn",
			},
		}
		let uri = "sip:" + ua.uri

		let userAgentOptions = {
			uri: UserAgent.makeURI(uri),
			authorizationPassword: auth.password,
			authorizationUsername: auth.username  + 'x0',
			logBuiltinEnabled: false,
			userAgentString: "CommunicatorWeb/" + "v1",
			displayName: "test",
			transportOptions: {
				server: ua.wsServers[0],
			},
			autoStop: true,
			// delegate: {
			//     onInvite: this.onInvite
			// }
			//non-event based call answering
		}

		// delegates https://github.com/onsip/SIP.js/blob/master/docs/simple-user/sip.js.simpleuserdelegate.md
		var options = {
			// delegate: this.callEventsDelegate,
			media: {
				local: {},
				remote: {
					audio: document.getElementById("remoteAudio") as HTMLAudioElement,
				},
			},
			connectionRecoveryMinInterval: 10,
			aor: "sip:" + ua.uri,
			userAgentOptions: userAgentOptions,
		}

		PDCOpenConnection.on("call_received", this.onCallNotification)
		let simpleUser = new SimpleUser(ua.wsServers[0], options)
		this.simpleUser = simpleUser
		simpleUser
			.connect()
			.then(() => {
				return simpleUser
			})
			.catch((err: any) => console.log(err))

		return null
	}

	protected onCallNotification = (notification: any): void => {
		console.log(notification)

		if (
			notification.call.status === "canceled" ||
			notification.call.status === "missed" ||
			(notification.call.status === "answered" &&
				this.calls[notification.call.from].callState === CallState.INCOMING)
		) {
			this.hangupById(notification.call.from)
		} else if (!notification.call.status) {
			const theirCallInfo: CallerInfo = {
				phoneNumber: notification.call.from,
				callerId: notification.call.caller_contact_name,
			}
			let myCallInfo: CallerInfo = SipCallManager.getMyNumberInfo()
			let session = new SipCallSession(
				[theirCallInfo],
				myCallInfo,
				theirCallInfo.phoneNumber,
				CallState.INCOMING,
				this.callEventsDelegate
			)

			if (!this.calls[session.callId]) this.calls[session.callId] = session

			let e = { notification }
			this.calls[session.callId].callEventsDelegate.onCallCreated(e)
			this.calls[session.callId].callEventsDelegate.onCallReceived(e)
		}
	}

	public call = async (callee: string) => {
		// Ask permission to use microphone
		let stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
		console.log("worked, heres stream", stream)

		this.initPreCallCheck(callee)

		if (this.calls[callee]) {
			//if existing session with same number, hang up
			this.hangupById(callee)
		}

		let myNumberInfo = SipCallManager.getMyNumberInfo()
		let theirNumberInfo: CallerInfo = {
			callerId: callee,
			phoneNumber: callee,
		}
		// let callee just be ID youre searching for from session hashmap.
		let sessionId: string = callee
		let session = new SipCallSession(
			[theirNumberInfo],
			myNumberInfo,
			sessionId,
			CallState.ACTIVE,
			this.callEventsDelegate
		)
		this.calls[sessionId] = session

		this.emit(CallManagerEvents.STATE_UPDATE, {})
		this.registerAndPrepareInvite(sessionId)
	}

	public switchCall(callId: string): void {
		// this.setupRemoteMedia(this.calls[callId].session)
		if (callId && this.calls[callId]) {
			//before switching, mute my mic for this stream
			this.muteAndCleanupSession()
			this.setupRemoteMedia(this.calls[callId].session)
			this.activeCallId = callId
			this.simpleUser!["session"] = this.calls[callId].session
			this.muteCurrentLocal(false)
			this.muteCurrentRemote(false)
			this.emit(CallManagerEvents.SWITCH_CALL, { callId })
		}
	}

	async hold(): Promise<any> {
		await this.simpleUser!.hold()
	}

	async unhold(): Promise<any> {
		await this.simpleUser!.unhold()
	}
	async sendDTMF(tone: string): Promise<void> {
		await this.simpleUser!.sendDTMF(tone)
	}
	public mergeCall(callIdToMerge: string): void {
		//take all received tracks from the sessions you want to merge
		let activeSession = this.calls[this.activeCallId!] as any
		let sessiontoMerge = this.calls[callIdToMerge] as any

		//make sure nothing is muted
		activeSession.muteLocal(false)
		activeSession.muteRemote(false)

		sessiontoMerge.muteLocal(false)
		sessiontoMerge.muteRemote(false)

		
		let sessions: SipCallSession[] = [activeSession, sessiontoMerge]
		let remoteAudioId = "remoteAudio"

		let receivedTracks: any[] = []
		sessions.forEach((session: any) => {
			if (session) {
				session.session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver: any) => {
					receivedTracks.push(receiver.track)
				})
			}
		})
		//use the Web Audio API to mix the received tracks
		let AudioContext = window.AudioContext || (window as any).webkitAudioContext
		const context = new AudioContext()
		const allReceivedMediaStreams = new MediaStream()
		sessions.forEach((session: any) => {
			if (session) {
				const mixedOutput = context.createMediaStreamDestination()
				session.session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver: any) => {
					receivedTracks.forEach((track) => {
						allReceivedMediaStreams.addTrack(receiver.track)
						if (receiver.track.id !== track.id) {
							const sourceStream = context.createMediaStreamSource(new MediaStream([track]))
							sourceStream.connect(mixedOutput)
						}
					})
				})
				//mixing your voice with all the received audio
				session.session.sessionDescriptionHandler.peerConnection.getSenders().forEach((sender: any) => {
					const sourceStream = context.createMediaStreamSource(new MediaStream([sender.track]))
					sourceStream.connect(mixedOutput)
				})
				session.session.sessionDescriptionHandler.peerConnection
					.getSenders()[0]
					.replaceTrack(mixedOutput.stream.getTracks()[0])
			}
		})
		//play all received stream to you
		const remoteAudio = document.getElementById(remoteAudioId) as HTMLAudioElement
		remoteAudio.srcObject = allReceivedMediaStreams
		const promiseRemote = remoteAudio.play()
		if (promiseRemote !== undefined) {
			promiseRemote
				.then((_) => {
					console.log("playing all received streams to you")

					//if everything worked, attach joined callId to currentCall
					let current = this.calls[this.activeCallId!]
					let callToMerge = this.calls[callIdToMerge]
					callToMerge.isMerged = true
					let info = callToMerge.participants[0]
					current.participants.push(info)
					this.emit(CallManagerEvents.MERGE_CALL, null)
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}
	public muteCurrentLocal = (isMuted: boolean): void => {
		if (this.activeCallId) {
			this.calls[this.activeCallId].participants.forEach((p: CallerInfo) => {
				const id = p.phoneNumber
				this.calls[id].muteLocal(isMuted)
			})
			// let session = this.calls[this.activeCallId] as any
			// console.log(session)
			// let pc = session.session.sessionDescriptionHandler.peerConnection
			// pc.getLocalStreams().forEach((stream: any) =>
			// 	stream.getAudioTracks().forEach((track: any) => (track.enabled = !isMuted))
			// )
		}

		// if (this.activeCallId && this.calls[this.activeCallId].participants.length > 1) {
		// 	this.calls[this.activeCallId].participants.forEach((p: CallerInfo) => {
		// 		let session = this.calls[p.phoneNumber]
		// 		session.muteLocal(true)
		// 	})
		// }
	}

	public muteCurrentRemote = (isMuted: boolean): void => {
		// let session = this.sipCall.session
		// let pc = session.sessionDescriptionHandler.peerConnection
		// pc.getRemoteStreams().forEach(stream => stream.getAudioTracks().forEach(track => track.enabled = !isMuted))
		if (this.activeCallId) {
			this.calls[this.activeCallId].participants.forEach((p: CallerInfo) => {
				const id = p.phoneNumber
				this.calls[id].muteRemote(isMuted)
			})
			// let session = this.calls[this.activeCallId] as any
			// console.log(session)
			// let pc = session.session.sessionDescriptionHandler.peerConnection
			// pc.getLocalStreams().forEach((stream: any) =>
			// 	stream.getAudioTracks().forEach((track: any) => (track.enabled = !isMuted))
			// )
		}

		// if (this.activeCallId && this.calls[this.activeCallId].participants.length > 1) {
		// 	this.calls[this.activeCallId].participants.forEach((p: CallerInfo) => {
		// 		let session = this.calls[p.phoneNumber] as any
		// 		console.log(session)
		// 		let pc = session.session.sessionDescriptionHandler.peerConnection
		// 		pc.getRemoteStreams().forEach((stream: any) =>
		// 			stream.getAudioTracks().forEach((track: any) => (track.enabled = !isMuted))
		// 		)
		// 	})
		// }
	}

	public answerById(id: string): void {
		this.initPreCallCheck(id)
		this.callEventsDelegate.onCallConnecting(id)
		this.registerAndPrepareInvite(id)
	}

	async hangupById(id: string, endAll: boolean = false): Promise<any> {
		const call = this.calls[id]
		if (!call) return

		call.hangup()


		//if call was merged, send bye to others
		if (endAll && call.participants.length > 1) {
			call.participants.forEach((p: CallerInfo, index: number) => {
				//TODO: make it so that primary caller isnt part of participants?
				if (index !== 0) {
					this.calls[p.phoneNumber].hangup()
				}
			})
		}

		//if call was merged, send bye to others
		
	}

	private initPreCallCheck(callee: string) {
		//if there is an active call, put that session on hold, and then switch
		if (this.activeCallId) {
			// await this.hold()
			this.emit(CallManagerEvents.SWITCH_CALL, { callId: callee })
			this.muteAndCleanupSession()
		}
	}

	private muteAndCleanupSession() {
		this.muteCurrentLocal(true)
		this.muteCurrentRemote(true)
		this.cleanupMedia()
	}

	private registerAndPrepareInvite(sessionId: string) {
		this.activeCallId = sessionId
		let registererOpts: RegistererRegisterOptions = {
			requestDelegate: {
				onAccept: () => {
					console.log("register options worked")
					this.calls[sessionId].prepareInvite(this.simpleUser!["userAgent"])
				},
			},
		}
		this.simpleUser!.register(undefined, registererOpts).catch((err) => console.log(err))
	}
	static getMyNumberInfo() {
		let numbers = PhoneComUser.getPhoneNumber()
		let selected = numbers.find((n: any) => n.selected) || numbers[0] || {number:'Private', nickname:'Private'}
		const number = formatPhoneNumber(selected.number || selected)
		let myNumberInfo: CallerInfo = {
			phoneNumber: number,
			callerId: selected.nickname || null,
		}
		return myNumberInfo
	}
	cleanupMedia(): void {
		const mediaElement: HTMLAudioElement = document.getElementById("remoteAudio")! as HTMLAudioElement
		mediaElement.srcObject = null
		mediaElement.pause()
	}
	setupRemoteMedia(session: any): void {
		const mediaElement: HTMLAudioElement = document.getElementById("remoteAudio")! as HTMLAudioElement
		const remoteStream = new MediaStream()
		session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver: any) => {
			if (receiver.track) {
				remoteStream.addTrack(receiver.track)
			}
		})
		mediaElement.srcObject = remoteStream
		mediaElement.play()
	}
	test(): void {
		throw new Error("Method not implemented.")
	}
	muteById(id: string): void {
		throw new Error("Method not implemented.")
	}
	addSession(session: CallSession): void {
		throw new Error("Method not implemented.")
	}
	removeSession(session: CallSession): void {
		throw new Error("Method not implemented.")
	}
	setActiveCall(callId: string): void {
		throw new Error("Method not implemented.")
	}
	supports(): string[] {
		//TODO: []
		// {}
		throw new Error("Method not implemented.")
	}
	getMyNumberInfo(): CallerInfo {
		throw new Error("Method not implemented.")
	}
}

export default SipCallManager