import React, { Component } from 'react'
import LoaderFull from 'loader-full'
import { formatPhoneNumber } from 'phone-numbers'
import { AsYouType } from 'libphonenumber-js'
import { DefaultArrowTooltip } from 'tooltips'
import SelectionControl from 'selection-control'
import ConfirmModal from 'confirm-modal'
import Menu from 'pdc-menu'
import api from '../../util/api_v5'

import {isValidNumber as isValidNumberCustom} from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'

import Select from '@material-ui/core/es/Select/Select'
import MenuItem from '@material-ui/core/es/MenuItem/MenuItem'
import CallIcon from '@material-ui/icons/PhoneCallback'
import PhoneIcon from '@material-ui/icons/PhoneAndroid'
import ComputerIcon from '@material-ui/icons/Computer'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import { withStyles, TextField } from '@material-ui/core'
import Button from 'button'

export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const styles = theme => ({
	settingWrapper:	{
		...theme.personalSettingsApp.settingWrapper,
		flexDirection: 'column'
	},
	loadingDiv:		theme.loadingDiv,
	section: {
		marginBottom:	30,
		width:			400,
		'&:not(:first-child)': {
			marginTop: 30
		}
	},
	question: {
		fontSize:		16,
		fontWeight:		'bold',
		marginBottom:	10,
		color:			'gray',
		'& .em': {
			color: 'black'
		}
	},
	optionItem: {
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'space-between',
		'& .left-part': {
			display:	'flex',
			alignItems:	'center',
			'& svg': {
				marginRight: 10
			}
		},
		'& .right-part': {
			display:	'flex',
			alignItems:	'center'
		}
	},
	removeButton: {
		cursor:		'pointer',
		marginLeft:	10,
		color:		'#ff000077',
		'&:hover': {
			color:	'#ff0000'
		}
	},
	addButtonWrapper: {
		display: 'inline-block'
	},
	addButton: {
		display: 'flex'
	},
	menuOptionSelect: {
		width: 100
	}
})

class IncomingCalls extends Component {

	state = {
		loading:				true,
		forwardNumberSaving:	false,
		computerDeviceState:	false,
		phoneDeviceState:		false,
		callScreening:			false,
		newForwardNumber:		'',
		cellForward:			{},
		screenCallsModal:		null
	}

	componentDidMount() {
		this.getForwardNumbers()
	}

	getForwardNumbers = async () => {
		let extension = await api.getExtension()
		try {
			let route = extension.route
			if (!route) throw true
			let rules = route.rules
			if (!rules || rules.length === 0) throw true
			let actions = rules[0].actions
			if (!actions || actions.length === 0) throw true
			if (actions[0].action !== 'forward') throw true
			let forwardItems = actions[0].items
			if (!forwardItems || forwardItems.length === 0) throw true
			let phoneNumberForwardAction = null
			forwardItems.forEach(item => {
				if (phoneNumberForwardAction) return
				if (item.type !== 'phone_number') return
				phoneNumberForwardAction = item
			})
			if (!phoneNumberForwardAction) throw true
			let number		= phoneNumberForwardAction.number
			let screening	= phoneNumberForwardAction.screening
			this.setState({cellForward: {number, enabled: true, screening}})
			this.setState({loading: false})
		} catch (e) {
			this.setState({loading: false})
		}
	}

	renderPhoneNumbersSection = () => {
		const { classes } = this.props
		let cellForward	= this.state.cellForward
		let phoneNumber	= cellForward ? formatPhoneNumber(cellForward.number) : null
		let onRemove	= this.removeForwardNumber //previously had confirmation pop up
		let onChange	= () => {
			cellForward.enabled = !cellForward.enabled
			this.setState({cellForward})
		}
		let tooltipText = 'You can only forward to 1 external number. Either remove the number first, or if you want to add another user like your business partner, add a user instead.'
		return (
			<div className={classes.section}>
				<div className={classes.question}>Should we ring a cell number on new calls?</div>
				{phoneNumber ? this.renderItem(CallIcon, phoneNumber, cellForward.enabled, onChange, onRemove) : null}
				<DefaultArrowTooltip
					title		= {phoneNumber ? tooltipText : ''}
					placement	= 'right'
				>
					<div className={classes.addButtonWrapper}>
						<Button
							variant		= 'filled'
							disabled	= {Boolean(phoneNumber)}
							onClick		= {() => this.setState({isAddForwardNumberModalShown: true})}
							className	= {classes.addButton}
						><AddIcon/> Add number</Button>
					</div>
				</DefaultArrowTooltip>
				{this.renderRemoveForwardNumberModal()}
				{this.renderAddForwardNumberModal()}
			</div>
		)
	}
	// todo remove this once it is 100% confirmed this is no longer wanted, currently this should never show as per Oksana's request
	renderRemoveForwardNumberModal = () => {
		let isShown = Boolean(this.state.isRemoveForwardNumberModalShown)
		return (
			<ConfirmModal
				isShown			= {isShown}
				title			= {'Are you sure you want to stop remove this number?'}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Remove'
				yesButtonColor	= 'danger'
				onReject		= {() => this.setState({isRemoveForwardNumberModalShown: false})}
				onConfirm		= {this.removeForwardNumber}
				size			= 'size2' // size440
			/>
		)
	}

	removeForwardNumber = async () => {
		let removeNumber = this.state.cellForward.number
		this.setState({cellForward: {}, isRemoveForwardNumberModalShown: false, loading: true})
		await api.removeExtensionForward()
		api.fixRules(removeNumber)
		this.setState({loading: false})
	}

	renderAddForwardNumberModal = () => {
		let isShown = Boolean(this.state.isAddForwardNumberModalShown)

		let onReject = () => this.setState({isAddForwardNumberModalShown: false})
		let onConfirm = () => this.saveForwardNumber()

		let onChange = e => {
			let number = e.target.value
			if (number.length > 3) {
				let numberArray = Array.from(number)
				numberArray.reverse()
				if (numberArray[0] !== ')' && numberArray[3] === '(') {
					number = number.substring(0, number.length - 1)
				}
			}
			if (number.length < 2) return this.setState({newForwardNumber: number})
			if (number[0] === '+' && number[1] !== '1') {
				number = formatPhoneNumber(number)
				return this.setState({newForwardNumber: number})
			}
			if (number.substring(0, 2) === '+1') number = number.substring(2)
			number = new AsYouType('US').input(number)
			this.setState({newForwardNumber: number})
		}

		return (
			<ConfirmModal
				isShown			= {isShown}
				title			= 'Enter a phone number'
				content			= {
					<TextField
						autoFocus
						value		= {this.state.newForwardNumber}
						onChange	= {onChange}
						// style		= {{width: 250}}
						error		= {Boolean(this.state.newForwardNumberError)}
						helperText	= {this.state.newForwardNumberError ? 'Invalid number' : null}
					/>
				}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Confirm'
				onReject		= {onReject}
				onConfirm		= {onConfirm}
				size			= 'size2' // size440
			/>
		)
	}

	saveForwardNumber = async (screening=true) => {
		let newForwardNumber	= this.state.newForwardNumber
		let numberSplit			= (newForwardNumber.match(/\d|\+/g) || [])
		newForwardNumber		= numberSplit.join('')
		if (newForwardNumber[0] !== '+') {
			if (newForwardNumber[0] !== '1') newForwardNumber = `1${newForwardNumber}`
			newForwardNumber	= `+${newForwardNumber}`
		}
		if (!isValidNumber(newForwardNumber)) return this.setState({newForwardNumberError: true})
		this.setState({isAddForwardNumberModalShown: false, newForwardNumberError: false, loading: true})
		await api.addExtensionForward(newForwardNumber, screening)
		this.setState({cellForward: {number: newForwardNumber, enabled: true, screening}, loading: false})
	}

	renderItem = (Icon, text, toggleValue, onChange, onRemove) => {
		const { classes } = this.props
		return (
			<div key={text} className={classes.optionItem}>
				<div className='left-part'><Icon/> <span>{text}</span></div>
				<div className='right-part'>
					<SelectionControl
						variant	= 'switch'
						checked	= {toggleValue}
						onClick	= {onChange}
						name	= 'checked-toggle'
						value	= 'checked-toggle'
					/>
					<DefaultArrowTooltip
						title		= 'Remove'
						placement	= 'right'
					>
						<div className={classes.removeButton} onClick={onRemove}><CloseIcon/></div>
					</DefaultArrowTooltip>
				</div>
			</div>
		)
	}

	renderDevicesSection = () => {
		const { classes } = this.props
		return null //todo finish this section, was put on hold
		return (
			<div className={classes.section}>
				<div className={classes.question}>Which devices should we ring when you get a call?</div>
				{this.renderItem(ComputerIcon, 'Chrome, Mac - Web App', undefined, () => {}, () => {})}
				{this.renderItem(PhoneIcon, 'iPhone 11 - Mobile App', undefined, () => {}, () => {})}
			</div>
		)
	}

	onCallScreeningChange = screenCallsMenuSelection => {
		if (this.state.cellForward.screening === (screenCallsMenuSelection.value === 'yes')) return
		this.setState({screenCallsModal: screenCallsMenuSelection})
	}

	toggleCallScreening = () => {
		let screenCallsModal	= this.state.screenCallsModal
		let screenCalls			= screenCallsModal.value === 'yes'
		this.setState({newForwardNumber: this.state.cellForward.number}, async () => {
			let cellForward			= this.state.cellForward
			cellForward.screening	= screenCalls
			this.setState({screenCallsModal: null, loading: true})
			await this.saveForwardNumber(screenCallsModal.value === 'yes')
			this.setState({loading: false})
		})
	}

	renderCallScreeningConfirmModal = () => {
		let screenCallsModal	= this.state.screenCallsModal
		let isShown				= Boolean(screenCallsModal)
		let title				= `Are you sure you want to turn call screening ${screenCallsModal ? 'on' : 'off'}?`
		return (
			<ConfirmModal
				isShown			= {isShown}
				title			= {title}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Yes'
				onReject		= {() => this.setState({screenCallsModal: null})}
				onConfirm		= {this.toggleCallScreening}
				size			= 'size2' // size440
			/>
		)
	}

	renderCallScreeningSection = () => {
		const { classes } = this.props
		if (!this.state.cellForward.number) return null
		let items = [
			{value: 'yes', content: 'Yes'},
			{value: 'no', content: 'No'}
		]
		return (
			<div className={classes.section}>
				<div className={classes.question}>Would you like Phone.com to screen calls?</div>
				<Menu
					label		= ''
					variant		= 'single'
					items		= {items}
					default		= {this.state.cellForward.screening ? 'yes' : 'no'}
					controlable	= {true}
					onChange	= {this.onCallScreeningChange}
					className	= {{wrapper: classes.menuOptionSelect}}
				/>
				{this.renderCallScreeningConfirmModal()}
			</div>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
				{this.state.loading ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div> : null}
				{this.renderPhoneNumbersSection()}
				{this.renderDevicesSection()}
				{this.renderCallScreeningSection()}
			</div>
		)
	}
}

export default withStyles(styles)(IncomingCalls)