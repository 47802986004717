import React, { Component } from 'react'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { withStyles } from '@material-ui/core'

import tone0 from './tones/dtmf-0.mp3'
import tone1 from './tones/dtmf-1.mp3'
import tone2 from './tones/dtmf-2.mp3'
import tone3 from './tones/dtmf-3.mp3'
import tone4 from './tones/dtmf-4.mp3'
import tone5 from './tones/dtmf-5.mp3'
import tone6 from './tones/dtmf-6.mp3'
import tone7 from './tones/dtmf-7.mp3'
import tone8 from './tones/dtmf-8.mp3'
import tone9 from './tones/dtmf-9.mp3'
import toneH from './tones/dtmf-hash.mp3'
import toneS from './tones/dtmf-star.mp3'

const styles = theme => ({
	dialpad: {
		padding:		10,
		border:			'1px solid gray',
		borderRadius:	4,
		display:		'flex',
		flexDirection:	'column',
		boxShadow:		'0 5px 5px gray'
	},
	row: {
		display:		'flex',
		justifyContent:	'space-between',
		'&:not(:last-child)': {
			marginBottom: 3
		}
	},
	item: {
		padding:		5,
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'center',
		alignItems:		'center',
		width:			60,
		height:			55,
		lineHeight:		1,
		borderRadius:	4,
		color:			'#585858',
		cursor:			'pointer',
		'&:hover': {
			color:		theme.palette.primary.main,
			background:	'#efefef'
		},
		'&:not(:last-child)': {
			marginRight: 3
		}
	},
	digit: {
		fontSize:		30,
		marginBottom:	3
	},
	letters: {
		fontSize:	9,
		fontWeight:	500,
		height:		9,
		color:		'darkgray'
	}
})

const numberLettersMap = {1: ' ', 2: 'ABC', 3: 'DEF', 4: 'GHI', 5: 'JKL', 6: 'MNO', 7: 'PRQS', 8: 'TUV', 9: 'WXYZ', 0: ' ', '+': ' ', new_line: ' ', '*': '', '#': ''}
const validDTMFChars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '#', '*']
class Dialpad extends Component {
	constructor(props){
		super(props)

		this.state = {call: null}
	}
	componentDidMount(){
		if(this.props.enableDTMF){
			// PDCCall.connect('sip').then(call => {
			// 	console.log('caller')
			// 	console.log(call)
			// 	this.setState({call})
			// })
		}
	}

	onClick = char =>{
		console.log('clicked')
		if (this.props.enableDTMF && validDTMFChars.includes(char) && this.props.currentCall) {
			console.log('sent DTMF +++++')
			console.log(char)
			// this.state.call.sendDTMF(`${char}`)
			this.props.sendDTMF(`${char}`)
			// Sound the DTMF
			let domElement = document.getElementById(`sound${char}`)
			if (domElement) {
				domElement.pause()
				domElement.currentTime = 0
				domElement.play()
			}
		}
		if (this.props.onClick) {
			this.props.onClick(char === 'new_line' ? '\n' : char)
		}
	}

	renderKey = char => {
		const { classes } = this.props
		return <div className={classes.item} onClick={() => this.onClick(char)}>
			<div className={classes.digit}>{char === 'new_line' ? String.fromCharCode('8629') : char}</div>
			<div className={classes.letters}>{numberLettersMap[char]}</div>
		</div>
	}

	renderDTMFSounds = () => {
		return (
			<>
				<audio id='sound0' style={{display: 'none'}}><source src={tone0} type='audio/mp3'/></audio>
				<audio id='sound1' style={{display: 'none'}}><source src={tone1} type='audio/mp3'/></audio>
				<audio id='sound2' style={{display: 'none'}}><source src={tone2} type='audio/mp3'/></audio>
				<audio id='sound3' style={{display: 'none'}}><source src={tone3} type='audio/mp3'/></audio>
				<audio id='sound4' style={{display: 'none'}}><source src={tone4} type='audio/mp3'/></audio>
				<audio id='sound5' style={{display: 'none'}}><source src={tone5} type='audio/mp3'/></audio>
				<audio id='sound6' style={{display: 'none'}}><source src={tone6} type='audio/mp3'/></audio>
				<audio id='sound7' style={{display: 'none'}}><source src={tone7} type='audio/mp3'/></audio>
				<audio id='sound8' style={{display: 'none'}}><source src={tone8} type='audio/mp3'/></audio>
				<audio id='sound9' style={{display: 'none'}}><source src={tone9} type='audio/mp3'/></audio>
				<audio id='sound#' style={{display: 'none'}}><source src={toneH} type='audio/mp3'/></audio>
				<audio id='sound*' style={{display: 'none'}}><source src={toneS} type='audio/mp3'/></audio>
			</>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<Popper style={this.props.style} open={this.props.open} anchorEl={this.props.anchorEl} placement='bottom'>
				<ClickAwayListener onClickAway={this.props.onClickAway}>
					<Paper classes={{root: classes.dialpad}}>
						<div className={classes.row}>
							{this.renderKey(1)}
							{this.renderKey(2)}
							{this.renderKey(3)}
						</div>
						<div className={classes.row}>
							{this.renderKey(4)}
							{this.renderKey(5)}
							{this.renderKey(6)}
						</div>
						<div className={classes.row}>
							{this.renderKey(7)}
							{this.renderKey(8)}
							{this.renderKey(9)}
						</div>
						<div className={classes.row}>
							{this.renderKey('#')}
							{this.renderKey(0)}
							{this.renderKey('*')}
						</div>
						<div className={classes.row}>
							{this.renderKey('+')}
							{this.renderKey('new_line')}
						</div>
					</Paper>
				</ClickAwayListener>
				{this.renderDTMFSounds()}
			</Popper>
		)
	}
}

export default withStyles(styles)(Dialpad)