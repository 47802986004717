import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectorItem from 'selector-item'
import { VoicemailIcon } from 'pdc-svg-icons'
import { updateVoicemail, switchVoicemail, expandVoicemail } from '../actions/voicemails'
import { getFormattedTime } from 'time-format'
import ConfirmDeleteModal from 'confirm-delete-modal'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import VoicemailHeaderDropdownMenu from './VoicemailHeaderDropdownMenu'
import { withStyles } from '@material-ui/core'
import { Salesforce } from '../../../communicator/src/util/salesforce'
const mapStateToProps = state => ({
	voicemails:			state.voicemails,
	currentVoicemail:	state.currentVoicemail
})

const mapDispatchToProps = dispatch => ({
	updateVoicemail: voicemail => dispatch(updateVoicemail(voicemail)),
	switchVoicemail: voicemail => dispatch(switchVoicemail(voicemail)),
	expandVoicemail: voicemailId => dispatch(expandVoicemail(voicemailId))
})

const styles = theme => ({
	beforeMainText: {
		marginRight: '7px',
		'&>svg': {
			width:	'20px',
			height:	'20px'
		}
	},
	mobileVoicemailInfo: {
		display:		'flex',
		flexDirection:	'column',
		overflow:		'hidden',
		maxHeight:		'calc(100% - 0px)',
		transition: 	'max-height .4s, padding .4s',
		paddingLeft:	20,
		paddingRight:	20,
		'& hr': {
			margin:		0,
			borderTop:	theme.palette.primary.flatBorder
		}
	},
	transcript: {
		marginTop: 20
	},
	timeInfo: {
		fontSize:		14,
		fontWeight:		500,
		margin:			'15px 0',
		lineHeight:		1.36,
		letterSpacing:	-0.1,
		color:			'black',
		'& .date-time': {
			paddingRight:	10,
			borderRight:	'1px solid #b6c0c5'
		},
		'& .audio-length': {
			marginLeft:	10
		}
	}
})

class VoicemailSelectorItem extends Component {

	constructor(props) {
		super(props)
		this.expandableAreaRef = React.createRef()
		this.state = {
			showDeleteVoicemailDialog: false,
			expanded: props.voicemail.expanded ? 2 : 0
		}
	}

	componentWillUnmount() {
		// this.props.collapseVoicemail(this.props.voicemail.id)
	}

	getExpandedAreaMaxHeigth = () => {
		return (this.expandableAreaRef && this.expandableAreaRef.current) ? this.expandableAreaRef.current.scrollHeight + 56 : 0
	}

	formatDuration = type => {

		let _twoDigits = (number) => {
			return (number > 9) ? number : "0" + number
		}

		let duration = this.props.voicemail.duration

		if (type === 'short') {
			let hours		= parseInt(duration / 60)
			let minutes		= _twoDigits(parseInt((duration - hours * 60) / 60))
			let seconds		= _twoDigits(parseInt(duration - (hours * 360) - (minutes * 60)))
			return hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`
		} else {
			let minutes	= parseInt(duration / 60)
			let seconds	= duration - (minutes * 60)
			return `${minutes ? `${minutes} min ` : ''}${seconds} sec`
		}
	}

	renderVoicemailIcon = () => {
		let { classes } = this.props
		return <span className={classes.beforeMainText}><VoicemailIcon/></span>
	}

	onClick = () => {
		let voicemail = this.props.voicemail
		let shouldExpand = true
		if (this.props.screenViewType.isMobileView) {
			shouldExpand = !voicemail.expanded
			if (!shouldExpand) {
				this.props.expandVoicemail(null)
				this.props.recalculateScroll()
			} else {
				this.props.expandVoicemail(voicemail.id)
				this.props.switchVoicemail(voicemail)
			}
		}
		this.props.onClick(shouldExpand)
	}

	onDropdownClose = () => this.setState({dropdownAnchor: null})

	showDropdown = e => this.setState({dropdownAnchor: e.currentTarget})

	onDeleteClick = () => this.setState({showDeleteVoicemailDialog: true})

	hideDialog = () => this.setState({showDeleteVoicemailDialog: false})

	deleteAndSwitchVoicemail = () => {
		this.props.deleteVoicemail()
		this.hideDialog()
	}

	makeCall = () => {
		let fromNumber = this.props.voicemail.from.number
		this.props.makeCall(fromNumber)
	}

	renderDropdownMenu = () => {
		let editContactType	= 'Add'
		let contactId		= null
		let voicemail		= this.props.voicemail
		let fromNumber		= voicemail.from.number
		this.props.extraContacts.forEach(c => {
			let contactNumbers	= c.numbers.map(n => n.number)
			if (contactNumbers.includes(fromNumber)) {
				editContactType	= 'Edit'
				contactId		= c.id
			}
		})
		let editContact = {
			call: () => this.props.editContact(contactId, fromNumber),
			type: editContactType
		}
		let isVirtual = this.props.extension.is_virtual
		return (
			<VoicemailHeaderDropdownMenu
				deleteVoicemail		= {this.onDeleteClick}
				changeReadStatus	= {{call: this.props.changeReadStatus, text: `Mark as ${voicemail.is_new ? '' : 'un'}read`}}
				downloadVoicemail	= {{url: voicemail.link}}
				id					= {voicemail.id}
				editContact			= {editContact}
				makeCall			= {isVirtual ? null : this.makeCall}
			/>
		)
	}

	renderExpandedArea = () => {
		const { classes } = this.props
		let voicemail = this.props.voicemail
		if (!this.props.screenViewType.isMobileView || !voicemail) return
		let expandedAreaMaxHeight = this.getExpandedAreaMaxHeigth()
		let maxHeight = voicemail.expanded ? expandedAreaMaxHeight : 0
		return (
			<div
				className	= {classes.mobileVoicemailInfo}
				ref			= {this.expandableAreaRef}
				style		= {{maxHeight, paddingBottom: voicemail.expanded ? 25 : 0}}
			>
				<hr/>
				<div className={classes.timeInfo}>
					<span className='date-time'>{getFormattedTime(voicemail.created_at, true)}</span>
					<span className='audio-length'>{this.formatDuration('long')}</span>
				</div>
				<div>
					{(this.props.voicemail.link) ?
						<AudioPlayer key={voicemail.id} url={this.props.voicemail.link} onPlay={this.onPlay}/>
						: <LoadingBar/>
					}
				</div>
				<div className={`${classes.transcript} fs-block`}>{voicemail.transcript || '[Transcript not available]'}</div>
			</div>
		)
	}

	getParticipants = () => {
		let voicemail	= this.props.voicemail
		let number		= voicemail.fromNumber
		let name		= (voicemail.from && voicemail.from.name) ? voicemail.from.name : voicemail.fromNumber
		this.props.extraContacts.forEach(cd => {
			let foundNumber = cd.numbers.find(n => n.number === number)
			if (!foundNumber) return
			name = cd.name.display
		})
		let sf_contact = Salesforce.getContactName(number)
		if(sf_contact) name = sf_contact

		return [{number, name}]
	}

	render() {
		let isExpanded		= this.props.voicemail.expanded
		let text			= <span className='fs-block'>{!isExpanded ? this.props.voicemail.transcript || '[Transcript not available]' : ''}</span>
		let timestamp		= !isExpanded ? this.props.voicemail.created_at : ''
		let additionalText	= !isExpanded ? this.formatDuration('short') : ''
		let isUnread		= this.props.voicemail.is_new
		let styles			= {}
		if (isUnread) {
			styles.mainText = {
				color: 'black',
				fontWeight: 500
			}
		}
		let voicemail = this.props.voicemail
		return (
			<>
				<SelectorItem
					mainText		= {text}
					additionalText	= {additionalText}
					beforeMainText	= {this.renderVoicemailIcon()}
					participants	= {this.getParticipants()}
					timestamp		= {timestamp}
					showMyNums		= {false}
					active			= {this.props.active}
					isNew			= {false}
					bold			= {isUnread}
					onClick			= {this.onClick}
					styles			= {styles}
					renderDropdownMenu = {isExpanded ? this.renderDropdownMenu : null}
					renderExpandedArea = {this.renderExpandedArea}
					setRef			= {this.props.setRef}
				/>
				<ConfirmDeleteModal
					itemType					= 'voicemail'
					isShown						= {this.state.showDeleteVoicemailDialog}
					acknowledgedTitle			= 'Permanently delete voicemail'
					notAcknowledgedTitle		= 'Permanently delete voicemail'
					acknowledgedMainContent		= ''
					notAcknowledgedMainContent	= ''
					onClose 					= {this.hideDialog}
					onDelete					= {this.deleteAndSwitchVoicemail}
				/>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(VoicemailSelectorItem))