import { combineReducers } from 'redux'
import { smallView, currentView } from './view'
import { currentCall, calls } from './calls'
import { currentExtension } from './pdcuser'

export default combineReducers({
	smallView,
	currentView,
	currentCall,
	calls,
	currentExtension
})