import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5'
import Api from 'api'
import VoicemailContent from './VoicemailContent'
import VoicemailSelector from './VoicemailSelector'
import ConfirmDeleteModal from 'confirm-delete-modal'
import EditContactModal from 'edit-contact-modal'
import PhoneComUser from 'phone-com-user'
import { updateVoicemails, switchVoicemail, addVoicemail, updateVoicemail, deleteVoicemail, addContactsToVoicemails, removeContactFromVoicemails } from '../actions/voicemails'
import { switchExtension, setIsVoicemailConfigured } from '../actions/pdcuser'
import { setSmallView } from '../actions/view'
import ResizeAware from 'react-resize-aware'
import LoaderFull from 'loader-full'
import { withStyles } from '@material-ui/core'
import {pushVoicemailNotification} from 'notification-pusher'
import gtmDataPush from 'gtm-events'
let threshold = 768

const mapStateToProps = state => ({
	currentView:			state.currentView,
	currentVoicemail:		state.currentVoicemail,
	voicemails:				state.voicemails,
	smallView:				state.smallView,
	currentExtensionRedux:	state.currentExtension,
	isVoicemailConfigured:	state.isVoicemailConfigured
})

const mapDispatchToProps = dispatch => ({
	updateVoicemails:				voicemails	=> dispatch(updateVoicemails(voicemails)),
	switchVoicemail:				voicemail	=> dispatch(switchVoicemail(voicemail)),
	addVoicemail:					voicemail	=> dispatch(addVoicemail(voicemail)),
	updateVoicemail:				voicemail	=> dispatch(updateVoicemail(voicemail)),
	deleteVoicemail:				voicemail	=> dispatch(deleteVoicemail(voicemail)),
	setSmallView:					boolVal		=> dispatch(setSmallView(boolVal)),
	switchExtension:				extension	=> dispatch(switchExtension(extension)),
	setIsVoicemailConfigured:		isConf		=> dispatch(setIsVoicemailConfigured(isConf)),
	addContactsToVoicemails:		contacts	=> dispatch(addContactsToVoicemails(contacts)),
	removeContactFromVoicemails:	contactId	=> dispatch(removeContactFromVoicemails(contactId))
})

const styles = theme => ({
	appWrapper: {
		display:	'flex',
		height:		'100%',
		position:	'relative'
	},
	loadingDiv: theme.loadingDiv,
	voicemailPanel: {
		display:		'flex',
		flexDirection:	'column',
		minWidth:		theme.selector.width,
		boxShadow:		'0 0 0 1px #e0e0e0',
		position:		'relative',
		'&.small-view': {
			width:		'100%'
		},
		'&:not(.small-view)': {
			maxWidth:	theme.selector.width,
		}
	},
	noVoicemailMessage: {
		transform:	'translate(-50%, -50%)',
		position:	'absolute',
		top:		'50%',
		left:		'50%',
		width:		'fit-content',
		fontSize:	'16px'
	},
	noSetupWrapper: {
		boxShadow:	'0 0 0 1px #e0e0e0',
		minWidth:	theme.selector.width,
		'&.small-view': {
			minWidth:	'100%'
		},
		'&:not(.small-view)': {
			maxWidth:		theme.selector.width,
		}
	},
	title: {
		fontSize:		20,
		fontWeight:		600,
		lineHeight:		1.25,
		letterSpacing:	-0.2,
		color:			'black',
		padding:		'17px 20px',
		boxShadow:		theme.palette.primary.flatBottomShadow
	}
})

class App extends Component {

	constructor(props) {
		super(props)

		this.state = {
			loading:							false,
			loadedOnce:							false,
			isConfirmDeleteVoicemailModalShown:	false,
			newVoicemail:						null,
			deletedVoicemails:					{num: 0, randomString: ''},
			editContact:						null
		}

		this.props.resetSubscription(true)
		if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(props.extension.extension_id)
		}
	}

	componentDidMount() {
		this._ismounted = true
		this.props.switchVoicemail(null)
		this.props.subscribeForNotifications('voicemail', this.voicemailUpdate, true)
		this.initialLoad()
		this.checkIfVoicemailConfigured()
	}

	checkIfVoicemailConfigured = () => {
		api.isVoicemailConfigured().then(response => {
			console.log('isVoicemailConfigured returns:', response)
			if (!this._ismounted) return
			this.props.setIsVoicemailConfigured(Boolean(response))
		})
	}

	componentWillUnmount() {
		this._ismounted = false
	}

	componentDidUpdate(prevProps) {
		if (this.props.extension && this.props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(this.props.extension.extension_id)
			this.props.resetSubscription(true)
			this.checkIfVoicemailConfigured()
			this.initialLoad(true)
		}

		this.updateEditContactIfNeeded(prevProps)

		if (prevProps.isOffline && !this.props.isOffline) this.adjustView()

		this.updateContactsInfoInVoicemails()
	}

	updateContactsInfoInVoicemails = () => {
		if (!this.props.contactsUtil.extraContactsLoaded) return
		let numNewContacts	= 0
		let voicemailItems	= this.props.voicemails.items
		if (!voicemailItems) return
		let contactIdsToBeRemoved = []
		voicemailItems.forEach(voicemail => {
			let extraContact = this.props.contactsUtil.extraContacts.find(contact => contact.numbers.find(n => n.number === voicemail.from.number))
			if (extraContact && !voicemail.from.contact_id) {
				numNewContacts++
			} else if (!extraContact && voicemail.from.contact_id) {
				contactIdsToBeRemoved.push(voicemail.from.contact_id)
			}
		})
		if (numNewContacts > 0) {
			this.props.addContactsToVoicemails(this.props.contactsUtil.extraContacts)
		}
		if (contactIdsToBeRemoved.length) {
			contactIdsToBeRemoved.forEach(contactId => this.props.removeContactFromVoicemails(contactId))
		}
	}

	adjustView = () => {
		this.initialLoad(true)
	}

	updateEditContactIfNeeded = prevProps => {
		if (prevProps.contactsUtil.extraContacts.length === this.props.contactsUtil.extraContacts.length || !this.state.editContact || this.state.editContact.id) return
		let editContact	= this.state.editContact
		let number		= editContact.number
		let contactId	= null
		this.props.contactsUtil.extraContacts.forEach(cd => {
			if (cd.numbers.find(n => n.number === number)) contactId = cd.id
		})
		if (!contactId) return
		editContact.id = contactId
		this.setState({editContact})
	}

	initialLoad = async force => {
		let extensionSwitched = Boolean(!this.props.currentExtensionRedux || this.props.extension.extension_id !== this.props.currentExtensionRedux.extension_id)
		if (!extensionSwitched && this.props.voicemails.items && !force) return this.setState({loadedOnce: true})
		if (extensionSwitched) this.props.switchExtension(this.props.extension)

		this.setState({loading: true})
		// limit so based on height so it always gets filled
		let extensionId	= PhoneComUser.getExtensionId()
		let limit		= Math.max(parseInt(window.innerHeight / 50), 15)
		let response	= await api.loadVoicemails(0, limit)
		if (response === 'network-error') return
		if (!this._ismounted) return // console.log('Voicemail App.js got unmounted')
		if (extensionId !== PhoneComUser.getExtensionId()) {
			// This may happen if you change the extension while this extension voicemails are being loaded
			return // console.log('The extension got changed so stop.')
		}
		this.props.updateVoicemails(response)
		await this.loadExtraContacts()
		let switchedByUrl = this.openUrlVoicemail()
		this.setState({loading: false, loadedOnce: true})
		this.props.onLoaded()
		if (switchedByUrl) return
		if (!this.props.voicemails.items || !this.props.voicemails.items.length) {
			this.props.switchVoicemail()
		} else if (!this.props.smallView) {
			this.props.switchVoicemail(this.props.voicemails.items[0])
		}
	}

	loadExtraContacts = async () => {
		let voicemails		= this.props.voicemails
		let phoneNumbers	= []
		// Collect all of the phone numbers which are not connected to a contact
		voicemails.items.forEach(v => {
			if (v.from.contact_id) return
			if (v.from.number) phoneNumbers.push(v.from.number)
		})
		if (phoneNumbers.length === 0) return
		// Remove duplicates
		phoneNumbers		= Array.from(new Set(phoneNumbers))
		let filters			= {keyword: phoneNumbers}
		let extraContacts	= await this.props.contactsUtil.loadExtraContacts(filters)
		this.props.addContactsToVoicemails(extraContacts)
	}

	openUrlVoicemail = () => {

		// If the url path links to a voicemail then open it

		let pathname		= window.location.pathname
		let pathnameSplit	= pathname.split('/').filter(e => e)
		let voicemailId		= undefined
		if (pathnameSplit.length > 2 && pathnameSplit[1] === 'voicemail') {
			voicemailId		= pathnameSplit[2].substring(1)
		}
		let voicemailItems	= this.props.voicemails.items
		if (!voicemailItems || !voicemailId) return false

		let pathVoicemail = voicemailItems.find(v => v.id === voicemailId)
		if (pathVoicemail) {
			this.props.switchVoicemail(pathVoicemail)
			return true
		}
		return false
	}

	voicemailUpdate = updatedVoicemail => {

		if (updatedVoicemail.type === 'delete') {
			return this.voicemailDeleted(updatedVoicemail)
		}

		if (updatedVoicemail.type === 'read_status') {
			let voicemailIds	= updatedVoicemail.voicemail_id
			voicemailIds.forEach(voicemailId => {
				let isNew		= updatedVoicemail.is_new
				let voicemail	= this.props.voicemails.items.find(updatedVoicemail => updatedVoicemail.id === voicemailId)
				if (!voicemail || Boolean(voicemail.is_new) === Boolean(isNew)) return
				this.changeReadStatus(voicemail, false)
				if (voicemail.id === this.props.currentVoicemail.id) this.props.switchVoicemail(voicemail)
			})
			return
		}

		let voicemails = this.props.voicemails
		let voicemailItems = voicemails.items
		for (let voicemail of voicemailItems) {
			if (voicemail.id === updatedVoicemail.id) {
				return this.voicemailUpdated(updatedVoicemail)
			}
		}
		this.newVoicemailReceived(updatedVoicemail)
	}

	newVoicemailReceived = voicemail => {
		this.props.addVoicemail(voicemail)
		this.setState({newVoicemail: voicemail.id})

		if (this.props.standalone) {
			let extensionId	= this.props.extension.extension_id
			pushVoicemailNotification(voicemail, extensionId);
		}
	}

	setNoNewVoicemail = () => {
		this.setState({newVoicemail: null})
	}

	voicemailUpdated = voicemail => {
		this.props.updateVoicemail(voicemail)
	}

	voicemailDeleted = voicemail => {

		let currentVoicemailId		= this.props.currentVoicemail.id
		let voicemailIds			= voicemail.voicemail_ids.map(v => `${v}`)
		let voicemailDeleteItems	= this.props.voicemails.items.filter(v => voicemailIds.includes(`${v.id}`))
		voicemailDeleteItems.forEach(v => this.props.deleteVoicemail(v))

		if (voicemailIds.includes(`${currentVoicemailId}`)) {
			let voicemailItems = this.props.voicemails.items
			let switchVoicemail = null
			if (voicemailItems.length) switchVoicemail = voicemailItems[0]
			this.props.switchVoicemail(switchVoicemail)
		}

		let deletedVoicemails = {
			num:			voicemailDeleteItems.length,
			randomString:	`${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`
		}
		if (voicemailDeleteItems.length) this.setState({deletedVoicemails})
	}

	handleResize = size => {
		let needToExpand = this.props.smallView && ((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
		let needToShrink = !this.props.smallView && ( (this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
		if (needToShrink) {
			this.props.setSmallView(true)
		} else if (needToExpand) {
			this.props.setSmallView(false)
		}
	}

	deleteVoicemail = async () => {
		let currentVoicemail	= this.props.currentVoicemail
		let currentVoicemailId	= currentVoicemail.id

		this.props.deleteVoicemail(currentVoicemail)

		let voicemailItems	= this.props.voicemails.items
		let switchVoicemail	= null
		if (voicemailItems.length > 0) {
			switchVoicemail	= voicemailItems[0]
			if (switchVoicemail.id === currentVoicemailId) {
				console.log('This code is needed') // Probably this code is never executed
				if (voicemailItems.length > 1) {
					switchVoicemail = voicemailItems[1]
				} else {
					switchVoicemail = null
				}
			}

			if (switchVoicemail) {
				this.props.switchVoicemail(switchVoicemail)
			}
		} else {
			this.props.switchVoicemail()
		}

		gtmDataPush({
			PDC_Action:	'voicemail-deleted',
		})
		await api.deleteVoicemail(currentVoicemail.id)
		let deletedVoicemails = {
			num:			1,
			randomString:	`${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`
		}
		this.setState({deletedVoicemails})
	}

	changeReadStatus = (voicemail, backendChange=true) => {
		voicemail			= voicemail || this.props.currentVoicemail
		let isNew			= !voicemail.is_new
		voicemail.is_new	= isNew
		this.props.changeVoicemailReadStatus(isNew ? 'unread' : 'read', 1)
		if (backendChange) {
			api.markVoicemailRead(voicemail.id, isNew).then(statusChanged => {
				if (!statusChanged) return console.error(`Error while marking voicemail ${isNew ? 'un' : ''}read`)
				this.props.updateUnreadCounts()
			})
		} else {
			this.props.updateUnreadCounts()
		}
		this.props.updateVoicemail(voicemail)
		gtmDataPush({
			PDC_Action:	`voicemail-marked-${isNew ? 'un' : ''}read`,
		})
	}

	editContact = (id, number, prefillData) => {
		this.setState({editContact: {id, number: id ? '' : number, prefillData}})
	}

	getContact = () => {
		let extraContacts	= this.props.contactsUtil.extraContacts
		let editContact		= this.state.editContact
		let contactId		= editContact ? editContact.id : null
		if (!editContact || !contactId || !extraContacts) return null
		let contact			= null
		this.props.contactsUtil.extraContacts.forEach(c => c.id === contactId ? contact = c : null)
		return contact
	}

	saveContact = contact => {
		let extraContacts	= this.props.contactsUtil.extraContacts
		let isNew			= !Boolean(extraContacts.find(c => c.id === contact.id))
		this.props.contactsUtil.updateContact(contact)
		if (isNew) this.props.addContactsToVoicemails([contact])
		this.setState({editContact: null})
	}

	deleteContact = contactId => {
		this.props.contactsUtil.deleteContact(contactId)
		this.setState({editContact: null})
	}

	startConversation = phoneNumber => {
		let extensionId		= parseInt(window.location.pathname.split('/')[1].substring(1))
		let redirectPath	= `/e${extensionId}/messages/new-conversation/${phoneNumber}`
		this.props.redirect(redirectPath)
	}

	render() {
		let { classes } = this.props
		return (
			<div className='App'>
				<ResizeAware
					style		= {{height: this.props.standalone ? 'calc(100% - 60px)' : '100%'}}
					onResize	= {this.handleResize}
				>
					{this.state.loading ?
						<div className={classes.loadingDiv}>
							<LoaderFull styles={{loaderFull: {left: this.props.smallView ? '50%' : 'calc(50% + 120px)'}}} size='big'/>
						</div>
					: null}
					{this.state.loadedOnce &&
						<div className={classes.appWrapper}>
							<div className={`${classes.voicemailPanel} ${this.props.smallView ? 'small-view' : ''}`}>
								{!this.props.smallView ? <div className={classes.title}>Voicemail</div> : null}
								<VoicemailSelector
									newVoicemail			= {this.state.newVoicemail}
									setNoNewVoicemail		= {this.setNoNewVoicemail}
									smallView				= {this.props.smallView}
									screenViewType			= {this.props.screenViewType}
									deleteVoicemail			= {this.deleteVoicemail}
									changeReadStatus		= {this.changeReadStatus}
									isVoicemailConfigured	= {this.props.isVoicemailConfigured}
									extraContacts			= {this.props.contactsUtil.extraContacts}
									loadExtraContacts		= {this.loadExtraContacts}
									deletedVoicemails		= {this.state.deletedVoicemails}
									editContact				= {this.editContact}
									extension				= {this.props.extension}
									makeCall				= {this.props.makeCall}
								/>
							</div>
							{!this.props.smallView ?
								<VoicemailContent
									extension			= {this.props.extension}
									deleteVoicemail		= {this.deleteVoicemail}
									changeReadStatus	= {this.changeReadStatus}
									redirect			= {this.props.redirect}
									extraContacts		= {this.props.contactsUtil.extraContacts}
									editContact			= {this.editContact}
									makeCall			= {this.props.makeCall}
								/>
							: null}
						</div>
					}
				</ResizeAware>
				<ConfirmDeleteModal
					itemType					= 'voicemail'
					isShown						= {this.state.isConfirmDeleteVoicemailModalShown}
					acknowledgedTitle			= 'Permanently delete voicemail?'
					notAcknowledgedTitle		= 'Permanently delete voicemail?'
					acknowledgedMainContent		= ''
					notAcknowledgedMainContent	= ''
				/>
				<EditContactModal
					type				= {this.state.editContact ? this.state.editContact.id ? 'Edit' : 'Add' : false}
					onClose				= {() => this.setState({editContact: null})}
					fixedNumber			= {this.state.editContact ? this.state.editContact.number : null}
					prefillData			= {this.state.editContact ? this.state.editContact.prefillData : null}
					contact				= {this.getContact()}
					contactGroupTypes	= {this.props.contactsUtil.groupTypes}
					saveContact			= {this.saveContact}
					deleteContact		= {this.deleteContact}
					makeCall			= {this.props.makeCall}
					startConversation	= {this.startConversation}
					isVirtualExtension	= {this.props.extension.is_virtual}
					smallView			= {this.props.smallView}
				/>
			</div>
		)
	}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))