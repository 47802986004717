import {mainTheme} from 'themes'
import {webComTheme} from "web-com-theme";
import {revenuewellTheme} from "revenuewell-theme";

let theme = mainTheme;

let url_string = window.location.href;
let url = new URL(url_string);
let theme_type = (url.searchParams && url.searchParams.get('theme')) || process.env.REACT_APP_THEME
console.log(process.env.REACT_APP_THEME)
if(theme_type === 'webCom'){ // todo replace with however we are detecting web.com theme change
    theme = webComTheme;

    window.localStorage.setItem('first_my_phone_com', 'true');
} else if (theme_type === 'Revenuewell') {
    theme = revenuewellTheme;
}
try {
    document.getElementsByName('favicon')[0].href = theme.favicon.default
}catch (e) {
    console.warn('could not change favicon in theme')
}
window.document.title = `${(theme.appName || 'My Phone.com')}`


export { theme }