import React, { Component } from 'react'
import { formatPhoneNumber } from 'phone-numbers'
import { XIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	dialog: {
		position:		'fixed',
		top:			0,
		left:			0,
		right:			0,
		bottom:			0,
		background:		'rgba(255, 255, 255, 65%);',
		zIndex:			99999999,
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center'
	},
	dialogWrapper: {
		minWidth:		400,
		maxWidth:		500,
		width:			'100%',
		display:		'flex',
		flexDirection:	'column',
		background:		'white',
		padding:		60,
		borderRadius:	30,
		boxShadow:		'0px 7px 15px rgba(0, 0, 0, 0.1), 0px 20px 35px rgba(0, 0, 0, 0.15), 0px 10px 45px rgba(0, 0, 0, 0.1)',
		position:		'relative'
	},
	dialogTitle: {
		fontSize:		24,
		lineHeight:		'32px',
		letterSpacing:	-0.1,
		marginBottom:	15
	},
	dialogContent: {
		fontSize:		16,
		lineHeight:		'24px',
		letterSpacing:	-0.05
	},
	numberItem: {
		display:	'flex',
		alignItems:	'center',
		lineHeight:	'30px',
		width:		'fit-content',
		cursor:		'pointer',
		'& span': {
			'&:first-child': {
				minWidth:		130,
				marginRight:	10
			},
			'&:last-child': {
				color:			theme.palette.secondary[0],
				letterSpacing:	-0.5,
				'-webkit-text-stroke-width': '0.1px'
			}
		},
		'&:hover': {
			color: theme.palette.primary[0]
		}
	},
	closeButton: {
		position:	'absolute',
		top:		28,
		right:		28,
		color:		theme.palette.secondary[0],
		cursor:		'pointer',
		'&:hover': {
			color:	theme.palette.secondary['+100'],
		}
	}
})

class ChooseANumberModal extends Component {
	render() {
		const { classes, isShown, numbers, type, onChoose, onClose } = this.props
		if (!isShown) return null
		return (
			<div className={classes.dialog}>
				<div className={classes.dialogWrapper}>
					<div className={classes.dialogTitle}>Choose a number to {type}</div>
					<div className={classes.dialogContent}>
						{numbers.map((n, i) => {
							let number		= formatPhoneNumber(n.number)
							let numberType	= `${n.type[0].toUpperCase()}${n.type.substring(1).toLowerCase()}`
							return (
								<div key={i} className={classes.numberItem} onClick={() => onChoose(n)}>
									<span>{number}</span><span>{numberType}</span>
								</div>
							)
						})}
					</div>
					<XIcon onClick={onClose} className={classes.closeButton}/>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(ChooseANumberModal)