export function currentFax(state=null, action) {
	switch (action.type) {
		case 'SWITCH_FAX':
			let faxId = action.fax ? action.fax.id : null
			sessionStorage.setItem('session_fax_id', faxId)
			let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
			if (faxId) {
				window.history.replaceState(`FaxId: ${faxId}`, 'Fax Switched', `/e${extensionId}/faxes/f${faxId}`)
			} else {
				window.history.replaceState(`No FaxId`, 'Fax Switched', `/e${extensionId}/faxes`)
			}
			return action.fax ? {...action.fax} : null
		default:
			return state
	}
}

export function faxes(state = {}, action) {
	let faxes				= null
	let fax					= null
	let incomingFaxItems	= null
	let outgoingFaxItems	= null
	switch (action.type) {
		case 'UPDATE_FAXES':
			faxes			= state
			let newFaxes	= action.faxes
			let faxItems	= newFaxes.items || []
			faxItems.forEach(formatFax)
			faxes[newFaxes.filters.message_type] = newFaxes
			if (newFaxes.filters.message_type === 'fax-outgoing') {
				faxes['fax-outgoing'].items.forEach(f => {
					if (f.delivery_status === 'failed') return
					f.status = 'sent'
					f.is_new = false
				})
			}
			return {...faxes}
		case 'UPDATE_FAX':
			faxes	= state
			fax		= action.fax
			let faxIndex = faxes['fax-incoming'].items.findIndex(v => v.id === fax.id)
			if (faxIndex >= 0) {
				formatFax(fax)
				faxes['fax-incoming'].items[faxIndex] = fax
			} else {
				faxIndex = faxes['fax-outgoing'].items.findIndex(v => v.id === fax.id)
				if (faxIndex >= 0) {
					formatFax(fax)
					faxes['fax-outgoing'].items[faxIndex] = fax
				}
			}
			return {...faxes}
		case 'DELETE_FAX':
			faxes = state
			incomingFaxItems = faxes['fax-incoming'].items.filter(v => v.id !== action.fax.id)
			outgoingFaxItems = faxes['fax-outgoing'].items.filter(v => v.id !== action.fax.id)
			if (incomingFaxItems.length !== faxes['fax-incoming'].items.length) {
				faxes['fax-incoming'].items = incomingFaxItems
				faxes['fax-incoming'].total--
				if (action.fax.is_new) faxes['fax-incoming'].unread--
			}
			if (outgoingFaxItems.length !== faxes['fax-outgoing'].items.length) {
				faxes['fax-outgoing'].items = outgoingFaxItems
				faxes['fax-outgoing'].total--
				if (action.fax.is_new) faxes['fax-outgoing'].unread--
			}
			return {...faxes}
		case 'ADD_FAX':
			fax		= action.fax
			faxes	= state
			let faxType = `fax-${fax.direction === 'in' ? 'incoming' : 'outgoing'}`
			faxes[faxType].total++
			if (fax.is_new) faxes[faxType].unread++
			formatFax(fax)
			faxes[faxType].items.unshift(fax)
			return {...faxes}
		case 'ADD_CONTACTS_TO_FAXES':
			faxes				= state
			incomingFaxItems	= faxes['fax-incoming'].items
			outgoingFaxItems	= faxes['fax-outgoing'].items
			// Update redux for incoming faxes
			incomingFaxItems.forEach(f => {
				if (f.from.contact_id) return
				let foundContact = null
				action.contacts.forEach(cd => {
					if (foundContact) return
					let foundNumber = cd.numbers.find(n => n.number === f.from.number)
					if (foundNumber) foundContact = cd
				})
				if (!foundContact) return
				f.from.contact_id = foundContact.id
			})
			// Update redux for outgoing faxes
			outgoingFaxItems.forEach(f => {
				if (f.to.contact_id) return
				let foundContact = null
				action.contacts.forEach(cd => {
					if (foundContact) return
					let foundNumber = cd.numbers.find(n => n.number === f.to.number)
					if (foundNumber) foundContact = cd
				})
				if (!foundContact) return
				f.to.contact_id = foundContact.id
			})
			return {...faxes}
		case 'REMOVE_CONTACT_FROM_FAXES':
			let contactId		= action.contactId
			faxes				= state
			incomingFaxItems	= faxes['fax-incoming'].items
			outgoingFaxItems	= faxes['fax-outgoing'].items
			incomingFaxItems.forEach(f => {
				if (f.from.contact_id === contactId) delete f.from.contact_id
			})
			outgoingFaxItems.forEach(f => {
				if (f.to.contact_id === contactId) delete f.to.contact_id
			})
			return {...faxes}
		default:
			return state
	}
}

let formatFax = fax => {
	fax.fromNumber	= fax.from.number || fax.from.phone
	fax.fromName	= fax.from.name || fax.from.from
	fax.toNumber	= fax.to.number
	fax.toName		= fax.to.name
}

export function faxLinks(state={}, action) {
	switch (action.type) {
		case 'UPDATE_FAX_LINKS':
			return JSON.parse(JSON.stringify(action.faxLinks))
		default:
			return state
	}
}