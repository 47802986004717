
import React, { Component } from 'react'
import { getShortFormattedTime, getFormattedTime } from 'time-format'
import { iconBackgroundStyle } from 'colors'
import { formatPhoneNumber } from 'phone-numbers'
import { withStyles } from '@material-ui/core'
import { CallsNavIcon, MessagesNavIcon,
VoicemailNavIcon, FaxesNavIcon } from 'pdc-svg-icons'
import { newChatHoverIcon } from 'pdc-icons'
import { mainTheme } from 'themes'
import PDCButton from 'pdc-button'

const styles = theme => ({
	selectorItem: theme.selector.selectorItem,
	newItemInfo: {
		position:	'absolute',
		right:		'10px',
		top:		'2px',
		fontSize:	'11px',
		fontWeight:	'bold',
		color:		'#4c86bd'
	},
	mainContent: {
		marginLeft:	'12px',
		position:	'relative',
		width:		'100%'
	},
	topSide: {
		position:		'absolute',
		width:			'100%',
		top:			0,
		display:		'flex',
		overflow:		'hidden',
		lineHeight:		1.31,
		letterSpacing:	-0.1
	},
	participants: {
		margin:			'0 8px 0 0',
		maxWidth:		'80%',
		whiteSpace:		'nowrap',
		overflow:		'hidden',
		textOverflow:	'ellipsis',
		fontSize:		'16px'
	},
	timeInfo: {
		whiteSpace:		'nowrap',
		marginLeft:		'auto',
		position:		'absolute',
		right:			0,
		fontSize:		13,
		fontWeight:		500,
		color:			theme.messagesApp.conversationSelector.timeColor,
		lineHeight:		1.05,
		letterSpacing:	0.2,
		textTransform:	'uppercase'
	},
	bottomSide: {
		position:		'absolute',
		width:			'100%',
		bottom:			0,
		display:		'flex',
		alignItems:		'center',
		overflow:		'hidden',
		lineHeight:		1.36,
		letterSpacing:	-0.1
	},
	mainText: {
		margin:			'0 5px 0 0',
		maxWidth:		'95%',
		width:			'95%',
		whiteSpace:		'nowrap',
		overflow:		'hidden',
		textOverflow:	'ellipsis',
		color:			'grey',
		display:		'flex'
	},
	additionalText: {
		color:		'black',
		fontSize:	'11px'
	},
	itemIcon: {
		position:			'relative',
		width:				'40px',
		height:				'40px',
		margin:				'auto',
		backgroundColor:	'#eee',
		borderRadius:		'90%',
		boxSizing:			'border-box'
	},
	iconImage: {
		borderRadius: '50px',
		width: '100%',
		height: '100%',
	},
	dropdown: {
		marginLeft: 'auto'
	},
	infoText: {
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	bold: {
		'&>span': {
			fontWeight: 'bold',
			color: 'black'
		},
		'& .time-info span': {
			fontWeight: 600
		}
	},
	expandedMessageBubble: {
		textAlign:			'center',
		fontSize:			20,
		width:				'max-content',
		backgroundColor:	'white',
		paddingTop:			6,
		paddingBottom:		6,
		borderRadius:		15,
		transform:			'translate(50%, 0%)',
	},
	expandedDate: {
		margin: 9
	},
	shorterText: {
		width: 'auto',
	},
	cutOffMessage: {
		whiteSpace:		'noWrap',
		overflow:		'hidden',
		textOverflow:	'ellipsis'
	},
	goToConversation: {
		marginTop:		20,
		marginBottom:	-5,
		float:			'right'
	},
	goToConversationMobile: {
		marginTop:		7,
		marginBottom:	26,
		float:			'right',
	},
	imagePreview: {
		height:			20,
		width:			20,
		paddingRight:	5,
	},
	mediaArea: {
		background:	'white',
		border:		'3px solid #E6EBEE',
		cursor:		'initial'
	},
	image: {
		maxHeight:		500,
		maxWidth:		500,
		padding:		10,
		marginLeft:		6,
		marginBottom:	6
	}
})

class InboxSelectorItem extends Component {

	constructor(props) {
		super(props)
		let timestamp = this.props.inbox.created_at
		this.state = {
			expanded: false,
			timeInfo: timestamp ? getFormattedTime(timestamp, true) : ''
		}
	}

	componentDidMount() {
		this.timeRefreshInterval = setInterval(this.formatTime, 6000)
	}

	componentWillUnmount() {
		clearInterval(this.timeRefreshInterval)
	}

	formatTime = () => {
		let timestamp = this.props.inbox.created_at
		if (!timestamp) return ''
		let formattedTime = getFormattedTime(timestamp, true)
		if (formattedTime !== this.state.timeInfo) {
			this.setState({timeInfo: formattedTime})
		}
	}

	renderTypeIcon = () => {
		let { classes } = this.props
		let item = this.props.inbox
		let itemType = item.type
		const typeIconSwitch = t => ({
			'voicemail': <VoicemailNavIcon />,
			'call': <CallsNavIcon className={classes.iconImage}/>,
			'sms': <img alt='' src={newChatHoverIcon} className={classes.iconImage}/>,
			'mms': <img alt='' src={newChatHoverIcon} className={classes.iconImage}/>,
			'fax': <FaxesNavIcon className={classes.iconImage}/>
		})[t] || <MessagesNavIcon read={item.read_at} />
		let typeIcon = typeIconSwitch(itemType)
		return <div className={classes.itemIcon}>{typeIcon}</div>
	}

	separateNums = () => {
		let contact = null
		let participants = this.props.participants
		for (let i = 0; i < this.props.peopleItems.length; i++) {
			if (participants in this.props.peopleItems[i].numbers) {
				contact = this.props.peopleItems[i].mainText
				break
			}
		}
		participants.sort(p => p.mine ? 1 : -1) // If the number is mine put it last
		let allNums		= participants.map(p => formatPhoneNumber(p.number))
		let myNums		= participants.filter(p => p.mine).map(p => p.name || formatPhoneNumber(p.number))
		let notMyNums	= participants.filter(p => !p.mine).map(p => p.name || formatPhoneNumber(p.number))
		return [allNums, myNums, notMyNums, contact]
	}

	renderParticipants = () => {
		let [allNums, myNums, notMyNums, contact] = this.separateNums()
		return (
			<span title={allNums.join(', ')}>
				{notMyNums.map((p, i) => {
					return [i > 0 && ', ', <span key={`${p}${i}`}>{contact ? contact : p}</span>]
				})}

				{this.props.showMyNums && Boolean(notMyNums.length) && [', ']}

				{(this.props.showMyNums || !Boolean(notMyNums.length)) &&
					myNums.map((n, i) => {
						return [i > 0 && ', ', <span key={`${n}${i}`}><em>{n}</em></span>]
					})
				}
			</span>
		)
	}

	renderInfoPanel() {
		let { classes }			= this.props
		let styles				= this.props.styles || {}
		let bold				= this.props.bold ? classes.bold : ''
		let imageWarning		= '[ Contains Image ]'
		let placeholderImageUrl	= 'https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png'

		return (
			<div className={classes.mainContent} style={styles.mainContent}>
			<>
				<div className={`${classes.topSide} ${bold}`} style={styles.toSide}>
					<span className={`${classes.participants} fs-block`} style={styles.participants}>
						{this.renderParticipants()}
					</span>
					{this.props.renderDropdownMenu ? <div className={`${classes.dropdown} dropdown`}>{this.props.renderDropdownMenu()}</div> :
						<span className={`${classes.timeInfo} time-info`} style={styles.timeInfo}>
							{this.props.isMobile ? null : this.state.timeInfo}
						</span>
					}
				</div>
				<div className={classes.bottomSide} style={styles.bottomSide}>
					<div className={`${classes.mainText} fs-block`} style={styles.mainText}>
						{this.props.beforeMainText || null}
						<div className={`${bold} ${classes.infoText}`}>

							{!this.state.expanded ?
								<div className={classes.shorterText}>
									<div className={classes.cutOffMessage}>
										{!this.props.isMobile && this.props.inbox.media.length > 0  && placeholderImageUrl?
											<img src={placeholderImageUrl} alt={imageWarning} className={classes.imagePreview}/>
										: null}
										{this.props.mainText}
									</div>
								</div>
							:
								<div><div>{this.props.mainText}</div></div>
							}
						</div>
					</div>
				</div>

				{this.props.isMobile ? <br/> : null}
				{this.renderGoToConversation()}

			</>
			</div>
		)
	}

	renderGoToConversation = () => {
		return (
			<div className={this.props.isMobile ? this.props.classes.goToConversationMobile : this.props.classes.goToConversation}>
				<PDCButton
					variant='outlined'
					onClick={(event) => this.props.goToConversation(this.props.inbox)}
				 >Go to Conversation
				</PDCButton>
			</div>
		)
	}


	toggleExpand = () => this.setState({expanded: !this.state.expanded})

	renderExpandedArea = () => {
		let { classes } = this.props
		const contentItemClasses = {}
		let styles = Object.assign({}, contentItemClasses)
		let messageStatusClasses = {
			successMessage: {},
			pendingMessage: {},
			failedMessage: {
				backgroundColor: mainTheme.messagesApp.contentItems.contentItemBackgroundError
			}
		}
		let delivery = {status: 'delivered', class: 'success', message: 'Message successfully delivered'}
		styles.itemContent	= messageStatusClasses[`${delivery.class}Message`]

		return (
			<div className={classes.mediaArea}>
				{this.props.inbox.media.length > 0 ?
					this.props.inbox.media.map(mediaObj => {
						let url = mediaObj.url
						if (mediaObj.has_thumbnail) {
							let urlSplit = url.split('.')
							let fileExtension = urlSplit.pop()
							url = `${urlSplit.join('.')}_thumbnail.${fileExtension}`
						} else return null;
						if (['jpg', 'jpeg', 'png', 'webp', 'tiff', 'ico', 'gif', 'bmp'].includes()) {
							return <img
								src			= {url}
								alt			= 'Media not Viewable'
								key			= {mediaObj.url}
								className	= {classes.image}
							/>
						} else return null;


					})
				: null}
			</div>
		)
	}

	getConversation = (conversationId) => {
		let location = window.location.href.split('/')
		let type = this.props.inbox.type
		if (type === 'sms' || type === 'mms') type = 'messages'
		else if (type === 'fax') type = 'faxes'
		else if (type === 'voicemail') type = 'voicemail'
		location[4] = type
		location[5] = conversationId

		location = location.join('/')
		console.log('location')
		console.log(location)
		window.location.assign(location)
	}


	render() {
		const { classes }	= this.props
		let styles			= this.props.styles || {}
		let wrapperStyles	= {}
		let classNames		= classes.selectorItem
		Object.assign(wrapperStyles, styles.selectorItem)
		if (this.props.active) {
			classNames += ' selected-item'
			Object.assign(wrapperStyles, styles.selectedItem)
		}

		return (
			<div
				className	= {classNames}
				style		= {wrapperStyles}
				key			= {this.props.inbox.uuid}
			>
				<div className='main-view' onClick={this.toggleExpand}>
					{this.renderTypeIcon()}
					{this.renderInfoPanel()}
					{this.props.isNew && <span className={classes.newItemInfo} style={styles.newItemInfo}>NEW</span>}
				</div>
				{this.state.expanded && this.props.inbox.media.length ? this.renderExpandedArea() : null}
			</div>
		)
	}
}

export default withStyles(styles)(InboxSelectorItem)