import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

const api_base = process.env.REACT_APP_USER_API_URL

class API {
	static getMusicOnHoldLink = voip_recording_id => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/greetings/get-music-on-hold-link`
			return ajax.post(requestUrl, {voip_recording_id}).then(response => response.data)
		})
	}

	static createFileGreeting = (name, file) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
			return ajax.post(requestUrl, {name, file}).then(response => response.data)
		})
	}

	static createTTSGreeting = (tts_text, tts_voice, name) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
			return ajax.post(requestUrl, {origin: 'tts', tts_text, tts_voice, name}).then(response => response.data)
		})
	}

	static getVoicemailConfig = () => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/get-ext-vm-config`
			return ajax.post(requestUrl, {}).then(response => response.data)
		})
	}

	static configureVoicemail = voip_recording_id => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/configure-voicemail`
			return ajax.post(requestUrl, {voip_recording_id}).then(response => response.data)
		})
	}

	static getNotificationSettings = async () => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/notifications/get-notification-settings`
		let response = (await ajax.post(requestUrl, {})).data
		return response
	}

	static setNotificationSettings = async data => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/notifications/set-notification-settings`
		let response = (await ajax.post(requestUrl, data)).data
		return response
	}

	static getExtension = async () => {
		let voip_id		= PhoneComUser.getAPIAccountId()
		let extensionId	= parseInt(PhoneComUser.getExtensionId())
		let base_url	= 'https://api.phone.com/v4'
		let requestUrl	= `${base_url}/accounts/${voip_id}/extensions/${extensionId}`
		let response	= await ajax.get(requestUrl, 'Bearer')
		return response.data
	}

	static fixRules = async unset_number => {
		let data		= {type: 'extension', unset_number}
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/call-handling-rules/fix-rules`
		let response	= await ajax.post(requestUrl, data)
		return response.data
	}

	static addExtensionForward = async (phoneNumber, screening) => {
		let data = {
			mode:			'ring-external',
			phone_number:	{number: phoneNumber, screening},
			origin:			'extension'
		}
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
		let response	= await ajax.post(requestUrl, data)
		return response.data
	}

	static removeExtensionForward = async () => {
		// On remove we set the extension's forwarding rules to ring the extension itself.
		// In the control panel it is the option 'Just ring my communicator'
		let extensionId = parseInt(PhoneComUser.getExtensionId())
		let data = {
			mode:					'ring-users',
			extension_id:			extensionId,
			voip_phone_ids:			[extensionId],
			sms_forward_user_id:	extensionId,
			origin:					'extension'
		}
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
		let response	= await ajax.post(requestUrl, data)
		return response.data
	}

	static getUser = async userId => {
		let account_id	= PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users?page_size=1&offset=0&order_by=status.asc&include=extension,plan,add_ons,devices,numbers&user_ids=${userId}`
		let response	= await ajax.get(requestUrl)
		if (response.message === 'Network Error') return null
		return response.data.data[0]
	}

	static updateUser = async user => {
		let account_id	= PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users`
		let response	= await ajax.put(requestUrl, [user])
		if (response.toString().substr(0, 5) === 'Error') return { error: true }
		return response.data
	}

	static uploadAvatar = async (user, base64image) => {
		let account_id = PhoneComUser.getAPIAccountId()
		let requestUrl	= `${api_base}/voip/${account_id}/users/${user.id}/avatar`
		let response	= await ajax.put(requestUrl, {image: base64image})
		if (response.response && response.response.status >= 400) {
			// Response is an axios error object - server returned a non-2xx response,
			// but we can return response payload
			console.log('Got non-2xx response from server:', response.response.data)
			return response.response.data
		}
		try {
			return response.data ? response.data : { error: true }
		} catch (err) {
			console.error('Error calling upload-avatar endpoint', err)
			return { error: true }
		}
	}
}

export default API