import { CallManager } from './CallManager';
import { CallState } from '../enums/CallState';
import { CallType } from '../enums/CallType';
import { CallerInfo } from '../interfaces/CallerInfo';
import { Click2CallSession } from './Click2CallSession';
import { CallManagerEvents } from "../enums/CallManagerEvents";

//@ts-ignore
import axios from 'axios';
//@ts-ignore
import PDCOpenConnection from 'pdc-open-connection';
//@ts-ignore
import PhoneComUser from 'phone-com-user'

export class Click2CallManager extends CallManager {
    calls: { [key: string]: Click2CallSession } = {};
    activeCallId: string | null =  null;
    callMode: CallType = CallType.CLK2C;

    topicCallbacks: any[]= [];

    constructor() {
        super();
    }

    //This should initiate a click2call via v4
    call(callee: string): Promise<any> {
        let voip_id = PhoneComUser.getAPIAccountId()
        let pdc_number = PhoneComUser.getPhoneNumber()[0]
        let voip_phone_id = PhoneComUser.getExtensionId()
    
        let berr = PhoneComUser.getToken()
        let role = PhoneComUser.getRole()

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + berr,
        };

        let body = {
            //caller_phone_number : pdc_number,
            caller_voip_phone_id : voip_phone_id,
            caller_caller_id    : callee,
            caller_extension    : voip_phone_id,
            callee_phone_number : callee,
            callee_caller_id    : pdc_number
        };    

        let url = `https://api.phone.com/v4/accounts/${voip_id}/calls`;
        if (role === 'extension')
            url = `https://api.phone.com/v4/accounts/${voip_id}/extensions/${voip_phone_id}/calls`
        
         // Create Session
         const theirCallInfo: CallerInfo = { 
            phoneNumber: callee,
            callerId: ''
        }
        let myCallInfo: CallerInfo = {
            phoneNumber: pdc_number,
            callerId: ''
        }
        let session = new Click2CallSession([theirCallInfo], myCallInfo, callee, CallState.PLACING)
        this.calls[callee] = session
        this.emit(CallManagerEvents.CALL_CREATED, session)
        this.switchCall(session.callId)

        setTimeout(() => {
            if(session.callState === CallState.INCOMING || session.callState === CallState.PLACING){
                this.removeSession(session)
                this.emit(CallManagerEvents.CALL_HANGUP, null)
            }
        }, 30000)

        return axios.post( url, body, {headers} )
        .then( (response: any) => {
            //Add session to calls
            this.removeSession(session)
            session.callId = response.data.id
            this.calls[session.callId] = session
            this.switchCall(session.callId)
            this.emit(CallManagerEvents.CONNECTING, session)
            console.log('c2c response', response)
        }, error => {
            this.removeSession(session)
            this.emit(CallManagerEvents.CALL_HANGUP, null)
            alert('There was an error making the call.')
        });
    }

    hangupById(callId: string): void {
        this.removeSession(this.calls[callId])
        this.emit(CallManagerEvents.CALL_HANGUP, null)
    }
    switchCall(callId: string): void {
        if(callId && this.calls[callId]){
            this.activeCallId = callId
            this.emit(CallManagerEvents.SWITCH_CALL, { callId })
        }
    }
    removeSession(session: Click2CallSession): void {
        let callId = session.callId
        delete this.calls[callId]
        if(this.activeCallId === callId){
            this.activeCallId = null
            let callIds = Object.keys(this.calls)
            if(callIds.length> 0) this.switchCall(callIds[0])
        }
    }

    public connect = async (): Promise<any> => {
        PDCOpenConnection.on('sf:call-events', this.onCallNotification)
    }
    
    protected onCallNotification = (notification: { [key:string]: any }): void => {
        console.log('call event', notification, this.calls)
        let call_id: string = notification.call_id
        let leg_id: string = notification.leg_id

        let voip_id: string = notification.voip_id
        let voip_phone_id: string = notification.voip_phone_id

        let direction: string = notification.direction
        let from_did: string = notification.from_did
        let to_did: string = notification.to_did
        let caller_id: string = notification.caller_id
        if(caller_id === to_did || caller_id === from_did) caller_id = ''
        
        let state: string = notification.state
        let completion_state: string = notification.completion_state

        let event: string = notification.event_name

        // Is session in calls?
        let session: Click2CallSession;

        if(!this.calls.hasOwnProperty(call_id)){
            // Create Session
            const theirCallInfo: CallerInfo = { 
                phoneNumber: (direction == 'in' ? from_did : to_did), 
                callerId: caller_id
            }
            let myCallInfo: CallerInfo = {
                phoneNumber: (direction == 'out' ? from_did : to_did),
                callerId: ''
            }
            session = new Click2CallSession([theirCallInfo], myCallInfo, call_id, CallState.INCOMING)
            // Upsert session in calls list
            this.calls[call_id] = session
            this.emit(CallManagerEvents.CALL_CREATED, session)
        }
        else{
            session = this.calls[call_id]
        }

        // Fix for click2call missing call information
        if(session.myCallInfo && (typeof session.myCallInfo.phoneNumber) === 'undefined') session.myCallInfo.phoneNumber = String(notification.extension)
        notification.from_did = (direction == 'in' ? session.participants[0]?.phoneNumber : session.myCallInfo?.phoneNumber)
        notification.to_did = (direction == 'out' ? session.participants[0]?.phoneNumber : session.myCallInfo?.phoneNumber)
        notification.caller_id = session.participants[0]?.callerId

        if(event === 'Received' && (session.callState === CallState.INCOMING || session.callState === CallState.PLACING)){
            session.callState = CallState.INCOMING
            session.callAnswered = false
            session.isNewCall = true
            setTimeout(() => {
                console.log('timeout session', session)
                if(session.callState === CallState.INCOMING){
                    this.removeSession(session)
                    this.emit(CallManagerEvents.CALL_HANGUP, null)
                }
            }, 30000)
            this.emit(CallManagerEvents.CONNECTING, session)
            if(this.activeCallId === null) this.switchCall(call_id);
        }
        else if(event == 'Connecting' && (session.callState !== CallState.ACTIVE && session.callState !== CallState.CONNECTING)){
            session.callState = CallState.CONNECTING
            this.emit(CallManagerEvents.CONNECTING, session)
            if(this.activeCallId === null) this.switchCall(call_id);
        }
        else if(event === 'Answered' && (session.callState !== CallState.ACTIVE)){
            session.callState = CallState.ACTIVE
            session.callAnswered = true
            this.emit(CallManagerEvents.CALL_ANSWERED, notification)
            if(this.activeCallId !== call_id) this.switchCall(call_id);
        }
        else if(event === 'Hangup' && (session.callState !== CallState.POSTCALL)){
            session.callState = CallState.POSTCALL
            session.callAnswered = false
            session.callEndTime = Date.now() / 1000
            this.emit(CallManagerEvents.CALL_HANGUP, notification)
        }
        else if(event === 'Cancelled'){
            session.callState = null
            session.callAnswered = false
            this.removeSession(session)
            this.emit(CallManagerEvents.CALL_HANGUP, notification)
        } 
    }

    protected getCallerIdInfo(): void {
        throw new Error("Method not implemented.");
    }
    supports(): string[] {

        throw new Error("Method not implemented.");
    }
    test(): void {
        throw new Error("Method not implemented.");
    }
    hangup(): void {
        throw new Error("Method not implemented.");
    }

    answer(): void {
        throw new Error("Method not implemented.");
    }
    hold(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    unhold(): Promise<any> {
        throw new Error("Method not implemented.");
    }

    sendDTMF(tone: string): void {
        throw new Error("Method not implemented.");
    }
    mergeCall(callId: string): void {
        throw new Error("Method not implemented.");
    }
    muteCurrentLocal(isMuted: boolean): void {
        throw new Error("Method not implemented.");
    }
    muteCurrentRemote(isMuted: boolean): void {
        throw new Error("Method not implemented.");
    }
    showCallStats(): void {
        throw new Error("Method not implemented.");
    }
    answerById(callId: string): void {
        this.switchCall(callId);
        // throw new Error("Method not implemented.");
    }
    muteById(): void {
        throw new Error("Method not implemented.");
    }
    addSession(): void {
        throw new Error("Method not implemented.");
    }
    setActiveCall(callId: string): void {
        throw new Error("Method not implemented.");
    }
}

export default Click2CallManager