import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { Zoom, Fade } from '@material-ui/core'
import { xBtn } from 'pdc-icons'

const Transition = React.forwardRef(function Transition(props, ref) {
	return props.in ? <Zoom in={true} ref={ref} {...props} /> : <Fade in={false} ref={ref} {...props} />
})

const useStyles = makeStyles(theme => ({
	infoPopupRoot: {
		backgroundColor:	'background-color: rgba(255, 255, 255, 0.75)',
		color:				'#fff'
	},
	infoPopupPaper: {
		background:	theme.palette.tertiary.dark
	},
	infoPopupTitle: {
		padding:	0,
		height:		'75px'
	},
	infoPopupContent: {
		padding:	'0px 65px 40px'
	},
	closeButton: {
		position:	'absolute',
		top:		'13px',
		right:		'28px',
		color:		theme.palette.grey[500],
		'& img': {
			width:	'20px'
		}
	}
}))

var hasRendered = false

export default function InfoPopup(props) {
	const [open, setOpen] = React.useState(false)
	const classes = useStyles()

	function handleOpen() {
		setOpen(true)
	}

	function handleClose() {
		setOpen(false)
	}

	let shouldShow = false
	if (props.showOnceIdentifier) {
		shouldShow = (!open && window.localStorage.getItem(props.showOnceIdentifier) !== 'true')
		window.localStorage.setItem(props.showOnceIdentifier, 'true')
	} else {
		shouldShow = (!open && !hasRendered)
		hasRendered = true
	}

	if (shouldShow) {
		handleOpen()
	}

	return (
		<Dialog
			open				= {open}
			onClose				= {handleClose}
			aria-labelledby		= 'customized-dialog-title'
			maxWidth			= 'md'
			classes				= {{root: classes.infoPopupRoot, paper: classes.infoPopupPaper}}
			// TransitionComponent	= {Transition} removed temporarily as it causes everything underneath to be un touchable until refresh
		>
			<DialogTitle id='alert-dialog-title' className={classes.infoPopupTitle}>
				<IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
					<img src={xBtn} alt='close' />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.infoPopupContent}>
				{props.content}
			</DialogContent>
		</Dialog>
	)
}