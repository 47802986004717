import React, { Component } from 'react'
import { timezoneMenuItems } from './timezones'
import { formatPhoneNumber } from 'phone-numbers'
import AvatarIcon from 'avatar-icon'
import Menu from 'pdc-menu'
import TextField from 'pdc-text-field'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Tooltip from '@material-ui/core/Tooltip'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
	modalMain: {
		display: 'flex',
		flexDirection: 'column',
		width: 500
	},
	row: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 36,
		'&.main': {
			alignItems: 'center',
			marginBottom:	56
		},
		'& > div:first-child': {
			marginRight: 30
		}
	},
	infoColumn: {
		marginRight: 	'auto',
		marginLeft:		30,
		maxWidth:		250,
		'& .name': {
			fontSize: 24,
			lineHeight: '32px',
			letterSpacing:	'-0.2px',
			marginBottom:	10
		},
		'& .email': {
			fontSize:	14,
			lineHeight:	'21px',
			color:		theme.palette.primary[0],
			fontWeight:	600,
			marginBottom:	7,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		'& .direct-number': {
			fontSize:	14,
			lineHeight:	'21px',
			color:		theme.palette.primary[0],
			fontWeight:	600,
			marginBottom:	7,
			'& span' :{
				color: 'black',
				fontWeight:	'normal'
			}
		},
		'& .extension': {
			fontSize:	14,
			lineHeight:	'21px',
			color:		theme.palette.primary[0],
			fontWeight:	600,
			'& span' :{
				color: 'black',
				fontWeight:	'normal'
			}
		}
	},
	pdcMenu: {
		width: '100%'
	},
	pdcMenuItems: {
		maxHeight:	190,
		overflowX:	'hidden',
		overflowY:	'auto'
	},
	avatarError: {
		position:	'absolute',
		fontSize:	'11px',
		color:		'red',
		top:		210,
		left:		60,
	},
	cropContainer: {
		position: 		'absolute',
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center',
		background:		'white',
		width:			240,
		height:			240,
		top:			15,
		left:			240,
		zIndex:			1,
	},
	tooltip: {
		backgroundColor: '#48B9D5',
		fontSize:	12
	},
})

class EditProfile extends Component {

	state = {
		avatarError: false,
		src:	null,
		crop:	{
			x: 25,
			y: 25,
			width: 50,
			height: 50,
			unit: "%",
			aspect: 1,
		}
	}

	renderImageSection = () => {
		const { classes, tmpUser } = this.props
		let directNumber = tmpUser.direct_number ? formatPhoneNumber(tmpUser.direct_number.e164_number) : false
		return (
			<div className={`${classes.row} main`}>
				<div>
					<AvatarIcon
						imageUrl	= {tmpUser.avatar_url}
						selectImage	= {this.selectImage}
						edittable	= {true}
						text		= {false}
						height		= {125}
						width		= {125}
						noBorder	= {true}
						opacity		= {tmpUser.status === 'invite_pending' ? 0.7 : 1}

					/>
					{this.state.avatarError 
					?
						<span className={classes.avatarError}>{this.state.avatarError}</span>
					:
						null
					}

					{this.cropImage()
					? 
						<div className={classes.cropContainer}>
							{this.cropImage()}
						</div>
					:
						null
					}
				</div>
				<div className={classes.infoColumn}>
					<div className='name'>{tmpUser.first_name} {tmpUser.last_name}</div>
					<Tooltip classes={{tooltip:classes.tooltip}} title={tmpUser.email} placement='top'>
						<div className='email'>{tmpUser.email}</div>
					</Tooltip>
					{directNumber 
						? 
							<div className='direct-number'><span>Direct Number: </span>{directNumber}</div>
						:
							null
					}
					<div className='extension'><span>Extension: </span>{tmpUser.extension ? tmpUser.extension.data.extension_number : '/'}</div>
				</div>
			</div>
		)
	}



	updateFieldInUser = (field, value) => {
		let { tmpUser }	= this.props
		value = typeof value != 'boolean' && !Number.isInteger(value) ? value.trimStart() : value
		if (['first_name', 'last_name', 'email', 'personal_phone_number', 'voicemail_password'].includes(field)) {
			delete tmpUser[field+'_error'] // reset validation
		}
		if (field == 'personal_phone_number'){
			let number	= (value.match(/\d/g) || [])
			number		= number.join('')
			if (isNaN(number) || number.length > 10) return;
			value		= this.liveFormatNumber(number);
		}
		tmpUser[field]	= value
		this.props.updateUser(tmpUser)
	}

	onImageLoaded = image => {
		this.imageRef = image;
	};
	onCropComplete = crop => {
		this.makeClientCrop(crop);
	  };

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
			this.imageRef,
			crop,
			'newFile.jpeg'
			);
			this.updateFieldInUser('avatar_url', croppedImageUrl)
			this.setState({ croppedImageUrl });
		}
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return canvas.toDataURL('image/jpeg')
	}

	cropImage = () => {
		if (!this.state.src) return null
		return (
			<ReactCrop 
				src={this.state.src}
				crop={this.state.crop}
				keepSelection={true}
				// locked={true}
				circularCrop={true}
				onImageLoaded={this.onImageLoaded}
            	onComplete={this.onCropComplete}
				onChange={newCrop => {this.setState({crop:newCrop})}}
			/>
		);
	}

	selectImage = event => {
		this.setState({avatarError: false})
		var file = event.target.files[0];
		if (!this.validateFile(file)) {
			return
		}
		var reader = new FileReader();
		reader.onload = (event) => {
		  this.updateFieldInUser('avatar_url', event.target.result)
		  this.setState({src: event.target.result})

		};
		reader.readAsDataURL(file);
	}

	validateFile = file => {
		let isValidType	= file.type.split('/')[0] === 'image'
		if (!isValidType) {
			this.setState({avatarError: 'Only images allowed'})
			return false
		}

		let maxFileSize		= 10000000
		let isValidSize		= !file.size || file.size < maxFileSize
		if (!isValidSize) {
			this.setState({avatarError: 'Max allowed size is 10mb.'})
			return false
		}

		return true
	}

	liveFormatNumber = (number) =>{
		let length		= number.length
		let prevNumber	= (this.props.tmpUser.personal_phone_number.match(/\d/g) || [])
		prevNumber		= prevNumber.join('')
		
		if (prevNumber.length === 3 && length === 3){
			number = number.slice(0, -1)
			length = number.length
		}
		if (length > 0){
			number = number.slice(0, 0) + '(' + number.slice(0)
		}
		if (length > 2){
			number = number.slice(0, 4) + ')' + number.slice(4)
		}
		if (length > 3){
			number = number.slice(0, 5) + ' ' + number.slice(5)
		}
		if (length > 6){
			number = number.slice(0, 9) + '-' + number.slice(9)
		}

		return number
	}


	renderEditSection = () => {
		const { classes } = this.props
		let user = this.props.tmpUser

		return (
			<>
				<div className={classes.row}>

					<TextField
						fullWidth		= {true}
						label			= 'First name'
						error			= {user.first_name_error ? 'Please enter a valid name' : ''}
						editable		= {true}
						onInputChange	= {value => this.updateFieldInUser('first_name', value)}
						content			= {user.first_name}
					/>
					<TextField
						fullWidth		= {true}
						label			= 'Last Name'
						error			= {user.last_name_error ? 'Please enter a valid name' : ''}
						editable		= {true}
						onInputChange	= {value => this.updateFieldInUser('last_name', value)}
						content			= {user.last_name}
					/>
					
				</div>
				<div className={classes.row}>

					<TextField
							fullWidth		= {true}
							label			= 'Your Email'
							error			= {user.email_error ? 'Not a valid address' : ''}
							editable		= {true}
							onInputChange	= {value => this.updateFieldInUser('email', value)}
							content			= {user.email}
					/>
					<TextField
							fullWidth		= {true}
							label			= 'Mobile Number'
							error			= {user.personal_phone_number_error ? 'Please enter a valid number' : ''}
							editable		= {true}
							onInputChange	= {value => this.updateFieldInUser('personal_phone_number', value)}
							content			= {formatPhoneNumber(user.personal_phone_number)}
					/>
				</div>
				<div className={classes.row}>
					<Menu
						items={timezoneMenuItems}
						variant='single' 
						default={user.timezone ? user.timezone.split(' ').join('') : 'America/New_York'}
						onChange={(item)=> this.updateFieldInUser('timezone', item.content)}
						className={{wrapper: classes.pdcMenu, itemsWrapper: classes.pdcMenuItems}}
					/>
					<TextField
						fullWidth		= {true}
						label			= 'Voicemail Pin'
						error			= {user.voicemail_password_error ? user.voicemail_password_error : ''}
						editable		= {true}
						onInputChange	= {value => this.updateFieldInUser('voicemail_password', value)}
						content			= {user.voicemail_password}
					/>
				</div>
			</>
		)
	}

	render() {
		return (
			<>
				{this.renderImageSection()}
				{this.renderEditSection()}
			</>
		)
	}
}

export default withStyles(styles)(EditProfile)