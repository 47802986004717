import React, { Component } from "react"
import { avatarSingleSvg } from "pdc-icons"
import Dialpad from "dialpad"

import MicOnIcon from "@material-ui/icons/Mic"
import MicOffIcon from "@material-ui/icons/MicOff"
import KeyPadIcon from "@material-ui/icons/Apps"
import AudioOnIcon from "@material-ui/icons/VolumeUp"
import AudioOffIcon from "@material-ui/icons/VolumeOff"
import CallMergeIcon from "@material-ui/icons/CallMerge"
import AddIcon from "@material-ui/icons/Add"
import Pause from "@material-ui/icons/Pause"
import PhonePausedIcon from "@material-ui/icons/PhonePaused"
import TransferIcon from "@material-ui/icons/CompareArrows"
import CallEndIcon from "@material-ui/icons/CallEnd"
import { withStyles } from "@material-ui/core"
import SignalInfo from "signal-info"
import HeaderBar from "header-bar"
import MultiCallBottomBar from "./MultiCallBottomBar"
import Timer from "timer"

const whiteDisabled = "rgba(255, 255, 255, 0.4)"
const buttonColor = "#1A6274"
const styles = (theme) => ({
	wrapper: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		// display: 'inline'
	},
	container: {
		// paddingTop: '10%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 16,
		"&.mobile": {
			fontSize: 12,
		},
		// position: 'absolute'
	},
	info: {
		color: buttonColor,
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
		margin: "3em 0",
	},
	itemIcon: {
		width: "5em",
		height: "5em",
		borderRadius: "50%",
		backgroundColor: "#CECECE",
		border: `1px solid ${buttonColor}`,
		marginBottom: "1em",
	},
	iconImage: {
		width: "100%",
		borderRadius: "50%",
	},
	name: {
		fontSize: "1.75em",
	},
	number: {
		fontSize: "1.375em",
	},
	status: {
		fontSize: "1.375em",
		textTransform: "capitalize",
	},
	actions: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		flexWrap: "wrap",
		userSelect: "none",
	},
	actionRow: {
		width: "100%",
		margin: "1em 0",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	action: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		cursor: "pointer",
		"&:not(:last-child)": {
			marginRight: "2em",
		},
		"& .actionIcon": {
			"& svg": {
				fontSize: 16,
				width: "5.25em",
				height: "5.25em",
				borderRadius: "50%",
				// border: `3px solid ${buttonColor}`,
				padding: "1.25em",
				color: buttonColor,
				cursor: "pointer",
				background: "linear-gradient(218.57deg, #C9F7BF -5.49%, #DCF3D7 82.73%)",
			},
			"&.mobile svg": {
				fontSize: 12,
			},
		},
		"& .actionText": {
			color: buttonColor,
			fontSize: "1.375em",
		},
		"&.disabled": {
			cursor: "not-allowed",
			"& .actionIcon": {
				"& svg": {
					border: `3px solid ${whiteDisabled}`,
					color: whiteDisabled,
				},
			},
			"& .actionText": {
				color: whiteDisabled,
			},
		},
		"&:hover, &.active": {
			"& .actionIcon": {
				"& svg": {
					background: buttonColor,
					color: "#062B27",
				},
			},
		},
	},
	footer: {
		display: "flex",
		justifyContent: "center",
		padding: "3em 0 4.75em",
		"& svg": {
			// fontSize: 16,
			// background: "#E22021",
			color: "white",
			// width: "5em",
			cursor: "pointer",
			// height: "5em",
			// padding: "1em",
			borderRadius: "50%",
		},
		"&.mobile svg": {
			fontSize: 12,
		},
	},
	signalStatus: {
		zIndex: 10,
		float: "right",
	},
	callInfoBar: {
		padding: "4px 20px 4px 20px",
		boxShadow: "1px 0 0 1px #e0e0e0",
		lineHeight: 1,
	},
	phoneInfoBar: {
		padding: "4px 20px 4px 20px",
		textAlign: 'right',
		lineHeight: 1,
	},
	contactInfoHeader : {
		padding: "20px 20px 10px 20px",
		boxShadow: "1px 0 0 1px #e0e0e0",
		lineHeight: 1,
	},
	contactInfo: {
		flex: 1,
		minWidth: 115,
		display: "flex",
		justifyContent: "flex-end",
		paddingLeft: 10,
	},
	headerItem: theme.vmFaxHeaderItem,
	statusInfo: {
		textAlign:'left',
		textTransform: "uppercase",
		fontWeight: "bold",
		color: theme.palette.primary.main
	},
	dateTimeInfo: {
		float: 'right',
		"& > span:last-child": {
			textTransform: "uppercase",
			fontWeight: "bold",
			color: theme.palette.primary.main,
		},
	},
	endBtn: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		background: `linear-gradient(218.07deg, ${theme.palette.attention["0"]} 15.45%, ${theme.palette.attention["-100"]} 85.86%)`,
		borderRadius: "50%",
		cursor: "pointer",
		width: 63,
		height: 63,
	},
})

class Dialer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isDialpadShown: false,
			isOnHold: false,
		}
	}

	renderInfo() {
		const { classes } = this.props
		return (
			<div className={classes.info}>
				<div className={classes.itemIcon}>
					<img className={classes.iconImage} src={avatarSingleSvg} alt="User Icon" />
				</div>
				<div className={classes.name}>{this.props.displayName}</div>
				<div className={classes.number}>{this.props.phoneNumber}</div>
				<div className={classes.status}>{this.props.status}</div>
			</div>
		)
	}

	renderAction = (Icon, text, isEnabled, onClick) => {
		const { classes } = this.props
		let isMobile = this.props.screenViewType.isMobileView
		return (
			<div className={`${classes.action} ${!isEnabled ? "disabled" : ""}`} onClick={onClick} id={text}>
				<div className={`actionIcon ${isMobile ? "mobile" : ""}`}>
					<Icon />
				</div>
				<div className="actionText">{text}</div>
			</div>
		)
	}

	toggleMute = () => {
		console.log("click muted")
		if (this.props.onMuteClick) this.props.onMuteClick(!this.props.isMutedLocal)
		// this.setState({ isMuted: !this.state.isMuted })
	}
	showKeypad = () => this.setState({ isDialpadShown: true })
	toggleAudio = () => {
		if (this.props.onAudioClick) {
			this.props.onAudioClick(!this.props.isMutedRemote)
		}
		// this.setState({ isAudioOff: !this.state.isAudioOff })
	}
	onAddClick = () => {
		if (this.props.onAddClick) this.props.onAddClick()
	}
	onHoldClick = () => {
		let isOnHold = this.state.isOnHold
		this.setState({ isOnHold: !isOnHold })
		if (this.props.onHoldClick) {
			this.props.onHoldClick(!isOnHold)
		}
	}
	onTransferClick = () => {
		if (this.props.onTransferClick) this.props.onTransferClick()
	}
	endCallClicked = () => {
		if (this.props.endCallClick) this.props.endCallClick()
	}
	renderActions = () => {
		const { classes } = this.props
		let callActionsEnabled = this.props.status === "active"
		let MicIcon = this.props.isMutedLocal ? MicOffIcon : MicOnIcon
		let AudioIcon = this.props.isMutedRemote ? AudioOffIcon : AudioOnIcon
		let HoldIcon = this.state.isOnHold ? PhonePausedIcon : Pause
		return (
			<div className={classes.actions}>
				<div className={classes.actionRow}>
					{this.renderAction(MicIcon, "Mute", true, this.toggleMute)}
					{this.renderAction(KeyPadIcon, "KeyPad", true, this.showKeypad)}
					{this.renderAction(AudioIcon, "Audio", true, this.toggleAudio)}
				</div>
				<div className={classes.actionRow}>
					{this.renderAction(CallMergeIcon, "Merge", false, this.mergeCall)}
					{this.renderAction(HoldIcon, "Hold", callActionsEnabled, this.onHoldClick)}
					{this.renderAction(TransferIcon, "Transfer", false, this.onTransferClick)}
				</div>
			</div>
		)
	}

	renderFooter = () => {
		const { classes } = this.props
		let isMobile = this.props.screenViewType.isMobileView
		return (
			<div className={`${classes.footer} ${isMobile ? "mobile" : ""}`}>
				<div
					className={classes.endBtn}
					onClick={this.endCallClicked}
					data-test-id={"calls-main-dialer-bar-hangup_button"}
				>
					<CallEndIcon style={{ width: 24, height: 24 }} />
					<div style={{ fontSize: 9, marginTop: 3, color: "white" }}>END CALL</div>
				</div>
			</div>
		)
	}

	renderDialpad = () => {
		return (
			<Dialpad
				enableDTMF={true}
				style={{ zIndex: 100 }}
				open={this.state.isDialpadShown}
				anchorEl={document.getElementById("KeyPad")}
				onClickAway={() => this.setState({ isDialpadShown: false })}
				onSubmit={() => this.props.submitEntry(this.props.inputValue)}
				sendDTMF={this.props.sendDTMF}
				currentCall={this.props.currentCall}
			/>
		)
	}

	renderCallInfoBar = () => {
		const { classes, currentCall, screenViewType } = this.props
		let displayName = this.props.displayName
		let phoneNumber = this.props.phoneNumber
		if(displayName === '') displayName = phoneNumber
		console.log(this.props)
		if(!screenViewType.isMobileView){
			return(
				<div>
					<HeaderBar
						returnFnc={this.switchToSelector}
						showReturnButton={this.props.smallView}
						smallView={this.props.smallView}
					>
						<div>
							<span className={classes.headerItem}>{displayName}</span>
							{(phoneNumber !== displayName) ? <span className={classes.headerItem}>{phoneNumber}</span> : null }
						</div>
					</HeaderBar>
					<div className={classes.callInfoBar}>
						<span className={classes.statusInfo}>{this.props.status}</span>
						<span className={classes.dateTimeInfo}>
						{currentCall && currentCall.callStartTime ? (
							<Timer startTime={currentCall.callStartTime} endTime={currentCall.callEndTime} />
						) : (
							<span> </span>
						)}
						</span>
					</div>
				</div>	
			)
		}
		return (
		<div>		
			<div className={classes.contactInfoHeader}>{displayName}</div>
			{phoneNumber !== displayName? <div className={classes.phoneInfoBar}>{phoneNumber}</div> : null}
			<div className={classes.callInfoBar}>
				<span className={classes.statusInfo}>{this.props.status}</span>
				<span className={classes.dateTimeInfo}>
					{currentCall && currentCall.callStartTime ? (
						<Timer startTime={currentCall.callStartTime} endTime={currentCall.callEndTime} />
					) : (
						<span> </span>
					)}
				</span>
			</div>
		</div>)
	}


	renderCallSessionBar = () => {
		if (this.props.callsCnt > 1) {
			return (
				<MultiCallBottomBar
					style={{ zIndex: 101 }}
					calls={this.props.calls}
					callsCnt={this.props.callsCnt}
					switchCall={this.props.switchCall}
					mergeCall={this.props.mergeCall}
					answerById={this.props.answerById}
					activeCallId={this.props.activeCallId}
				/>
			)
		}
	}

	render() {
		const { classes } = this.props
		const { isNewCallIncoming } = this.state
		let isMobile = this.props.screenViewType.isMobileView
		console.log('display name', this.props.displayName, this.props.phoneNumber)
		return (
			<div className={classes.wrapper}>
				{this.renderCallInfoBar()}
				<div className={`${classes.container} ${isMobile ? "mobile" : ""}`}>
					{/* {this.renderInfo()} */}
					{/* {this.renderActions()} */}
					{this.props.children}
					{/* {this.renderFooter()} */}
					{/* {this.renderCallSessionBar()} */}
				</div>
				{/* {this.renderCallSessionBar()} */}
				{this.renderDialpad()}
			</div>
		)
	}
}

export default withStyles(styles)(Dialer)
