import React from "react"
import PDCButton from "pdc-button"
import { withStyles } from "@material-ui/core"
import Calls from "calls"
import { CallState } from "../../pages/communicator-app/src/enums/CallState"
import { currentCall } from "calls/src/reducers/calls"
import SettingsPhoneIcon from "@material-ui/icons/SettingsPhone"
import CallMergeIcon from "@material-ui/icons/CallMerge"
import { formatPhoneNumber } from 'phone-numbers'
import CallEndIcon from "@material-ui/icons/CallEnd"

const styles = (theme) => ({
	listRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "white",
		height: 65,
		// margin: 5,
		// border: '1px solid black'
	},
	listRowDesktop: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "white",
		height: 80,
		// margin: 5,
		// border: '1px solid black'
	},
	listRowMerged: {
		backgroundColor: theme.palette.tertiary["+100"],
	},
	switchCallBtn: {
		width: 72,
		height: 30,
		padding: 10,
		color: theme.palette.primary["+100"],
		backgroundColor: theme.palette.primary["-300"],
		fontSize: 13,
		marginRight: 10,
	},
	mergeCallBtn: {
		width: 72,
		height: 30,
		padding: 10,
		color: theme.palette.primary["+100"],
		backgroundColor: theme.palette.primary["-300"],
		fontSize: 13,
		marginRight: 10,
	},
	infoContainer: {
		display: "flex",
		flexDirection: "column",
		marginTop: 12,
		alignItems: "start",
		marginLeft: 18.27,
	},
	endCallBtn: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		//todo: NOT IN PALETTE
		// background: "#FF6352",
		background: theme.palette.attention["-100"],
		borderRadius: "50%",
		cursor: "pointer",
		width: 50,
		height: 50,
		borderStyle: "solid",
		"&:hover": {
			backgroundColor: theme.palette.attention["+100"],
			opacity: 0.65,
		},
		"&:active": {
			opacity: 1,
		},
	},
})
class CallSelectorItem extends React.Component {
	state = {}

	checkIfMergedCallIsActiveCall = () => {
		let mergedCallIsActiveCall = false


		if (this.props.currentCall) {
			this.props.currentCall.participants.forEach((p) => {
				//if the active/current call has this call in its participants list - then make it blue
				// if not just change the icon to merge icon, and keep it white.
				if (p.phoneNumber === this.props.session.callId) mergedCallIsActiveCall = true
			})
		}

		return mergedCallIsActiveCall
	}

	onEndCallClick = () => {
		this.props.hangupById(this.props.session.callId)
	}

	renderCallControls = () => {
		const { session, disabled, classes, isMerged, listIndex } = this.props
		if (!isMerged) {
			return (
				<>{/*
					<button
						className={classes.endCallBtn}
						onClick={this.onEndCallClick}
						data-test-id="calls-callbar-end-btn"
					>
						<CallEndIcon style={{ width: 24, height: 24, color: "white" }} />
						<div style={{ fontSize: 9, marginTop: 3, color: "white" }}>END</div>
					</button>
					*/}
					<PDCButton
						variant="new-primary"
						onClick={() => this.props.onSwitchCallClick(session.callId)}
						disabled={disabled || isMerged}
						className={classes.switchCallBtn}
						data-test-id={`call-session-inactive-row-${listIndex}`}
					>
						{"SWITCH"}
					</PDCButton>
					{/*
					<PDCButton
						variant="standard"
						onClick={this.props.onMergeCallClick}
						disabled={disabled || session.callState !== CallState.ACTIVE || isMerged}
						className={classes.mergeCallBtn}
					>
						{"MERGE"}
					</PDCButton>
					*/}
				</>
			)
		} else {
			return <span style={{ color: "#7ACCE1", fontSize: 18, marginRight: 44 }}>MERGED</span>
		}
	}

	render() {
		const { session, disabled, classes, isMerged, isMobile } = this.props
		const mergedCallIsActiveCall = this.checkIfMergedCallIsActiveCall()

		const displayName = session && session.participants.length > 0 ? 
		(session.participants[0].callerId !== '' ? session.participants[0].callerId : session.participants[0].phoneNumber) : 'Call'
		return (
			<div
				className={`${isMobile ? classes.listRow : classes.listRowDesktop} ${
					isMerged ? classes.listRowMerged : ""
				}`}
			>
				<div style={{ display: "flex", flexDirection: "row", marginLeft: 17.33 }}>
					<div style={{ color: "red", marginTop: 22.27 }}>
						{!isMerged ? (
							<SettingsPhoneIcon style={{ width: 22.4, height: 22.4 }} />
						) : (
							<CallMergeIcon
								style={{ width: 22.4, height: 22.4, color: mergedCallIsActiveCall ? "white" : "black" }}
							/>
						)}
					</div>
					<div className={classes.infoContainer}>
						<span style={{ color: mergedCallIsActiveCall ? "white" : "black", fontSize: 14 }}>
							{displayName}
						</span>
						{/* TODO: ADD HOLD FEATURE */}
						{/*
						{!isMerged ? (
							<span style={{ color: "red", fontSize: 12 }}>{"holding 00:00"}</span>
						) : (
							<span
								style={{
									color: "red",
									fontSize: 12,
									color: mergedCallIsActiveCall ? "white" : "black",
								}}
							>
								{"has joined the active call"}
							</span>
						)}
						*/}
					</div>
				</div>
				<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
					{this.renderCallControls()}
				</div>
			</div>
		)
	}
}
export default withStyles(styles)(CallSelectorItem)
