import React, { Component } from 'react'
import styles from './styles'
import { AddIcon, LogoutIcon, PlayIcon, SearchIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

class Button extends Component {

	getButtonClasses(color, variant) {
		let { classes, disabled, icon } =  this.props

		let buttonColorClass = `${variant}-button ${color}-${variant}-button`
		if (disabled) buttonColorClass += ' disabled'

		if (variant === 'graphic') {
			buttonColorClass += ' graphic-button'
			if (typeof(icon) === 'string') buttonColorClass += ` ${icon}-graphic-button ${color}-graphic-button`
		}

		let iconSpaceClass = icon ? 'icon-space' : ''
		let classNames = `${classes.pdcButton} ${buttonColorClass} ${this.props.className} ${iconSpaceClass}`

		return classNames
	}

	getIcon = () => {
		const { classes } = this.props
		let mappedIcons = {
			add:	AddIcon,
			logout:	LogoutIcon,
			search:	SearchIcon,
			play:	PlayIcon
		}

		let Icon = null

		let icon = this.props.icon
		if (icon) Icon = typeof(icon) === 'string' ? mappedIcons[icon] : icon

		let graphic = this.props.graphic
		if (graphic) Icon = mappedIcons[graphic]

		if (!Icon) return null
		return <div className='pdc-button-icon'><Icon/></div>
	}

	render() {
		let { variant, color } =  this.props
		variant			= variant || 'filled'
		color			= color || 'primary'
		let Icon		= this.getIcon()
		let classNames	= this.getButtonClasses(color, variant)

		return (
			<button {...this.props} className={classNames}>
				{Icon}{this.props.children}
			</button>
		)
	}
}

Button.propTypes = {
	/**
	 * The variant of the button
	 * 'filled', 'outlined' or 'graphic'
	 */
	variant: PropTypes.string,
	/**
	 * The color of the button
	 * 'primary', 'secondary', 'tertiary', 'attention' or 'important'
	 */
	color: PropTypes.string,
	/**
	 * Icon component that will be placed at the beginning of the button.
	 * If string provided then it can be 'add', 'logout', 'search' or 'play';
	 * Otherwise it should be a pdc svg component.
	 */
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	/**
	 * Is it disabled
	 * true or false
	 */
	disabled: PropTypes.bool
}

Button.defaultProps = {
	variant:	'filled',
	color:		'primary',
	icon:		null,
	disabled:	false
}

export default withStyles(styles)(Button)