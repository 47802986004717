import React, { Component } from 'react'
import BackButton from 'back-button'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	headerBar: {
		boxShadow:		theme.palette.primary.flatBottomShadow,
		width:			'100%',
		padding:		20,
		boxSizing:		'border-box',
		zIndex:			1,
		flex:			'0 1 auto',
		display:		'flex',
		justifyContent:	'space-between',
		maxHeight:		'60px',
		flexDirection:	'row',
		alignItems:		'center',
		fontSize:		'16px',
		/* 
		* Setting height to a value less than min-height
		* will properly center flex items in IE.
		*/
		height:			'58px',
		minHeight:		'59px',
		'&.small-view': {
			padding:	'22px 20px',
			'&>*:first-child': {
				marginRight: 15
			}
		}
	}
})

class Header extends Component {
	render() {
		const { classes, smallView, children, returnFnc } = this.props
		return (
			<div className={`${classes.headerBar} ${smallView ? 'small-view' : ''}`}>
				{smallView ? <BackButton onClick={returnFnc}/> : null}
				{children}
			</div>
		)
	}
}

export default withStyles(styles)(Header)