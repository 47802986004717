import React, { Component } from 'react'
import TagsInput from 'tags-input'
import { formatPhoneNumber } from 'phone-numbers'

class RecipientsInput extends Component {

	hasTextError = () => {
		if (!this.props.recipients.length) return false
		let tollFreeSuffixes = ['800', '833', '844', '855', '866', '877', '888']
		let value = this.props.inputValue
		if (value[0] === '+') value = value.substring(1)
		if (value[0] === '1') value = value.substring(1)
		return tollFreeSuffixes.includes(value.substr(0, 3))
	}

	render() {

		let tagItems = this.props.recipients.map(c => {
			let tagItem = {mainText: formatPhoneNumber(c.nickname)}
			if (c.number !== c.nickname) tagItem.additionalText = formatPhoneNumber(c.number)
			if (c.number[0] === '#') tagItem = {mainText: c.number, additionalText: 'Extension'}
			return tagItem
		})

		let inputPlaceholder = `Add a contact${!this.props.smallView ? ' or enter a phone number' : ''}`
		if (this.props.inputPlaceholder) inputPlaceholder = this.props.inputPlaceholder

		return (
			<TagsInput
			    prompt              = 'To'
				items				= {tagItems}
				updateItems			= {this.props.handleChange}
				onChangeInput		= {this.props.handleInputChange}
				submitEntry			= {this.props.submitRecipient}
				inputValue			= {this.props.inputValue}
				textError			= {this.hasTextError()}
				showLoading			= {this.props.showLoading}
				notAllowed			= {this.props.notAllowed}
				inputPlaceholder	= {inputPlaceholder}
				singleSelect		= {this.props.singleContact}
				showDialpad			= {this.props.showDialpad}
			/>
		)
	}
}

export default RecipientsInput