import React, { Component } from 'react'
import { connect } from 'react-redux'
import { switchTab } from '../actions/personal-settings'

import HeaderTabs from 'header-tabs'
import IncomingCalls from './IncomingCalls/IncomingCalls'
import Voicemail from './Voicemail/Voicemail'
import Notifications from './Notifications/Notifications'
import MyProfile from './MyProfile/MyProfile'
import ConfirmModal from 'confirm-modal'
import PhoneComUser from 'phone-com-user'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	wrapper: {
		height:			'100%',
		display:		'flex',
		flexDirection:	'column'
	}
})

const mapStateToProps = state => ({
	currentTab: state.currentTab
})
const mapDispatchToProps = dispatch => ({
	switchTab: tab => dispatch(switchTab(tab))
})

let tabs = [
	{id: 'incoming-calls',	icon: null},
	{id: 'voicemail',		icon: null},
	{id: 'notifications',	icon: null},
	{id: 'my-profile',		icon: null}
]

class App extends Component {

	constructor(props) {
		super(props)
		if (!this.props.currentTab) {
			let currentTab = this.getCurrentTab()
			this.props.switchTab(currentTab)
			this.setPathnameTab(currentTab)
		}
		this.state = {isBusy: false}
		if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(props.extension.extension_id)
		}
	}

	getCurrentTab = () => {
		let currentTab = ''
		let pathnameSplit = window.location.pathname.split('/')
		if (pathnameSplit.length > 3) currentTab = pathnameSplit[3]
		if (!tabs.map(t => t.id).includes(currentTab)) return 'incoming-calls'
		return currentTab
	}

	setPathnameTab = tabName => {
		let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
		window.history.replaceState(`Personal settings tab: ${tabName}`, 'Personal Settings Tab Switched', `/e${extensionId}/personal-settings/${tabName}`)
	}

	componentDidMount() {
		// This goes up to Communicator.js in order to hide the loading animation
		this.props.onLoaded()
	}

	onTabSwitch = newTab => {
		if (this.state.isBusy) {
			this.setState({exitAttempt: newTab})
			return false
		}
		this.props.switchTab(newTab)
		this.setPathnameTab(newTab)
	}

	setBusy = isBusy => this.setState({isBusy})

	onExitAttemptAnswer = () => {}

	renderApp = () => {
		let { currentTab } = this.props
		switch (currentTab) {
			case 'incoming-calls':
				return <IncomingCalls setBusy={this.setBusy}/>
			case 'voicemail':
				return <Voicemail setBusy={this.setBusy}/>
			case 'notifications':
				return <Notifications setBusy={this.setBusy}/>
			case 'my-profile':
				return <MyProfile extension={this.props.extension} setBusy={this.setBusy}/>
		}
	}

	discardChanges = () => {
		let newTab = this.state.exitAttempt
		this.setState({isBusy: false, exitAttempt: null}, () => this.onTabSwitch(newTab))
	}

	renderDiscardChangesModal = () => {
		return (
			<ConfirmModal
				isShown			= {Boolean(this.state.exitAttempt)}
				title			= 'Discard changes?'
				content			= {null}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Discard'
				yesButtonColor	= 'danger'
				onReject		= {() => this.setState({exitAttempt: null})}
				onConfirm		= {this.discardChanges}
				size			= 'size2' // size440
			/>
		)
	}

	render() {
		const { classes, currentTab } = this.props
		return (
			<div className={classes.wrapper}>
				{currentTab ? <HeaderTabs selectedTabId={currentTab} tabs={tabs} onTabSwitch={this.onTabSwitch}/> : null}
				{this.renderApp()}
				{this.renderDiscardChangesModal()}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))