import React, { Component } from 'react'
import { connect } from 'react-redux'
import AudioPlayer from 'audio-player'
import { avatarSingleSvg } from 'pdc-icons'
import { iconBackgroundStyle } from 'colors'
import CallHeader from './CallHeader.js'
import ParticipantsModal from 'participants-modal'
import EditContactModal from 'edit-contact-modal'
import { VoicemailIcon } from 'pdc-svg-icons'
import { getFormattedTime2 } from 'time-format'
import { formatPhoneNumber } from 'phone-numbers'
import { switchCall, removeContactFromCalls, addContactsToCalls, updateCalls } from '../actions/calls'
import { switchView } from '../actions/view'
import LoadingBar from 'loading-bar'
import gtmDataPush from 'gtm-events'
import { theme } from 'get-theme'
import API from '../util/api_v5'
import PhoneComUser from 'phone-com-user'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { withStyles } from '@material-ui/core'

const mapStateToProps = state => ({
	smallView: state.smallView,
	currentCall: state.currentCall,
	calls: state.calls
})
const mapDispatchToProps = dispatch => ({
	switchCall: call => dispatch(switchCall(call)),
	updateCalls: call => dispatch(updateCalls(call)),
	addContactsToCalls: contacts => dispatch(addContactsToCalls(contacts)),
	removeContactFromCalls: contactId => dispatch(removeContactFromCalls(contactId)),
	switchView: view => dispatch(switchView(view)),
})

const styles = theme => ({
	callContent: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative'
	},
	mainContentWrapper: {
		heigth: '100%',
		overflowY: 'auto'
	},
	mainContent: {
		padding: '50px 0',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		height: 'fit-content',
		position: 'relative',
		width: '100%',
		maxWidth: 475,
		margin: '0 auto',
		'&.small-view': {
			padding: '0 20px'
		}
	},
	itemIcon: {
		position: 'relative',
		width: 100,
		height: 100,
		minHeight: 100,
		backgroundColor: '#eee',
		borderRadius: '50%',
		boxSizing: 'border-box',
		marginLeft: 'auto',
		marginBottom: 25
	},
	iconImage: {
		position: 'absolute',
		height: '100%',
		left: '50%',
		top: '50%',
		borderRadius: '50px',
		transform: 'translate(-50%, -50%)'
	},
	infoWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		'& .info-item': {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			'& .title': {
				fontSize: 28,
				fontWeight: 500
			}
		}
	},
	callInfoBar: {
		display: 'flex',
		alignItems: 'center',
		padding: '8px 20px',
		boxShadow: '1px 0 0 1px #e0e0e0',
		lineHeight: 1.36
	},
	dateTimeInfo: {
		flex: 3,
		display: 'flex',
		justifyContent: 'space-between',
		borderRight: '1px solid #e0e0e0',
		paddingRight: 10,
		'& > span:last-child': {
			textTransform: 'uppercase',
			fontWeight: 'bold',
			color: theme.palette.primary.main
		}
	},
	contactInfo: {
		flex: 1,
		minWidth: 115,
		display: 'flex',
		justifyContent: 'flex-end',
		paddingLeft: 10,
	},
	audioPlayerWrapper: {
		position: 'relative',
		marginTop: 40
	},
	recIcons: {
		display: 'flex',
		justifyContent: 'center',
		'& .rec-icon': {
			width: 65,
			height: 40,
			color: '#88969E',
			'&:not(last-child):first-child': {
				marginRight: 10
			}
		}
	},
	recordingInfo: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 50,
		fontStyle: 'italic',
		color: 'gray'
	},
	recordingsSeparator: {
		borderTop: '1px dashed lightgray',
		marginTop: 40
	},
	loadingBar: {
		marginBottom: 28
	}
})

class CallContent extends Component {

	state = {
		iconColor: null,
		recordingReadyToPlay: false,
		voicemailReadyToPlay: false
	}

	componentDidMount() {
		this.init()
	}

	async componentDidUpdate(prevProps) {
		if (this.props.currentCall && (!prevProps.currentCall || prevProps.currentCall.id != this.props.currentCall.id)) {
			this.setState({ recordingReadyToPlay: false, voicemailReadyToPlay: false })
		}
		if (this.props.currentCall && this.props.currentCall.isTempCall) {
			//upon getting call log event, fetch the call log. bringing in call log into event would involve doing that for every single call
			const voipId = PhoneComUser.getAPIAccountId()
			const call = await API.getCall(this.props.currentCall.id, voipId)
			if (call) {

				//if there is id's get the recording and voicemail data at same time.
				if (call.recording && call.recording.id || call.voicemail && call.voicemail.id) {
					let recordings = []
					let voicemails = []
					if (call.recording.id) recordings.push(call.recording.id)
					if (call.voicemail.id) voicemails.push(call.voicemail.id)

					let res = await API.getCallRecordings(recordings, voicemails)
					if (res.recordings.length > 0) call.recording = res.recordings[0]
					if (res.voicemails.length > 0) call.voicemail = res.voicemails[0]
				}

				let calls = this.props.calls.items;
				//filter out the old version of this call, add new one
				const index = calls.findIndex(c => c.id === call.id)
				if(index === -1) return

				calls[index] = call

				let callsOBj = {
					filters: this.props.calls.filters,
					items: [...calls],
					limit: this.props.calls.limit,
					offset: this.props.calls.offset,
					sort: this.props.calls.sort,
					total: this.props.calls.total
				}
				this.props.updateCalls(callsOBj)
				this.props.switchCall(call)
				//TODO: maybe force fetch the voicemail and recording
			}
			this.init()
		}
	}

	init = () => {
		let currentCall = this.props.currentCall
		if (currentCall && currentCall.id !== this.currentId) {
			this.currentId = currentCall.id

			this.setState({
				iconColor: iconBackgroundStyle([currentCall.type === 'incoming' ? currentCall.from.number : currentCall.to.number])
			})
		}

		if (this.props.calls.items && this.props.calls.items.length && !currentCall) {
			let call = this.props.calls.items[0]
			this.props.switchCall(call)
		}

		if (currentCall) {
			this.type = currentCall.type.charAt(0).toUpperCase() + currentCall.type.slice(1)
		}
	}

	renderCallInfoBar = () => {
		const { classes, currentCall } = this.props
		let contactInfo = formatPhoneNumber(currentCall.type === 'incoming' ? currentCall.to.number : currentCall.from.number)
		return (
			<div className={classes.callInfoBar}>
				<div className={classes.dateTimeInfo}>
					<span>{currentCall.start_time !== 0 ? getFormattedTime2(currentCall.start_time) : '--:--'}</span>
					<span>{currentCall.type}</span>
				</div>
				<div className={classes.contactInfo}>{contactInfo}</div>
			</div>
		)
	}

	onPlay = () => {
		let currentCall = this.props.currentCall
		gtmDataPush({
			PDC_Action: `${currentCall.recording.url ? 'recording' : 'voicemail'}-recording-played`,
		})
	}

	onRecordingReadyToPlay = () => this.setState({ recordingReadyToPlay: true })
	onVoicemailReadyToPlay = () => this.setState({ voicemailReadyToPlay: true })

	editContact = (id, number) => {
		this.setState({ editContact: { id, number: id ? '' : number } })
	}

	getContact = () => {
		let extraContacts = this.props.extraContacts
		let editContact = this.state.editContact
		let contactId = editContact ? editContact.id : null
		if (!editContact || !contactId || !extraContacts) return null
		let contact = null
		this.props.extraContacts.forEach(c => c.id === contactId ? contact = c : null)
		return contact
	}

	saveContact = contact => {
		let extraContacts = this.props.extraContacts
		let isNew = !Boolean(extraContacts.find(c => c.id === contact.id))
		this.props.updateContact(contact)
		if (isNew) this.props.addContactsToCalls([contact])
		this.setState({ editContact: null })
		// NOTE: This line below is a workaround in order the contact name in the header to get changed immediately after the contact is saved.
		this.props.switchCall(this.props.currentCall)
	}

	deleteContact = contactId => {
		this.props.deleteContact(contactId)
		// NOTE: This line below is a workaround in order the contact name in the selector to get removed immediately after the contact is deleted.
		this.props.switchCall(this.props.currentCall)
		this.setState({editContact: null})
	}

	toggleParticipantsHover = hoverOverParticipants => {
		if (this.state.hoverOverParticipants !== hoverOverParticipants) {
			this.setState({ hoverOverParticipants })
		}
	}

	// makeCall = async () => {
	// 	const { currentCall } = this.props
	// 	let sipCall = await PDCCall.connect('sip')
	// 	const theOther = currentCall.type === 'outgoing' ? 'to' : 'from'
	// 	const callee = currentCall[theOther].number
	// 	sipCall.call(callee)
	// 	this.props.switchView('dialer')
	// }

	renderAllParticipants = () => {
		let currentCall = this.props.currentCall
		let me = currentCall.type === 'outgoing' ? 'from' : 'to'
		let theOther = currentCall.type === 'outgoing' ? 'to' : 'from'
		let myNumber = { number: currentCall[me].number }
		let otherNumber = { number: currentCall[theOther].number }
		let extraContacts = this.props.extraContacts
		extraContacts.forEach(c => {
			if (otherNumber.contactId) return
			let contactNumbers = c.numbers.map(n => n.number)
			if (contactNumbers.includes(otherNumber.number)) {
				otherNumber.name = c.name.display
				otherNumber.contactId = c.id
			}
		})
		let extensionPhoneNumbers = this.props.extension.phone_number
		if (extensionPhoneNumbers[myNumber.number]) myNumber.numberNickname = extensionPhoneNumbers[myNumber.number].name

		return (
			<ParticipantsModal
				data-test-id		= {'calls-participants-modal'}
				selectedNumber		= {myNumber.number}
				myNumbers			= {[myNumber]}
				otherNumbers		= {[otherNumber]}
				participantsHovered	= {this.state.hoverOverParticipants}
				editContact			= {this.editContact}
				changeNumber		= {() => {}}
				makeCall			= {this.props.makeCall}
				extension			= {this.props.extension}
			/>
		)
	}

	startConversation = phoneNumber => {
		let extensionId		= parseInt(window.location.pathname.split('/')[1].substring(1))
		let redirectPath	= `/e${extensionId}/messages/new-conversation/${phoneNumber}`
		this.props.redirect(redirectPath)
	}

	render() {

		const { classes } = this.props
		const currentCall = this.props.currentCall
		if (!currentCall) return ''

		let fromNumber = currentCall.from.number
		let toNumber = currentCall.to.number
		let recordingStatus = currentCall.recording.url ? 'loaded' : currentCall.recording.loading ? 'loading' : 'none'
		let voicemailStatus = currentCall.voicemail.url ? 'loaded' : currentCall.voicemail.loading ? 'loading' : 'none'

		return (
			<div className={classes.callContent}>
				<CallHeader
					data-test-id={'calls-call-header'}
					call={currentCall}
					smallView={this.props.smallView}
					toggleParticipantsHover={this.toggleParticipantsHover}
					extraContacts={this.props.extraContacts}
				/>
				{this.renderAllParticipants()}
				{this.renderCallInfoBar()}
				<div className={classes.mainContentWrapper}>
					<div className={`${classes.mainContent} ${this.props.smallView ? 'small-view' : ''}`}>
						<div className={classes.itemIcon} style={this.state.iconColor}>
							<img className={classes.iconImage} src={avatarSingleSvg} alt='User Icon' />
						</div>
						<div className={classes.infoWrapper}>
							<div className='info-item'>
								<span className='title'>Type</span>
								<span className='content'>{this.type}</span>
							</div>
							<div className='info-item'>
								<span className='title'>From</span>
								<span className='content'>{formatPhoneNumber(fromNumber)}</span>
							</div>
							<div className='info-item'>
								<span className='title'>To</span>
								<span className='content'>{formatPhoneNumber(toNumber)}</span>
							</div>
						</div>
						{!theme.showNoRecordingMessage ? null
							: recordingStatus !== 'none' ?
								<div className={classes.audioPlayerWrapper}>
									<div style={{ display: this.state.recordingReadyToPlay ? 'block' : 'none' }}>
										<AudioPlayer
											key={currentCall.id}
											url={currentCall.recording.url}
											onPlay={this.onPlay}
											onReadyToPlay={this.onRecordingReadyToPlay}
										/>
									</div>
									{!this.state.recordingReadyToPlay ? <LoadingBar /> : null}
									<div className={classes.recIcons}>
										{currentCall.recording.url ? <RecordVoiceOverIcon classes={{ root: 'rec-icon' }} /> : null}
									</div>
								</div>
								:
								<div className={classes.recordingInfo}>
									{recordingStatus === 'loading' ? <LoadingBar /> : 'No recording available'}
								</div>
						}
						{theme.showNoRecordingMessage ?
							currentCall.type === 'incoming' ? <div className={classes.recordingsSeparator}></div> : null
							: null}
						{currentCall.type === 'incoming' ?
							(voicemailStatus !== 'none' ?
								<div className={classes.audioPlayerWrapper}>
									<div style={{ display: this.state.voicemailReadyToPlay ? 'block' : 'none' }}>
										<AudioPlayer
											key={currentCall.id}
											url={currentCall.voicemail.url}
											onPlay={this.onPlay}
											onReadyToPlay={this.onVoicemailReadyToPlay}
										/>
									</div>
									{!this.state.voicemailReadyToPlay ? <LoadingBar className={classes.loadingBar} /> : null}
									<div className={classes.recIcons}>
										{currentCall.voicemail.url ? <VoicemailIcon className='rec-icon' /> : null}
									</div>
								</div>
								:
								<div className={classes.recordingInfo}>
									{recordingStatus === 'loading' ? <LoadingBar /> : 'No voicemail available'}
								</div>
							)
							: null}
						{/* <PDCButton
							onClick		= {this.makeCall}
						>
							Call Back
						</PDCButton> */}
					</div>
				</div>

				<EditContactModal
					type				= {this.state.editContact ? this.state.editContact.id ? 'Edit' : 'Add' : false}
					onClose				= {() => this.setState({ editContact: null })}
					fixedNumber			= {this.state.editContact ? this.state.editContact.number : null}
					contact				= {this.getContact()}
					contactGroupTypes	= {this.props.contactGroupTypes}
					saveContact			= {this.saveContact}
					deleteContact		= {this.deleteContact}
					makeCall			= {this.props.makeCall}
					startConversation	= {this.startConversation}
					isVirtualExtension	= {this.props.extension.is_virtual}
					smallView			= {this.props.smallView}
				/>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CallContent))