import React from 'react';
import PhonelinkErase from '@material-ui/icons/PhonelinkErase';
import reportError from 'error-reporter';
import { withStyles } from '@material-ui/styles';
import Typography from "@material-ui/core/Typography/Typography";
import {mainTheme} from "themes";

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        backgroundColor: 'lightgray',
        margin: 'auto'
    },
    icon: {
        verticalAlign: 'text-bottom',
        height: '50%',
        width: '30%'
    },
    textStyle: {
        width: "80%",
        marginRight: '10%',
        marginLeft: '10%',
        textAlign: 'center',
        wordBreak:"break-word"
    }
});

class ErrorCatcher extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }    

    componentDidCatch(error, errorInfo) {

        let lastErrorAt = localStorage.getItem('lastErrorAt');
        let fiveMinAgo = Date.now() - 300000;
        if(lastErrorAt < fiveMinAgo){
            reportError(errorInfo.componentStack, `${window.location.href}: recovered/reloaded`, 0, 0, error);
            localStorage.setItem('lastErrorAt', String(Date.now()))
            window.location.reload(true);
        }else {
            reportError(errorInfo.componentStack, window.location.href, 0, 0, error);
        }


    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        const linkStyle = {color:(this.props.theme)? this.props.theme.palette.primary.main : mainTheme.palette.primary.main, fontWeight:'bold'}
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className={this.props.classes.container}>
                    <PhonelinkErase className={this.props.classes.icon}/>
                    <Typography className={this.props.classes.textStyle} align={'left'} >
                        Oops! Something went wrong. You can try going back, or contact our <a style={linkStyle} href="https://www.phone.com/support/">Support Center</a> if you need further assistance.
                    </Typography>
                </div>
            )
        }

        return this.props.children;
    }

}

export default withStyles(styles)(ErrorCatcher);