import React, { Component } from 'react'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	searchContactItemsWrapper: {
		background:	'#f9f9f9',
		maxHeight:	150,
		overflowY:	'auto'
	},
	contactsListWrapper: {
		maxHeight:		150,
		marginTop:		10,
		marginBottom:	10,
		overflowY:		'auto',
		display:		'flex',
		flexWrap:		'wrap'
	},
	contactItem: {
		display:		'flex',
		flexDirection:	'column',
		flex:			'1 0 21%',
		margin:			3
	},
	contactName: {
		padding:	5,
		fontWeight:	500,
		cursor:		'pointer',
		'&:hover': {
			background: 'rgba(0, 0, 0, 0.1)',
			'& + div': {
				background: 'rgba(0, 0, 0, 0.1)',
			}
		}
	},
	contactNumberInfo: {
		paddingLeft: 20,
		cursor: 'pointer',
		'&:hover': {
			background: 'rgba(0, 0, 0, 0.08)'
		},
		'& > span:first-child': {
			color:		theme.palette.primary.main,
			fontWeight:	600
		}
	},
	numberType: {
		color:			'gray',
		textTransform:	'capitalize',
		padding:		'3px 0',
		'&::before': {
			content:	' - ',
			position:	'absoulte',
			left:		0
		}
	}
})

class SearchContactsItems extends Component {
	render() {
		let { classes }			= this.props
		let contactItems		= this.props.contactItems.filter(c => c.numbers.find(n => n.number.trim()))
		let numPlaceholderItems	= 4 - (contactItems.length % 4)
		for (let i = 0; i < numPlaceholderItems; i++) {
			contactItems.push({placeholder: true})
		}

		return (
			<div className={classes.searchContactItemsWrapper} style={contactItems.length ? {minHeight: 150} : {heigth: 0}}>
				<InfiniteScroller
					loader		= {<Spinner/>}
					hasMore		= {this.props.totalContacts > this.props.contactItems.length}
					loadMore	= {() => this.props.getUserContacts(true)}
					styles		= {{display: 'flex', flexWrap: 'wrap'}}
				>
					{contactItems.map((contact, i) =>
						<div key={i} className={classes.contactItem}>
							{contact.placeholder ? null : <>
								<div
									className	= {classes.contactName}
									onClick		= {() => this.props.onClick(contact.numbers.map(n => n.number.trim()).filter(n => n), contact.name.display)}
								>
									{contact.name.display}
								</div>
								<div className={classes.contactNumbersWrapper}>
									{contact.numbers.map((n, i) => 
										n.number ?
										<div
											key			= {i}
											className	= {classes.contactNumberInfo}
											onClick		= {() => this.props.onClick([n.number], contact.name.display)}
										>
											<span>{n.number}</span> <span className={classes.numberType}>{n.type}</span>
										</div>
										: null
									)}
								</div>
							</>}
						</div>
					)}
				</InfiniteScroller>
			</div>
		)
	}
}

export default withStyles(styles)(SearchContactsItems)