import React, {Component} from 'react'
import { connect } from 'react-redux'
import MessageItem from './MessageItem'
import ContentItems from 'content-items'
import userIcon from '../../images/user_icon.png'
import MessagesGapSection from './MessagesGapSection'

const mapStateToProps = state => ({currentConversation: state.currentConversation})
const mapDispatchToProps = dispatch => ({})

class ConversationMessages extends Component {

	renderMessage = (index, message, senderName, position) => {

		return (
			<React.Fragment key={`${index}-${message.message_id}`}>
				<MessageItem
					key				= {`msg-${message.message_id}`}
					from			= {senderName}
					direction		= {message.direction}
					date			= {message.created_at}
					text			= {message.text}
					media			= {message.media}
					position		= {position}
					to				= {message.to}
					messageId		= {message.message_id}
					status			= {message.to[0] ? message.to[0].delivery_status : null}
					isSending		= {message.isPending}
					fromNumber		= {message.from}
					tag				= {message.tag}
					isUploading		= {message.isUploading}
					scrollHere		= {message.message_id === this.props.currentConversation.scrollToMessage}
					isHighlighted	= {message.isHighlighted}

					onMessageDeleted			= {this.props.onMessageDeleted}
					openModal					= {this.props.openModal}
					generateModalContentData	= {this.props.generateModalContentData}
					setMessageToBeSent			= {this.props.setMessageToBeSent}
				/>
				{message.loadGapMessages ? <MessagesGapSection loadGapMessages={message.loadGapMessages} key={`${index}-ln`}/> : null}
			</React.Fragment>
		)
	}

	render() {
		return (
			<ContentItems
				items			= {this.props.messages}
				participants	= {this.props.participants}
				reverseScroll	= {true}
				loadMore		= {this.props.loadMore}
				hasMoreItems	= {this.props.hasMoreMessages}
				userIcon		= {userIcon}
				renderItem		= {this.renderMessage}
				extraContacts	= {this.props.extraContacts}
				makeCall		= {!this.props.extension.is_virtual ? this.props.makeCall : null}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationMessages)