import React, { Component } from 'react'

class ErrorOverlay extends Component {

	state = {seconds: 5}

	componentWillMount(){
		this.countdownTimer()
	}

	redirect = () => window.location = this.props.redirect

	updateSeconds = () => {
		let seconds = this.state.seconds - 1
		this.setState({seconds})
		if (seconds < 1) this.redirect()
	}

	countdownTimer = () => setInterval(this.updateSeconds, 1000)

	render() {
		return (
			<div className="error-overlay">
				<span className="overlay-message"> {this.props.message}: Please <a href={this.props.redirect}> log in</a> again. 
				<br />You will be redirected in {this.state.seconds} seconds to login page</span>
			</div>
		)
	}
}

export default ErrorOverlay