import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateVoicemail } from '../../actions/voicemail'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import LoaderFull from 'loader-full'
import AudioRecorder from 'audio-recorder'
import AudioUploader from 'audio-uploader'
import Button from 'button'
import api from '../../util/api_v5'

import TextFieldsIcon from '@material-ui/icons/TextFields'
import PublishIcon from '@material-ui/icons/Publish'
import MicIcon from '@material-ui/icons/Mic'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	settingWrapper:	theme.personalSettingsApp.settingWrapper,
	loadingDiv:		theme.loadingDiv,
	saveButton:		{
		height:		0,
		marginLeft:	10
	},
	container: {
		borderRadius:	6,
		width:			540,
		'&.small-view': {
			width:		'100%',
			'& .tab': {
				padding: 10,
				'&:not(.selected)': {
					flex:		'unset',
					padding:	'10px 20px'
				}
			}
		}
	},
	voicemailHeader: {
		display:				'flex',
		border:					'1px solid lightgray',
		borderTopLeftRadius:	6,
		borderTopRightRadius:	6,
		'&.round-corners': {
			borderRadius:		6,
			'& .tab:first-child': {
				borderBottomLeftRadius: 6
			},
			'& .tab:last-child': {
				borderBottomRightRadius: 6
			}
		},
		'& .tab': {
			flex:			1,
			display:		'flex',
			justifyContent:	'center',
			alignItems:		'center',
			textAlign:		'center',
			padding:		'16px 24px',
			margin:			0,
			fontSize:		18,
			color:			'black',
			cursor:			'pointer',
			'&.selected': {
				color:		'white',
				background:	theme.palette.primary.main
			},
			'&:first-child': {
				borderTopLeftRadius: 6
			},
			'&:last-child': {
				borderTopRightRadius: 6
			},
			'&:not(:last-child)': {
				borderRight: '1px solid lightgray',
			}
		}
	},
	ttsContent: {
		border:						'1px solid lightgray',
		borderTop:					'none',
		borderBottomLeftRadius:		6,
		borderBottomRightRadius:	6,
		'& textarea': {
			padding:	10,
			border:		'none',
			fontSize:	20,
			width:		'100%',
			minHeight:	125,
			maxHeight:	300,
			outline:	'none'
		}
	},
	separator: {
		border:		'none',
		borderTop:	'1px dashed lightgray',
		margin:		'0 10px'
	},
	languageOptions: {
		display:	'flex',
		padding:	10,
		'& > div': {
			flex:		1,
			fontSize:	18,
			fontWeight:	500,
			'&:first-child': {
				borderRight: '1px dashed lightgray'
			},
			'&:last-child': {
				paddingLeft: 10
			}
		}
	},
	audioPlayerContainer: {
		width:		'100%',
		padding:	'20px 10px',
		borderTop:	'1px solid lightgray'
	},
	infoSection: {
		borderTop:	'1px solid lightgray',
		padding:	'8px 10px',
		color:		'gray',
		display:	'flex',
		alignItems:	'center'
	},
	infoIcon: {
		marginRight:	5
	}
})

const mapStateToProps = state => ({
	voicemail: state.voicemail
})

const mapDispatchToProps = dispatch => ({
	updateVoicemail: voicemail => dispatch(updateVoicemail(voicemail))
})

const voices = ['Joanna', 'Salli', 'Joey', 'Matthew']
const languages = ['English']

class Voicemail extends Component {
	state = {
		selectedTab:	'tts',
		ttsText:		'',
		ttsVoice:		voices[0],
		language:		languages[0],
		uploadedAudio:	null,
		readyToPlay:	false,
		loading:		true,
		recording:		{isRecording: false},
		removeBtnHover:	false
	}

	componentDidMount = async () => {
		this.init()
	}

	init = async () => {
		this.setState({
			selectedTab:		'tts',
			ttsText:			'',
			ttsVoice:			voices[0],
			language:			languages[0],
			uploadedAudio:		null,
			readyToPlay:		false,
			loading:			true,
			recording:			{isRecording: false},
			removeBtnHover:		false
		})

		let textArea		= document.getElementById('voicemail-textarea')
		if (textArea) textArea.focus()
		let voicemailConfig	= this.props.voicemail
		if (!voicemailConfig) voicemailConfig = await api.getVoicemailConfig()
		let voipRecording	= voicemailConfig.voip_recording
		if (!voipRecording) return this.setState({loading: false})
		let voipRecordingId	= voipRecording.voip_recording_id
		let selectedTab		= 'tts'
		let voicemailLink	= voicemailConfig.download_link
		if (!voicemailLink) voicemailLink = (await api.getMusicOnHoldLink(voipRecordingId)).download_link
		let updatedVoicemail = {
			download_link:		voicemailLink,
			voip_recording_id:	voipRecordingId,
			type:				'file',
			filename:			voipRecording.name,
			voip_recording:		voipRecording
		}
		if (voipRecording.tts_text) {
			updatedVoicemail.type = 'tts'
			this.setState({ttsText: voipRecording.tts_text, ttsVoice: voipRecording.tts_voice})
		} else if (this.isRecordedAudio(voipRecording)) {
			selectedTab					= 'record'
			let recording				= this.state.recording
			recording.recordedAudio		= JSON.parse(JSON.stringify(updatedVoicemail))
			this.setState({recording})
		} else {
			selectedTab					= 'upload'
			this.setState({uploadedAudio: JSON.parse(JSON.stringify(updatedVoicemail))})
		}
		this.props.updateVoicemail(updatedVoicemail)
		this.setState({selectedTab, loading: false})
	}

	isRecordedAudio = voipRecording => {
		let name		= voipRecording.name || voipRecording.filename
		if (!name) return false
		let nameSplit	= name.split(' ')
		if (name.length !== 23 || nameSplit.length !== 2) return false
		if (nameSplit[0] !== 'recording') return false
		let timestamp = nameSplit[1]
		for (let c = 0; c < timestamp.length; c++) {
			if (isNaN(parseInt(timestamp[c]))) return false
		}
		return true
	}

	onVoicemailMessageChange = e => this.setState({ttsText: e.target.value})

	isSaveEnabled = () => {
		let ttsText			= this.state.ttsText
		let language		= this.state.language
		let ttsVoice		= this.state.ttsVoice
		let voicemail		= this.props.voicemail
		let selectedTab		= this.state.selectedTab
		let uploadedAudio	= this.state.uploadedAudio
		let recording		= this.state.recording
		if (selectedTab === 'tts') {
			return Boolean(language && ttsText && ttsVoice && (
				!voicemail ||
				voicemail.type !== 'tts' ||
				ttsText !== voicemail.voip_recording.tts_text ||
				ttsVoice !== voicemail.voip_recording.tts_voice
			))
		}
		if (selectedTab === 'upload') {
			return Boolean(uploadedAudio && (
				!voicemail ||
				voicemail.type !== 'file' ||
				voicemail.voip_recording_id !== uploadedAudio.voip_recording_id ||
				voicemail.download_link !== uploadedAudio.download_link
			))
		}
		if (selectedTab === 'record') {
			return Boolean(recording.recordedAudio && recording.start && recording.end)
		}
	}

	onSave = async () => {
		if (!this.isSaveEnabled()) return
		this.setState({loading: true})
		let voip_recording_id = null
		let newVoicemail = null
		if (this.state.selectedTab === 'tts') {
			let { ttsText, ttsVoice, name } = this.state
			let response = await api.createTTSGreeting(ttsText, ttsVoice, name)
			voip_recording_id = response.voip_recording_id
		} else if (this.state.selectedTab === 'upload') {
			let uploadedAudio				= this.state.uploadedAudio
			let response					= await api.createFileGreeting(uploadedAudio.filename, uploadedAudio.base64Data)
			delete uploadedAudio.base64Data
			uploadedAudio.voip_recording_id	= response.voip_recording_id
			this.setState({uploadedAudio})
			voip_recording_id				= uploadedAudio.voip_recording_id
			newVoicemail = {
				download_link:		uploadedAudio.download_link,
				type:				'file',
				voip_recording:		{voip_recording_id, filename: uploadedAudio.filename},
				voip_recording_id
			}
		} else {
			let recordedAudio	= this.state.recording.recordedAudio
			let download_link	= recordedAudio.download_link
			let filename		= recordedAudio.filename
			let base64Data		= download_link.split(';')[1].split(',')[1]
			let response		= await api.createFileGreeting(filename, base64Data)
			voip_recording_id	= response.voip_recording_id
			this.removeRecordedAudio()
			newVoicemail = {
				download_link,
				type: 'file',
				voip_recording: {voip_recording_id, filename},
				voip_recording_id
			}
		}
		this.props.updateVoicemail(newVoicemail)
		let configureVoicemailResponse = await api.configureVoicemail(voip_recording_id)
		this.init()
	}

	renderTtsContent = () => {
		const { classes } = this.props
		return (
			<div className={classes.ttsContent}>
				<textarea
					id			= 'voicemail-textarea'
					value		= {this.state.ttsText}
					onChange	= {this.onVoicemailMessageChange}
				/>
				<hr className={classes.separator}/>
				<div className={classes.languageOptions}>
					<div>{this.state.language}</div>
					<div>{this.state.ttsVoice}</div>
				</div>
				{this.props.voicemail && this.props.voicemail.type === 'tts' ? this.renderAudioPlayer(this.props.voicemail) : null}
				<div className={classes.infoSection}>
					<InfoOutlinedIcon classes={{root: classes.infoIcon}}/>At this time we only support English TTS voices
				</div>
			</div>
		)
	}

	renderAudioPlayer = voicemail => {
		const { classes } = this.props
		return <div className={classes.audioPlayerContainer}>
			<div style={{display: this.state.readyToPlay ? 'block' : 'none'}}>
				<AudioPlayer
					key				= {voicemail.voip_recording_id}
					url				= {voicemail.download_link}
					onPlay			= {() => {}}
					onReadyToPlay	= {() => this.setState({readyToPlay: true})}
				/>
			</div>
			{!this.state.readyToPlay ? <LoadingBar/> : null}
		</div>
	}

	removeRecordedAudio = () => this.setState({recording: {isRecording: false}})

	renderRecordAMessage = () => {
		return (
			<AudioRecorder
				onRecorded	= {recording => this.setState({recording})}
				recording	= {this.state.recording}
				onDelete	= {this.removeRecordedAudio}
			/>
		)
	}

	renderUploadAudioFile = () => {
		return (
			<AudioUploader
				id				= ''
				onUploaded		= {uploadedAudio => this.setState({uploadedAudio})}
				uploadedAudio	= {this.state.uploadedAudio}
				onDelete		= {() => this.setState({uploadedAudio: null})}
				maxSize			= {1024 * 1024 * 4} // 4MB
			/>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
				{this.state.loading ?
					<div className={classes.loadingDiv}><LoaderFull size='big'/></div>
				:
					<div className={`${classes.container} ${this.props.smallView ? 'small-view' : ''}`}>
						<div className={`${classes.voicemailHeader} ${this.state.selectedTab !== 'tts' ? 'round-corners' : ''}`}>
							<div
								className	= {`tab ${this.state.selectedTab === 'tts' ? 'selected' : ''}`}
								onClick		= {() => this.setState({selectedTab: 'tts'})}
							>
								{this.props.smallView && this.state.selectedTab !== 'tts' ? <TextFieldsIcon/> : 'Text To Speech'}
							</div>
							<div
								className	= {`tab ${this.state.selectedTab === 'upload' ? 'selected' : ''}`}
								onClick		= {() => this.setState({selectedTab: 'upload'})}
							>
								{this.props.smallView && this.state.selectedTab !== 'upload' ? <PublishIcon/> : 'Custom File'}
							</div>
							<div
								className	= {`tab ${this.state.selectedTab === 'record' ? 'selected' : ''}`}
								onClick		= {() => this.setState({selectedTab: 'record'})}
							>
								{this.props.smallView && this.state.selectedTab !== 'record' ? <MicIcon/> : 'Record audio'}
							</div>
						</div>
						{this.state.selectedTab === 'tts' ? this.renderTtsContent()
						: this.state.selectedTab === 'upload' ? this.renderUploadAudioFile()
						: this.state.selectedTab === 'record' ? this.renderRecordAMessage()
						: null}
					</div>
				}
				<Button
					variant		= 'filled'
					disabled	= {!this.isSaveEnabled()}
					onClick		= {this.onSave}
					className	= {classes.saveButton}
				>Save</Button>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Voicemail))