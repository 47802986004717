import React, { Component } from 'react'
import { ExpandTriangle, XIcon } from 'pdc-svg-icons'
import styles from './styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { withStyles } from '@material-ui/core'

class TextField extends Component {

	constructor(props) {
		super(props)
		this.state = {active: false}
		this.inputRef = props.inputRef || React.createRef()
	}

	onFocus = () => {
		if (this.props.onInputFocus) this.props.onInputFocus()
		// We need the 1ms timeout because in some cases
		// the ClickAwayListener fires immediately after this and instead of active it becomes inactive
		setTimeout(() => this.setState({active: true}), 1)
	}

	renderInput = () => {
		const { id, label, onInputChange, content, multiline } = this.props
		let activeClass		= this.state.active ? 'active' : ''
		let hasLabelClass	= label ? 'has-label' : ''
		let idProp			= id ? {id} : {}
		let TagName			= multiline ? 'textarea' : 'input'
		return (
			<TagName
				ref			= {this.inputRef}
				className	= {`edit-element ${TagName} ${activeClass} ${hasLabelClass}`}
				value		= {content}
				type		= 'text'
				onChange	= {e => onInputChange(e.target.value)}
				onFocus		= {this.onFocus}
				disabled	= {Boolean(this.props.disabled)}
				{...idProp}
			/>
		)
	}

	onClick = e => {
		let editable = (!('editable' in this.props)) ? true : this.props.editable
		if (editable) {
			this.focusSearch()
			this.setState({active: true})
		} else {
			this.setState({active: !this.state.active})
		}
		if (this.props.onClick) this.props.onClick(e)
	}

	focusSearch = () => this.inputRef.current.focus()

	onXClick = () => {
		// We do it like this (focus -> select -> insertText) in order undo / redo to work fine
		this.focusSearch()
		this.inputRef.current.select()
		document.execCommand('insertText', false, '')
		setTimeout(this.focusSearch, 1)
	}

	onClickAway = () => this.setState({active: false})

	onBlur = e => {
		e.persist()
		this.setState({active: false})
	}

	onWrapperFocus = e => {
		e.persist()
		let target = e.target
		let editable = (!('editable' in this.props)) ? true : this.props.editable
		let isTheWrapper = Array.from(target.classList).find(c => c.includes('TextField-wrapper'))
		if (isTheWrapper && editable) this.focusSearch()
	}

	render() {
		let {
			classes,
			label,
			content,
			error,
			showExpandIcon,
			boxShadow,
			fullWidth,
			active,
			multiline,
			disabled,
			className
		} = this.props
		let editable = (!('editable' in this.props)) ? true : this.props.editable
		active					= !disabled && [true, false].includes(active) ? active : this.state.active
		let activeClass			= active ? 'active' : ''
		let topClass			= (content || active) ? 'top' : ''
		let boxShadowClass		= boxShadow ? 'box-shadow' : ''
		let textAreaClass		= multiline && (active || content) ? 'textarea-field' : ''
		let textFieldClassNames	= `text-field ${activeClass} ${boxShadowClass} ${textAreaClass}`
		let errorClass			= error ? 'error' : ''
		let wrapperClassNames	= `${classes.wrapper} ${errorClass} ${activeClass}`
		if (className && className.wrapper) wrapperClassNames += ` ${className.wrapper}`
		if (fullWidth) wrapperClassNames += ' full-width'
		if (disabled) wrapperClassNames += ' disabled'
		return (
			<ClickAwayListener onClickAway={this.onClickAway}>
				<div
					className	= {wrapperClassNames}
					onBlur		= {this.onBlur}
					onFocus		= {this.onWrapperFocus}
					tabIndex	= {-1}
				>
					<div className={textFieldClassNames} onClick={this.onClick}>
						{label ? <div className={`label ${topClass}`}>{label}</div> : null}
						{editable ?
							this.renderInput() :
							content
						}
						{showExpandIcon ? <ExpandTriangle className={`${classes.expandIcon} expand-icon`}/> : null}
						{editable && !this.props.disabled ? <XIcon className='x-icon' onClick={this.onXClick}/> : null}
					</div>
					<div className={`button-underline ${activeClass}`}/>
					{error ? <div className='error-label'>{error}</div> : null}
				</div>
			</ClickAwayListener>
		)
	}
}

export default withStyles(styles)(TextField)