import React, { Component } from "react"
import Messages from "messages"
import People from "people"
import Meetings from "meetings"
import Voicemail from "voicemail"
import Settings from "settings"
import Call from "calls"
import Faxes from "faxes"
import PersonalSettings from "personal-settings"
import Navigation from "./src/nav/Navigation"
import AppLoader from "./src/AppLoader.js"
import api from "./src/util/api"
import Api from "api"
import {
	loadContacts,
	loadMore,
	addGroup,
	loadExtraContacts,
	updateExtraContacts,
	updateContact,
	deleteContact,
} from "./src/util/contacts"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { ThemeProvider } from "@material-ui/styles"
import FirstTimeUserInfoPopupContent from "./src/temp/FirstTimeUserInfoPopupContent.js"
import OfflineBar from "./src/OfflineBar.js"
import NotificationBar from "./src/NotificationBar.js"
import CallBar from "./src/CallBar.js"

import { initializePhoneCom, getAppConfig } from "phonecom"
import InfoPopup from "info-popup"
import PDCOpenConnection from "pdc-open-connection"
import {
	pushMessageNotification,
	pushVoicemailNotification,
	pushFaxNotification,
	pushCallNotification,
} from "notification-pusher"
import { sendElectronNotification, electronNotificationListener, addElectronEventListener } from "pdc-electron-utils"
import PhoneComUser from "phone-com-user"
import { theme } from "get-theme"
import { withStyles } from "@material-ui/core"
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback"
import API from "calls/src/util/api_v5"
import CallProvider, { PdcCallConsumer } from "../../pages/communicator-app/src/PdcCallProvider"
import { CallState } from "../../pages/communicator-app/src/enums/CallState"
import Prompt from "pdc-prompt"
import { isUpdateAvailable } from "service-worker-utils"
import IncomingCallModal from "./src/IncomingCallModal"

import { Salesforce } from './src/util/salesforce'

const themeFontFamily = theme.fontFamily || "Montserrat, Helvetica, arial, sans-serif"

const styles = (theme) => ({
	mainDiv: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		"&.inactive": {
			opacity: 0.9,
		},
		"& *": {
			fontFamily: themeFontFamily,
		},
	},
})

const INACTIVITY_PERIOD = 30 * 1000 // half minute

class Communicator extends Component {
	constructor(props) {
		super(props)
		this.infoPopupContent = <FirstTimeUserInfoPopupContent />
		this.state = {
			focused: document.hasFocus(),
			userActive: false,
			selectedExtension: null,
			contactsInfo: {
				contacts: null,
				extraContacts: [],
				groupTypes: [],
				contactsLoaded: false,
				extraContactsLoaded: false,
			},
			loadingContacts: false,
			loading: true,
			appLoading: true,
			screenViewType: {
				isMobileView: false,
				isTabletView: false,
			},
			unreadMessages: null,
			showNotificationBar: false,
			currentAppName: "calls",
			appData: null,
			isOffline: false,
			isDialerOpen: false,
			hideCallBar: true,
			waitingSW: null,
			triedCallWithoutMicPermissions: false,
			appHasChange: false,
		}

		this.notificationSubscriptions = {
			messages: [this.setUnreadCounts.bind(this, null)],
			voicemail: [this.setUnreadCounts.bind(this, null)],
			faxes: [this.setUnreadCounts.bind(this, null)],
			read_status: [this.setUnreadCounts.bind(this, null)], // This is about messages
		}
		PDCOpenConnection.onReconnect((retryingConnection) => {
			if (!retryingConnection) {
				// window.location.reload();
			}
		})
	}

	setUnreadCounts = (extensionId) => {
		let ext = extensionId || this.state.selectedExtension.extension_id
		api.getUnreadCounts(ext).then((res) => {
			if (!res) return
			let unread = res.items[0]
			this.setState({
				unreadMessages: unread.messages,
				unreadVoicemails: unread.voicemails,
				unreadFaxes: unread.faxes,
			})
			this.sendElectronUnreadCounts(unread)
		})
	}

	changeMessageReadStatus = (type, howMany) => {
		if (howMany === "all") {
			this.setState({ unreadMessages: 0 })
		} else if (type === "read") {
			this.setState({ unreadMessages: this.state.unreadMessages - howMany })
		} else if (type === "unread") {
			this.setState({ unreadMessages: this.state.unreadMessages + howMany })
		} else {
			console.error("Invalid type for changing message read status")
		}
	}

	changeVoicemailReadStatus = (type, howMany) => {
		if (howMany === "all") {
			this.setState({ unreadVoicemails: 0 })
		} else if (type === "read") {
			this.setState({ unreadVoicemails: this.state.unreadVoicemails - howMany })
		} else if (type === "unread") {
			this.setState({ unreadVoicemails: this.state.unreadVoicemails + howMany })
		} else {
			console.error("Invalid type for changing voicemail read status")
		}
	}

	changeFaxReadStatus = (type, howMany) => {
		if (howMany === "all") {
			this.setState({ unreadFaxes: 0 })
		} else if (type === "read") {
			this.setState({ unreadFaxes: this.state.unreadFaxes - howMany })
		} else if (type === "unread") {
			this.setState({ unreadFaxes: this.state.unreadFaxes + howMany })
		} else {
			console.error("Invalid type for changing fax read status")
		}
	}

	setScreenView() {
		let window_size = window.innerWidth
		console.log(this.props.theme.screenViewSizes.mobileViewSize)
		if (
			this.props.theme.screenViewSizes.mobileViewSize < window_size &&
			window_size < this.props.theme.screenViewSizes.tabletViewSize
		) {
			this.updateScreenViewState({
				screenViewType: {
					isTabletView: true,
					isMobileView: false,
				},
			})
		} else if (window_size < this.props.theme.screenViewSizes.mobileViewSize) {
			console.log("mobile")

			this.updateScreenViewState({
				screenViewType: {
					isTabletView: false,
					isMobileView: true,
				},
			})
		} else {
			this.updateScreenViewState({
				screenViewType: {
					isTabletView: false,
					isMobileView: false,
				},
			})
		}
	}

	updateScreenViewState(screenViewState) {
		let currentScreenViewState = this.state.screenViewType

		if (
			screenViewState.screenViewType.isMobileView !== currentScreenViewState.isMobileView ||
			screenViewState.screenViewType.isTabletView !== currentScreenViewState.isTabletView
		) {
			console.log("updating")
			this.setState(screenViewState)
		}
	}

	componentWillMount() {
		this.setScreenView()
		window.addEventListener("resize", () => this.setScreenView())

		addElectronEventListener("deeplink-event", ({ action, payload }) => {
			switch (action) {
				case "makeCall":
					return this.makeCall(payload.toNumber)
				case "sendMessage":
					return this.sendMessage(payload.toNumber, payload.text)
				case "startMessage":
				// return this.startMessage(data.toNumber)
			}
		})

		window.ononline = this.onOnline
		window.onoffline = this.onOffline
		if (window.navigator && !window.navigator.onLine) {
			this.onOffline()
		}
	}

	sendMessage = (toNumber = null, text = null) => {
		console.log(`Should send message to ${toNumber}:`, text)
	}

	startMessage = () => {
		console.log(`Start message `)
	}

	onOnline = (e) => {
		let isOffline = this.state.isOffline
		if (!isOffline) return
		return window.location.reload()
		setTimeout(() => {
			loadContacts(this)
			this.setUnreadCounts()
			this.setState({ isOffline: false })
		}, 2000)
	}
	onOffline = (e) => this.setState({ isOffline: Date.now() })

	componentDidMount = () => {
		this.focusHandlers()
		this.init()
	}

	focusHandlers = () => {
		let that = this
		window.onfocus = function () {
			that.setState({ focused: true })
		}
		window.onblur = function () {
			that.setState({ focused: false })
		}
		window.onmousemove = function () {
			if (!that.state.focused) return
			clearTimeout(that.notMovingTimeout)
			if (that.state.userActive !== true) {
				that.setState({ userActive: true })
			}
			that.notMovingTimeout = setTimeout(function () {
				that.setState({ userActive: false })
			}, INACTIVITY_PERIOD)
		}
	}

	initNotifications = () => {
		let onVoicemailNotification = (notification) => {
			let voicemail = notification
			let extensionId = this.state.selectedExtension.extension_id

			if (!["read_status", "delete"].includes(voicemail.type)) pushVoicemailNotification(voicemail, extensionId)

			this.notificationSubscriptions.voicemail.forEach((subscription) => subscription(notification))
		}

		let onFaxNotification = (notification) => {
			let fax = notification
			let extensionId = this.state.selectedExtension.extension_id

			if (!["read_status", "delivery_status", "delete"].includes(fax.type)) pushFaxNotification(fax, extensionId)

			this.notificationSubscriptions.faxes.forEach((subscription) => subscription(notification))
		}

		let onMessageNotification = (notification) => {
			let message = notification
			let extensionId = this.state.selectedExtension.extension_id

			if (!["read_status", "delete"].includes(message.type)) message = message.details
			if (message.type !== "read_status") pushMessageNotification(message, extensionId)

			this.notificationSubscriptions.messages.forEach((subscription) => subscription(notification))
		}

		let onReadStatusNotification = (notification) => {
			this.notificationSubscriptions.read_status.forEach((subscription) => subscription(notification))
		}

		PDCOpenConnection.on("voicemail", onVoicemailNotification)
		PDCOpenConnection.on("fax", onFaxNotification)
		PDCOpenConnection.on("messages", onMessageNotification)
		PDCOpenConnection.on("read_status", onReadStatusNotification)
	}

	async intWebRTCCalling() {
		console.log(PdcCallConsumer)
		console.log(this.context)
		await this.props.connect()

		API.configureCallListeners(PhoneComUser.getAPIAccountId())

		// window.onbeforeunload = (e) => {
		// 	// the absence of a returnValue property on the event will guarantee the browser unload happens
		// 	e.preventDefault()

		// 	// 	//only show alert if there is a call incoming or active.
		// 	// 	if (window.pdcCall.call.callState !== null) {
		// 	// 		return 'Are you sure you want to leave the page with an active call?'
		// 	// 	}
		// }
	}

	resetSubscription = (isForce) => {
		if (!PDCOpenConnection.connected) PDCOpenConnection.hardReset()
	}

	subscribeForNotifications = (type, callback, reinitialize = false) => {
		if (type === "messages") {
			if (reinitialize) this.notificationSubscriptions.messages = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.messages.push(callback)
		} else if (type === "voicemail") {
			if (reinitialize) this.notificationSubscriptions.voicemail = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.voicemail.push(callback)
		} else if (type === "fax") {
			if (reinitialize) this.notificationSubscriptions.faxes = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.faxes.push(callback)
		} else if (type === "read_status") {
			this.notificationSubscriptions.read_status.push(callback)
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		this.fixUrlPath()
		if (this.state.userInfo && !this.state.selectedExtension) {
			let phoneNumbers = Object.keys(this.state.userInfo.extension.phone_number)
			PhoneComUser.changePhoneNumber(phoneNumbers)
			let selectedExtension		= this.state.userInfo.extension
			selectedExtension.userId	= this.state.userInfo.user_id
			this.setState({ selectedExtension })
		}
		this.setFavicon()
	}

	setFavicon = () => {
		if (this.state.unreadMessages || this.state.unreadVoicemails || this.state.unreadFaxes) {
			if (document.getElementsByName("favicon")[0].href !== theme.favicon.unread) {
				document.getElementsByName("favicon")[0].href = theme.favicon.unread
			}
		} else {
			if (document.getElementsByName("favicon")[0].href !== theme.favicon.default) {
				document.getElementsByName("favicon")[0].href = theme.favicon.default
			}
		}
		if (document.getElementsByName("apple-icon")[0].href !== theme.favicon.default) {
			document.getElementsByName("apple-icon")[0].href = theme.favicon.default
		}
		if (document.getElementsByName("apple-icon")[1].href !== theme.favicon.default) {
			document.getElementsByName("apple-icon")[1].href = theme.favicon.default
		}
	}

	sendElectronUnreadCounts = (unread) => {
		let allUnreadMEssages = unread.messages + unread.voicemails + unread.faxes
		sendElectronNotification("unreadMessages", allUnreadMEssages)
	}

	//todo rework and remove this. This should be in the init phone come util not here
	init = async () => {
		let config = await getAppConfig()
		window.APP_CONFIG = config
		let v5phonecom = {
			stage: config.stage,
			v4ApiRoot: config.v4ApiRoot,
			v5ApiRoot: config.v5ApiRoot,
			v5ToolsRoot: config.v5ToolsRoot,
			redirect_url: config.redirect_url,
		}
		window.V5PHONECOM = v5phonecom
		await this.initialLoad()
		window.dataLayer.push({ PDC_voip_id: window.V5PHONECOM.voip_phone_id })
		electronNotificationListener()
		window.dataLayer.push({ 'PDC_voip_id': window.V5PHONECOM.voip_phone_id })
		if(window.sforce) Salesforce.init(this);
	}

	initialLoad = async (config) => {
		let res = await initializePhoneCom(config)
		if (!res) return
		if (window.Rollbar) {
			window.Rollbar.configure({
				payload: {
					person: {
						id: res.account_id, // required
						voip_id: res.account_id,
						voip_phone_id: res.extension_id,
						account_name: res.account.name,
					},
					fullstory_url:
						window.FS && typeof window.FS.getCurrentSessionURL === "function"
							? window.FS.getCurrentSessionURL(true)
							: null,
				},
			})
		}

		console.log(`APP_CONFIG: ${JSON.stringify(window.APP_CONFIG)}`)
		console.log("ok we're ready!")

		//this.setSelectedExtensionFromUrl(res)
		this.setSelectedExtensionFromLocalStorage(res)
		this.fixUrlPath(res.extension.extension_id)

		//added a 5sec timeout for the loader cover, incase the the app does not do a call back for when it is done loading
		setTimeout(() => {
			if (!this.state.appLoading) return
			this.setState({ appLoading: false })
			this.checkShowNotificaitionBar()
		}, 5000)

		this.setUnreadCounts(res.extension.extension_id)

		let phoneNumbers = Object.keys(res.extension.phone_number)
		PhoneComUser.changePhoneNumber(phoneNumbers)
		let selectedExtension = res.extension
		selectedExtension.userId = res.user_id
		this.setState({
			loading: false,
			userInfo: res,
			selectedExtension
		})
		this.initNotifications()
		loadContacts(this)
		if (
			!process.env.REACT_APP_IS_CALLING_DISABLED ||
			(window.V5PHONECOM && window.V5PHONECOM.features.has("calling_enabled"))
		) {
			console.log("hit" + process.env.REACT_APP_IS_CALLING_DISABLED)
			console.log(process.env)

			this.intWebRTCCalling()
		}
		isUpdateAvailable((registration) => {
			console.log(registration)
			this.setState({ waitingSW: registration.waiting })
		})
	}
	
	setSelectedExtensionFromLocalStorage = res =>{
		let extensionId = parseInt(localStorage.getItem('extensionId'))
		if(!extensionId) return
		let extensionElement = res.extensions.find(e => e.extension_id === extensionId)
		if (!extensionElement) return
		res.extension = JSON.parse(JSON.stringify(extensionElement))
		res.extension_id = extensionElement.extension_id
	}


	setSelectedExtensionFromUrl = (res) => {
		let pathname = window.location.pathname
		let pathnameSplit = pathname.split("/")
		pathnameSplit.shift()
		if (pathnameSplit.length === 0 || !this.isExtensionPathElement(pathnameSplit[0])) return
		let extensionId = parseInt(pathnameSplit[0].substring(1))
		let extensionElement = res.extensions.find((e) => e.extension_id === extensionId)
		if (!extensionElement) {
			return window.history.replaceState(
				"Extension Id",
				"Reset the extension id to default",
				`/e${res.extension_id}`
			)
		}
		res.extension = JSON.parse(JSON.stringify(extensionElement))
		res.extension_id = extensionElement.extension_id
	}

	isExtensionPathElement = (s) => {
		return s[0] === "e" && !isNaN(s.substring(1))
	}

	fixUrlPath = (extension_id) => {
		if (!this.state.userInfo) return
		let ext_id = extension_id || this.state.userInfo.extension_id
		let pathname = window.location.pathname
		let pathnameSplit = pathname.split("/").filter((e) => e)

		if (pathnameSplit.length === 0 || !this.isExtensionPathElement(pathnameSplit[0])) {
			pathname = `/e${ext_id}/${pathnameSplit.join("/")}`
		}

		if ([0, 1].includes(pathnameSplit.length)) {
			if (pathname[pathname.length - 1] !== "/") pathname += "/"
			pathname += theme.defaultRoute
		}

		window.history.replaceState("Extension Id", "Add missing extension id", pathname)
	}

	getAppsHeight = () => `calc(100% - ${this.mobileAppBarSpacer()})`

	mobileAppBarSpacer() {
		if (this.state.screenViewType.isMobileView || this.state.screenViewType.isTabletView) {
			return `${this.props.theme.appBarHeight}px`
		}
		return "0px"
	}

	onExtensionSwitch = (extension) => {
		let userInfo = this.state.userInfo
		let extensionElement = userInfo.extensions.find((e) => e.extension_id === extension.extension_id)
		userInfo.extension = extensionElement
		userInfo.extension_id = extensionElement.extension_id

		let phoneNumbers = Object.keys(extension.phone_number)
		PhoneComUser.changePhoneNumber(phoneNumbers)

		localStorage.setItem('extensionId', extension.extension_id)

		let pathnameSplit = window.location.pathname.split('/').filter(e => e)
		pathnameSplit.shift()
		let path = pathnameSplit[0] || "" // .join('/')
		window.history.replaceState("Extension", "Switched extension", `/e${extensionElement.extension_id}/${path}/`)
		window.location.reload()

		// this.setState({selectedExtension: extension, userInfo})
		// this.setUnreadCounts(extension.extension_id)
		// PDCOpenConnection.hardReset()
	}

	redirect = (redirectPath) => this.setState({ redirectPath })

	logout = () => {
		this.setState({ userInfo: null })

		window.APP_CONFIG.cp_session_id = ""
		window.V5PHONECOM.cp_token = ""

		// TODO: Redirect to login
	}

	onAppLoaded = () => {
		if (this.state.appLoading) this.checkShowNotificaitionBar()
		this.setState({ appLoading: false })
	}

	getCookie = (cname) => {
		let name = cname + "="
		let decodedCookie = decodeURIComponent(document.cookie)
		let ca = decodedCookie.split(";")
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === " ") {
				c = c.substring(1)
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length)
			}
		}
		return ""
	}

	checkShowNotificaitionBar = () => {
		// if (SALESFORCE)
		return
		let cookieValue = this.getCookie('mpdcdafn')
		let enabledNotificationBarShow = cookieValue !== '1' && window.Notification && Notification.permission === 'default'
		return enabledNotificationBarShow ? this.setState({ showNotificationBar: true }) : null
	}

	hideNotificationBar = () => this.setState({ showNotificationBar: false })

	returnApp = (currentApp, currentAppName) => {
		if (this.state.currentAppName !== currentAppName) {
			// If it is only this.setState({currentAppName}) then we get react error that cannot set state from within render method
			setTimeout(() => this.setState({ currentAppName }), 0)
		}
		return currentApp
	}

	unsetRedirectPath = () => this.setState({ redirectPath: null })

	getContactsUtil = () => {
		return {
			contacts: this.state.contactsInfo.contacts,
			extraContacts: this.state.contactsInfo.extraContacts,
			groupTypes: this.state.contactsInfo.groupTypes,
			contactsLoaded: this.state.contactsInfo.contactsLoaded,
			extraContactsLoaded: this.state.contactsInfo.extraContactsLoaded,
			addGroup: addGroup.bind(this, this),
			reload: () => loadContacts(this),
			loadMore: () => loadMore(this),
			getContactsApi: Api.loadContacts,
			loadExtraContacts: loadExtraContacts.bind(this, this),
			updateExtraContacts: updateExtraContacts.bind(this, this),
			deleteContact: deleteContact.bind(this, this),
			updateContact: updateContact.bind(this, this),
		}
	}

	goTo = (app, appData) => {
		this.redirect(`/e${this.state.userInfo.extension_id}/${app}`)
		let randomString = `${Math.floor(Math.random() * 1000000)}${Date.now()}`
		appData.randomString = randomString
		appData.onProcessed = () => this.setState({ appData: null })
		this.setState({ appData })
	}

	setIsDialerOpen = (isDialerOpen) => this.setState({ isDialerOpen })

	openDialer = () => {
		this.goTo("calls", { view: "dialer" })
	}
	
	openMakeACall = () => {
		this.goTo("calls", { view: "make_call" })
	}

	openCallLogList = () => {
		this.goTo("calls", { view: "select" })
	}


	makeCall = async (calleeNumber) => {
		if (
			this.state.callContext &&
			this.state.callContext.callsCnt > 0 &&
			this.state.callContext.calls.includes(calleeNumber)
		)
			return

		try {
			await this.props.call(calleeNumber)
		} catch (error) {
			console.log("error caught", error)
			this.setState({ triedCallWithoutMicPermissions: true })
		}
	}

	dismissNotification = () => {
		this.setState({ triedCallWithoutMicPermissions: false })

		//TODO: not avail yet. experimental
		// navigator.permissions.request({ name: 'microphone' })
		// .then(permissions => console.log(permissions))
	}

	onUpdatePromptClose = () => {
		this.setState({ waitingSW: null })
	}

	setHasChange = (appHasChange) => this.setState({ appHasChange })
	discardChanges = () => this.setHasChange(false)

	render() {
		let { classes } = this.props
		let showLoader = (this.state.loading || this.state.appLoading) && !this.state.isOffline
		// if (!this.state.appLoading) console.log('HIDE WELCOME LOADER') // If you see this log twice that menas that content got loaded after 5 seconds
		let generalData = {
			isOffline:					Boolean(this.state.isOffline),
			screenViewType:				this.state.screenViewType,
			extension:					this.state.selectedExtension,
			appData:					this.state.appData,
			focused:					this.state.focused,
			userActive:					this.state.userActive,
			contactsUtil:				this.getContactsUtil(),
			onLoaded:					this.onAppLoaded,
			updateUnreadCounts:			this.setUnreadCounts,
			resetSubscription:			this.resetSubscription,
			subscribeForNotifications:	this.subscribeForNotifications,
			redirect:					this.redirect,
			makeCall:					this.makeCall,
			setHasChange: 				this.setHasChange,

			openDialer: this.openDialer,
			openMakeACall: this.openMakeACall,
			openCallLogList: this.openCallLogList
		}

		let callsApp			= <Call				{...generalData} setIsDialerOpen			= {this.setIsDialerOpen}/>
		let messagesApp			= <Messages			{...generalData} changeMessageReadStatus	= {this.changeMessageReadStatus}/>
		let voicemailsApp		= <Voicemail		{...generalData} changeVoicemailReadStatus	= {this.changeVoicemailReadStatus}/>
		let faxesApp			= <Faxes			{...generalData} changeFaxReadStatus		= {this.changeFaxReadStatus}/>
		//let settingsApp			= <Settings			{...generalData}/>
		//let meetingsApp			= <Meetings			{...generalData}/>
		//let peopleApp			= <People			{...generalData}/>
		//let personalSettingsApp	= <PersonalSettings	{...generalData}/>

		let isInactive = !process.env.REACT_APP_INACTIVE_DESIGN_DISABLED && (!this.state.focused || !this.state.userActive)
		return (
			<ThemeProvider theme={this.props.theme}>
				<div className={`${classes.mainDiv} ${isInactive ? "inactive" : ""}`}>
					{this.state.isOffline ? <OfflineBar /> : null}
					{this.state.showNotificationBar ? <NotificationBar hideBar={this.hideNotificationBar} /> : null}
					{/* {this.state.userInfo && !this.state.loading ?
						<InfoPopup showOnceIdentifier={'first_my_phone_com'} content={this.infoPopupContent} />
						: null} */}
					<AppLoader hidden={!showLoader} request={'Timing'} />
					{/* Turn this state pass into context */}
					{this.state.userInfo && !this.state.loading && (<Router>
						<Navigation
							userInfo			= {this.state.userInfo}
							screenViewType		= {this.state.screenViewType}
							onExtensionSwitch	= {this.onExtensionSwitch}
							currentUser			= {this.state.selectedExtension}
							unreadMessages		= {this.state.unreadMessages}
							unreadVoicemails	= {this.state.unreadVoicemails}
							logout				= {this.logout}
							unreadFaxes			= {this.state.unreadFaxes}
							currentAppName		= {this.state.currentAppName}
							goTo				= {this.goTo}
							redirect			= {this.redirect}
							discardChanges		= {this.discardChanges}
						>
							<div style={{ height: this.mobileAppBarSpacer() }} />
							<div style={{ height: this.getAppsHeight() }}>
								<Switch>
									<Route path={`/e${this.state.userInfo.extension_id}/messages/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.unsetRedirectPath()
											return <Redirect to={path} />
										}
										return this.returnApp(messagesApp, 'messages')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/voicemail/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.unsetRedirectPath()
											return <Redirect to={path} />
										}
										return this.returnApp(voicemailsApp, 'voicemail')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/calls/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.unsetRedirectPath()
											return <Redirect to={path} />
										}
										return this.returnApp(callsApp, 'calls')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/faxes/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.unsetRedirectPath()
											return <Redirect to={path} />
										}
										return this.returnApp(faxesApp, 'faxes')
									}} />
									{/*
									<Route path={`/e${this.state.userInfo.extension_id}/settings/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.unsetRedirectPath()
											return <Redirect to={path} />
										}
										return this.returnApp(settingsApp, 'settings')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/people/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.unsetRedirectPath()
											return <Redirect to={path}/>
										}
										return this.returnApp(peopleApp, 'people')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/meetings/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.unsetRedirectPath()
											return <Redirect to={path} />
										}
										return this.returnApp(meetingsApp, 'meetings')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/personal-settings/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.unsetRedirectPath()
											return <Redirect to={path}/>
										}
										return this.returnApp(personalSettingsApp, 'personalSettings')
									}} />
									*/}
									<Route path={`/`} render={() => {
										let pathnameSplit = window.location.pathname.split('/')
										let appName = pathnameSplit.slice(2, 3)[0] || theme.defaultRoute.replace('/', '')
										switch (appName) {
											case 'calls':
												return this.returnApp(callsApp, 'calls')
											case 'messages':
												return this.returnApp(messagesApp, 'messages')
											case 'voicemail':
												return this.returnApp(voicemailsApp, 'voicemails')
											case 'faxes':
												return this.returnApp(faxesApp, 'faxes')
											/*
											case 'settings':
												return this.returnApp(settingsApp, 'settings')
											case 'people':
												return this.returnApp(peopleApp, 'people')
											case 'meetings':
												return this.returnApp(meetingsApp, 'meetings')
											case 'personal-settings':
												return this.returnApp(personalSettingsApp, 'personalSettings')
											*/
										}
									}} />
								</Switch>
							</div>
						</Navigation>
					</Router>)}
					{false && !process.env.REACT_APP_CALLS_TAB_HIDDEN ?
					<div>
                        <PdcCallConsumer>
                            {(context) => (
                                <IncomingCallModal
                                    oldestIncomingCall={context.incomingCalls[0]}
                                    answerById={context.answerById}
                                    hangupById={context.hangupById}
                                />
                            )}
                        </PdcCallConsumer>
					</div>: null}
                    {true ? <div></div> : null}
					{!this.state.appLoading ?
						(<PdcCallConsumer>
							{(context) => {
								if (!this.state.callContext || context.callsCnt !== this.state.callContext.callsCnt) {
									this.setState({
										callContext: {
											calls: Object.keys(context.calls || {}),
											callsCnt: JSON.parse(JSON.stringify(context.callsCnt)),
										},
									})
								}
								return (
									<CallBar
										isDialerOpen={this.state.isDialerOpen}
										isRinging={
											(!context.currentCall && context.incomingCallsCnt > 0) ||
											(context.currentCall &&
												context.currentCall.callState === CallState.INACTIVE)
										}
										openDialer={this.openDialer}
										setIsDialerOpen={this.setIsDialerOpen}
										screenViewType={this.state.screenViewType}
										answerById={context.answerById}
										hangupById={context.hangupById}
										switchCall={context.switchCall}
										mergeCall={context.mergeCall}
										holdCall={context.holdCall}
										getIncomingCalls={context.getIncomingCalls}
										callStats={context.callStats}
										calls={context.calls}
										activeCallId={context.activeCallId}
										currentCall={context.currentCall}
										incomingCallsCnt={context.incomingCallsCnt}
										activeCallsCnt={context.activeCallsCnt}
										callsCnt={context.callsCnt}
										backgroundCalls={context.backgroundCalls}
										showCallSessionSelector={
											context.callsCnt > 1 ||
											(!context.activeCallId && context.callsCnt > 0) ||
											(!context.currentCall &&
												Object.values(context.calls).filter((call) => call.callAnswered))
										}
										muteLocal={context.muteLocal}
										isMutedLocal={context.isMutedLocal}

									/>
								)
							}}
						</PdcCallConsumer>
					) : null}
					<Prompt
						isOpen={this.state.waitingSW}
						color="primary"
						content={
							<div
								onClick={() => {
									this.state.waitingSW.postMessage({ type: "SKIP_WAITING" })
								}}
							>
								<div>Update Available!</div>
								<div>Click Here to Update</div>
							</div>
						}
						position="bottom-left"
						onClose={this.onUpdatePromptClose}
					/>
					<Prompt
						isOpen={this.state.triedCallWithoutMicPermissions}
						color="important"
						content={
							<div onClick={this.dismissNotification}>
								<div>Unable to make call</div>
								<div>Audio browser's permissions are not enabled</div>
							</div>
						}
						position="bottom-left"
						onClose={this.dismissNotification}
					/>
				</div>
			</ThemeProvider>
		)
	}
}

export default withStyles(styles)(Communicator)
