// View 20 pages at a time.

// 2.0 
// Any text/find processing (faxes are images)
// 2.0 More than 20 at a time as it requires windowing.
// Advanced toolbar features

import React from 'react';
import pdfjsLib from 'pdfjs-dist';
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer';
import cx from 'classnames';
import PDFUnavailable from './PDFUnavailable';
import { debounce } from 'debounce';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
var BAR_HEIGHT = '32px';
var PDF_PAGE_LENGTH = 10;

// Constant in pdfjs
var CSS_UNITS = 96/72;

class PDFViewer extends React.Component {

    constructor(props) {
        super(props);
        this.page = 1;
        this.group = 0;

        this.queuedPage = null;

        this.pdf = null;
        this.numPages = 'Loading';
        this.pageRendering = false;
        this.pdfPageView = null;
        this.rotation = 0;
        this.canvas = null;

        this.state = {
            pages: 'Loading',
            error: false
        };
    }

    componentDidMount() {
        this.initDrawingFax()
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        if(this.props.url !== nextProps.url) {
        this.initDrawingFax()
        }

    }

    initDrawingFax= () =>{
             console.log('mounted')
            this.canvas = document.getElementById('pdf-canvas');
            this.canvas.innerHTML = '';

            window.addEventListener('resize', debounce(this.redraw, 200));

            pdfjsLib.getDocument(this.props.url).promise.then((pdf) => {
                if (!pdf) {
                    this.fail();
                    return;
                }

                this.pdf = pdf;
                this.numPages = pdf.numPages;
                this.drawGroup(this.group);
            }).catch(err => {
                console.log(`There was an error loading the pdf. ${this.props.url}`);
                this.fail();
            });
    }


    componentWillUnmount() {
        console.log('unmounted, clearing')
        if(this.canvas) {
            this.canvas.innerHTML = '';
        }
    }

    fail = () => { this.setState({error: true}) };

    drawGroup = (groupNum) => {
        this.clearPage();
        let start = groupNum * PDF_PAGE_LENGTH + 1;
        let end = Math.min(groupNum * PDF_PAGE_LENGTH + PDF_PAGE_LENGTH, this.pdf.numPages);

        this.setState({pages: `${start}-${end}`});
        for (let i = start; i <= end; i++) {
            this.drawPage(i);
        }
    }

    drawPage = (pageNum) => {
        this.canvas = document.getElementById('pdf-canvas');
        this.pdf.getPage(pageNum).then(pdfPage => {
            let scale = 1.0;
            let viewport = pdfPage.getViewport({scale: scale});

            // Scale by heigh or width based on orientation
            if (this.canvas === null) return
            if (this.rotation % 180 === 90) {
                scale = Math.min(1, this.canvas.clientWidth / (viewport.height * CSS_UNITS));
            } else {
                scale = Math.min(1, this.canvas.clientWidth / (viewport.width * CSS_UNITS));
            }

            this.pdfPageView = new pdfjsViewer.PDFPageView({
                container: this.canvas,
                id: pageNum,
                scale: scale,
                defaultViewport: pdfPage.getViewport({scale: scale})
            });

            this.canvas.height = viewport.height;
            this.pdfPage = pdfPage;
            this.pdfPageView.setPdfPage(pdfPage);
            this.pdfPageView.update(scale, this.rotation);
            return this.pdfPageView.draw();
        });
    }

    clearPage() {
        while (this.canvas.lastChild) {
            this.canvas.removeChild(this.canvas.lastChild);
        }
    }

    rotate = () => {
        this.rotation = (this.rotation + 90) % 360;
        this.pdfPageView.update(this.rotation);
        this.drawGroup(this.group);
    }

    redraw = () => { this.drawGroup(this.group); }

    nextGroup = () => {
        let nextGroup = this.group + 1;
        if (nextGroup * PDF_PAGE_LENGTH > this.pdf.numPages)
            return;

        this.group = nextGroup;
        this.drawGroup(nextGroup);
    }

    prevGroup = () => {
        let prevGroup = this.group - 1;
        if (prevGroup < 0)
            return;

        this.group = prevGroup;
        this.drawGroup(prevGroup);
    }

    download = () => {
        console.log('download');
    }

    actions = {
        prev: this.prevGroup,
        next: this.nextGroup,
        rotate: this.rotate,
        download: this.download,
    }

    render() {
        if (this.state.error) {
            return <PDFUnavailable {...this.props}/>
        }
        

        return(
            <div className='pdf-wrapper' style={{height: '100%', width: '100%', overflow: 'scroll'}}>
                {/*<PDFBar actions={this.actions} title={`Pages ${this.state.pages} of ${this.numPages}`} {...this.props}/>*/}
                <PDFCanvas/>
            </div>
        )
    }
}

const PDFCanvas = props => {
    return (
        <div style={{'WebkitOverflowScrolling': 'touch', 'overflow': 'scroll', 'height': `calc(100% - ${BAR_HEIGHT})`, 'top': BAR_HEIGHT, 'margin': 'auto'}} id='pdf-canvas'></div>
    )
}

const PDFBar = props => {
    let actions = props.actions;
    return (
        <div className={cx('pdf-header')} style={{position: 'relative', left: 0, right: 0, height: BAR_HEIGHT, zIndex: 9999, cursor: 'default'}}>
            <button onClick={actions.prev}>Prev Page</button>
            <button onClick={actions.next}>Next Page</button>
            <button onClick={actions.rotate}>Rotate</button>
            <button onClick={actions.download}>Download</button>
            <a href={props.url} target='blank'>Pop Out</a>
            <span title={props.title}>{props.title}</span>
        </div>
    )
}



export default PDFViewer;