import CallSession from "./CallSession"
import {
	UserAgent,
	Inviter,
	SessionState,
	RegistererState,
	Session,
	Registerer,
	RegistererRegisterOptions,
	SessionByeOptions,
} from "sip.js"
import { CallState } from "../enums/CallState"
import { CallerInfo } from "../interfaces/CallerInfo"
//@ts-ignore
import getStats from "getstats"
import { CallEventsDelegate } from "../interfaces/CallEventsDelegate"

const CALLSESSIONTYPE = "sip"
export class SipCallSession extends CallSession {
	isNewCall: boolean = false
	joinedSessions: string[] = []

	myCallInfo: CallerInfo | null
	callType: string
	callId: string
	callStartTime?: number
	callEndTime?: number
	session?: Inviter
	callState: CallState | null = CallState.INACTIVE
	callAnswered: boolean = false
	participants: CallerInfo[] = []
	callInviteEvent: any
	callEventsDelegate: CallEventsDelegate

	recentConnectionStats: Array<any> = []

	isMerged: boolean = false
	isMutedLocal: boolean = false
	isMutedRemote: boolean = false

	constructor(
		participants: CallerInfo[],
		myNumber: CallerInfo,
		callId: string,
		callState: CallState,
		delegate: CallEventsDelegate
	) {
		super()
		this.participants = participants
		this.myCallInfo = myNumber
		this.callType = CALLSESSIONTYPE
		this.callId = callId
		this.callEventsDelegate = delegate
		this.callStartTime = Date.now() / 1000
	}

	public hangup = async () => {
		//TODO: a session cannot dispose of itself correctly can it?
		let byeOpts: SessionByeOptions = {
			requestDelegate: {
				// onProgress: () => {
				//     //soon as its sent dont worry if it comes back, hangup thecall locally.
				//     this.callEventsDelegate.onCallHangup(this.callId)
				// },
			},
		}
		//TODO: if canceling before session established on outgoing, cant stop ringing so user's callee still picks up a call that was canceled
		if (this.session && SessionState && this.session!.state === SessionState.Established) {
			await this.session!.bye()
		}else if (this.session) {
			await this.session.cancel()
		} else {
			this.callEventsDelegate.onCallHangup(this.callId)
		}
	}
	public prepareInvite = async (userAgent: UserAgent) => {
		let headers: string[] = []
		if (this.callInviteEvent) {
			headers = [
				`X-Slot: ${this.callInviteEvent.call.uuid}`,
				`X-Server: ${this.callInviteEvent.call.host}`,
			]
		}

		this.sendInvite(userAgent, headers)
	}
	private sendInvite = (userAgent: any, extraHeaders: any = []) => {
		// Create a user agent client to establish a session
		console.log(extraHeaders)
		const target = UserAgent.makeURI(`sip:${this.callId}@phone.com`)
		if (!target) return

		const inviter = new Inviter(userAgent, target, {
			sessionDescriptionHandlerOptions: {
				constraints: { audio: true, video: false },
			},
			extraHeaders: extraHeaders,
		})
		const outgoingSession = inviter

		// Setup outgoing session delegate
		outgoingSession.delegate = {
			// Handle incoming REFER request.
			onRefer: (referal) => {
				console.log("referal detected", referal)
			},
		}
		// Handle outgoing session state changes
		console.log("inviter", inviter)
		this.addInvSessionStateChangeListeners(inviter)
		return inviter.invite({
			requestOptions: { extraHeaders: extraHeaders },
		})
	}
	public hold = (): void => {
		//TODO: mute tracks and play fetched local mp3 from browser
		throw new Error("Method not implemented.")
	}
	public unhold = (): void => {
		throw new Error("Method not implemented.")
	}

	public muteLocal = (isMuted: boolean): void => {
		if (this.session) {
			let session = this.session as any
			console.log(session)
			let pc = session.sessionDescriptionHandler.peerConnection
			pc.getSenders().forEach((sender: RTCRtpSender) => {
				let track = sender.track!
				track.enabled = !isMuted
				this.isMutedLocal = !track.enabled
				console.log(this.isMutedLocal)
			})
			this.callEventsDelegate.onManagerStateUpdate(null)
		}
	}

	//cannot be toggle for scenario where one call is muted and third call isnt. youd never be able to mute all calls.
	public muteRemote = (isMuted: boolean): void => {
		if (this.session) {
			let session = this.session as any
			console.log(session)
			let pc = session.sessionDescriptionHandler.peerConnection
			pc.getReceivers().forEach((receiver: RTCRtpReceiver) => {
				let track = receiver.track
				track.enabled = !isMuted
				this.isMutedRemote = !track.enabled
			})
			this.callEventsDelegate.onManagerStateUpdate(null)
		}
	}
	public supports = (): void => {
		throw new Error("Method not implemented.")
	}
	public createCall = (): void => {
		throw new Error("Method not implemented.")
	}
	public showCallStats = (): void => {
		console.log("starting interval")
		if (this.session && this.session.sessionDescriptionHandler) {
			let conn = (this.session as any).sessionDescriptionHandler.peerConnection
			let interval = 3000
			getStats(
				conn,
				(stats: any) => {
					//queue obj of size 5
					console.log(stats)
					let connStats = {
						audio: {
							latency:
								typeof stats.audio.latency === "string"
									? parseFloat(stats.audio.latency)
									: stats.audio.latency,
							packetsLost:
								typeof stats.audio.packetsLost === "string"
									? parseFloat(stats.audio.packetsLost)
									: stats.audio.packetsLost,
							sendCodec: stats.audio.send.codecs[0],
							recvCodec: stats.audio.recv.codecs[0],
						},
					}
					if (this.recentConnectionStats.length === 5) {
						this.recentConnectionStats.shift()
					}
					if (this.recentConnectionStats.length < 5) {
						this.recentConnectionStats.push(connStats)
					}
					// console.log('connection_stats: ', this.recentConnectionStats)

					let jitter = this.getJitter(this.recentConnectionStats) || 0
					let packetsLost = this.getPacketsLost(this.recentConnectionStats) || 0
					let avgLatency = this.getAverageLatency(this.recentConnectionStats) || 0
					let signalStr = this.getSignalStrength(this.recentConnectionStats) || 0
					this.callEventsDelegate.onCallStatUpdate(this.callId, {
						jitter,
						packetsLost,
						avgLatency,
						signalStr,
						sendCodec: connStats.audio.sendCodec,
						recvCodec: connStats.audio.recvCodec,
					})
				},
				interval
			)

			// TODO: vanilla imp, switch to this after getting pdc mos algo
			// let myPeerConnection = this.sipCall.session.sessionDescriptionHandler.peerConnection
			// setInterval(function () {
			//     myPeerConnection.getStats(null).then(stats => {
			//         let statsOutput = {}
			//         stats.forEach(report => {
			//             statsOutput =
			//             let connStats = {
			//                 audio: {
			//                     latency: typeof stats.audio.latency === 'string' ? parseFloat(stats.audio.latency) : stats.audio.latency,
			//                     packetsLost: typeof stats.audio.packetsLost === 'string' ? parseFloat(stats.audio.packetsLost) : stats.audio.packetsLost,
			//                     sendCodec: stats.audio.send.codecs[0],
			//                     recvCodec: stats.audio.recv.codecs[0],
			//                 }
			//             }
			//                 if (this.recentConnectionStats.length === 5) {
			//                     this.recentConnectionStats.shift()
			//                 }
			//                 if (this.recentConnectionStats.length < 5) {
			//                     this.recentConnectionStats.push(connStats)
			//                 }
			//                 console.log('connection_stats: ', this.recentConnectionStats)

			//                 let jitter = this.getJitter(this.recentConnectionStats) || 0
			//                 let packetsLost = this.getPacketsLost(this.recentConnectionStats) || 0
			//                 let avgLatency = this.getAverageLatency(this.recentConnectionStats) || 0
			//                 let signalStr = this.getSignalStrength(this.recentConnectionStats) || 0
			//                 this.__emit('callStatsUpdate', { jitter, packetsLost, avgLatency, signalStr, sendCodec: connStats.audio.sendCodec, recvCodec: connStats.audio.recvCodec })
			//             }, interval)

			//             console.log(report)
			//             packets lost from inbound rtp
			//             jitter from inbound-rtp
			//             latency  = last packetsenttimestamp - last packetreceivedtimestamp candidate-pair
			//             signalstr

			//             if (report.type === 'candidate-pair' && report.state === 'succeeded') {
			//                 statsOutput = { audio: { latency: report.lastPacketSentTime - } }
			//             } else if (report.type === 'inbound-rtp' || report.type === 'inbound-rtp') {

			//             }
			//         });

			//         //   document.querySelector(".stats-box").innerHTML = statsOutput;
			//     });
			// }, 3000);
		}
	}

	private addInvSessionStateChangeListeners = (invSession: Inviter): void => {
		invSession.stateChange.addListener((newState) => {
			switch (newState) {
				case SessionState.Initial:
					console.log("init")
					break
				case SessionState.Establishing:
					console.log("establishing")
					console.log("invSession", invSession)
					this.session = invSession
					this.callEventsDelegate.onCallCreated(this.callId)
					break
				case SessionState.Established:
					console.log(this.session)
					if (!this.session) this.session = invSession
					this.callEventsDelegate.onCallAnswered(this.callId)
					break
				case SessionState.Terminating:
				// fall through
				case SessionState.Terminated:
					console.log("sessionstate terminated")
					console.log(this.session)
					this.callEventsDelegate.onCallHangup(this.callId)
					console.log(this.session)
					this.session = undefined
					console.log("statement before unregister")
					break
				default:
					console.log("error session state", newState, typeof newState)
					throw new Error("Unknown session state.")
			}
		})
	}

	private getSignalStrength = (arr: any) => {
		//TODO: packets lost %, jitter,

		let avgLatency = this.getAverageLatency(arr)
		let jitter = this.getJitter(arr)
		let packetLossPercent = this.getPacketsLost(arr)
		let effectiveLatency = avgLatency + jitter * 2 + 10
		let rVal = this.getRVal(effectiveLatency, packetLossPercent)
		let mosScore = this.getMosScore(rVal)

		console.log(
			`avgLatency: ${avgLatency}, jitter: ${jitter}, packetLossPercent: ${packetLossPercent}, effectiveLatency: ${effectiveLatency}`
		)

		console.log(`r: ${rVal}, mos: ${mosScore}`)

		// cant use rounding, 1 or 5 would never be hit.
		return Math.ceil(mosScore)
	}

	private getAverageLatency = (statsArr: any) => {
		let latencyArr = statsArr.map((i: any) => i.audio.latency)
		let total = latencyArr.reduce((sum: any, i: any) => sum + i, 0)
		return total / latencyArr.length
	}

	private getJitter = (statsArr: any) => {
		let latencyArr = statsArr.map((i: any) => i.audio.latency)
		//need at least 2 to get the diff.
		if (latencyArr.length < 2) {
			return 0
		}

		let diffs = []
		for (let i = latencyArr.length - 1; i > 1; i--) {
			let diff = Math.abs(latencyArr[i] - latencyArr[i - 1])
			diffs.push(diff)
		}
		let total = diffs.reduce((sum, i) => sum + i, 0)
		return total / diffs.length
	}

	private getRVal = (effectiveLatency: any, packetLossPercent: any) => {
		let rval = 5
		if (effectiveLatency < 160) {
			rval = 93.2 - effectiveLatency / 40
		} else {
			rval = 93.2 - (effectiveLatency - 120) / 10
		}
		return rval - packetLossPercent * 2.5
	}

	private getMosScore = (rval: any) => {
		return 1 + 0.035 * rval + 0.000007 * rval * (rval - 60) * (100 - rval)
	}

	private getPacketsLost = (statsArr: any) => {
		if (statsArr.length < 1) {
			return 0
		}
		return statsArr[statsArr.length - 1].audio.packetsLost
	}
}