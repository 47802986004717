import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
class API {

	static loadCalls = async (filters, limit, cursor, sort) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/calls/list-calls`
		let response = await ajax.post(requestUrl, { filters, limit, cursor, sort, no_recordings: true })
		if (response.message === 'Network Error') return 'network-error'
		return response.data
	}

	static getCall = async (callId, voipId) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/calls/get-call`
		let response = await ajax.post(requestUrl, { call_id: callId, voip_id: voipId })
		if (response.message === 'Network Error') return 'network-error'
		return response.data
	}

	static getCallRecordings = async (recording_ids, voicemail_ids) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/calls/get-call-recordings`
		let response = await ajax.post(requestUrl, { recording_ids, voicemail_ids })
		return response.data
	}

	static makeCall = (callerExtension, calleePhoneNumber, callerCallerId, calleeCallerId) => {
		let body = {
			voip_id: parseInt(PhoneComUser.getAPIAccountId()),
			caller_extension: callerExtension,
			callee_phone_number: calleePhoneNumber,
			caller_caller_id: callerCallerId,
			callee_caller_id: calleeCallerId
		}
		return ajax.post(`${PhoneComUser.getv5ApiRoot()}/calls/make-click-to-call`, body)
			.then(response => response.data)
	}

	static configureCallListeners = async (voip_id) => {
		let requestUrl = `${PhoneComUser.getv5ToolsRoot()}/temp-services/configure-default-listeners`
		try {
			let response = await ajax.post(requestUrl, { voip_id })
			console.log(response)
			return response.data
		} catch (error) {
			console.log(error)
		}
	}

	static updateCallFromNumber = (fromCallerId) => {
		let body = {
			voip_phone_id: parseInt(PhoneComUser.getExtensionId()),
			caller_id: fromCallerId
		}
		return ajax.post(`${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/calls/set-caller-id`, body)
			.then(response => response.data)
	}

}


export default API