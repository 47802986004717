import React, {Component} from 'react'
import SelectorItem from 'selector-item'
import { formatPhoneNumber } from 'phone-numbers'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core'
import { attachmentIconSvg } from 'pdc-icons';
import { Salesforce } from '../../../../communicator/src/util/salesforce'

const styles = theme => ({
	itemBadge: {
		position:	'absolute',
		left:		'-16px',
		top:		'-2px',
		'&>span>span': {
			background:		theme.notifications.inAppNotificationColor,
			color: 	theme.notifications.inAppNotificationTextColor,
			fontWeight:	'bold',
			fontSize:	'11.5px'
		}
	},
	attachmentIcon: {
		marginRight: '3px'
	}
})

class ConversationSelectorItem extends Component {

	getParticipants = () => {
		let participants = this.props.conversation.participants.map(p => {
			let contact	= this.props.extraContacts.find(c => c.id === p.voip_contact_id)
			let name	= contact ? contact.name.display : ''
			let number	= p.number
			let mine	= this.props.conversation.my_nums.map(n => formatPhoneNumber(n.number)).includes(formatPhoneNumber(p.number))
			
			let sf_contact = Salesforce.getContactName(number)
			if(sf_contact) name = sf_contact
	
			return {number, name, mine}
		})
		return participants
	}

	renderMessage() {
		let { classes } = this.props
		let showMedia = Boolean(this.props.conversation.last_message.media && this.props.conversation.last_message.media.length)
		return (
			<>
			{showMedia && <img className={classes.attachmentIcon} alt='Attachment' src={attachmentIconSvg}/>}
			<span className={this.props.conversation.unread_messages ? classes.unreadMessage : ''}>
				{this.props.conversation.last_message.text}
			</span>
			</>
		)
	}
	
	renderBadge = () => {
		let { classes }		= this.props
		let unreadMessages	= this.props.conversation.unread_messages
		return unreadMessages ? (
			<div className={classes.itemBadge}>
				<Badge badgeContent={unreadMessages}> </Badge>
			</div>
		) : null
	}

	render() {
		let messageHtml = this.renderMessage()
		return (
			<SelectorItem
				mainText		= {messageHtml}
				participants	= {this.getParticipants()}
				timestamp		= {this.props.conversation.last_message.created_at}
				showMyNums		= {this.props.conversation.hasDuplicate}
				renderBadge		= {this.renderBadge}
				active			= {this.props.active}
				bold			= {this.props.conversation.unread_messages}
				isNew			= {this.props.conversation.newlyAdded}
				onClick			= {this.props.onClick}
				setRef			= {this.props.setRef}
			/>
		)
	}
}

export default withStyles(styles)(ConversationSelectorItem)