import {Workbox} from 'workbox-window';

// import PDCCall from 'pdc-call'


//https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
const isUpdateAvailable  = (callback, swFileName=`${process.env.PUBLIC_URL}/service-worker.js`) => {



	const wb = new Workbox(swFileName);
	let registration;
	let callbackWrapper = () => {
		console.log('registration')
		console.log(registration)
		if(!registration){
		wb.register().then((r) =>{
			registration = r
			console.log('registered')
			console.log(registration)
			if(r & r.waiting)
			callback(r)

	
		})
	}else{
		callback(registration)

	}
	}
	wb.addEventListener('waiting', callbackWrapper);
	wb.addEventListener('externalwaiting', callbackWrapper);


	wb.register().then((r) =>{
		registration = r
		console.log('registered 1')
		console.log(registration)

	}
		);

};

const isServiceWorkerSupported = navigator && navigator.serviceWorker

const addSWEventListener = (type, callback )=> {
	if(isServiceWorkerSupported) {
        return navigator.serviceWorker.addEventListener(type, callback);
    }
	console.warn('Service worker not supported on browser')
}

const postMessageToSW = (message) =>{
	if(isServiceWorkerSupported) {
        navigator.serviceWorker.ready.then(() => {
        	if( navigator.serviceWorker &&  navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage)
                navigator.serviceWorker.controller.postMessage(message);
            }
        );
    }
}




export {isUpdateAvailable, isServiceWorkerSupported, addSWEventListener, postMessageToSW}