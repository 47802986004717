import {theme} from 'get-theme'
let mainTheme=theme



const styles = t => {

	let theme = (t.palette.secondary['-300'])? t : mainTheme

	return {
	switchWrapper: {
		padding:		6,
		borderRadius:	23,
		background:		'transparent',
		width:			'fit-content',
		cursor:			'pointer',
		'&:hover': {
			backgroundColor: theme.palette.primary['-400']
		},
		'&.checked': {
			'& .switch': {
				backgroundColor: theme.palette.primary['0'],
				'& .thumb': {
					marginLeft: 16,
					'& > svg': {
						fontSize:	16,
						color:		theme.palette.primary['0']
					}
				}
			}
		},
		'&.unchecked': {
			'& .switch': {
				backgroundColor: theme.palette.secondary['+100']
			}
		},
		'& .switch': {
			width:			50,
			height:			34,
			borderRadius:	25,
			padding:		2,
			'& .thumb': {
				position:		'relative',
				background:		'white',
				width:			30,
				height:			30,
				borderRadius:	15,
				boxShadow:		'0px 2px 1px rgba(0, 0, 0, 0.12)',
				transition:		'margin .2s',
				'& > svg': {
					fontSize:	0,
					position:	'absolute',
					left:		'50%',
					top:		'50%',
					transform:	'translate(-50%, -50%)'
				}
			}
		},
		'&.disabled': {
			'& .switch .thumb': {
				boxShadow: 'none'
			},
			'&.checked .switch': {
				backgroundColor: theme.palette.primary['-300'],
				'& .thumb > svg': {
					color: theme.palette.primary['-300']
				}
			},
			'&.unchecked .switch': {
				backgroundColor: theme.palette.secondary['-300']
			}
		}
	},
	checkboxWrapper: {
		padding:		12,
		borderRadius:	'50%',
		background:		'transparent',
		width:			'fit-content',
		cursor:			'pointer',
		'&:not(.no-hover):hover': {
			backgroundColor: theme.palette.secondary['-400']
		},
		'&.checked': {
			'& .checkbox': {
				backgroundColor: theme.palette.secondary['+100'],
				'& > svg': {
					fontSize:	16,
					color:		'white'
				}
			}
		},
		'&.unchecked': {
			'& .checkbox': {
				backgroundColor: 'transparent'
			}
		},
		'& .checkbox': {
			width:			28,
			height:			28,
			border:			`1.5px solid ${theme.palette.secondary['+100']}`,
			borderRadius:	4,
			padding:		'5.93px 4.1px',
			position:		'relative',
			'& > svg': {
				fontSize:	0,
				position:	'absolute',
				left:		'50%',
				top:		'50%',
				transform:	'translate(-50%, -50%)'
			}
		},
		'&.disabled': {
			'& .checkbox': {
				borderColor: theme.palette.secondary['-300']
			},
			'&.checked .checkbox': {
				backgroundColor: theme.palette.secondary['-300']
			}
		}
	},
	radioWrapper: {
		padding:		12,
		borderRadius:	'50%',
		background:		'transparent',
		width:			'fit-content',
		cursor:			'pointer',
		'&:hover': {
			backgroundColor: theme.palette.secondary['-400']
		},
		'&.checked': {
			'& .radio': {
				backgroundColor: 'transparent',
				'& .check-circle': {
					width:	16,
					height:	16
				}
			}
		},
		'&.unchecked': {
			'& .radio': {
				backgroundColor: 'transparent'
			}
		},
		'& .radio': {
			width:			28,
			height:			28,
			border:			`1.5px solid ${theme.palette.secondary['+100']}`,
			borderRadius:	14,
			padding:		4.5,
			position:		'relative',
			'& .check-circle': {
				position:			'absolute',
				width:				0,
				height:				0,
				borderRadius:		'50%',
				backgroundColor:	theme.palette.secondary['+100'],
				left:				'50%',
				top:				'50%',
				transform:			'translate(-50%, -50%)',
				transition:			'width .1s, height .1s'
			}
		},
		'&.disabled': {
			'& .radio': {
				borderColor: theme.palette.secondary['-300']
			},
			'&.checked .radio .check-circle': {
				backgroundColor: theme.palette.secondary['-300']
			}
		}
	}
}}

export default styles