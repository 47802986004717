import React, { Component } from 'react'
import LoaderFull from 'loader-full'
import EditProfile from 'edit-profile'
import Button from 'button'
import { convertNumberToE164 } from 'phone-numbers'
import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import api from '../../util/api_v5'

import { withStyles } from '@material-ui/core'

export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const styles = theme => ({
	settingWrapper:	{
		...theme.personalSettingsApp.settingWrapper,
		flexDirection: 'column'
	},
	loadingDiv: theme.loadingDiv,
	editProfile: {
		width: 600
	}
})

class MyProfile extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading:	true,
			updating:	false,
			user:		null,
			tmpUser:	null
		}
	}

	extractName = () => {
		let extension	= this.props.extension
		let name		= extension.extension_name
		let nameSplit	= name.split(' ')
		let firstName	= nameSplit[0]
		nameSplit.shift()
		let lastName	= nameSplit.join(' ')
		return { firstName, lastName }
	}

	componentDidMount = async () => {
		let userId	= this.props.extension.userId
		let user	= null
		let tmpUser	= null
		if (userId) user = await api.getUser(userId)
		if (user) {
			user.extension	= user.relationships.extension
			tmpUser			= JSON.parse(JSON.stringify(user))
		}
		this.setState({loading: false, user, tmpUser})
	}

	updateUser = newUser => {
		this.setState({tmpUser: newUser})
	}

	hasChange = () => {
		let { user, tmpUser } = this.state
		if (!user) return false
		let hasChange = false
		let checkKeys = ['avatar_url', 'first_name', 'last_name', 'email', 'timezone', 'voicemail_password', 'personal_phone_number']
		checkKeys.forEach(k => {
			if (hasChange) return
			if (k === 'personal_phone_number') {
				if (!user[k] && tmpUser[k]) hasChange = true
				else if (user[k] && convertNumberToE164(user[k]) !== convertNumberToE164(tmpUser[k])) hasChange = true
			} else if (k === 'avatar_url') {
				if (Boolean(user[k]) !== Boolean(tmpUser[k])) hasChange = true
				else if (user[k] && user[k].trim() !== tmpUser[k].trim()) hasChange = true
			} else if (user[k].trim() !== tmpUser[k].trim()) return hasChange = true
		})
		return hasChange
	}

	validate = () => {
		let isValid = true
		let user = this.state.tmpUser
		isValid = !this.validateFirstName(user)		? false : isValid
		isValid = !this.validateLastName(user)		? false : isValid
		isValid = !this.validateEmailForUser(user)	? false : isValid
		isValid = !this.validateNumber(user)		? false : isValid
		isValid = !this.validateVoicemailpin(user)	? false : isValid
		return isValid
	}

	updateFieldInUser = (field, value) => {
		let tmpUser	= this.state.tmpUser
		tmpUser[field]	= value
		this.updateUser(tmpUser)
	}

	validateFirstName = user => {
		if (user.first_name.length === 0){
			let error = 'Please enter a valid First Name.'
			this.updateFieldInUser('first_name_error', error)
			return false
		}
		return true
	}
	validateLastName = user => {
		if (user.last_name.length === 0){
			let error = 'Please enter a valid Last Name.'
			this.updateFieldInUser('last_name_error', error)
			return false
		}
		return true
	}

	validateEmailForUser = user => {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (!re.test(String(user.email).toLowerCase())) {
			let error = 'Please enter a valid email address.'
			this.updateFieldInUser('email_error', error)
			return false
		}
		return true	
	}

	validateNumber = user => {
		let isValid = true
		let personalPhoneNumber = user.personal_phone_number
		if (!personalPhoneNumber) isValid = false
		else if (!isValidNumber(personalPhoneNumber) && !isValidNumber(personalPhoneNumber, 'US')) isValid = false
		if (!isValid) this.updateFieldInUser('personal_phone_number_error', 'Please enter a valid number.')
		return isValid
	}

	validateVoicemailpin = user => {
		let re = /^\d+$/
		if (!re.test(user.voicemail_password)){
			let error = 'Pin must contain 6 digits'
			this.updateFieldInUser('voicemail_password_error', error)
			return false
		}
		return true
	}

	onSave = async () => {
		if (!this.validate()) return
		if (!this.hasChange() || this.state.updating) return
		let { user, tmpUser } = this.state
		delete tmpUser.first_name_error
		delete tmpUser.last_name_error
		delete tmpUser.email_error
		delete tmpUser.personal_phone_number_error
		delete tmpUser.voicemail_password_error
		tmpUser.first_name	= tmpUser.first_name.trim()
		tmpUser.last_name	= tmpUser.last_name.trim()
		tmpUser.email		= tmpUser.email.trim()
		let updateAvatar	= tmpUser.avatar_url === user.avatar_url ? false : true

		this.setState({updating: true})
		if (updateAvatar) {
			let uploadAvatarResponse = await api.uploadAvatar(tmpUser, tmpUser.avatar_url)
			if (uploadAvatarResponse && uploadAvatarResponse.data){
				let image_name = uploadAvatarResponse.data.message.split('/')[2]
				tmpUser.avatar_url = `https://pdc-users-avatar.s3-us-west-2.amazonaws.com/${image_name}`
			}
			if (uploadAvatarResponse.error) tmpUser.avatar_url = this.state.editUserOldAvatar
		}
		let response = await api.updateUser(this.prepareUserForSave(tmpUser))
		if (response.error) {
			return this.setState({pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while updating user.', updating: false})
		}
		this.setState({pdcPromptOpen: true, pdcPromptMessage: 'User updated successfully.', updating: false})

		let newUser = JSON.parse(JSON.stringify(tmpUser))
		this.setState({user: newUser, tmpUser})
	}

	prepareUserForSave = user => {
		let newUser = { ...user }
		let extraKeys = ['relationships', 'direct_number', 'meeting_plan', 'device', 'expanded']
		extraKeys.forEach(key => delete newUser[key])
		if (newUser.personal_phone_number) newUser.personal_phone_number = convertNumberToE164(newUser.personal_phone_number)
		return newUser
	}

	render() {
		const { classes } = this.props
		let { user, tmpUser, loading, updating } = this.state
		let hasChange = this.hasChange()
		return (
			<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
				{loading || updating ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div> : null}
				{!loading && user && tmpUser ?
					<>
						<div className={classes.editProfile}>
							<EditProfile
								user		= {user}
								tmpUser		= {tmpUser}
								updateUser	= {this.updateUser}
							/>
						</div>
						<Button
							variant		= 'filled'
							style		= {{width: 100}}
							disabled	= {!hasChange || updating}
							onClick		= {this.onSave}
						>Save</Button>
					</>
				: !loading && !user ? <div style={{textAlign: 'center'}}>This extension is not assigned to any user</div> : null}
			</div>
		)
	}
}

export default withStyles(styles)(MyProfile)