import React, { Component, isValidElement, cloneElement } from 'react'
import Spinner from 'spinner'

class InfiniteScroller extends Component {

	state = {loadingMore: false}

	componentDidMount() {
		// trigger distance of more than 0 makes scroller jump when loading more items
		this.triggerDistance = this.props.triggerDistance || 0

		if (this.props.reverseScroll && this.scroller) {
			this.scroller.scrollTop = this.scroller.scrollHeight
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot !== null && this.props.reverseScroll) {
			this.scroller.scrollTop = this.scroller.scrollHeight - snapshot
		}

		// const prevItemsCount	= prevProps.itemsCount || prevProps.children.length
		// const itemsCount		= this.props.itemsCount || this.props.children.length
		// if (itemsCount !== prevItemsCount && this.scroller && !this.inLoadingRange()) {
		// 	this.scroller.scrollTop = this.props.reverseScroll ? this.scroller.scrollHeight : 0
		// }

	}

	getSnapshotBeforeUpdate(prevProps) {
		if (prevProps.children.length < this.props.children.length) {
			return this.scroller.scrollHeight - this.scroller.scrollTop
		}
		return null
	}


	inLoadingRange = () => {
		if (this.props.reverseScroll) {
			if (Math.floor(this.scroller.scrollTop - this.triggerDistance) <= 0) {
				return true
			}
		} else {
			let scrollBottom = this.scroller.scrollTop + this.scroller.offsetHeight
			if (Math.ceil(scrollBottom + this.triggerDistance) >= this.scroller.scrollHeight) {
				return true
			}
		}
		return false
	}

	handleScroll = async () => {

		if (!this.scroller) return
		if (this.props.onScroll) this.props.onScroll(this.scroller)

		if (this.state.loadingMore || !this.props.hasMore) return
		if (!this.inLoadingRange()) return
		let scroller = this.scroller
		this.setState({loadingMore:true})
		let prevHeight = this.scroller.scrollHeight
		let prevScrollTop = this.scroller.scrollTop

		await this.props.loadMore()

		let heightDiff = scroller.scrollHeight - prevHeight
		let newScrollTop = prevScrollTop
		if (this.props.reverseScroll) {
			newScrollTop += heightDiff
		}
		this.setState({loadingMore:false}, () => this.props.reverseScroll ? this.scroller.scrollTop = newScrollTop : null)
	}

	getLoader() {
		let key = parseInt(Math.random() * 10000)
		return (
			<div key={key} className='pdc-loader-wrapper'>
				{this.props.loader ? this.props.loader : <Spinner/>}
			</div>
		)
	}

	renderLoader = () => this.state.loadingMore ? this.getLoader() : null

	/**
	 * The prop childType can be 'element' or 'component'.
	 * 'element' is for a dom element - in this case the scroller props will be set directly
	 * 'component' is for a component - in this case the scroller props will be sent to the component in a prop named scrollerProps
	 */
	render() {
		let testIdProps	= this.props['data-test-id'] ? {'data-test-id': this.props['data-test-id']} : {}
		let setToChild	= this.props.setToChild
		let childType	= this.props.childType
		let scrollerProps = {
			onScroll:	this.handleScroll,
			onWheel:	this.handleScroll,
			ref:		scroller => this.scroller = scroller,
			style:		this.props.styles || {},
			...testIdProps
		}
		if (!setToChild) {
			return (
				<div className='infinite-scroller' {...scrollerProps}>
					{this.props.reverseScroll ? this.renderLoader() : null}
					{this.props.children}
					{!this.props.reverseScroll ? this.renderLoader() : null}
				</div>
			)
		} else {
			let child				= this.props.children
			let scrollerChildren	= [...child.props.children]

			if (this.state.loadingMore) {
				let loaderDiv = this.getLoader()
				if (this.props.reverseScroll) scrollerChildren.unshift(loaderDiv)
				if (!this.props.reverseScroll) scrollerChildren.push(loaderDiv)
			}

			let scrollerClassName		= child.props.className || ''
			let scrollerElementProps	= childType === 'component' ? {scrollerProps: {...scrollerProps}} : {...scrollerProps}
			let properties = {
				children:	scrollerChildren,
				className: `${scrollerClassName} infinite-scroller`,
				...scrollerElementProps
			}
			if (isValidElement(child)) return cloneElement(child, properties)
			return child
		}
	}
}

export default InfiniteScroller