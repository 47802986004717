import dateFormat from 'dateformat'

const getFormattedTime = (unix_timestamp, longFormat=false) => {
	let now		= new Date()
	let myTime	= new Date(unix_timestamp * 1000)
	let msdiff	= now - myTime

	if (!longFormat) {
		if (msdiff < 1.08e+7) { // 3 hours
			if (msdiff < 3.6e+6) { // 1 hour
				if (msdiff < 60000) { // 1 minute
					return 'now'
				}
				return '' + Math.floor(msdiff / 60000) + ' min'
			}
			return '' + Math.floor(msdiff / 3.6e+6) + ' hr'
		}
	} else {
		if (msdiff < 300000) { // 5 minutes
			if (msdiff < 60000) { // 1 minute
				return 'now'
			}
			return '' + Math.floor(msdiff / 60000) + ' min'
		}
	}

	let diffFromMonday = now.getTime() - getMonday(now).getTime()

	let format = 'h:MMTT'
	let formattedTime = ''
	if (!(now.toDateString() === myTime.toDateString())) {
		if (now.getFullYear() === myTime.getFullYear()) {
			let yesterday = new Date()
			yesterday.setDate(now.getDate() - 1)
			if (myTime.toDateString() === yesterday.toDateString()) {
				if (!longFormat) return 'YESTERDAY'
				else return 'YESTERDAY ' + dateFormat(myTime, format)
			} else if (msdiff < diffFromMonday) {
				format = longFormat ? 'dddd, mmmm d h:MMTT' : 'ddd'
			} else {
				format = longFormat ? 'mmmm d h:MMTT' : 'mmm d'
			}
		} else {
			format = longFormat ? 'mmmm d, yyyy h:MMTT' : 'yyyy'
		}
		formattedTime = dateFormat(myTime, format)
	} else {
		formattedTime = `TODAY ${dateFormat(myTime, format)}`
	}
	return formattedTime
}

const getFormattedTime2 = unix_timestamp => {
	let myTime			= new Date(unix_timestamp * 1000)
	let format			= 'mmmm d, yyyy h:MM tt'
	let formattedTime	= dateFormat(myTime, format)
	return formattedTime
}

const getMonday = d => {
	let date = new Date(d)
	let day = date.getDay() || 7
	if (day !== 1) date.setHours(-24 * (day - 1))
	date.setHours(0, 0, 0, 0)
	return date
}

const getShortFormattedTime = (unix_timestamp) => {
	let now = new Date()
	let myTime = new Date(unix_timestamp * 1000)
	let msdiff = now - myTime

	if (msdiff < 1200000) { // 20 minutes
		if (msdiff < 60000) { // 1 minute
			return 'now'
		}
		return  `${Math.floor(msdiff / 60000)} min`
	}

	let format = 'mmm yyyy'
	// if (msdiff < 4.32e+7) { // 12 hrs
	if (now.toDateString() === myTime.toDateString()) {
		format = 'h:MMTT'
	}
	else if (now.getFullYear() === myTime.getFullYear()) {
		format = 'mmm d'
	}
	return dateFormat(myTime, format)
}

export { getFormattedTime, getFormattedTime2, getShortFormattedTime }