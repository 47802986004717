import React, { Component } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import { DefaultArrowTooltip } from 'tooltips'
import Button from 'button'

import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

const styles = theme => ({
	recordInfo: {
		marginLeft: 10
	},
	removeButton: {
		cursor:		'pointer',
		marginLeft:	10,
		color:		'#ff000077',
		'&:hover': {
			color:	'#ff0000'
		}
	},
	audioPlayerWrapper: {
		width: 500
	},
	recordSection: {
		display:	'flex',
		alignItems:	'center',
		marginTop:	20
	},
	audioSection: {
		display:	'flex',
		alignItems:	'center',
		marginTop:	20
	}
})

class AudioRecorder extends Component {

	constructor(props) {
		super(props)
		this.state = {
			recording:	props.recording || { isRecording: false },
			isBlocked:	false
		}
	}

	componentDidMount() {
		navigator.mediaDevices.getUserMedia({ audio: true },
			() => this.setState({ isBlocked: false }),
			() => this.setState({ isBlocked: true })
		)
	}

	getRecordingDuration = () => {
		let start	= this.state.recording.start / 1000
		let end		= (this.state.recording.end || Date.now()) / 1000
		return (end - start).toFixed(2)
	}

	startRecording = async () => {
		if (this.state.isBlocked) return console.log('Permission Denied')
		try { await Mp3Recorder.start() } catch(e) {return console.error(e)}
		let recording = {
			isRecording:	true,
			start:			Date.now(),
			end:			0
		}
		this.setState({
			recording,
			readyToPlay: false
		})
		this.props.onRecorded(recording)
		this.recordingInterval = setInterval(() => {
			let recording				= this.state.recording
			recording.currentDuration	= this.getRecordingDuration()
			this.setState(recording)
			this.props.onRecorded(recording)
		}, 25)
	}

	stopRecording = async () => {

		let blob = null
		try {
			let [buffer, blob2] = await Mp3Recorder.stop().getMp3()
			blob = blob2
		} catch(e) {return console.error(e)}

		this.setState({ isRecording: false })
		clearInterval(this.recordingInterval)
		delete this.recordingInterval
		let recording			= this.state.recording
		recording.isRecording	= false
		recording.end			= Date.now()
		this.setState(recording)
		this.props.onRecorded(recording)

		this.onRecordingComplete(blob)
	}

	onRecordingComplete = blob => {
		let reader		= new FileReader
		let filename	= `recording ${Date.now()}`
		reader.onload	= async () => {
			let result				= reader.result
			let recordedAudio		= {download_link: result, filename}
			let recording			= this.state.recording
			recording.recordedAudio	= recordedAudio
			this.setState({recording})
			this.props.onRecorded(recording)
		}
		reader.readAsDataURL(blob)
	}

	removeRecordedAudio = () => {
		this.setState({recording: {isRecording: false}})
		this.props.onDelete()
	}

	toggleRecord = () => {
		if (this.state.recording && this.state.recording.isRecording) this.stopRecording()
		else this.startRecording()
	}

	renderRecordSection = () => {
		const { classes, hasError } = this.props
		let recordingDuration	= this.state.recording.currentDuration
		let isRecording			= this.state.recording && this.state.recording.isRecording
		let Icon				= isRecording ? MicOffIcon : MicIcon
		let disabled			= this.state.isBlocked
		return (
			<div className={classes.recordSection}>
				<DefaultArrowTooltip
					title		= {disabled ? 'Enable audio recording in your browser' : ''}
					placement	= 'right'
				>
					<div>
						<Button
							color		= {hasError ? 'attention' : 'primary'}
							icon		= {Icon}
							disabled	= {disabled}
							onClick		= {this.toggleRecord}
						>{isRecording ? 'Stop' : 'Start'} recording</Button>
					</div>
				</DefaultArrowTooltip>
				<span className={classes.recordInfo}>
					{this.state.recording.isRecording ? <span>Recording {recordingDuration}s</span> : null}
				</span>
			</div>
		)
	}

	renderRecordingSection = () => {
		const { classes } = this.props
		return (
			<div className={classes.audioSection}>
				<div className={classes.audioPlayerWrapper} style={{display: this.state.readyToPlay ? 'block' : 'none'}}>
					<AudioPlayer
						url				= {this.state.recording.recordedAudio.download_link}
						onError			= {this.removeRecordedAudio}
						onReadyToPlay	= {() => this.setState({readyToPlay: true})}
					/>
				</div>
				{!this.state.readyToPlay ? <LoadingBar/> :
					<DefaultArrowTooltip
						title			= 'Remove'
						placement		= 'right'
						leaveDelay		= {1}
						enterTouchDelay	= {0}
					>
						<div className={classes.removeButton} onClick={this.removeRecordedAudio}><CloseIcon/></div>
					</DefaultArrowTooltip>
				}
			</div>
		)
	}

	render() {
		return (!this.state.recording.recordedAudio ?
			this.renderRecordSection()
			: this.renderRecordingSection()
		)
	}
}

export default withStyles(styles)(AudioRecorder)