import React, { Component } from 'react'
import PDCButton from 'pdc-button'
import { xBtnGray } from 'pdc-icons'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core'

let styles = theme => ({
	addNoteDialog: {
		padding:		'40px 50px',
		boxShadow:		'5px 5px 0 0 rgba(0, 0, 0, 0.4)',
		borderRadius:	15,
		position:		'relative'
	},
	title: {
		fontSize:		24,
		letterSpacing:	-0.4,
		lineHeight:		1.25,
		marginBottom:	10
	},
	infoText: {
		fontSize:	14,
		lineHeight:	1.36,
		color:		theme.faxesApp.infoColor
	},
	content: {
		position:		'relative',
		marginTop:		25,
		border:			'0.5px solid gray',
		borderRadius:	6,
		padding:		'18px 24px',
		lineHeight:		1.71,
		outline:		'none',
		maxHeight:		500,
		overflow:		'initial',
		cursor:			'text',
		'& .error': {
			position:	'absolute',
			top:		-12,
			left:		20,
			color:		'red',
			background:	'white',
			padding:	'0 5px'
		},
		'& .characters-info': {
			position:	'absolute',
			bottom:		-11,
			right:		20,
			background:	'white',
			padding:	'0 5px',
		},
		'& textarea': {
			border:		'none',
			resize:		'none',
			outline:	'none',
			height:		114,
			width:		'100%'
		}
	},
	buttonWrapper: {
		marginTop:		30,
		display:		'flex',
		justifyContent:	'flex-end'
	},
	closeButton: {
		position:	'absolute',
		top:		28,
		right:		28,
		cursor:		'pointer'
	}
})

class AddNoteDialog extends Component {

	state = {value: '', lengthError: false}

	componentDidUpdate(prevProps) {
		if (!prevProps.note && this.props.note) this.setState({value: this.props.note.content})
		if (prevProps.note && !this.props.note) this.setState({value: ''})
	}

	onChange = e => {
		let value = e.target.value
		if (value.length > 2500) {
			this.setState({lengthError: true})
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.setState({lengthError: false}), 4000)
			value = value.substring(0, 2500)
		}
		this.setState({value})
	}

	addNote = () => {
		let note = this.props.note
		if (note) {
			this.props.addNote({id: note.id, content: this.state.value}, 'update')
		} else {
			this.props.addNote(this.state.value, 'new')
		}
		this.props.onClose()
		this.setState({value: ''})
	}

	render() {
		const { classes } = this.props
		return (
			<Dialog
				open	= {this.props.open}
				onClose	= {this.props.onClose}
				classes	= {{paper: classes.addNoteDialog}}
			>
				<img className={classes.closeButton} src={xBtnGray} alt='Close' onClick={this.props.onClose} />
				<div className={classes.title}>Include a note with your fax</div>
				<div className={classes.infoText}>Consider adding the recipient’s name, relevant contact information, 
					and any notes that may be helpful following the receipt of this fax.</div>
				<div className={classes.content} onClick={() => document.getElementById('textarea').focus()}>
					{this.state.lengthError ? <span className='error'>Max size is 2500 characters</span> : null}
					<textarea autoFocus id='textarea' value={this.state.value} onChange={this.onChange}/>
					<span className='characters-info'>{this.state.value.length} / 2500</span>
				</div>
				<div className={classes.buttonWrapper}>
					<PDCButton
						onClick		= {this.addNote}
						disabled	= {!this.state.value}
					>
						{this.props.note ? 'Update Note' : 'Add Note'}
					</PDCButton>
				</div>
			</Dialog>
		)
	}
}

export default withStyles(styles)(AddNoteDialog)