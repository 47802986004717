import {CallerInfo} from "../interfaces/CallerInfo"
import { CallEventsDelegate } from "../interfaces/CallEventsDelegate"
import { CallState } from "../enums/CallState"
import { CallManagerEvents } from "../enums/CallManagerEvents";

import CallSession from "./CallSession"
import {Click2CallManager} from "./Click2CallManager"

const CALLSESSIONTYPE = 'c2c'

export class Click2CallSession extends CallSession {
    public isMutedLocal: boolean = false;
    public isMutedRemote: boolean = false;
    isNewCall: boolean = false;
    isMerged: boolean = false;
    participants: CallerInfo[] = []
    myCallInfo: CallerInfo | null;
    callType: string
    callId: string
    callStartTime?: number
    callEndTime?: number
    callState: CallState | null = CallState.INACTIVE

    callAnswered: boolean = false

    constructor(participants: CallerInfo[], myNumber: CallerInfo, callId: string, callState: CallState) {
        super()
        this.participants = participants
        this.myCallInfo = myNumber
        this.callType = CALLSESSIONTYPE
        this.callId = callId
        this.callStartTime = Date.now() / 1000
        this.callState = callState
    }

    showCallStats(): void {}
}

