
import Cookies from 'js-cookie';
import 'whatwg-fetch';

class API {
    static getPhoneCom = () => {

        return API.getAppConfig().then(APP_CONFIG => {
            console.log(document.cookie);
            console.log(APP_CONFIG);
            console.log(APP_CONFIG.cp_session_id_cookie_name);
            let cookie = Cookies.get(APP_CONFIG.cp_session_id_cookie_name);
                 console.log(cookie);

            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
                cookie = APP_CONFIG.cp_session_id; // this is to allow local testing never put a session_id in the app.config for a deployment
            }

            if (!cookie) {
                // todo SignInRedirect();
            }
            let fetchCredentials = 'include';
            let headers = {
                    'Content-Type': 'application/json'
                };

            if(cookie){
                headers['Authorization'] = `CP ${cookie}`;
                fetchCredentials = 'omit';
            }

            let url = APP_CONFIG.get_user_info_url;
            console.log(`calling ${url}`);
            return fetch(url, {
                method: 'POST',
                body: JSON.stringify({}),
                headers: headers,
                credentials: fetchCredentials
            })
            .then((response) => {
                return response.json();
            }).catch((err) => {
                console.log(err);
            });
        });

    };


    static getAppConfig = () => {
        console.log('getting appconfig');
        if(window.APP_CONFIG){
            return window.APP_CONFIG;
        }
        return fetch('/app.config', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            return response.json()
        })
        .then(res => {
            window.APP_CONFIG = res;
            console.log(`app config ${window.APP_CONFIG}`);
            return window.APP_CONFIG;
        })
        .catch((err) => {
            console.log(err);
        });
    }

    static getUnreadCounts = (ext) => {
        let url = `${window.APP_CONFIG.v5ApiRoot}/messaging/get-unread-counts`.replace('services', 'app');
        let body = {
            account_id: window.V5PHONECOM.voip_id,
            extension_id: ext
        }
        let headers = {
            'Content-Type': 'application/json',
            Authorization: `CP ${window.V5PHONECOM.cp_token}`
        }
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers
        })
        .then(res => res.json())
        .catch(console.warn)
    }
}



export default API;