const defaultFontFamily = 'Montserrat, Helvetica, arial, sans-serif !important'
const styles = theme => ({
	wrapper: {
		position:	'relative',
		height:		'100%'
	},
	addDialog: {
		padding:		'20px 40px',
		borderRadius:	10
	},
	addDialogContent: {
		padding:		'0 !important',
		marginTop:		0,
		marginBottom:	30
	},
	addDialogFooter:	theme.confirmDialog.dialogFooter,
	loadingDiv:			theme.loadingDiv,
	dialogRoot: {
		backgroundColor: 'initial'
	},
	dialog:	{
		width:			500,
		minHeight:		500,
		borderRadius:	10,
		fontFamily:		theme.fontFamily || defaultFontFamily
	},
	dialogContent: {
		padding: '25px 33px !important'
	},
	headerSection: {
		display:		'flex',
		flexDirection:	'column',
		alignItems:		'center',
		marginBottom:	30,
		'&.edit-mode': {
			marginBottom: 14
		},
		'& .avatar-icon-wrapper': {
			position:		'relative',
			marginBottom:	10,
			'& .avatar-icon': {
				width:	85,
				height:	85
			},
			'& .cam-icon': {
				position:	'absolute',
				bottom:		-5,
				right:		-5,
				width:		39,
				height:		39
			}
		},
		'& .contact-name': {
			fontSize:		22,
			fontWeight:		600,
			lineHeight:		'30px',
			letterSpacing:	-0.2
		},
		'& .actions-wrapper': {
			display:	'flex',
			alignItems:	'center',
			'& svg': {
				width:	40,
				height:	40,
				cursor:	'pointer',
				'&:first-child': {
					marginRight: 15
				}
			},
			'& .disabled-calls-icon': {
				opacity:	0.3,
				cursor:		'not-allowed'
			}
		}
	},
	mainSection: {},
	dataSection: {
		borderRadius:	4,
		position:		'relative',
		transition:		'max-height .4s',
		maxHeight:		500,
		'&.edit-mode': {
			padding:	'8px 0 20px'
		},
		'&:not(:first-child)': {
			marginTop:	12
		},
		'&.collapsed': {
			maxHeight:		24,
			overflow:		'hidden',
			marginBottom:	30
		},
		'& > .label': {
			display:		'flex',
			alignItems:		'center',
			fontFamily:		theme.fontFamily || defaultFontFamily,
			fontSize:		16,
			fontWeight:		600,
			lineHeight:		'24px',
			letterSpacing:	-0.1,
			background:		'white',
			color:			theme.palette.tertiary[0],
			transition:		'all .4s',
			zIndex:			1,
			'&.clickable': {
				cursor:			'pointer',
				paddingRight:	0
			},
			'& .highlight': theme.pdcInput.label.active
		}
	},
	inputWrapper: {
		marginTop:	8,
		width:		'100%'
	},
	inputsGroupWrapper: {
		display: 'flex',
		'& > *:not(:last-child)': {
			marginRight: 10
		}
	},
	phoneNumberSection: {
		margin:		'6px -30px 0 0',
		display:	'flex',
		alignItems:	'flex-end',
		'&.edit-mode': {
			alignItems: 'center'
		},
		'& > div:first-child': {
			marginRight: 10
		},
		'& > div:nth-child(2)': {
			marginRight: 30
		},
		'& .remove-icon-wrapper': {
			display: 'none'
		},
		'&:hover .remove-icon-wrapper, &.small-view .remove-icon-wrapper': {
			display: 'block'
		}
	},
	emailWrapper: {
		margin:		'6px -30px 0 0',
		display:	'flex',
		alignItems:	'center',
		'& > div:first-child': {
			marginRight: 30
		},
		'& .remove-icon-wrapper': {
			display: 'none'
		},
		'&:hover .remove-icon-wrapper, &.small-view .remove-icon-wrapper': {
			display: 'block'
		}
	},
	textField: {
		'& input': {
			padding:	'8px 0 !important',
			fontFamily:	theme.fontFamily || defaultFontFamily
		},
		'& .MuiOutlinedInput-multiline': {
			padding: '8px 0 !important'
		},
		'& .MuiInputBase-adornedEnd': {
			padding: '0px !important'
		},
		'& .MuiInputLabel-root': Object.assign({
			fontFamily: theme.fontFamily || defaultFontFamily
		}, theme.pdcInput.label),
		'& .MuiInputLabel-shrink': theme.pdcInput.label.active,
		'& .MuiInput-underline:after': {
			borderBottomColor: 'black'
		}
	},
	optionText: {
		'&.custom': {
			color: 'gray'
		}
	},
	removeIconWrapper: {
		position:	'absolute',
		right:		-30,
		cursor:		'pointer',
		'&.not-allowed': {
			cursor: 'not-allowed',
			'& > svg': {
				color: theme.palette.secondary[-300]
			}
		},
		'& > svg': {
			color: theme.palette.attention[0],
			'&:hover': {
				color: theme.palette.attention['+100']
			}
		}
	},
	addButton: {
		display:	'flex',
		alignItems:	'center',
		width:		'fit-content',
		paddingTop:	30,
		cursor:		'pointer',
		color:		theme.palette.primary[0],
		'&:hover': {
			color:	theme.palette.primary['+100']
		},
		'& span': {
			fontWeight:	600,
			fontSize:	14,
			lineHeight:	'21px'
		},
		'& svg': {
			marginRight: 7
		}
	},
	addIcon: {
		fontSize:		24,
		marginRight:	10,
		transition:		'all .4s'
	},
	footer: {
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'space-between',
		marginTop:		10
	},
	buttonsGroup: {
		display: 'flex',
		'& > button:first-child': {
			marginRight: 25
		}
	},
	dropdownItemsWrapper: {
		maxHeight:	300,
		overflowY:	'auto'
	},
	expandIcon: {
		width:		16,
		height:		9,
		marginLeft:	10,
		transition:	'transform .2s',
		'&.rotate': {
			transform: 'rotate(180deg)'
		}
	},
	fieldWrapper: {
		display:		'flex',
		flexDirection:	'column',
		fontSize:		16,
		lineHeight:		'24px',
		marginBottom:	30,
		'& .title': {
			fontWeight:		600,
			marginBottom:	10
		}
	}
})

export default styles