import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import React, { Component } from "react"
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk"
import Timer from "timer"

const styles = (theme) => ({
    activeCallBar: {
		width: "100%",
		// maxHeight: "100%",
		textAlign: "center",
		color: "white",
		backgroundColor: "#5ABF46",
		position: "relative",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	postCallBar: {
		width: "100%",
		// maxHeight: "100%",
		textAlign: "center",
		color: "white",
		backgroundColor: "red",
		position: "relative",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	preCallBar: {
		width: "100%",
		// maxHeight: "100%",
		textAlign: "center",
		color: "white",
		backgroundColor: "#ebcc02",
		position: "relative",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	}
})

class CallBarActiveCallBar extends Component {
	render() {
		const { classes, currentCall, visible } =this.props
		if(!visible) return <></>
		if(currentCall.callState === 'CALL ENDED'){
			return (
				<div className={classes.postCallBar}>
					<div>
						<PhoneInTalkIcon style={{ width: 28, height: 28 }} />
					</div>
					<div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>{"Call Ended"}</div>
				</div>)
		}
		if(currentCall.callState !== 'ACTIVE'){
			return (
				<div className={classes.preCallBar}>
					<div>
						<PhoneInTalkIcon style={{ width: 28, height: 28 }} />
					</div>
					<div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>{"Call Connecting"}</div>
				</div>)
		}
		return (
			<div className={classes.activeCallBar}>
				<div>
					<PhoneInTalkIcon style={{ width: 28, height: 28 }} />
				</div>
				<div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>{"Active Call"}</div>
				<div style={{ fontSize: 12 }}>
					{currentCall && currentCall.callState === 'ACTIVE' ? (
						<Timer startTime={currentCall.callStartTime} />
					) : null}
				</div>
			</div>)
	}
}
export default withStyles(styles)(CallBarActiveCallBar)