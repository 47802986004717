import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './app-styles'
import { setSmallView, switchTab, switchView } from '../actions/people'
import { switchContact } from '../actions/users'

import ContactSelector from './selectors/ContactSelector'
import TeammateSelector from './selectors/TeammateSelector'
import ContactContent from './contents/ContactContent'
import TeammatesContent from './contents/TeammateContent'
import { AddContactIcon2 } from 'pdc-svg-icons'
import ConfirmModal from 'confirm-modal'

import PhoneComUser from 'phone-com-user'
import ResizeAware from 'react-resize-aware'
import { withStyles } from '@material-ui/styles'

let threshold = 768

const mapStateToProps = state => ({
	smallView:		state.smallView,
	currentTab:		state.currentTab,
	currentView:	state.currentView
})
const mapDispatchToProps = dispatch => ({
	setSmallView:	isSmall		=> dispatch(setSmallView(isSmall)),
	switchTab:		tab			=> dispatch(switchTab(tab)),
	switchContact:	contactId	=> dispatch(switchContact(contactId)),
	switchView:		view		=> dispatch(switchView(view))
})

class App extends Component {

	constructor(props) {
		super(props)
		this.setTabs()
		if (!this.props.currentTab) {
			let currentTab = this.getCurrentTab()
			this.props.switchTab(currentTab)
			this.setPathnameTab(currentTab)
		}
		this.state = {
			isEditingContact:		false,
			discardChangesReason:	null
		}
		if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(props.extension.extension_id)
		}
	}

	setTabs = () => {
		let tabs = [{id: 'contacts', icon: null}]
		let tabs2 = {CONTACTS: 'contacts'}
		if (this.props.extension.userId) {
			tabs.push({id: 'teammates', icon: null})
			tabs2.TEAMMATES = 'teammates'
		}
		this.tabs = tabs
		this.tabs2 = tabs2
	}

	getCurrentTab = () => {
		let currentTab = ''
		let pathnameSplit = window.location.pathname.split('/')
		if (pathnameSplit.length > 3) currentTab = pathnameSplit[3]
		if (!this.tabs.map(t => t.id).includes(currentTab)) return 'contacts'
		return currentTab
	}

	setPathnameTab = tabName => {
		let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
		window.history.replaceState(`People tab: ${tabName}`, 'People Tab Switched', `/e${extensionId}/people/${tabName}`)
	}

	componentDidMount() {
		if (this.props.currentTab) this.setPathnameTab(this.props.currentTab)
		this.props.onLoaded()
	}

	onTabSwitch = currentTab => {
		if (this.state.isEditingContact) return this.setState({discardChangesReason: currentTab})
		this.switchTab(currentTab)
	}

	switchTab = currentTab => {
		this.props.switchTab(currentTab)
		this.setPathnameTab(currentTab)
	}

	startConversation = phoneNumber => {
		let extensionId		= parseInt(window.location.pathname.split('/')[1].substring(1))
		let redirectPath	= `/e${extensionId}/messages/new-conversation/${phoneNumber}`
		this.props.redirect(redirectPath)
	}

	handleResize = size => {
		let needToExpand = this.props.smallView && ((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
		let needToShrink = !this.props.smallView && ((this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
		if (needToShrink) {
			this.props.setSmallView(true)
		} else if (needToExpand) {
			this.props.setSmallView(false)
		}
	}

	renderTabs = () => {
		const { classes, currentTab } = this.props
		let tabs2 = this.tabs2
		let tabsValues = Object.values(tabs2)
		if (tabsValues.length < 2) return null
		let placeholderPosition = tabsValues.findIndex(t => t === currentTab)
		if (placeholderPosition === 0) placeholderPosition = 'left'
		else if (placeholderPosition === tabsValues.length - 1) placeholderPosition = 'right'
		else placeholderPosition = 'center'
		return (
			<div className={classes.tabsSection}>
				<div className={classes.tabsWrapper}>
					<div onClick={() => this.onTabSwitch(tabs2.CONTACTS)} className={`tab ${currentTab === tabs2.CONTACTS ? 'selected' : ''}`}>Contacts</div>
					<div onClick={() => this.onTabSwitch(tabs2.TEAMMATES)} className={`tab ${currentTab === tabs2.TEAMMATES ? 'selected' : ''}`}>Teammates</div>
					{/* <div onClick={() => this.onTabSwitch(tabs2.BLOCK_LIST)} className={`tab ${currentTab === tabs2.BLOCK_LIST ? 'selected' : ''}`}>Block List</div> */}
				</div>
				<div className={`${classes.tabsSmallPlaceholder} ${placeholderPosition}`}></div>
				<div className={`${classes.tabsBigPlaceholder} ${placeholderPosition}`}></div>
			</div>
		)
	}

	onAddContactClick = () => {
		if (this.state.isEditingContact) return this.setState({discardChangesReason: 'add-contact'})
		this.props.switchContact('create-new-contact')
		this.props.switchView('content')
	}

	renderAddContactButton = () => {
		const { classes } = this.props
		return (
			<div
				className	= {classes.addContactButton}
				onClick		= {this.onAddContactClick}
			><AddContactIcon2/><span>Add contact</span></div>
		)
	}

	renderSelector = () => {
		const { currentTab } = this.props
		if (currentTab === this.tabs2.CONTACTS)
			return (
				<ContactSelector
					contactsUtil	= {this.props.contactsUtil}
					isEditing		= {this.state.isEditingContact}
					setEditing		= {this.setEditing}
				/>
			)
		if (currentTab === this.tabs2.TEAMMATES) return <TeammateSelector/>
	}

	setEditing = isEditingContact => {
		this.setState({isEditingContact})
		this.props.setHasChange(isEditingContact)
	}

	renderMainContent = () => {
		const { currentTab } = this.props
		if (currentTab === this.tabs2.CONTACTS)
			return (
				<ContactContent
					isVirtualExtension	= {this.props.extension.is_virtual}
					contactsUtil		= {this.props.contactsUtil}
					setEditing			= {this.setEditing}
					makeCall			= {this.props.makeCall}
					startConversation	= {this.startConversation}
					onAddContactClick	= {this.onAddContactClick}
				/>
			)
		if (currentTab === this.tabs2.TEAMMATES) return (
			<TeammatesContent
				isVirtualExtension	= {this.props.extension.is_virtual}
				makeCall			= {this.props.makeCall}
				startConversation	= {this.startConversation}
			/>
		)
		return (
			<div>Main Content</div>
		)
	}

	discardChanges = () => {
		let { discardChangesReason } = this.state
		if (discardChangesReason === 'add-contact') this.props.switchContact('create-new-contact')
		else this.switchTab(discardChangesReason)
		this.setState({discardChangesReason: null, isEditingContact: false})
	}

	renderDiscardChangesModal = () => {
		return (
			<ConfirmModal
				title			= 'You have unsaved changes'
				isShown			= {Boolean(this.state.discardChangesReason)}
				content			= 'Do you wish to continue? All changes will be lost. '
				noButtonText	= 'Cancel'
				yesButtonText	= 'Continue'
				onReject		= {() => this.setState({discardChangesReason: null})}
				onConfirm		= {this.discardChanges}
				size			= 'size550'
			/>
		)
	}

	renderLeftPanel = () => {
		const { classes }	= this.props
		let isActive		= !this.props.smallView || this.props.currentView === 'select'
		return (
			<div className={`${classes.leftPanel} ${!isActive ? 'hidden' : ''} ${this.props.smallView ? 'small-view' : ''}`}>
				<div className={classes.panelHeader}>{this.renderAddContactButton()}</div>
				<div className={classes.leftPanelContent}>
					{this.renderTabs()}
					{this.renderSelector()}
				</div>
			</div>
		)
	}

	renderRightPanel = () => {
		const { classes }	= this.props
		let isActive		= !this.props.smallView || this.props.currentView === 'content'
		return (
			<div className={`${classes.rightPanel} ${!isActive ? 'hidden' : ''}`}>
				{this.renderMainContent()}
			</div>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<ResizeAware
				style		= {{height: '100%'}}
				onResize	= {this.handleResize}
			>
				<div className={classes.wrapper2}>
					{this.renderLeftPanel()}
					{this.renderRightPanel()}
					{this.renderDiscardChangesModal()}
				</div>
			</ResizeAware>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))