import React, { Component } from "react"
import PauseIcon from "@material-ui/icons/Pause"
import PhonePausedIcon from "@material-ui/icons/PhonePaused"
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk"
import CallEndIcon from "@material-ui/icons/CallEnd"
import { withStyles } from "@material-ui/core"
import { theme } from "get-theme"
import Timer from "timer"
import { CallState } from "../../pages/communicator-app/src/enums/CallState"
const styles = (theme) => ({
	activeCallBar: {
		width: "100%",
		// maxHeight: "100%",
		textAlign: "center",
		color: "white",
		//TODO: not in palette
		backgroundColor: theme.palette.primary['0'],
		// backgroundColor: "#5ABF46",
		position: "relative",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	activeCallRow: {
		width: "100%",
		height: 65,
		zIndex: 4,
		textAlign: "center",
		color: "white",
		backgroundColor: theme.palette.tertiary['+100'],
		display: "flex",
		flexDirection: "row",
		borderTopRightRadius: 12,
		borderTopLeftRadius: 12,
		justifyContent: "space-between",
	},
	activeCallRowDesktop: {
		width: "100%",
		height: 80,
		zIndex: 4,
		textAlign: "center",
		color: "white",
		backgroundColor: theme.palette.tertiary['+100'],
		display: "flex",
		flexDirection: "row",
		borderTopRightRadius: 12,
		borderTopLeftRadius: 12,
		justifyContent: "space-between",
	},
	infoContainer: {
		marginTop: 12,
		marginLeft: 17,
		display: "flex",
		flexDirection: "row",
	},
	holdBtn: {
		backgroundColor: theme.palette.primary['-300'],
		color: theme.palette.primary['+200'],
		width: 84,
		// height: 65,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 13,
		margin: 1,
	},
	endBtn: {
		backgroundColor: theme.palette.primary['-300'],
		color: theme.palette.primary['+200'],
		width: 84,
		// height: 65,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 13,
		margin: 1,
		borderTopRightRadius: 12,
	}
})
class CallSessionSelectorActiveCall extends Component {
	endCall = async () => {
		if (this.props.currentCall) {
			//change answerbyId
			await this.props.hangupById(this.props.currentCall.callId)
			//if theres not an current call hangup the latest
		}
	}

	holdCall = () => {
		if (this.props.currentCall) {
			this.props.holdCall()
		}
	}

	render() {
		const { classes, currentCall, isMobile } = this.props
		return (
			<>
				{currentCall ? (
					<div className={isMobile ? classes.activeCallRow : classes.activeCallRowDesktop}>
						<div
							className={classes.infoContainer}
						>
							<div style={{ marginTop: 10 }}>
								<PhoneInTalkIcon style={{ width: 24, height: 24 }} />
							</div>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div style={{ marginLeft: 10, marginRight: 10, fontSize: 14 }}>
									{currentCall.participants[0].callerId || currentCall.participants[0].phoneNumber}
								</div>
								<div style={{ fontSize: 12 }}>
									{currentCall.callState}
									{currentCall.callState === 'ACTIVE' ? <Timer startTime={currentCall.callStartTime} /> : null}
								</div>
							</div>
						</div>
						{/*
						<div style={{ display: "flex", flexDirection: "row" }}>
							<div
								className={classes.holdBtn}
								onClick={this.holdCall}
							>
								<div>
									<PauseIcon />
								</div>
								<div>HOLD</div>
							</div>
							<div
								className={classes.endBtn}
								onClick={this.endCall}
								data-test-id={'end-active-call-button-session-selector'}
							>
								<div>
									<CallEndIcon />
								</div>
								<div>END</div>
							</div>
						</div>
						*/}
					</div>
				) : null}
			</>
		)
	}
}

export default withStyles(styles)(CallSessionSelectorActiveCall)
