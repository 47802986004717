import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import 'core-js/es';
import 'es6-promise/auto';

import Communicator from 'communicator';
import {theme} from "get-theme";
import ErrorCatcher from "error-catcher";
import LoginRedirector from "login-redirector";
import {CallState} from "./enums/CallState"
import CallProvider, { PdcCallConsumer } from "./PdcCallProvider"
import { CallType } from "./enums/CallType"
import {addSWEventListener, postMessageToSW, isServiceWorkerSupported} from 'service-worker-utils'
console.log(CallState.ACTIVE)
ReactDOM.render(
    <ErrorCatcher theme={theme}>
      <LoginRedirector>
        {/* cannot connect before Communicator component is done being registered */}
        <CallProvider disableAutoConnect callMode={CallType.CLK2C}>
          <PdcCallConsumer>
            {
              (context) => (<Communicator theme={theme} connect={context.connect} call={context.call} />)
            }
          </PdcCallConsumer>
        </CallProvider>
      </LoginRedirector>
    </ErrorCatcher>,
    document.getElementById('root'));

//set up un auth url redirect to sign in



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if(isServiceWorkerSupported) {
    let refreshing = false;

    serviceWorker.register()

    postMessageToSW({'getClientId': true});


    addSWEventListener('message', event => {
        console.log(event);
        if (event.data && event.data.clientId) {
            window.clientId = event.data.clientId
        }
        if (event.data && event.data.action === 'reload') {
            console.log('reloading for new service worker');

            window.location.reload()
        }
    });


// The event listener that is fired when the service worker updates
// Here we reload the page
    addSWEventListener('controllerchange', function () {
        if (refreshing) return;
        window.location.reload();
        refreshing = true;
    });
}





