export enum CallState {
    PLACING = "PLACING CALL",
    INACTIVE = "INACTIVE",
    ACTIVE = "ACTIVE",
    INCOMING = "INCOMING",
    CONNECTING = "CONNECTING",
    POSTCALL = "CALL ENDED"
}


