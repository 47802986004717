import React, { Component } from 'react'
import ProfileMenu from './ProfileMenu'
import gtmDataPush from 'gtm-events'
import ConfirmModal from 'confirm-modal'

import Navigation from 'pdc-navigation'

import {
	CallsNavIcon,
	MessagesNavIcon,
	VoicemailNavIcon,
	FaxesNavIcon,
	PeopleNavIcon,
	ControlPanelNavIcon,
	AppsNavIcon,
	SupportNavIcon,
	LogoutNavIcon,
	SettingsNavIcon,
	MeetingsNavIcon
} from 'pdc-svg-icons'
import { theme } from 'get-theme'
import { logout } from 'phonecom'
import Search from './Search'

const appTitles = {
	calls:				'Calls',
	messages:			'Messages',
	voicemail:			'Voicemail',
	faxes:				'Fax',
	controlPanel:		'Control Panel',
	configure:			'Configure',
	people:				'People',
	personalSettings:	'Personal Settings',
	meetings:			'Meetings',
	settings:			'Settings',
	apps:				'Apps',
	support:			'Support',
	logout:				'Logout'
}

class CNavigation extends Component {
	constructor(props) {
		super(props)
		this.state = {
			mobileOpen:					false,
			appBarLabel:				this.getInitialAppBarLabel(),
			discardChangesExtension:	null
		}
	}

	componentDidUpdate() {
		this.setTitle()
		this.resetAppBarLabel()
	}

	resetAppBarLabel = () => {
		let selectedAppBarLabel = this.getInitialAppBarLabel()
		if (this.state.appBarLabel !== selectedAppBarLabel) this.setState({ appBarLabel: selectedAppBarLabel })
	}

	setTitle = () => {
		let appTitle	= appTitles[this.props.currentAppName] || ''
		let appName		= theme.appName || 'My Phone.com'
		window.document.title = `${appTitle} - ${appName}`
	}

	getTabs = () => {

		let hasUserId = Boolean(this.props.userInfo.user_id)

		const callsTab = {
			testId: 'calls-tab',
			link: `/e${this.props.userInfo.extension_id}/calls`,
			icon: CallsNavIcon,
			text: appTitles.calls,
			enabled: !process.env.REACT_APP_CALLS_TAB_HIDDEN,
			unread: this.props.unreadCalls,
			active: this.props.currentAppName === 'calls'
		}

		const messageTab = {
			link: `/e${this.props.userInfo.extension_id}/messages`,
			icon: MessagesNavIcon,
			text: appTitles.messages,
			enabled: !process.env.REACT_APP_MESSAGES_TAB_HIDDEN,
			unread: this.props.unreadMessages,
			active: this.props.currentAppName === 'messages',
			testId: 'messages-tab'
		}

		const voicemailTab = {
			link: `/e${this.props.userInfo.extension_id}/voicemail`,
			icon: VoicemailNavIcon,
			text: appTitles.voicemail,
			enabled: !process.env.REACT_APP_VOICEMAIL_TAB_HIDDEN,
			unread: this.props.unreadVoicemails,
			active: this.props.currentAppName === 'voicemail',
			testId: 'voicemail-tab'
		}

		const faxesTab = {
			link: `/e${this.props.userInfo.extension_id}/faxes`,
			icon: FaxesNavIcon,
			text: appTitles.faxes,
			enabled: !process.env.REACT_APP_FAX_TAB_HIDDEN,
			unread: this.props.unreadFaxes,
			active: this.props.currentAppName === 'faxes',
			testId: 'faxes-tab'
		}
		const controlPanelTab = {
			testId: `control-panel-tab`,
			link: `${window.APP_CONFIG.cpBase}/voip/?_sid=${this.props.userInfo.cp_session_token}`,
			icon: ControlPanelNavIcon,
			text: appTitles.controlPanel,
			enabled: !hasUserId
		}
		let userRole = window.V5PHONECOM.role
		const configureTab = {
			testId: `configure-tab`,
			link: process.env.REACT_APP_CONFIGURE_APP_URL || '',
			icon: ControlPanelNavIcon,
			text: appTitles.configure,
			enabled: userRole === 'account' && hasUserId
		}

		const peopleTab = {
			link: `/e${this.props.userInfo.extension_id}/people`,
			icon: PeopleNavIcon,
			text: appTitles.people,
			enabled: true,
			active: this.props.currentAppName === 'people',
			testId: 'people-tab'
		}

		let hasVideoMeetingFeature = window.V5PHONECOM.features.has('video_meeting')
		const meetTab = {
			testId: `meet-tab`,
			link: `/e${this.props.userInfo.extension_id}/meetings`,
			icon: MeetingsNavIcon,
			text: appTitles.meetings,
			enabled: hasVideoMeetingFeature && !process.env.REACT_APP_CONTROL_PANEL_TAB_HIDDEN,
			active: this.props.currentAppName === 'meetings'
		}

		const personalSettingsTab = {
			link:		`/e${this.props.userInfo.extension_id}/personal-settings`,
			icon:		SettingsNavIcon,
			text:		appTitles.personalSettings,
			enabled:	hasUserId,
			active:		this.props.currentAppName === 'personalSettings'
		}

		const settingsTab = {
			link: `/e${this.props.userInfo.extension_id}/settings`,
			icon: SettingsNavIcon,
			text: appTitles.settings,
			enabled: !process.env.REACT_APP_SETTINGS_TAB_HIDDEN,
			active: this.props.currentAppName === 'settings'
		}

		const appsTab = {
			testId: `apps-tab`,
			link: theme.appsTabLink,
			icon: AppsNavIcon,
			text: appTitles.apps,
			enabled: !process.env.REACT_APP_APPS_TAB_HIDDEN
		}

		const supportTab = {
			testId: `support-tab`,
			link: theme.supportTabLink,
			icon: SupportNavIcon,
			text: appTitles.support,
			enabled: !process.env.REACT_APP_SUPPORT_TAB_HIDDEN
		}

		let baseUrl			= window.V5PHONECOM.v5ApiRoot.replace('services', 'app')
		let voip_id			= window.V5PHONECOM.voip_id
		let voip_phone_id	= window.V5PHONECOM.voip_phone_id
		const logoutTab = {
			testId: `support-tab`,
			link: `${baseUrl}/communicator/account/logout/?account_id=${voip_id}&extension_id=${voip_phone_id}`,
			icon: LogoutNavIcon,
			text: appTitles.logout,
			enabled: !process.env.REACT_APP_LOGOUT_TAB_HIDDEN
		}

		let routerConfig = [
			callsTab,
			messageTab,
			voicemailTab,
			faxesTab,
			/*peopleTab,
			meetTab,
			personalSettingsTab,
			settingsTab,*/
			controlPanelTab,
			//appsTab,
			configureTab,
			supportTab,
			logoutTab
		]
		// if (window.V5PHONECOM.features.has('fax')){
		//     routerConfig = [messageTab, voicemailTab, faxesTab, controlPanelTab]
		// }
		return routerConfig
	}

	getInitialAppBarLabel = () => {
		let routerConfig = this.getTabs()
		for (let index in routerConfig) {
			let route = routerConfig[index]
			if (window.location.pathname.indexOf(route['link']) !== -1) {
				return route['text']
			}
		}
		return 'Calls'
	}

	handleDrawerToggle = () => {
		let { isMobileView, isTabletView } = this.props.screenViewType
		if (isMobileView || isTabletView) this.setState({ mobileOpen: !this.state.mobileOpen })
	}

	extensionSelectHandler = extension => {
		let { isMobileView, isTabletView } = this.props.screenViewType
		if ((isMobileView || isTabletView) && this.state.mobileOpen) this.handleDrawerToggle()
		if (this.props.appHasChange) return this.setState({discardChangesExtension: extension})
		this.props.onExtensionSwitch(extension)
	}

	onMenuChange = route => {
		if (!this.checkPopout(route)) this.setState({ appBarLabel: route.text })
		// analythics
		console.log('theRoute', route)
		gtmDataPush({
			event: 'PDC_Category_Change',
			PDC_Action: route.text.toLowerCase(),
		})
	}

	checkPopout = route => {
		if (route.link.indexOf('https') !== -1) {
			if (route.text === 'Logout') logout()
			else window.open(route.link, route.text)
			return true
		}
		return false
	}

	renderSearch = () => {
		if (!process.env.REACT_APP_IS_SEARCH_DISABLED || window.V5PHONECOM.features.has('search'))
		return <Search goTo={this.props.goTo} isMobile={this.props.screenViewType.isMobileView} setMobileDrawerOpen={this.handleDrawerToggle}/>
	}

	renderNavHeader = () => (
		<>
			<ProfileMenu
				userInfo				= {this.props.userInfo}
				currentUser				= {this.props.currentUser}
				extensionSelectHandler	= {this.extensionSelectHandler}
				screenViewType			= {this.props.screenViewType}
				logout					= {this.props.logout}
			/>
			{this.renderSearch()}
		</>
	)

	discardChanges = () => {
		let extension = this.state.discardChangesExtension
		this.setState({discardChangesExtension: null})
		this.props.onExtensionSwitch(extension)
		this.props.discardChanges()
	}

	renderDiscardChangesModal = () => {
		return (
			<ConfirmModal
				title			= 'You have unsaved changes'
				isShown			= {Boolean(this.state.discardChangesExtension)}
				content			= 'Do you wish to continue? All changes will be lost. '
				noButtonText	= 'Cancel'
				yesButtonText	= 'Continue'
				onReject		= {() => this.setState({discardChangesExtension: null})}
				onConfirm		= {this.discardChanges}
				size			= 'size550'
			/>
		)
	}

	render() {
		return (
			<>
				<Navigation
					screenViewType		= {this.props.screenViewType}
					container			= {this.props.container}
					appBarLabel			= {this.state.appBarLabel}
					mobileOpen			= {this.state.mobileOpen}
					appHasChange		= {this.props.appHasChange}
					tabs				= {this.getTabs()}
					renderNavHeader		= {this.renderNavHeader}
					onMenuChange		= {this.onMenuChange}
					handleDrawerToggle	= {this.handleDrawerToggle}
					discardChanges		= {this.props.discardChanges}
				>{this.props.children}</Navigation>
				{this.renderDiscardChangesModal()}
			</>
		)
	}
}

export default CNavigation