import React, { Component } from 'react'
import { connect } from 'react-redux'
import { switchFax, updateFaxLinks, updateFax, removeContactFromFaxes, addContactsToFaxes } from '../actions/faxes'
import { switchView } from '../actions/view'
import FaxHeader from './FaxHeader'
import ParticipantsModal from 'participants-modal'
import EditContactModal from 'edit-contact-modal'
import PDF from 'pdf'
import api from '../util/api_v5'
import Spinner from 'spinner'
import { getFormattedTime2 } from 'time-format'
import { formatPhoneNumber } from 'phone-numbers'
import downloadIcon from '../images/icon-received-000.svg'
import sentIcon from '../images/icon-sent-blk.svg'
import failedIcon from '../images/icon-alert-000.svg'
import { withStyles } from '@material-ui/core'

const mapStateToProps = state => ({
	faxes:			state.faxes,
	currentFax:		state.currentFax,
	smallView:		state.smallView,
	faxLinks:		state.faxLinks
})

const mapDispatchToProps = dispatch => ({
	switchFax:				fax			=> dispatch(switchFax(fax)),
	switchView:				view		=> dispatch(switchView(view)),
	updateFaxLinks:			links		=> dispatch(updateFaxLinks(links)),
	updateFax:				fax			=> dispatch(updateFax(fax)),
	addContactsToFaxes:		contacts	=> dispatch(addContactsToFaxes(contacts)),
	removeContactFromFaxes:	contactId	=> dispatch(removeContactFromFaxes(contactId))
})

const styles = theme => ({
	faxContent: {
		width:			'100%',
		display:		'flex',
		flexDirection:	'column',
		position:		'relative'
	},
	mainContent: {
		padding:		20,
		flex:			1,
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'center',
		height:			'100%',
		overflow:		'scroll',
		'&.empty': {
			alignItems:	'center'
		}
	},
	spinnerWrapper:{
		display: 'table',
		margin: '0 auto'
	},
	faxInfoBar: {
		width:		'100%',
		padding:	'5px 20px',
		display:	'flex',
		alignItems:	'center',
		boxShadow:	theme.palette.primary.flatBottomShadow,
		color:		'black'
	},
	dateTimeInfo: {
		flex:				1,
		display:			'flex',
		justifyContent:		'space-between',
		alignItems:			'center',
		paddingRight:		15,
		borderRight:		'1px solid',
		borderRightColor:	theme.faxesApp.infoBorderColor,
		minWidth:			'fit-content',
		lineHeight:			1.36,
		'& img': {
			width:		24,
			marginLeft:	10
		}
	},
	contactInfo: {
		paddingLeft:	15,
		color:			theme.faxesApp.contactInfoColor,
		lineHeight:		1.36
	}
})

class FaxContent extends Component {

	state = {
		editContact:			null,
		hoverOverParticipants:	false
	}

	componentDidMount() {
		this._ismounted = true
		this.getFaxLink()
	}

	componentWillUnmount() {
		this._ismounted = false
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentFax && (!prevProps.currentFax || prevProps.currentFax.id !== this.props.currentFax.id)) this.getFaxLink()
		this.updateEditContactIfNeeded(prevProps)
	}

	updateEditContactIfNeeded = prevProps => {
		if (prevProps.extraContacts.length === this.props.extraContacts.length || !this.state.editContact || this.state.editContact.id) return
		let editContact	= this.state.editContact
		let number		= editContact.number
		let contactId	= null
		this.props.extraContacts.forEach(cd => {
			if (cd.numbers.find(n => n.number === number)) contactId = cd.id
		})
		if (!contactId) return
		editContact.id = contactId
		this.setState({editContact})
	}

	getFaxLink() {
		let fax = this.props.currentFax
		if (fax && fax.id && !this.props.faxLinks[fax.id]) {
			api.getFaxLink(fax.id).then(url => {
				let faxLinks = this.props.faxLinks
				if (url === null) url = 'n/a'
				faxLinks[fax.id] = url
				if (this._ismounted) this.props.updateFaxLinks(faxLinks)
			})
		}
	}

	startConversation = phoneNumber => {
		if (!phoneNumber) phoneNumber = this.props.currentFax.fromNumber
		let extensionId		= parseInt(window.location.pathname.split('/')[1].substring(1))
		let redirectPath	= `/e${extensionId}/messages/new-conversation/${phoneNumber}`
		this.props.redirect(redirectPath)
	}

	formatFromContactInfo = () => {
		let fax = this.props.currentFax
		let info = [fax.from.from || fax.from.name, fax.from.company, formatPhoneNumber(fax.from.number || fax.from.phone)]
		info = info.filter(i => i)
		return info.join(' – ')
	}

	editContact = (id, number, prefillData) => {
		this.setState({editContact: {id, number: id ? '' : number, prefillData}})
	}

	getContact = () => {
		let extraContacts	= this.props.extraContacts
		let editContact		= this.state.editContact
		let contactId		= editContact ? editContact.id : null
		if (!editContact || !contactId || !extraContacts) return null
		let contact			= null
		this.props.extraContacts.forEach(c => c.id === contactId ? contact = c : null)
		return contact
	}

	saveContact = contact => {
		let extraContacts	= this.props.extraContacts
		let isNew			= !Boolean(extraContacts.find(c => c.id === contact.id))
		this.props.updateContact(contact)
		if (isNew) this.props.addContactsToFaxes([contact])
		this.setState({editContact: null})
	}

	deleteContact = contactId => {
		this.props.deleteContact(contactId)
		this.setState({editContact: null})
	}

	toggleParticipantsHover = hoverOverParticipants => {
		if (this.state.hoverOverParticipants !== hoverOverParticipants) {
			this.setState({hoverOverParticipants})
		}
	}

	renderAllParticipants = () => {
		let currentFax		= this.props.currentFax
		let myNumber		= {number: currentFax.direction === 'out' ? currentFax.from.number : currentFax.to.number}
		let otherNumber		= {number: currentFax.direction === 'out' ? currentFax.to.number : currentFax.from.number, name: ''}
		let extraContacts	= this.props.extraContacts
		extraContacts.forEach(c => {
			if (otherNumber.name) return
			let contactNumbers = c.numbers.map(n => n.number)
			if (contactNumbers.includes(otherNumber.number)) {
				otherNumber.name		= c.name.display
				otherNumber.contactId	= c.id
			}
		})
		let theOtherParticipant = [currentFax.direction === 'out' ? 'to' : 'from']
		if (!otherNumber.name && currentFax[theOtherParticipant].name) {
			otherNumber.name = currentFax[theOtherParticipant].name
		}
		if (currentFax[theOtherParticipant].city || currentFax[theOtherParticipant].state) {
			otherNumber.address = [currentFax[theOtherParticipant].city, currentFax[theOtherParticipant].state]
		}
		let extensionPhoneNumbers = this.props.extension.phone_number
		if (extensionPhoneNumbers[myNumber.number]) myNumber.numberNickname = extensionPhoneNumbers[myNumber.number].name

		return (
			<ParticipantsModal
				selectedNumber		= {myNumber.number}
				myNumbers			= {[myNumber]}
				otherNumbers		= {[otherNumber]}
				participantsHovered	= {this.state.hoverOverParticipants}
				editContact			= {this.editContact}
				changeNumber		= {() => {}}
				makeCall			= {this.props.makeCall}
				extension			= {this.props.extension}
			/>
		)
	}

	render() {

		let { classes }	= this.props
		let currentFax	= this.props.currentFax
		if (!currentFax) return ''

		return (
			<div className={classes.faxContent}>
				<FaxHeader
					deleteFax				= {this.props.deleteFax}
					changeReadStatus		= {this.props.changeReadStatus}
					startConversation		= {this.startConversation}
					smallView				= {this.props.smallView}
					toggleParticipantsHover	= {this.toggleParticipantsHover}
					extraContacts			= {this.props.extraContacts}
				/>

				{this.renderAllParticipants()}

				<div className={classes.faxInfoBar}>
					<div className={classes.dateTimeInfo}>
						<span>{getFormattedTime2(this.props.currentFax.created_at, true)}</span>
						{/* <a href={this.props.faxLinks[currentFax.id]} target='_blank'> */}
						<img src={currentFax.direction === 'in' ? downloadIcon
									: currentFax.delivery_status === 'failed' ? failedIcon : sentIcon} alt='Download' />
						{/* </a> */}
					</div>
					<div className={classes.contactInfo}>
						{currentFax.direction === 'in' ? formatPhoneNumber(currentFax.to.number) : this.formatFromContactInfo()}
					</div>
				</div>

				<div className={`${classes.mainContent} ${this.props.faxLinks[currentFax.id] === 'n/a' ? 'empty' : ''}`}>
					{(this.props.faxLinks[currentFax.id])?
						this.props.faxLinks[currentFax.id] === 'n/a' ?
							'No fax preview available.':
						<PDF url={this.props.faxLinks[currentFax.id]}>{console.log(this.props.faxLinks[currentFax.id])}</PDF>

					: <div className={classes.spinnerWrapper}><Spinner/></div>}
				</div>

				<EditContactModal
					type				= {this.state.editContact ? this.state.editContact.id ? 'Edit' : 'Add' : false}
					onClose				= {() => this.setState({editContact: null})}
					fixedNumber			= {this.state.editContact ? this.state.editContact.number : null}
					prefillData			= {this.state.editContact ? this.state.editContact.prefillData : null}
					contact				= {this.getContact()}
					contactGroupTypes	= {this.props.contactGroupTypes}
					saveContact			= {this.saveContact}
					deleteContact		= {this.deleteContact}
					makeCall			= {this.props.makeCall}
					startConversation	= {this.startConversation}
					isVirtualExtension	= {this.props.extension.is_virtual}
					smallView			= {this.props.smallView}
				/>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FaxContent))