import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

class API {
	static loadContacts = async (filters={}, limit=20, cursor=null) => {
		await getPhoneCom()
		let data = {filters, limit}
		if (cursor) data.cursor = cursor
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/load-contacts`
		let response = await ajax.post(requestUrl, data)

		if (response.message === 'Network Error') return 'network-error'

		return response.data || {
			total:			0,
			limit:			limit,
			items:			[],
			group_types:	[]
		}
	}

	static createContact = async data => {
		await getPhoneCom()
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/create-contact`
		return (await ajax.post(requestUrl, data)).data
	}

	static updateContact = async data => {
		await getPhoneCom()
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/update-contact`
		return (await ajax.post(requestUrl, data)).data
	}

	static deleteContact = async contact_id => {
		await getPhoneCom()
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/delete-contact`
		return await ajax.post(requestUrl, {contact_id})
	}

	static listInboxes = async (filters, type='all', cursor=null, limit=25, sort={created_at: 'desc'}, fullObject=null) => {
		let url = `${PhoneComUser.getv5ApiRoot()}/inbox/list-inbox`
		let headers = {'Content-Type': 'application/json'}
		headers['Authorization'] = `CP ${window.V5PHONECOM.cp_token}`
		let fetchCredentials = 'omit'
		let data = {
			account_id: window.V5PHONECOM.voip_id,
			extension_id: window.V5PHONECOM.voip_phone_id,
			type,
			filters,
			limit,
			sort
		}
		if (cursor) data.cursor = cursor
		if (fullObject === true) data.full_object = true

		const itemsFetched = await fetch(url, {
			method:			'POST',
			headers:		headers,
			credentials:	fetchCredentials,
			body:			JSON.stringify(data)
		})

		const itemsJson = await itemsFetched.json()
		return itemsJson
	}

	// Voicemail

	static getMusicOnHoldLink = voip_recording_id => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/greetings/get-music-on-hold-link`
			return ajax.post(requestUrl, {voip_recording_id}).then(response => response.data)
		})
	}

	static createFileGreeting = (name, file) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
			return ajax.post(requestUrl, {name, file}).then(response => response.data)
		})
	}

	static createFileMusicOnHold = (name, file) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-music-on-hold`
			return ajax.post(requestUrl, {name, file}).then(response => response.data)
		})
	}

	static createTTSGreeting = (tts_text, tts_voice, name) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
			return ajax.post(requestUrl, {origin: 'tts', tts_text, tts_voice, name}).then(response => response.data)
		})
	}

	static createTTSMusicOnHold = (tts_text, tts_voice, name) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-music-on-hold`
			return ajax.post(requestUrl, {origin: 'tts', tts_text, tts_voice, name}).then(response => response.data)
		})
	}

	static getVoicemailConfig = () => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/get-ext-vm-config`
			return ajax.post(requestUrl, {}).then(response => response.data)
		})
	}

	static configureVoicemail = voip_recording_id => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/configure-voicemail`
			return ajax.post(requestUrl, {voip_recording_id}).then(response => response.data)
		})
	}

	static sendMessage = payload => {
		return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/send-message', payload)
			.then(response => response)
	}
	
	static registerSipDevice = async () => {
		await getPhoneCom()
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/register-sip-device`
		return await ajax.post(requestUrl, {})
	}
}

export default API 