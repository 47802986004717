import React, { Component } from 'react'

import { withRouter } from 'react-router-dom'

import AvatarIcon from 'avatar-icon'
import { XIcon, ChevronRight } from 'pdc-svg-icons'
import Button from 'button'
import ConfirmModal from 'confirm-modal'
import { logout } from 'phonecom'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography/Typography'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import AppBar from '@material-ui/core/AppBar/AppBar'
import { withStyles } from '@material-ui/core/styles'

import { theme } from 'get-theme'

const styles = theme => ({
	root: {
		display:	'flex',
		position:	'relative',
		height:		'100%',
		minHeight:	0
	},
	drawableContent: {
		display:		'flex',
		flexDirection:	'column',
		height:			'100%'
	},
	drawerWrapper: {
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'space-between',
		height:			'100%',
		overflowY:		'auto'
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: theme.drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: theme.appBar,
	menuButton: {
		marginRight:	theme.spacing(2),
		outline:		'none !important'
	},
	toolbar: {
		minHeight: 56
	},
	drawerPaper: theme.drawerPaper,
	content: {
		height:		'100%',
		maxWidth:	'100%',
		flexGrow:	1,
		'&:not(.mobile)': {
			maxWidth: `calc(100% - ${theme.drawerPaper.width}px)`
		}
	},
	drawerList: {
		paddingLeft: '10px !important',
		paddingRight: '10px !important'
	},
	drawIcon: {
		color: theme.drawerPaper.color,
		width: 35
	},
	drawerItem: {
		marginBottom: '2px !important',
		'&:not(.active):hover': {backgroundColor: 'rgba(136, 150, 158, 0.35)'},
		'&.active': {backgroundColor: 'rgba(136, 150, 158, 0.8)'},
		'&.separate': {marginTop: 42}
	},
	disabledLabel: {
		marginTop: '-10px',
		fontSize: theme.tinyText.fontSize,
		color: theme.disabledLabel.color
	},
	appLinkStyle: {
		textDecoration: 'none',
		color: theme.drawerPaper.color,
		'&:hover': {
			textDecoration: 'none',
			color: theme.drawerPaper.color,
		}
	},
	unreadIcon: {
		backgroundColor: theme.notificationDotColor,
		width: '10px',
		height: '10px',
		borderRadius: '50%'
	},
	ghostPDCIcon: theme.navigation.ghostIcon,
	avatarWrapper: {
		marginLeft:	'auto',
		cursor:		'pointer'
	},
	statusDrawerStyle: {
		borderTopRightRadius:	20,
		borderTopLeftRadius:	20
	},
	statusDrawer: {
		display:		'flex',
		flexDirection:	'column',
		height:			'100%'
	},
	statusDrawerHeader: {
		display:		'flex',
		alignItems:		'center',
		width:			'100%',
		justifyContent:	'center',
		alignItems:		'center',
		height:			130,
		borderBottom:	'1px solid #DEE1E3',
		position:		'relative'
	},
	statusDrawerItem: {
		display:		'flex',
		height:			70,
		borderBottom:	`1px solid ${theme.palette.secondary['-200']}`,
		alignItems:		'center',
		justifyContent:	'space-between',
		cursor:			'pointer',
		'&:hover': {
			background: theme.palette.primary['-300']
		},
		'& .text':{
			paddingLeft:	40,
			fontWeight:		600,
			fontSize:		'16px',
			lineHeight:		'24px'
		},
		'& .icon':{
			paddingRight:	40
		},
		'& svg': {
			color: '#85929A'
		}
	},
	statusDrawerFooter: {
		display:		'flex',
		width:			'100%',
		justifyContent:	'space-around',
		alignItems:		'center',
		minHeight:		130
	},
	xIcon: {
		position: 'absolute',
		right: 26, 
		top: 26,
		cursor: 'pointer',
		color: theme.palette.secondary['0']
	}
})

class Navigation extends Component {

	constructor(props) {
		super(props)
		this.state = {
			openStatusDrawer:	false,
			discardChangesData:	null
		}
	}

	renderAppBar = () => {
		const { classes } = this.props
		let { isMobileView, isTabletView } = this.props.screenViewType
		if (!isMobileView && !isTabletView) return null
		return (
			<AppBar className={classes.appBar}>
				<Toolbar classes={{ root: classes.toolbar }}>
					<IconButton
						color='inherit'
						aria-label='Open drawer'
						edge='start'
						onClick={this.props.handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant='h6' noWrap>
						{this.props.appBarLabel}
					</Typography>
					{this.props.showAvatar
						?
							<div data-navbar='avatar' onClick={()=>this.setState({openStatusDrawer: true})} className={classes.avatarWrapper}>
								<AvatarIcon
									imageUrl	= {window.V5PHONECOM.avatar_url ? window.V5PHONECOM.avatar_url : null}
									height		= {35}
									width		= {35}
									type		= {'available'}
									noBorder	= {true}
								/>
							</div>
						:
							null
					}
					
				</Toolbar>
			</AppBar>
		)
	}

	renderUserStatusDrawerContent = () => {
		const { classes } = this.props

		return (
			<div data-drawer='status' className={classes.statusDrawer}>
				<div className={classes.statusDrawerHeader}>
					<XIcon onClick={()=>{this.setState({openStatusDrawer: false})}} className={classes.xIcon}/>
					<AvatarIcon
						imageUrl	= {window.V5PHONECOM.avatar_url ? window.V5PHONECOM.avatar_url : null}
						type		= {'available'}
						noBorder	= {true}
						opacity		= {1}
					/>
				</div>
					<div className={classes.statusDrawerItem}>
						<div className='text'>
							My Settings
						</div>
						<div className='icon'>
							<ChevronRight/>
						</div>
					</div>
					<div className={classes.statusDrawerItem}>
					<div className='text'>
						Help
					</div>
					<div className='icon'>
						<ChevronRight/>
					</div>
				</div>
				<div className={classes.statusDrawerFooter}>
					<Button 
						onClick={() => {logout()}} 
						data-button='sign-out' 
						variant='outlined' 
						icon='logout'
						color='primary'>Sign out
					</Button>

				</div>
			</div>
		)

	}

	renderUserStatusDrawer = () => {
		const { classes } = this.props

		return <SwipeableDrawer
					classes={{paperAnchorBottom: classes.statusDrawerStyle}}
					anchor='bottom'
					open={this.state.openStatusDrawer}
					onClose={()=>this.setState({openStatusDrawer: false,})}
					onOpen={()=>this.setState({openStatusDrawer: true})}
				>
					{this.renderUserStatusDrawerContent()}
				</SwipeableDrawer>
	}

	renderMobileDrawer = () => {
		const { classes } = this.props
		return (
			<div>
				<SwipeableDrawer
					onOpen		= {this.props.handleDrawerToggle}
					container	= {this.props.container}
					variant		= 'temporary'
					anchor		= {theme.direction === 'rtl' ? 'right' : 'left'}
					open		= {this.props.mobileOpen}
					onClose		= {this.props.handleDrawerToggle}
					classes		= {{paper: classes.drawerPaper}}
					// Better open performance on mobile.
					ModalProps	= {{keepMounted: true}}
				>
					{this.renderDrawerContent()}
				</SwipeableDrawer>
			</div>
		)
	}

	renderDrawerContent = () => {
		const { classes } = this.props
		return (
			<div className={classes.drawableContent}>
				{this.props.renderNavHeader()}
				{this.renderDrawerTabs()}
			</div>
		)
	}

	renderDrawerTabs = () => {
		const { classes } = this.props
		return (
			<div className={classes.drawerWrapper}>
				<List className={classes.drawerList}>
					{this.props.tabs.map((route, index) => {
						if (!route.enabled) return
						let to = route.link.indexOf('http') === -1 ? route.link : '#'
						let activeClass = route.active ? 'active' : ''
						let separateClass = route.text === 'Control Panel' ? 'separate': ''
						let Icon = route.icon
						return (
							<div
								data-test-id={route.testId}
								key={route.text}
								onClick={() => this.handleMenuChange(route, to)}
								className={classes.appLinkStyle}
							>
								<ListItem button className={`${classes.drawerItem} ${activeClass} ${separateClass}`}>
									<ListItemIcon>
										<Icon
											active={route.active ? 1 : 0}
											read={!route.unread ? 1 : 0}
											className={classes.drawIcon}
										/>
									</ListItemIcon>

									<ListItemText primary={route.text} />
									{!route.enabled && (
										<span className={classes.disabledLabel}>
											Coming Soon!
										</span>
									)}
								</ListItem>
							</div>
						)
					})}
				</List>
				<div className={classes.ghostPDCIcon} />
			</div>
		)
	}

	handleMenuChange = (route, to) => {
		if (!route.enabled) return
		if (this.props.appHasChange) return this.setState({discardChangesData: {route, to}})
		this.goToRoute(route, to)
	}

	goToRoute = (route, to) => {
		this.props.history.push(to)
		this.props.handleDrawerToggle()
		this.props.onMenuChange(route)
	}

	renderDesktopDrawer = () => {
		const { classes } = this.props
		return (
			<div>
				<div style={{width: theme.drawerWidth}} />
				<SwipeableDrawer
					classes={{paper: classes.drawerPaper}}
					variant='permanent'
					onOpen={() => {}}
					onClose={() => {}}
					open
				>
					{this.renderDrawerContent()}
				</SwipeableDrawer>
			</div>
		)
	}

	discardChanges = () => {
		let { route, to } = this.state.discardChangesData
		this.setState({discardChangesData: null})
		this.props.discardChanges()
		this.goToRoute(route, to)
	}

	renderDiscardChangesModal = () => {
		return (
			<ConfirmModal
				title			= 'You have unsaved changes'
				isShown			= {Boolean(this.state.discardChangesData)}
				content			= 'Do you wish to continue? All changes will be lost. '
				noButtonText	= 'Cancel'
				yesButtonText	= 'Continue'
				onReject		= {() => this.setState({discardChangesData: null})}
				onConfirm		= {this.discardChanges}
				size			= 'size550'
			/>
		)
	}

	render() {
		const { classes } = this.props
		let { isMobileView, isTabletView } = this.props.screenViewType
		let isSmallView = isMobileView || isTabletView
		return (
			<div className={classes.root}>
				{this.renderAppBar()}
				{isSmallView ? this.renderMobileDrawer() : this.renderDesktopDrawer()}
				{/* {isSmallView ? this.renderUserStatusDrawer() : null} */}
				<div className={`${classes.content} ${isSmallView ? 'mobile' : ''}`}>
					{this.props.children}
				</div>
				{this.renderDiscardChangesModal()}
			</div>
		)
	}
}

export default withStyles(styles)(withRouter(Navigation))