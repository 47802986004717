import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const styles = theme => ({
	tabsWrapper: {
		display:		'flex',
		flexDirection:	'row',
		minHeight:		80,
		background:		'#DCF3D7',
		width:			'100%',
		overflowX:		'auto',
		overflowY:		'hidden',
		zIndex:			10,
		'&.small-view': {
			minHeight: 50
		}
	},
	tab: {
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'center',
		height:			80,
		minWidth:		220,
		color:			'#FFFFFF',
		fontWeight: 	'bold',
		fontSize:		16,
		lineHeight:		'22px',
		background:		'#3FAE29',
		cursor:			'pointer',
		'&.small-view': {
			minWidth:		1,
			height:			50,
			width:			'50%',
			fontSize:		14,
			lineHeight:		'21px',
		},
		'&.active': {
			color:		'#000000',
			background:	'#FFFFFF',
		},
		'&:hover': {
			'&:not(.active)': {
				
			}
		}
	}
})

class HeaderTabs extends Component {

	constructor(props) {
		super(props)
		let selectedTabId = this.props.selectedTabId || this.props.tabs[0].id
		this.state = {
			tabs: props.tabs.map(t => ({
				id:			t.id,
				text:		this.makeTabName(t.id),
				icon:		t.icon,
				onClick:	() => this.onTabClick(t.id),
				active:		t.id === selectedTabId
			}))
		}
		this.tabsWrapperRef = React.createRef()
	}

	componentDidMount() {
		let selectedTabId = this.props.selectedTabId
		if (!selectedTabId) return
		let tabsWrapperElement = this.tabsWrapperRef.current
		let selectedTabElement = Array.from(tabsWrapperElement.children).find(c => c.getAttribute('id') === `header-tab-${selectedTabId}`)
		if (!selectedTabElement) return
		tabsWrapperElement.scrollLeft = selectedTabElement.offsetLeft
	}

	componentDidUpdate(prevProps) {
		if (this.props.selectedTabId && (!prevProps.selectedTabId || prevProps.selectedTabId !== this.props.selectedTabId)) {
			let tabs = this.state.tabs
			tabs.forEach(t => t.active = t.id === this.props.selectedTabId)
			this.setState({tabs})
		}
	}

	makeTabName = t => {
		let tabName = ''
		let array =  t.split('-')
		array.forEach((value, key) => {
			tabName += value.charAt(0).toUpperCase() + value.slice(1);
			if (key+1 != array.legth){
				tabName += ' '
			}
		})
		return tabName		
	}
	

	onTabClick = tabId => {
		let tabs = this.state.tabs
		let stop = this.props.onTabSwitch(tabId) === false
		if (stop) return
		tabs.forEach(tab => tab.active = tab.id === tabId)
		this.setState({tabs: [...tabs]})
	}

	render() {
		const { classes } = this.props
		return (
			<div ref={this.tabsWrapperRef} className={`${classes.tabsWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
				{this.state.tabs.map((tab, i) => {
					let Icon = tab.icon
					return (
						<div
							key				= {tab.id}
							id				= {`header-tab-${tab.id}`}
							onClick			= {tab.onClick}
							className		= {`${classes.tab} ${tab.active ? 'active' : ''} ${this.props.smallView ? 'small-view' : ''}`}
							data-header-tab	= {tab.id}
						>
							{(!this.props.smallView && Icon) ? <Icon style={{fontSize: 50}} color={tab.active ? 'black' : 'white'}/> : null}
							{tab.text}
						</div>
					)
				})}
			</div>
		)
	}
}

export default withStyles(styles)(HeaderTabs)