import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	offlineBar: {
		width:			'100%',
		height:			'fit-content',
		padding:		10,
		background:		'#8a8a8a',
		color:			'#fff',
		fontWeight:		'bold',
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center',
		textAlign:		'center',
		zIndex:			3
	}
})

class OfflineBar extends Component {
	render = () => <div className={this.props.classes.offlineBar}>You're offline. Check your connection</div>
}

export default withStyles(styles)(OfflineBar)