import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import {getPhoneCom} from 'phonecom'
class API {
	static loadVoicemails = async (offset, limit, filters, sort) => {
		let requestUrl		= `${PhoneComUser.getv5ApiRoot()}/voicemails/list-voicemail`
		let response		= await ajax.post(requestUrl, {filters, sort, limit, offset})
		if (response.message === 'Network Error') return 'network-error'
		return response.data
	}

	static getVoicemailLink = uuid => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/get-voicemail-link`
		return ajax.post(requestUrl, {uuid, extension: 'mp3'}).then(response => response.data)
	}

	static isVoicemailConfigured = () => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/is-voicemail-configured`
			return ajax.post(requestUrl, {account_id: phonecom.voip_id, extension_id: phonecom.voip_phone_id}).then(response => response.data.voicemail_configured_setup)
		})
	}

	static markVoicemailRead = (id, isNew=false) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/voicemails/mark-voicemail-read`
		return ajax.post(requestUrl, {voicemail_id: id, is_new: isNew}).then(response => !response.data.errorMessage)
	}

	static deleteVoicemail = id => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/voicemails/delete-voicemail`
		return ajax.post(requestUrl, {voicemail_id: id}).then(response => !response.data.errorMessage)
	}
}

export default API